//lib
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { useTranslation } from 'react-i18next'

//redux
import { useSelector } from 'react-redux'

export default function Metrisc() {
  const { t } = useTranslation()
  const { metricsData } = useSelector((state) => state.profile)
  return (
    <MDBCol className="metrics-container">
      <MDBRow className="header-container text-start m-0">
        <label>{t(`Metrics`)}</label>
        <span>{t(`History Trading Performance`)}</span>
      </MDBRow>
      <MDBRow className="metrics-box-container m-0">
        <MDBCol className="col-3">
          <div className="metrics-box-container box">
            <MDBCol className="title">
              <label>{t(`Profit / Loss`)} (%)</label>
            </MDBCol>
            <MDBCol
              className={
                metricsData.totalPnl > 0
                  ? 'price green'
                  : metricsData.totalPnl < 0
                  ? 'price red'
                  : 'price'
              }>
              <label>
                {metricsData.totalPnl == '--'
                  ? metricsData.totalPnl
                  : Math.round(metricsData.totalPnl * 100) / 100}
              </label>
            </MDBCol>
            <MDBCol className="date">
              <label>{t(`Past 30 days`)}</label>
            </MDBCol>
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="metrics-box-container box">
            <MDBCol className="title">
              <label>{t(`Volume`)} ($)</label>
            </MDBCol>
            <MDBCol
              className={
                metricsData.volume > 0
                  ? 'price green'
                  : metricsData.volume < 0
                  ? 'price red'
                  : 'price'
              }>
              <label>
                {metricsData.volume == '--'
                  ? metricsData.volume
                  : Math.round(metricsData.volume * 100) / 100}
              </label>
            </MDBCol>
            <MDBCol className="date">
              <label>{t(`Past 30 days`)}</label>
            </MDBCol>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  )
}
