import axios from 'axios'
import { toast } from 'react-toastify'
import web3Services from './web3.services'

// import logger from "../services/log.services";
axios.interceptors.response.use(null, (error) => {
  const authError = error.response && error.response.status == 401
  const exptectedError = error.response && error.response.status >= 404
  const unexpectedError = error.response.status == 500

  if (authError) {
    // localStorage.clear()
    web3Services.clearLocalStorage()

    // return (window.location = "/login");
  }

  if (unexpectedError) {
    // eslint-disable-next-line default-case
    // logger.log(error);
    toast.error('An unexpected error occurred @contact support', {
      theme: 'colored',
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
    })
  }
    //this.props.history.push("/error500");
    //window.location = "/error500";
  //this.props.history.push("/error500");
  //window.location = "/error500";
  return Promise.reject(error)
})


function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
  }
}

function setSignature(signature) {
  axios.defaults.headers.common = {
    Accept: `application/json`,
    Signature: `${signature}`,
  }
}

const httpService = {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
  setSignature,
}

export default httpService
