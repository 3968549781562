import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers'
//redux
import { Provider } from 'react-redux'
import store from './redux/store'
import "./i18next"
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import ReactGA from 'react-ga';


if (process.env.REACT_APP_DEBUG_MODE === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    beforeSend(event, hint) {
      /* tslint:disable:no-string-literal only-arrow-functions */
      const isNonErrorException =
        event.exception.values[0].value.startsWith('Non-Error exception captured') ||
        hint.originalException['message'].startsWith('Non-Error exception captured')
      /* tslint:enable:no-string-literal only-arrow-functions */

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        return null
      }
      return event
    },
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
  Sentry.close()
}

ReactGA.initialize('G-CEHGMHR4L3');
ReactGA.pageview(window.location.pathname + window.location.search);

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 8000 // frequency provider is polling
  return library
}
window.Buffer = window.Buffer || require('buffer').Buffer
// window.Buffer = window.Buffer || Buffer;

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div></div>}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </Suspense>
  </Provider>,
  rootElement,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
