import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useEffect } from 'react'
import _ from 'lodash'

//asset
import { TrendingRadio } from '../../../assets/images'

//plug in @swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/effect-creative'
import { EffectCreative } from 'swiper' // import required modules
import { LazyLoadImage } from 'react-lazy-load-image-component'

//redux
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function TrendingTrade() {
  const { t } = useTranslation()

  const { listing } = useSelector((state) => state.market)

  let crytoTrendingList = []
  let equitytrendingList = []
  let forexTrendingList = []
  let metaltrendingList = []

  for (var key in listing) {
    if (listing[key].type == 'Forex') {
      forexTrendingList.push(listing[key])
    } else if (listing[key].type == 'Metals') {
      metaltrendingList.push(listing[key])
    } else if (listing[key].type == 'Crypto') {
      crytoTrendingList.push(listing[key])
    } else {
      equitytrendingList.push(listing[key])
    }
  }

  useEffect(() => {
    // requestPriceFeed()
  }, [])

  return (
    <MDBCol className="trending-trade-container">
      <MDBRow className="header-container-home">
        <label>{t(`Trending Today`)}</label>
        <span>{t(`24H Change`)}</span>
      </MDBRow>
      <MDBCol>
        <MDBCol></MDBCol>
      </MDBCol>
      <MDBCol>
        <Swiper
          grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: ['-120%', 0, -500],
            },
            next: {
              shadow: false,
              translate: ['120%', 0, -500],
            },
          }}
          modules={[EffectCreative]}
          className="mySwiper2">
          <SwiperSlide>
            <MDBContainer className="trending-card-container mobile-version">
              {crytoTrendingList.length >= 0
                ? crytoTrendingList.map((item, index) => (
                    <MDBRow className="card-row" key={index}>
                      <div className="line"></div>
                      <MDBCol className="col-1 trending-radio">
                        <LazyLoadImage src={TrendingRadio} width="25" />
                      </MDBCol>
                      <MDBCol className={`trending-card ${item.type}`}>
                        <MDBCol className="col-2 trending-image">
                          <LazyLoadImage src={item.image} width="40" />
                        </MDBCol>
                        <MDBCol className="col-4 trending-title text-center">
                          <label>{item.title}</label>
                        </MDBCol>
                        <MDBCol className="trending-value">
                          <MDBRow className="m-0">
                            <MDBCol className="col-5 justify-content-end label">
                              <label>{t(`Now`)}:</label>
                            </MDBCol>
                            <MDBCol className={`value ${item.type} nowrap`}>
                              <label>$ {parseFloat(item.price)}</label>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCol>
                    </MDBRow>
                  ))
                : ''}
            </MDBContainer>
          </SwiperSlide>
          <SwiperSlide>
            <MDBContainer className="trending-card-container mobile-version">
              {equitytrendingList.length >= 0
                ? equitytrendingList.map((item, index) => (
                    <MDBRow className="card-row" key={index}>
                      <div className="line"></div>
                      <MDBCol className="col-1 trending-radio">
                        <LazyLoadImage src={TrendingRadio} width="25" />
                      </MDBCol>
                      <MDBCol className={`trending-card ${item.type}`}>
                        <MDBCol className="col-2 trending-image">
                          <LazyLoadImage src={item.image} width="40" />
                        </MDBCol>
                        <MDBCol className="col-4 trending-title text-center">
                          <label>{item.title}</label>
                        </MDBCol>
                        <MDBCol className="trending-value">
                          <MDBRow className="m-0">
                            <MDBCol className="col-5 justify-content-end label">
                              <label>{t(`Now`)}:</label>
                            </MDBCol>
                            <MDBCol className={`value ${item.type} nowrap`}>
                              <label>$ {parseFloat(item.price)}</label>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCol>
                    </MDBRow>
                  ))
                : ''}
            </MDBContainer>
          </SwiperSlide>
          <SwiperSlide>
            <MDBContainer className="trending-card-container mobile-version">
              {forexTrendingList.length >= 0
                ? forexTrendingList.map((item, index) => (
                    <MDBRow className="card-row" key={index}>
                      <div className="line"></div>
                      <MDBCol className="col-1 trending-radio">
                        <LazyLoadImage src={TrendingRadio} width="25" />
                      </MDBCol>
                      <MDBCol className={`trending-card ${item.type}`}>
                        <MDBCol className="col-2 trending-image">
                          <LazyLoadImage src={item.image} width="40" />
                        </MDBCol>
                        <MDBCol className="col-4 trending-title text-center">
                          <label>{item.title}</label>
                        </MDBCol>
                        <MDBCol className="trending-value">
                          <MDBRow className="m-0">
                            <MDBCol className="col-5 justify-content-end label">
                              <label>{t(`Now`)}:</label>
                            </MDBCol>
                            <MDBCol className={`value ${item.type} nowrap`}>
                              <label>$ {parseFloat(item.price)}</label>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCol>
                    </MDBRow>
                  ))
                : ''}
            </MDBContainer>
          </SwiperSlide>
          <SwiperSlide>
            <MDBContainer className="trending-card-container mobile-version">
              {metaltrendingList.length >= 0
                ? metaltrendingList.map((item, index) => (
                    <MDBRow className="card-row" key={index}>
                      <div className="line"></div>
                      <MDBCol className="col-1 trending-radio">
                        <LazyLoadImage src={TrendingRadio} width="25" />
                      </MDBCol>
                      <MDBCol className={`trending-card ${item.type}`}>
                        <MDBCol className="col-2 trending-image">
                          <LazyLoadImage src={item.image} width="40" />
                        </MDBCol>
                        <MDBCol className="col-4 trending-title text-center">
                          <label>{item.title}</label>
                        </MDBCol>
                        <MDBCol className="trending-value">
                          <MDBRow className="m-0">
                            <MDBCol className="col-5 justify-content-end label">
                              <label>{t(`Now`)}:</label>
                            </MDBCol>
                            <MDBCol className={`value ${item.type} nowrap`}>
                              <label>$ {parseFloat(item.price)}</label>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBCol>
                    </MDBRow>
                  ))
                : ''}
            </MDBContainer>
          </SwiperSlide>
        </Swiper>
      </MDBCol>
    </MDBCol>
  )
}
