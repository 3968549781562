import { useState } from 'react'

//hook
import useTradeOrder from '../../../hook/useTradeOrder'
import useUtils from '../../../hook/useUtils'

//lib
import {
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
} from 'mdb-react-ui-kit'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Icon } from '@iconify/react'
import { toast } from 'react-toastify'

//component
import ModalBox from '../../../components/modal/full.modal.box'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedInput, setTakeProfitModal, setTpSl } from '../../../redux/reducer/tradeReducer'
import { useTranslation } from 'react-i18next'

export default function TakePofitModal({ show }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const exceptThisSymbols = ['e', 'E', '+', '-']
  const { convertSha256 } = useUtils()
  const {
    takeProfitPrice,
    stopPrice,
    countDecimals,
    liquidationPrice,
    calculateRolloverFee,
    calculateFundingFee,
    handlePairing,
  } = useTradeOrder()
  const [takeRate, setTakeRate] = useState(0)
  const [stopRate, setStopRate] = useState(0)
  const { listing } = useSelector((state) => state.market)
  const { passphrase } = useSelector((state) => state.profile)
  const { accounts, token, selectedTrading } = useSelector((state) => state.connector)
  const { position_detail_modal_data, selected_input, tpsl_loading } = useSelector(
    (state) => state.trade,
  )
  const newSymbol = position_detail_modal_data.symbol
    ? position_detail_modal_data.symbol.split('/')
    : ''

  const [takeProfit, setTakeProfit] = useState(
    position_detail_modal_data.takeProfit
      ? takeProfitPrice(
          position_detail_modal_data.type,
          'custom',
          position_detail_modal_data.takeProfit,
          position_detail_modal_data.averageOpen,
          position_detail_modal_data.leverage,
          newSymbol[0],
        ) + '%'
      : 'None',
  )
  const [stopProfit, setStopProfit] = useState(
    position_detail_modal_data.stopLoss
      ? stopPrice(
          position_detail_modal_data.type,
          'custom',
          position_detail_modal_data.stopLoss,
          position_detail_modal_data.averageOpen,
          position_detail_modal_data.leverage,
          newSymbol[0],
        ) + '%'
      : 'None',
  )
  const [takeProfitValue, setTakeProfitValue] = useState(
    position_detail_modal_data.takeProfit ? position_detail_modal_data.takeProfit : '',
  )
  const [StopLossValue, setStopLossValue] = useState(
    position_detail_modal_data.stopLoss ? position_detail_modal_data.stopLoss : '',
  )

  const [data, setData] = useState({
    take: position_detail_modal_data.takeProfit ? position_detail_modal_data.takeProfit : '',
    stop: position_detail_modal_data.stopLoss ? position_detail_modal_data.stopLoss : '',
  })

  const useSchema = Yup.object({
    take: Yup.number().test((values, ctx) => {
      if (
        values <= listing[newSymbol[0]].normal_price &&
        position_detail_modal_data.type == 'long'
      ) {
        return ctx.createError({ message: 'Take price must higher than market price' })
      } else if (
        values >= listing[newSymbol[0]].normal_price &&
        position_detail_modal_data.type == 'short'
      ) {
        return ctx.createError({ message: 'Take price must lower than market price' })
      }
      return true
    }),
    stop: Yup.number().test((values, ctx) => {
      if (
        values >= listing[newSymbol[0]].normal_price &&
        position_detail_modal_data.type == 'long'
      ) {
        return ctx.createError({ message: 'Stop price must lower than market price' })
      } else if (
        values <= listing[newSymbol[0]].normal_price &&
        position_detail_modal_data.type == 'short'
      ) {
        return ctx.createError({ message: 'Stop price must higher than market price' })
      } else if (
        values <
          liquidationPrice(
            position_detail_modal_data.type,
            Number(position_detail_modal_data.averageOpen),
            Number(position_detail_modal_data.amount),
            position_detail_modal_data.leverage,
            calculateRolloverFee(position_detail_modal_data.amount),
            calculateFundingFee(
              position_detail_modal_data.amount,
              position_detail_modal_data.leverage,
            ),
            newSymbol[0],
          ) &&
        position_detail_modal_data.type == 'long'
      ) {
        return ctx.createError({ message: 'Stop price must higher than liq. price' })
      } else if (
        values >
          liquidationPrice(
            position_detail_modal_data.type,
            Number(position_detail_modal_data.averageOpen),
            Number(position_detail_modal_data.amount),
            position_detail_modal_data.leverage,
            calculateRolloverFee(position_detail_modal_data.amount),
            calculateFundingFee(
              position_detail_modal_data.amount,
              position_detail_modal_data.leverage,
            ),
            newSymbol[0],
          ) &&
        position_detail_modal_data.type == 'short'
      ) {
        return ctx.createError({ message: 'Stop price must lower than liq. price' })
      }
      return true
    }),
  })

  const handleSubmit = (values, setFieldError) => {
    let payload = {
      id: position_detail_modal_data._id,
      address: accounts,
    }
    if (Number(values.take) > 0 || values.take == '') {
      payload.take_profit = Number(values.take)
    }
    if (Number(values.stop) > 0 || values.stop == '') {
      payload.stop_loss = Number(values.stop)
    }

    dispatch(
      setTpSl({
        payload: payload,
        data: convertSha256(token, passphrase),
        network: selectedTrading,
      }),
    )
      .unwrap()
      .then((data) => {})
      .catch((ex) => {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            toast.error(errors[item], {
              position: 'top-left',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'dark',
            })
          })
        } else {
          toast.error(JSON.stringify(ex.message), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          })
        }
      })
  }

  return (
    <ModalBox
      className={'trade-order-container'}
      show={show}
      setShow={() =>
        dispatch(
          setTakeProfitModal({
            isOpen: false,
            position_modal: position_detail_modal_data.position_modal ? true : false,
            data: position_detail_modal_data,
          }),
        )
      }
      screenSize="s"
      reactModal={true}
      content={
        <div style={{ width: '22em' }}>
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            validateOnMount={true}
            initialValues={data}
            validationSchema={useSchema}
            onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
              handleSubmit(values, setFieldError)
              resetForm({ values: '' })
            }}>
            {({ handleReset, errors, values, isValid, setFieldValue, isSubmitting }) => (
              <Form className="modal-form">
                <MDBCardHeader>
                  <MDBRow className="d-flex align-items-center">
                    <MDBCol className="col-8">
                      <label className="_label --symbol">{t('Take Profit/Stop Loss')}</label>
                    </MDBCol>
                    <MDBCol className="back-btn col-4">
                      <div
                        style={{ cursor: 'pointer' }}
                        className="round-btn"
                        onClick={() =>
                          dispatch(
                            setTakeProfitModal({
                              isOpen: false,
                              position_modal: position_detail_modal_data.position_modal
                                ? true
                                : false,
                              data: position_detail_modal_data,
                            }),
                          )
                        }>
                        <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCardHeader>
                <MDBModalBody>
                  <MDBContainer className="order-profit-container">
                    <MDBRow className="--row-special m-0">
                      <MDBCol className="p-0">
                        <label className="_label --symbol-title">
                          {position_detail_modal_data.symbol}
                        </label>
                      </MDBCol>
                      <MDBCol className="p-0">
                        <label className="_label --status justify-content-end text-end">
                          {Object.values(errors)[0] ? t(`${Object.values(errors)[0]}`) : ''}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="--row-special">
                      <label className="_label --profit">
                        {takeProfit !== 'NaN%' ? takeProfit : t('None')}
                      </label>
                    </MDBRow>
                    <MDBRow
                      className="m-0"
                      onClick={() => {
                        dispatch(setSelectedInput('take'))
                      }}>
                      <MDBCol className="p-0">
                        <input
                          placeholder={t('Enter Price')}
                          disabled={selected_input == 'take' ? false : true}
                          className="_input --trade-modal"
                          value={String(takeProfitValue)}
                          type="number"
                          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                          onWheelCapture={(e) => {
                            e.target.blur()
                          }}
                          onClick={() => {
                            setTakeRate(0)
                          }}
                          onBlur={() => {
                            const value = takeProfitValue == '' ? '' : parseFloat(takeProfitValue)
                            setTakeProfitValue(value)
                            setFieldValue('take', value)
                          }}
                          onChange={(e) => {
                            const number = e.target.value
                            setFieldValue('take', number)
                            setTakeProfitValue(number)
                            setTakeProfit(
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                'custom',
                                parseFloat(number),
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ) + '%',
                            )
                          }}
                        />
                      </MDBCol>
                      <MDBCol className="col-4" style={{ paddingRight: '0' }}>
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            selected_input == 'take'
                              ? '_label --border-text-active'
                              : '_label --border-text'
                          }>
                          {t(`TAKE`)}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0" style={{ gap: '0.8em', justifyContent: 'center' }}>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            takeRate == 25 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setTakeRate(25)
                            setFieldValue(
                              'take',
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                25,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setTakeProfitValue('')

                            setTakeProfit(
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                25,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          25%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            takeRate == 50 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setTakeRate(50)
                            setFieldValue('take', '')
                            setFieldValue(
                              'take',
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                50,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setTakeProfitValue('')

                            setTakeProfit(
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                50,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          50%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            takeRate == 100 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setTakeRate(100)
                            setFieldValue('take', '')
                            setFieldValue(
                              'take',
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                100,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setTakeProfitValue('')

                            setTakeProfit(
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                100,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          100%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            takeRate == 300 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setTakeRate(300)
                            setFieldValue('take', '')
                            setFieldValue(
                              'take',
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                300,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setTakeProfitValue('')

                            setTakeProfit(
                              takeProfitPrice(
                                position_detail_modal_data.type,
                                '',
                                300,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          300%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            takeRate == 'None'
                              ? '_label --border-text-active'
                              : '_label --border-text'
                          }
                          onClick={() => {
                            setTakeRate('None')
                            setFieldValue('take', '')
                            setTakeProfitValue('')
                            setTakeProfit('None')
                          }}>
                          None
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="--row-special mt-3">
                      <MDBCol className="p-0">
                        <label className="_label --total-title">{t(`Market price`)}</label>
                        <label className="_label --total-title">{t(`Open Price`)}</label>
                        <label className="_label --total-title">{t(`LIQ. Price`)}</label>
                      </MDBCol>
                      <MDBCol className="text-end p-0">
                        <label className="_label --amount justify-content-end">
                          {listing[newSymbol[0]] && listing[newSymbol[0]].normal_price
                            ? listing[newSymbol[0]].normal_price
                                .toFixed(listing[newSymbol[0]].decimal)
                                .replace(/\.?0+$/, '')
                            : 0}
                        </label>
                        <label className="_label --amount justify-content-end">
                          {Math.round(
                            position_detail_modal_data.averageOpen *
                              Math.pow(10, listing[handlePairing('symbol', newSymbol[0])].decimal),
                          ) / Math.pow(10, listing[handlePairing('symbol', newSymbol[0])].decimal)}
                        </label>
                        <label className="_label --amount justify-content-end">
                          {liquidationPrice(
                            position_detail_modal_data.type,
                            Number(position_detail_modal_data.averageOpen),
                            Number(position_detail_modal_data.amount),
                            position_detail_modal_data.leverage,
                            calculateRolloverFee(position_detail_modal_data.amount),
                            calculateFundingFee(
                              position_detail_modal_data.amount,
                              position_detail_modal_data.leverage,
                            ),
                            newSymbol[0],
                          )}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="--row-special">
                      <label className="_label --loss">
                        {stopProfit !== 'NaN%' ? stopProfit : t('None')}
                      </label>
                    </MDBRow>
                    <MDBRow
                      className="m-0"
                      onClick={() => {
                        dispatch(setSelectedInput('stop'))
                      }}>
                      <MDBCol className="p-0">
                        <input
                          disabled={selected_input == 'stop' ? false : true}
                          className="_input --trade-modal"
                          placeholder={t('Close Price')}
                          value={String(StopLossValue)}
                          type="number"
                          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                          onWheelCapture={(e) => {
                            e.target.blur()
                          }}
                          onClick={() => {
                            setStopRate(0)
                          }}
                          onBlur={() => {
                            const value = StopLossValue == '' ? '' : parseFloat(StopLossValue)
                            setStopLossValue(value)
                            setFieldValue('stop', value)
                          }}
                          onChange={(e) => {
                            const number = e.target.value
                            if (countDecimals(number) <= 2) {
                              setFieldValue('stop', number)
                              setStopLossValue(number)
                              setStopProfit(
                                stopPrice(
                                  position_detail_modal_data.type,
                                  'custom',
                                  parseFloat(number),
                                  position_detail_modal_data.averageOpen,
                                  position_detail_modal_data.leverage,
                                  newSymbol[0],
                                ) + '%',
                              )
                            }
                          }}
                        />
                      </MDBCol>
                      <MDBCol className="col-4" style={{ paddingRight: '0' }}>
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            selected_input == 'stop'
                              ? '_label --border-text-active'
                              : '_label --border-text'
                          }>
                          {t(`STOP`)}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0" style={{ gap: '0.8em', justifyContent: 'center' }}>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            stopRate == 10 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setStopRate(10)
                            setFieldValue(
                              'stop',
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                10,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setStopLossValue('')
                            setStopProfit(
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                10,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          -10%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            stopRate == 25 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setStopRate(25)
                            setFieldValue(
                              'stop',
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                25,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setStopLossValue('')

                            setStopProfit(
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                25,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          -25%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            stopRate == 50 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setStopRate(50)
                            setFieldValue(
                              'stop',
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                50,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setStopLossValue('')

                            setStopProfit(
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                50,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          -50%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            stopRate == 75 ? '_label --border-text-active' : '_label --border-text'
                          }
                          onClick={() => {
                            setStopRate(75)
                            setFieldValue(
                              'stop',
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                75,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                            setStopLossValue('')

                            setStopProfit(
                              stopPrice(
                                position_detail_modal_data.type,
                                '',
                                75,
                                position_detail_modal_data.averageOpen,
                                position_detail_modal_data.leverage,
                                newSymbol[0],
                              ),
                            )
                          }}>
                          -75%
                        </label>
                      </MDBCol>
                      <MDBCol className="col-2 --border-special">
                        <label
                          style={{ cursor: 'pointer' }}
                          className={
                            stopRate == 'None'
                              ? '_label --border-text-active'
                              : '_label --border-text'
                          }
                          onClick={() => {
                            setStopRate('None')
                            setFieldValue('stop', '')
                            setStopLossValue('')
                            setStopProfit('None')
                          }}>
                          None
                        </label>
                      </MDBCol>
                    </MDBRow>
                    {/* <MDBRow className="--row-special mt-4">
                      <label className="_label --note-text p-0">
                        Note : If the available balance is less than your holding position, it may
                        be because some of your assets are occupied by the margin
                      </label>
                    </MDBRow> */}
                  </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBRow className="--row-special d-flex align-items-center justify-content-center w-100">
                    <button type="submit" disabled={tpsl_loading} className="_button --confirm">
                      {tpsl_loading ? t('Waiting..') : t('Update')}
                    </button>
                  </MDBRow>
                </MDBModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}
