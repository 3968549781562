import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useSelector } from 'react-redux'
import { Skeleton } from 'primereact/skeleton'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../../components/common/window-dimension'

export default function Market() {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { marketInfo, marketInfoLoader } = useSelector((state) => state.home)
  const formatCash = (num, digits = 1) => {
    var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  const marketVolume = marketInfo.trading_volume
    ? Math.floor(marketInfo.trading_volume * Math.pow(10, 0)) / Math.pow(10, 0)
    : ''
  const open_interest = marketInfo.open_interest
    ? Math.floor(marketInfo.open_interest * Math.pow(10, 0)) / Math.pow(10, 0)
    : ''

  return (
    <MDBCol className="home-market-container">
      <MDBRow className="header-container-home">
        <label>{t(`Markets`)}</label>
        <span>{t(`Discover new assets available to trade on Deri Trade`)}</span>
      </MDBRow>
      <MDBContainer className="market-data-container">
        <MDBRow className="market-title m-0">
          <MDBCol className="p-0">
            <label className="w-100">{t(`Total Trading Volume`)}</label>
          </MDBCol>
          <MDBCol className="p-0">
            <label className="w-100">{t(`Open Interest`)}</label>
          </MDBCol>
          <MDBCol className="p-0">
            <label className="w-100">{t(`Total Users`)}</label>
          </MDBCol>
        </MDBRow>
        <MDBRow className="market-title m-0">
          <MDBCol className="p-0">
            {marketInfoLoader && marketInfo.trading_volume ? (
              <Skeleton width="3rem" height="1rem" className="mt-2 mb-2"></Skeleton>
            ) : (
              <label className="market-value">
                {marketInfo.trading_volume
                  ? width >= 991
                    ? '$ ' + marketVolume.toLocaleString()
                    : '$ ' +
                      formatCash(
                        Math.floor(marketInfo.trading_volume * Math.pow(10, 2)) / Math.pow(10, 2),
                      )
                  : '-'}
              </label>
            )}
          </MDBCol>
          <MDBCol className="p-0">
            {marketInfoLoader && marketInfo.open_interest ? (
              <Skeleton width="3rem" height="1rem" className="mt-2 mb-2"></Skeleton>
            ) : (
              <label className="market-value">
                {marketInfo.open_interest
                  ? width >= 991
                    ? '$ ' + open_interest.toLocaleString()
                    : '$ ' +
                      formatCash(
                        Math.floor(marketInfo.open_interest * Math.pow(10, 2)) / Math.pow(10, 2),
                      )
                  : '-'}
              </label>
            )}
          </MDBCol>
          <MDBCol className="p-0">
            {marketInfoLoader && marketInfo.total_user ? (
              <Skeleton width="3rem" height="1rem" className="mt-2 mb-2"></Skeleton>
            ) : (
              <label className="market-value">
                {marketInfo.total_user
                  ? width >= 991
                    ? marketInfo.total_user.toLocaleString()
                    : formatCash(
                        Math.floor(marketInfo.total_user * Math.pow(10, 2)) / Math.pow(10, 2),
                      )
                  : '-'}
              </label>
            )}
          </MDBCol>
        </MDBRow>
        {/* <MDBRow className="market-title desktop-version m-0">
          <MDBCol className="p-0">
            {marketInfoLoader ? (
              <Skeleton width="12rem" height="1rem" className="mb-2"></Skeleton>
            ) : (
              <label>{t(`exchanged in the last 24h`)}</label>
            )}
          </MDBCol>
          <MDBCol className="p-0">
            {marketInfoLoader ? (
              <Skeleton width="12rem" height="1rem" className="mb-2"></Skeleton>
            ) : (
              <label>{t(`in open positions on Deri Trade`)}</label>
            )}
          </MDBCol>
          <MDBCol className="p-0">
            {marketInfoLoader ? (
              <Skeleton width="12rem" height="1rem" className="mb-2"></Skeleton>
            ) : (
              <label>{t(`connected on Deri Trade`)}</label>
            )}
          </MDBCol>
        </MDBRow> */}
      </MDBContainer>
    </MDBCol>
  )
}
