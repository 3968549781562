
// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
	try {
		const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+).(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}
	

	let newSymbol = {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3]
	}

	if(newSymbol.exchange == "Crypto" || newSymbol.exchange == "CRYPTO" ){
		newSymbol.exchange = "Crypto"
		newSymbol.toSymbol = "USD"
	}else if(newSymbol.exchange == "Equity"){
		newSymbol.exchange = "Equity"

	}else if(newSymbol.exchange == "Forex"){
		newSymbol.exchange = "Forex"
	}else if(newSymbol.exchange == "Metals"){
		newSymbol.exchange = "Metals"
	}

	return {
		exchange: newSymbol.exchange,
		fromSymbol: newSymbol.fromSymbol,
		toSymbol: newSymbol.toSymbol,
	};
}

export function findExchangeForSymbol(pair, exchange = false){
	const pairing = {
		Crypto: [
			"AVAX/USD",
			"ADA/USD",
			"ATOM/USD",
			"BCH/USD",
			"BNB/USD",
			"BTC/USD",
			"DOGE/USD",
			"DOT/USD",
			"ETH/USD",
			"LTC/USD",
			"MATIC/USD",
			"NEAR/USD",
		],
		Equity: [
			"AAPL/USD",
			"AMZN/USD",
			"GOOG/USD",
			"NFLX/USD",
			"TSLA/USD",
		],
		Forex: [
			"AUD/USD",
			"EUR/USD",
			"GBP/USD",
			"NZD/USD",
			"USD/CAD",
			"USD/CHF",
			"USD/CNH",
			"USD/HKD",
			"USD/JPY",
			"USD/MXN",
			"USD/SGD",
			"USD/ZAR",
		],
		Metals: [
			"XAU/USD",
			"XAG/USD"
		]
	}


	  for (let key in pairing) {
		if (pairing[key].includes(pair)) {
			// console.log("key",key)
			if(key == "Crypto"){
				return {
					exchange: "Binance",
					fromSymbol: pair.split("/")[0],
					toSymbol: "USDT"
				}
			}else {
				return {
					exchange: key,
					fromSymbol: pair.split("/")[0],
					toSymbol: pair.split("/")[1]
				}
			}
		}
	  }
	  
	  return null;
}
