import { Icon } from '@iconify/react'
import {
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
} from 'mdb-react-ui-kit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import ModalBox from '../../../components/modal/full.modal.box'
import useTradeOrder from '../../../hook/useTradeOrder'
import {
  setPositionDetailModal,
  setShareButton,
  setTakeProfitModal,
} from '../../../redux/reducer/tradeReducer'

export default function PositionDetailModal({ show, setShow }) {
  const dispatch = useDispatch()
  const {
    positionSize,
    priceChange,
    calculateRolloverFee,
    calculateFundingFee,
    calculateCloseFee,
    netPnl,
    liquidationPrice,
    netPnlRate,
    handlePairing,
  } = useTradeOrder()
  const { t } = useTranslation()

  const decimal = 100
  const { position_detail_modal_data } = useSelector((state) => state.trade)
  const { listing } = useSelector((state) => state.market)
  const newSymbol = position_detail_modal_data.symbol
    ? position_detail_modal_data.symbol.split('/')
    : ''

  const [isDisabled, setIsDisabled] = useState(false)

  function showNotification() {
    if (isDisabled) {
      return
    }

    setIsDisabled(true)
    toast.info(
      <div>
        <MDBRow className="m-0">
          <MDBCol className="text-start">
            <label className="_label">{t('Sharing Coming Soon')}</label>
          </MDBCol>
        </MDBRow>
      </div>,
      {
        position: 'top-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        theme: 'dark',
      },
    )

    setTimeout(() => {
      setIsDisabled(false)
    }, 60000) // 禁用60秒
  }

  return (
    <ModalBox
      className={'trade-order-container'}
      show={show}
      setShow={setShow}
      screenSize="s"
      reactModal={true}
      content={
        <div style={{ width: '21em' }}>
          <MDBCardHeader>
            <MDBRow className="d-flex align-items-center">
              <MDBCol className="col-8">
                <label className="_label --symbol">
                  {position_detail_modal_data.symbol}{' '}
                  <div>
                    <Icon
                      icon="grommet-icons:share"
                      width="1em"
                      height="1em"
                      onClick={() => {
                        // showNotification()
                        dispatch(
                          setShareButton({
                            status: true,
                            data: position_detail_modal_data,
                          }),
                        )
                      }}
                    />
                  </div>
                </label>
                <label className={`_label --order-type --${position_detail_modal_data.type}`}>
                  {t(`${position_detail_modal_data.type}`)}
                </label>
              </MDBCol>
              <MDBCol className="back-btn col-4">
                <div
                  style={{ cursor: 'pointer' }}
                  className="round-btn"
                  onClick={() => dispatch(setPositionDetailModal({ status: false, data: {} }))}>
                  <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBModalBody>
            <MDBContainer className="order-detail-container">
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Collateral`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(position_detail_modal_data.amount * decimal) / decimal} USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Leverage`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {position_detail_modal_data.leverage}x
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Position Size`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {positionSize(
                      position_detail_modal_data.amount,
                      position_detail_modal_data.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Open Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(
                      position_detail_modal_data.averageOpen *
                        Math.pow(10, listing[handlePairing('symbol', newSymbol[0])].decimal),
                    ) / Math.pow(10, listing[handlePairing('symbol', newSymbol[0])].decimal)}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Current Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {listing[newSymbol[0]] && listing[newSymbol[0]].normal_price
                      ? listing[newSymbol[0]].normal_price
                          .toFixed(listing[newSymbol[0]].decimal)
                          .replace(/\.?0+$/, '')
                      : 0}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Price Change`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {priceChange(
                      position_detail_modal_data.averageOpen,
                      listing[newSymbol[0]].normal_price,
                    )}
                    %
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Rollover Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {calculateRolloverFee(position_detail_modal_data.amount)} USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Funding Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {calculateFundingFee(
                      position_detail_modal_data.amount,
                      position_detail_modal_data.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Closing Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {calculateCloseFee(
                      position_detail_modal_data.amount,
                      position_detail_modal_data.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>{' '}
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Take Profit`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {position_detail_modal_data.takeProfit
                      ? position_detail_modal_data.takeProfit
                      : t('None')}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Stop Loss`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {position_detail_modal_data.stopLoss
                      ? position_detail_modal_data.stopLoss
                      : t('None')}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Liq. Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {liquidationPrice(
                      position_detail_modal_data.type,
                      Number(position_detail_modal_data.averageOpen),
                      Number(position_detail_modal_data.amount),
                      position_detail_modal_data.leverage,
                      calculateRolloverFee(position_detail_modal_data.collateral),
                      calculateFundingFee(
                        position_detail_modal_data.amount,
                        position_detail_modal_data.leverage,
                      ),
                      position_detail_modal_data.symbol,
                    )}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="col-3">
                  <label className="_label --title">{t(`Net PnL`)}</label>
                </MDBCol>
                <MDBCol>
                  <label
                    className={`_label --title-value-details text-end --${
                      netPnl(
                        position_detail_modal_data.type,
                        positionSize(
                          position_detail_modal_data.amount,
                          position_detail_modal_data.leverage,
                        ),
                        listing[newSymbol[0]].normal_price,
                        position_detail_modal_data.averageOpen,
                        calculateRolloverFee(position_detail_modal_data.amount),
                        calculateFundingFee(
                          position_detail_modal_data.amount,
                          position_detail_modal_data.leverage,
                        ),
                        calculateCloseFee(
                          position_detail_modal_data.amount,
                          position_detail_modal_data.leverage,
                        ),
                      ) > 0
                        ? 'long'
                        : 'short'
                    }`}>
                    {netPnl(
                      position_detail_modal_data.type,
                      positionSize(
                        position_detail_modal_data.amount,
                        position_detail_modal_data.leverage,
                      ),
                      listing[newSymbol[0]].normal_price,
                      position_detail_modal_data.averageOpen,
                      calculateRolloverFee(position_detail_modal_data.amount),
                      calculateFundingFee(
                        position_detail_modal_data.amount,
                        position_detail_modal_data.leverage,
                      ),
                      calculateCloseFee(
                        position_detail_modal_data.amount,
                        position_detail_modal_data.leverage,
                      ),
                    ) +
                      ' (' +
                      netPnlRate(
                        netPnl(
                          position_detail_modal_data.type,
                          positionSize(
                            position_detail_modal_data.amount,
                            position_detail_modal_data.leverage,
                          ),
                          listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                          position_detail_modal_data.averageOpen,
                          calculateRolloverFee(position_detail_modal_data.collateral),
                          calculateFundingFee(
                            position_detail_modal_data.amount,
                            position_detail_modal_data.leverage,
                          ),
                          calculateCloseFee(
                            position_detail_modal_data.amount,
                            position_detail_modal_data.leverage,
                          ),
                        ),
                        position_detail_modal_data.amount,
                      ) +
                      ')'}
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRow className="m-0 w-100">
              <button
                className="_button --confirm"
                onClick={() =>
                  dispatch(setTakeProfitModal({ isOpen: true, data: position_detail_modal_data }))
                }>
                {t(`Set Take Profit/Stop Loss`)}
              </button>
            </MDBRow>
          </MDBModalFooter>
        </div>
      }
    />
  )
}
