import { Icon } from '@iconify/react'
import { MDBCardHeader, MDBCol, MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'

import ModalBox from '../../../components/modal/full.modal.box'
import useTradeOrder from '../../../hook/useTradeOrder'
import { setWalletHistoryDetailModal } from '../../../redux/reducer/profileReducer'

export default function HistoryDetailModal({ show, setShow }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { calculateOpenFee, handlePairing } = useTradeOrder()
  const decimal = 100
  const { historyDetailModalData } = useSelector((state) => state.profile)
  const { listing } = useSelector((state) => state.market)

  return (
    <ModalBox
      className={'trade-order-container'}
      show={show}
      setShow={setShow}
      screenSize="s"
      reactModal={true}
      content={
        <div style={{ width: '21em' }}>
          <MDBCardHeader>
            <MDBRow className="d-flex align-items-center">
              <MDBCol className="col-8">
                <label className="_label --symbol">{historyDetailModalData.symbol}</label>
                <label className={`_label --order-type --${historyDetailModalData.orderMethod}`}>
                  {t(`${historyDetailModalData.orderType}`)}
                </label>
              </MDBCol>
              <MDBCol className="back-btn col-4">
                <div
                  style={{ cursor: 'pointer' }}
                  className="round-btn"
                  onClick={() =>
                    dispatch(setWalletHistoryDetailModal({ status: false, data: {} }))
                  }>
                  <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBModalBody>
            <MDBContainer className="order-detail-container">
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Order Type`)}</label>
                </MDBCol>
                <MDBCol>
                  <label
                    className="_label --title-value-details"
                    style={{ textTransform: 'capitalize' }}>
                    {t(`${historyDetailModalData.type}`)}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Leverage`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {historyDetailModalData.leverage}x
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Collateral`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(historyDetailModalData.collateral * decimal) / decimal} USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Ask Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(
                      historyDetailModalData.price *
                        Math.pow(
                          10,
                          listing[handlePairing('symbol', historyDetailModalData.symbol)].decimal,
                        ),
                    ) /
                      Math.pow(
                        10,
                        listing[handlePairing('symbol', historyDetailModalData.symbol)].decimal,
                      )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Fulfill Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(
                      historyDetailModalData.price *
                        Math.pow(
                          10,
                          listing[handlePairing('symbol', historyDetailModalData.symbol)].decimal,
                        ),
                    ) /
                      Math.pow(
                        10,
                        listing[handlePairing('symbol', historyDetailModalData.symbol)].decimal,
                      )}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Opening Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {calculateOpenFee(
                      historyDetailModalData.collateral,
                      historyDetailModalData.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Status`)}</label>
                </MDBCol>
                <MDBCol>
                  <label
                    className={`_label --title-value-details --${historyDetailModalData.status}`}
                    style={{ textTransform: 'capitalize' }}>
                    {t(historyDetailModalData.status)}
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </div>
      }
    />
  )
}
