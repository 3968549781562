import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import apiServices from '../../services/api.services'

export const createOrder = createAsyncThunk(
  'trade/createOrder',
  async ({ payload, data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.createOrder(payload, data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const cancelOrder = createAsyncThunk(
  'trade/cancelOrder',
  async ({ payload, data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.cancelOrder(payload, data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getAllOrder = createAsyncThunk(
  'trade/getAllOrder',
  async ({ payload, data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.getAllOrder(payload, data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getPosition = createAsyncThunk(
  'trade/getPosition',
  async ({ payload, data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.getPosition(payload, data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const cancelPosition = createAsyncThunk(
  'trade/cancelPosition',
  async ({ payload, data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.cancelPosition(payload, data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getFill = createAsyncThunk(
  'trade/getFill',
  async ({ payload, data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.getFill(payload, data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const setTpSl = createAsyncThunk(
  'trade/setTpSl',
  async ({ payload, data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.setTpSl(payload, data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const initialState = {
  share_modal_detail: {},
  share_modal: false,
  trade_position_cancel_loading: false,
  trade_order_loading: false,
  trade_order_cancel_loading: false,
  trade_favorite_list: [],
  trade_data: {
    type: 'limit',
    orderType: 'long',
    orderPrice: '',
    triggerPrice: '',
    collateral: '',
    expiredAt: '24',
    leverage: '1',
  },
  trade_confirm_modal: false,
  trade_confirm_data: {
    trade_data: {},
    liquidation: '',
  },
  prompt_confirm_window: false,
  trading_view_info: {
    exchange: 'Binance',
    symbol: localStorage.getItem('symbol') ? localStorage.getItem('symbol') : 'BNB/USD',
    pricefeed: 0,
  },
  position_detail_modal: false,
  position_detail_modal_data: {},
  order_detail_modal: false,
  order_detail_modal_data: {},
  close_position: false,
  selected_position_id: '',
  cancel_order: false,
  selected_cancel_id: '',
  selected_input: '',
  take_profit_modal: false,
  take_profit_modal_detail: {},
  selected_tab_panel: 'position',
  position_detail: [],
  order_detail: [],
  history_detail: [],
}

const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    setShareButton: (state,action) => {
      state.share_modal = action.payload.status
      state.share_modal_detail = action.payload.data
    },
    setOrderDetailModal: (state, action) => {
      state.order_detail_modal = action.payload.status
      state.order_detail_modal_data = action.payload.data
    },
    setPositionDetailModal: (state, action) => {
      state.position_detail_modal = action.payload.status
      state.position_detail_modal_data = action.payload.data
    },
    setTradeData: (state, action) => {
      for (var key in action.payload) {
        void (state.trade_data[key] = action.payload[key])
      }
    },
    setPrompWindow: (state, action) => {
      state.prompt_confirm_window = action.payload
    },
    setTradingViewInfo: (state, action) => {
      for (var key in action.payload) {
        void (state.trade_data[key] = action.payload[key])
      }
      state.trade_data = {
        type: state.trade_data.type,
        orderType: state.trade_data.orderType,
        orderPrice: '',
        triggerPrice: '',
        expiredAt: state.trade_data.expiredAt,
        collateral: '',
        expiredAt: '24',
        leverage: '1',
      }
    },
    handlePercentageInput: (state, action) => {
      state.selected_input = action.payload
    },
    setCancelOrder: (state, action) => {
      if(action.payload.icon){
        if (action.payload.status) {
          state.order_detail_modal = false
        } else {
          state.order_detail_modal = true
        }
      }
      state.cancel_order = action.payload.status
      state.selected_cancel_id = action.payload.id
    },
    setClosePosition: (state, action) => {
      state.close_position = action.payload.status
      state.selected_position_id = action.payload.id
    },
    setSelectedInput: (state, action) => {
      state.selected_input = action.payload
    },
    setTakeProfitModal: (state, action) => {
      if (action.payload.isOpen) {
        state.position_detail_modal = false
      } else {
        if(action.payload.position_modal){
          state.position_detail_modal = false
        }else{
          state.position_detail_modal = true
        }
      }
      state.take_profit_modal = action.payload.isOpen
      state.position_detail_modal_data = action.payload.data
      state.selected_input = 'take'
    },
    setSelectedTabPanel: (state, action) => {
      state.selected_tab_panel = action.payload
    },
    setTradeConfirmModal: (state, action) => {
      state.trade_confirm_modal = action.payload.status
      state.trade_confirm_data = action.payload.data
      if (action.payload.status == false) {
        state.trade_confirm_data = {}
        state.trade_data = {
          type: state.trade_data.type,
          orderType: state.trade_data.orderType,
          orderPrice: '',
          triggerPrice: '',
          expiredAt: state.trade_data.expiredAt,
          collateral: '',
          expiredAt: '24',
          leverage: '1',
        }
      }
    },
    handleTradeDisconnect:(state, action) => {
      state.position_detail = []
      state.order_detail = []
    },
  },
  extraReducers: {
    [setTpSl.pending]: (state, action) => {
      state.tpsl_loading = true
    },
    [setTpSl.fulfilled]: (state, action) => {
      state.tpsl_loading = false
      state.take_profit_modal = false
    },
    [setTpSl.rejected]: (state, action) => {
      state.tpsl_loading = false
    },
    [cancelPosition.pending]: (state, action) => {
      state.trade_position_cancel_loading = true
    },
    [cancelPosition.fulfilled]: (state, action) => {
      state.trade_position_cancel_loading = false
      state.close_position = false
    },
    [cancelPosition.rejected]: (state, action) => {
      state.trade_position_cancel_loading = false
      state.close_position = false
    },
    [getAllOrder.fulfilled]: (state, action) => {
      state.order_detail = action.payload
    },
    [getPosition.fulfilled]: (state, action) => {
      state.position_detail = action.payload
    },
    [getFill.fulfilled]: (state, action) => {
      state.history_detail = action.payload
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.cancel_order = false
      state.order_detail_modal = false
      state.trade_order_cancel_loading = false
    },
    [cancelOrder.pending]: (state, action) => {
      state.trade_order_cancel_loading = true
    },
    [cancelOrder.rejected]: (state, action) => {
      state.trade_order_cancel_loading = false
    },
    [createOrder.pending]: (state, action) => {
      state.trade_order_loading = true
    },
    [createOrder.rejected]: (state, action) => {
      state.trade_order_loading = false
    },
    [createOrder.fulfilled]: (state, action) => {
      state.trade_order_loading = false
      state.trade_data = {
        type: state.trade_data.type,
        orderType: state.trade_data.orderType,
        orderPrice: '',
        triggerPrice: '',
        expiredAt: state.trade_data.expiredAt,
        collateral: '',
        expiredAt: '24',
        leverage: '1',
      }
    },
  },
})

export const {
  setShareButton,
  handleTradeDisconnect,
  setOrderDetailModal,
  setPositionDetailModal,
  setTradeData,
  setTradeConfirmModal,
  setTradingViewInfo,
  setCancelOrder,
  setClosePosition,
  setSelectedInput,
  setTakeProfitModal,
  setSelectedTabPanel,
  setPositionType,
  setPrompWindow,
} = tradeSlice.actions
const { reducer } = tradeSlice
export default reducer
