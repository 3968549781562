import { MDBCard, MDBCardBody, MDBCardText, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit'

//assets
import { Icon } from '@iconify/react'
import { useDispatch } from 'react-redux'
import { setModalAnnouncement } from '../../../redux/reducer/homeReducer'

import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function AnnouncementCard({ listing }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  var date1 = new Date(listing.status)
  var date2 = new Date()
  var Difference_In_Time = date2.getTime() - date1.getTime()

  // To calculate the no. of days between two dates
  var day = Math.floor(Difference_In_Time / (1000 * 3600 * 24))

  return (
    <MDBCard
      className="annoucement-card-container"
      onClick={() => {
        dispatch(setModalAnnouncement({ status: true, data: listing }))
      }}>
      {/* {listing.videoCard ? (
        <div className="video-container disable">
          <Player
            controls={false}
            muted
            duration={9}
            poster={listing.poster ? listing.poster : false}></Player>
        </div>
      ) : ( */}
      <LazyLoadImage
        className="card-img-top"
        src={listing.image}
        position="top"
        height="auto"
        width="auto"
        alt="..."
      />
      {/* )} */}
      <MDBCardBody style={{ position: 'relative' }}>
        <MDBCardText>
          {listing.title.length > 72 ? listing.title.slice(0, 72) + '...' : listing.title}
        </MDBCardText>
        <MDBRow style={{ position: 'absolute', width: '100%', bottom: '0.5em' }}>
          <MDBCol>
            <MDBBtn className="people-btn">
              {/* <img src={Avatar} width="25" /> */}
              <span>{t(`${listing.people}`)}</span>
            </MDBBtn>
          </MDBCol>
          <MDBCol className="time-status">
            <label>
              <Icon icon="bx:time" color="#9091a0" width="15" height="15" />
              {day == 0 ? 'Today' : t('days ago', { day })}
            </label>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}
