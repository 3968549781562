import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import useConnect from '../../../hook/useConnect'

//assets
import {
  WalletConnectIcon1,
  WalletConnectIcon2,
  WalletConnectIcon3,
  WalletConnectIcon4,
  WalletConnectIcon5,
} from '../../../assets/images'

//redux
import useAddToNetwork from '../../../hook/useAddToNetwork'
import { useTranslation } from 'react-i18next'

export default function ChooseWallet() {
  const connection = useConnect()
  const { t } = useTranslation()
  const { addToNetwork } = useAddToNetwork()
  // const { accounts } = useSelector((state) => state.connector)

  const walletList = [
    {
      name: 'MetaMask',
      icon: WalletConnectIcon1,
      action: () => {
        // useConnect({ walletType: 'metamaskWallet' })
        // connection({ walletType: 'metamaskWallet' })
        addToNetwork('metamaskWallet')
      },
    },
    {
      name: 'BitKeep',
      icon: WalletConnectIcon2,
      action: () => {
        // connectWallet({ walletType: 'bitkeepWallet' })
        connection({ walletType: 'bitkeepWallet' })
      },
    },
    {
      name: 'imToken',
      icon: WalletConnectIcon3,
      action: () => {
        // connectWallet({ walletType: 'imtokenWallet' })
        connection({ walletType: 'imtokenWallet' })
      },
    },
    {
      name: 'WalletConnect',
      icon: WalletConnectIcon4,
      action: () => {
        // connectWallet({ walletType: 'connectorWallet' })
        connection({ walletType: 'connectorWallet' })
      },
    },
    {
      name: 'OKX Wallet',
      icon: WalletConnectIcon5,
      action: () => {
        // connectWallet({ walletType: 'connectorWallet' })
        addToNetwork('okxWallet')
      },
    },
  ]

  return (
    <MDBContainer>
      <label className="header-title">{t('Connect your wallet')}</label>
      <div>
        {walletList.map((item, index) => (
          <div key={index}>
            <MDBRow className="wallet-connect-btn" onClick={item.action}>
              <MDBCol>{item.name}</MDBCol>
              <MDBCol className="text-end">
                <img src={item.icon} width="45" height="40" />
              </MDBCol>
            </MDBRow>
          </div>
        ))}
      </div>
    </MDBContainer>
  )
}
