import { useEffect, useState, useRef } from 'react'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Dropdown } from 'primereact/dropdown'
import { toast } from 'react-toastify'

//assets
import FullGif from '../../../assets/images/gif/favourite-icon-without-looping.gif'

//service
import { widget } from '../../../charting_library'
import Datafeed from '../../../datafeed'
import { parseFullSymbol } from '../../../helpers'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setTradingViewInfo } from '../../../redux/reducer/tradeReducer'

//hook
import useTradeOrder from '../../../hook/useTradeOrder'
import { useTranslation } from 'react-i18next'

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export default function TradeView({ responsive }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { handlePairing, convertPairing, convertInterval } = useTradeOrder()
  const [onChartReadyStatus, setOnChartReadyStatus] = useState(false)
  const [tvWidget, setTvWidget] = useState()
  const [changeSymbol, setChangeSymbol] = useState(
    convertPairing(localStorage.getItem('symbol'), 'exchange'),
  )

  const { listing } = useSelector((state) => state.market)
  let symbolList = []
  const ref = useRef()

  const symbol = localStorage.getItem('symbol')

  useEffect(() => {
    setOnChartReadyStatus(true)
    const widgetOptions = {
      // debug: true,
      symbol: changeSymbol,
      fullscreen: false,
      interval: convertInterval(localStorage.getItem('interval')),
      container: ref.current,
      datafeed: Datafeed,
      library_path: '/charting_library/',
      autosize: true,
      hide_legend: true,
      locale: getLanguageFromURL() || 'en',
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      theme: 'dark',
      show_popup_button: true,
      popup_width: '1000',
      popup_height: '650',
      backgroundColor: 'rgba(255,255,255,1)',
      enabled_features: ['hide_left_toolbar_by_default'],
      disabled_features: ['use_localstorage_for_settings', 'header_symbol_search'],
      loading_screen: { backgroundColor: '#242628', foregroundColor: '#242628' },
      custom_css_url: 'style.css',
    }

    const tvWidget = new widget(widgetOptions)
    setTvWidget(tvWidget)
    tvWidget.applyOverrides({
      'mainSeriesProperties.style': localStorage.getItem('chart.style')
        ? Number(localStorage.getItem('chart.style'))
        : 1,
      'scalesProperties.fontSize': 10,
      'paneProperties.background': '#242628',
      'paneProperties.backgroundType': 'solid',
    })

    tvWidget.onChartReady(() => {
      setOnChartReadyStatus(false)
      // var chart = tvWidget.chart()
      // tvWidget.chart().setSymbol(changeSymbol)
      // tvWidget
      //   .activeChart()
      //   .onSymbolChanged()
      //   .subscribe(null, () => {
      //     // Datafeed.resetData()
      //   })
      tvWidget.activeChart().executeActionById('chartReset')
      tvWidget.chart().resetData()
      tvWidget
        .activeChart()
        .onChartTypeChanged()
        .subscribe(null, (chartType) => localStorage.setItem('chart.style', chartType))
      const symbolName = parseFullSymbol(tvWidget.chart().symbol())
      dispatch(
        setTradingViewInfo({
          exchange: symbolName.exchange,
        }),
      )
      dispatch(
        setTradingViewInfo({
          symbol: `${symbolName.fromSymbol}/USD`,
        }),
      )
    })
  }, [changeSymbol])

  const open_price_feed = localStorage.getItem('open')
  const close_price_feed =
    symbol && listing[handlePairing('symbol', symbol)].normal_price
      ? listing[handlePairing('symbol', symbol)].normal_price
          .toFixed(listing[handlePairing('symbol', symbol)].decimal)
          .replace(/\.?0+$/, '')
      : listing['BNB'].price

  let array = []
  let favourite = []

  Object.entries(listing).map((item, index) => {
    if (item[1].symbol.startsWith('Crypto')) {
      array.push(item[1].symbol.split('.').pop())
    }
    symbolList.push({
      label: `${item[1].to}/${item[1].from}`,
      name: `${item[1].to}/${item[1].from}`,
      value: `${item[1].type}.${item[1].to}/${item[1].from}`,
    })
    if (item[1].is_favourite) {
      favourite.push(item[1])
    }
  })

  return (
    <MDBCol
      className="col-12"
      style={{
        height: responsive == 'mobile' ? '20em' : favourite.length > 0 ? '22em' : '24.5em',
        marginBottom: responsive == 'mobile' ? '3em' : favourite.length > 0 ? '6em' : '3.5em',
      }}>
      <MDBContainer style={{ paddingLeft: '0' }}>
        {responsive == 'desktop' && favourite.length > 0 && (
          <MDBRow className="trade-favorite-list">
            <MDBCol className="col-1" style={{ width: '2em' }}>
              <img src={FullGif} width="25" height="25" />
            </MDBCol>
            <MDBCol className="col-11 d-flex align-items-center">
              <div className="trade-favorite-content">
                {favourite.map((item, index) => (
                  <div key={index}>
                    {favourite ? (
                      <div
                        className="favourite-item"
                        onClick={() => {
                          if (array.includes(item.title + '/' + item.from)) {
                            localStorage.setItem('decimal', item.decimal)
                            localStorage.setItem('symbol', item.title + '/' + item.from)
                            setChangeSymbol(
                              convertPairing(item.title + '/' + item.from, 'exchange'),
                            )
                            dispatch(
                              setTradingViewInfo({
                                symbol: item.title + '/' + item.from,
                              }),
                            )
                            tvWidget.chart().setSymbol(item.title + '/' + item.from)
                          } else {
                            toast.info(
                              <div>
                                <MDBRow className="m-0">
                                  <MDBCol className="text-start">
                                    <label className="_label">
                                      {item.title + '/' + item.from} {t('Coming Soon')}
                                    </label>
                                  </MDBCol>
                                </MDBRow>
                              </div>,
                              {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                // progress: undefined,
                                theme: 'dark',
                              },
                            )
                          }
                        }}>
                        <img src={item.image} width="30" />
                        <label className="_label --symbol" style={{ cursor: 'pointer' }}>
                          {item.title} <span className="_label --currency">USDT</span>
                        </label>
                        <label className="_label --price-rate">{item.price}</label>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            </MDBCol>
          </MDBRow>
        )}
        <div className="trade-view-header">
          <div className="trade-view-details --symbol">
            <Dropdown
              value={changeSymbol}
              onChange={(e) => {
                if (array.includes(e.originalEvent.target.innerText)) {
                  tvWidget.chart().setSymbol(e.originalEvent.target.innerText)
                  setChangeSymbol(e.value)
                  localStorage.setItem(
                    'decimal',
                    listing[handlePairing('symbol', e.originalEvent.target.innerText)].decimal,
                  )
                  localStorage.setItem('symbol', e.originalEvent.target.innerText)
                  dispatch(
                    setTradingViewInfo({
                      symbol: e.originalEvent.target.innerText,
                    }),
                  )
                  localStorage.removeItem('i')
                } else {
                  toast.info(
                    <div>
                      <MDBRow className="m-0">
                        <MDBCol className="text-start">
                          <label className="_label">
                            {e.originalEvent.target.innerText} {t('Coming Soon')}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </div>,
                    {
                      position: 'top-left',
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      // progress: undefined,
                      theme: 'dark',
                    },
                  )
                }
              }}
              filter
              filterBy="label"
              options={symbolList}
            />
          </div>
          <div className="trade-view-details --price-rate">
            <label className={'_label --trade-price --long d-flex'}>{close_price_feed}</label>
          </div>
          {responsive == 'desktop' && (
            <div className="trade-view-col">
              <label className="_label --trade-label">
                {t('Funding')} ({t('Long')})
              </label>
              <label className="_label --trade-rate">0.01%</label>
            </div>
          )}
          {responsive == 'desktop' && (
            <div className="trade-view-col">
              <label className="_label --trade-label">
                {t('Funding')} ({t('Short')})
              </label>
              <label className="_label --trade-rate">0.01%</label>
            </div>
          )}
          {responsive == 'desktop' && (
            <div className="trade-view-col">
              <label className="_label --trade-label">{t('Rollover')}</label>
              <label className="_label --trade-rate">0.01%</label>
            </div>
          )}
        </div>
      </MDBContainer>
      <MDBContainer className="trading-view-container p-0 h-100">
        <div ref={ref} id="tradingview_90063" className="h-100" />
      </MDBContainer>
    </MDBCol>
  )
}
