import { MDBContainer } from 'mdb-react-ui-kit'
import { useEffect } from 'react'
import _ from 'lodash'

//redux
import { handleBackdrop, setWalletConnect } from '../../redux/reducer/connectorReducer'
import { useDispatch, useSelector } from 'react-redux'

//component
import ButtonConnectWallet from '../element/btnConnectWallet'
import ButtonDisconnect from '../element/btnDisconnect'

import useEventHandler from '../../hook/useEventHandler'
import { useTranslation } from 'react-i18next'

export default function Header({ announcementComponent }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { reconnectInjected, walletConnectEventHandler, injectedEventHandler } = useEventHandler()
  const { openRecoverKeySetting, openSettingModal, refresh, provider, backdrop } = useSelector(
    (state) => state.connector,
  )

  useEffect(() => {
    injectedEventHandler()
  }, [])

  useEffect(() => {
    if (provider) {
      if (provider == 'injected') {
        reconnectInjected()
        walletConnectEventHandler()
      } else {
        walletConnectEventHandler()
      }
    }
  }, [refresh])

  return (
    <MDBContainer className="common-header-main">
      {announcementComponent}
      <ButtonConnectWallet />
      {openRecoverKeySetting && (
        <MDBContainer className="setting-container recover-key">
          <label>
            {t('Welcome back!')}
            <br /> <span>{t('Sign in to verify ownership')}.</span>
          </label>
          <button
            className="recover-btn"
            onClick={() => {
              dispatch(setWalletConnect(true))
            }}>
            {t('Signature Here')}
          </button>
          <ButtonDisconnect />
        </MDBContainer>
      )}
      {openSettingModal && (
        <MDBContainer className="setting-container">
          <ButtonDisconnect />
        </MDBContainer>
      )}
      {backdrop && (
        <div
          onClick={() => {
            dispatch(handleBackdrop())
          }}
          className="backdrop-container"></div>
      )}
    </MDBContainer>
  )
}
