import { MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit'
import web3Services from '../../../services/web3.services'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export default function PortfolioWallet({}) {
  const { walletBalance } = useSelector((state) => state.profile)
  const { t } = useTranslation()

  const balance = Math.floor(walletBalance * Math.pow(10, 2)) / Math.pow(10, 2)

  return (
    <MDBCol className="portfolio-wallet-container">
      <MDBRow>
        <MDBCol>
          <label className="header-wallet">{t('Wallet Balance')}</label>
          <div className="price-wallet">
            <label>{web3Services.isSignRequire ? balance.toLocaleString() : '--'}</label>
            <span>USDT</span>
          </div>
          <MDBBtn className="wallet-btn">
            {web3Services.isSignRequire() ? t('Connected') : t('Connect')}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  )
}
