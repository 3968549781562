import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { handleDisconnect } from '../../redux/reducer/connectorReducer'
import _ from 'lodash'
import { connectors } from '../../pages/Home/component/connectors'
//assets
import { Icon } from '@iconify/react'
import { handleProfileDisconnect } from '../../redux/reducer/profileReducer'
import { handleTradeDisconnect } from '../../redux/reducer/tradeReducer'
import { useTranslation } from 'react-i18next'
export default function ButtonDisconnect() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const provider = localStorage.getItem('provider')
  const { provider } = useSelector((state) => state.connector)

  const { activate: networkActive, deactivate } = useWeb3React()

  return (
    <button
      type="button"
      className="disconnect-btn"
      onClick={() => {
        dispatch(handleDisconnect({ provider: provider }))
        dispatch(handleProfileDisconnect())
        dispatch(handleTradeDisconnect())
        _.isEqual(provider, 'connectorWallet') ? connectors[provider].killSession() : deactivate()
      }}>
      <Icon icon="fluent-emoji-high-contrast:cross-mark-button" color="#6f6e84" /> {t('Disconnect')}
    </button>
  )
}
