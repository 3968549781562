import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { useTranslation } from 'react-i18next'

import {
  CyberConnect,
  DoraHacks,
  Galxe,
  Guild,
  Port3,
  Pyth,
  Quest3,
  SpaceID,
  TaskOn,
  Trantor,
  BNBLogo,
  TradingView,
  Crew3,
  DappBay,
  DappRadar,
  OpenSea,
  Toz,
  NFTTrade,
  Element,
} from '../../../assets/images'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function Partner({ responsive }) {
  const { t } = useTranslation()

  return (
    <MDBCol className="home-market-container home-partner-container">
      <MDBRow className="header-container-home">
        <label>{t(`Our Partners`)}</label>
        <span>
          {t(
            `Our projects are seamlessly integrated providing a cohesive and efficient solution for our clients`,
          )}
        </span>
      </MDBRow>
      <MDBRow className="partner-row mt-2">
        <MDBCol className="col-4">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={BNBLogo}
              width={responsive == 'mobile' ? '85' : '210'}
              height={responsive == 'mobile' ? '32.22' : '79.6'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-4">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={TradingView}
              width={responsive == 'mobile' ? '85' : '210'}
              height={responsive == 'mobile' ? '32.22' : '79.6'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-4">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={Pyth}
              width={responsive == 'mobile' ? '85' : '210'}
              height={responsive == 'mobile' ? '32.22' : '79.6'}
            />
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="partner-row">
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={CyberConnect}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={Guild}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>

        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={Quest3}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>

        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={TaskOn}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="partner-row">
        <MDBCol className="col3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={DappRadar}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={DappBay}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={Crew3}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={Trantor}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="partner-row">
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={Galxe}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={DoraHacks}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={SpaceID}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <LazyLoadImage
              alt="..."
              src={Port3}
              width={responsive == 'mobile' ? '70' : '140'}
              height={responsive == 'mobile' ? '26.54' : '53.07'}
            />
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="partner-row">
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <a
              target="_blank"
              href="https://tofunft.com/collection/deri-trade-alpha-pass/items"
              aria-label="toz">
              <LazyLoadImage
                alt="..."
                src={Toz}
                width={responsive == 'mobile' ? '70' : '140'}
                height={responsive == 'mobile' ? '26.54' : '53.07'}
              />
            </a>
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <a
              target="_blank"
              href="https://element.market/collections/deri-trade-alpha-pass"
              aria-label="element">
              <LazyLoadImage
                alt="..."
                src={Element}
                width={responsive == 'mobile' ? '70' : '140'}
                height={responsive == 'mobile' ? '26.54' : '53.07'}
              />
            </a>
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <a
              target="_blank"
              href="https://opensea.io/collection/deri-trade-alpha-pass"
              aria-label="opensea">
              <LazyLoadImage
                alt="..."
                src={OpenSea}
                width={responsive == 'mobile' ? '70' : '140'}
                height={responsive == 'mobile' ? '26.54' : '53.07'}
              />
            </a>
          </div>
        </MDBCol>
        <MDBCol className="col-3">
          <div className="d-grid justify-content-center align-items-center">
            <a
              target="_blank"
              href="https://nftrade.com/collection/deri-trade-alpha-pass"
              aria-label="nfttrade">
              <LazyLoadImage
                alt="..."
                src={NFTTrade}
                width={responsive == 'mobile' ? '70' : '140'}
                height={responsive == 'mobile' ? '26.54' : '53.07'}
              />
            </a>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  )
}
