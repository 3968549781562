import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import Loader from '../../../assets/images/svg/loader.svg'
import { useTranslation } from 'react-i18next'

export default function LinkingWalletStep1({ verifyStatus }) {
  const { t } = useTranslation()
  return (
    <MDBRow className="verify-containet">
      <MDBCol className="col-3">
        <div className="round-btn">
          {verifyStatus == 'success' ? (
            <Icon
              icon="material-symbols:check-small-rounded"
              color="#f0a000"
              width="40"
              height="40"
            />
          ) : verifyStatus == 'pending' ? (
            <img src={Loader} width="45" />
          ) : (
            <Icon icon="bx:user-circle" color="#EDEBEB" width="25" height="30" />
          )}
        </div>
      </MDBCol>
      <MDBCol className="p-0">
        <label className="title">{t('Verify ownership')}</label>
        <span className="desc">{t('Confirm you are the owner of this wallet')}</span>
      </MDBCol>
    </MDBRow>
  )
}
