import { parseFullSymbol } from './helpers.js'

import store from "../src/redux/store"
import moment from 'moment'

function getNextDailyBarTime(barTime,resolution) {
  if(resolution == "1"){
    const date = barTime + 60 * 1000
    return date
  }else if(resolution == "5"){
    const date = barTime + 300 * 1000
    return date
  }else if(resolution == "15"){
    const date = barTime + 900 * 1000
    return date
  }else if(resolution == "30"){
    const date = barTime + 1800 * 1000
    return date
  }else if(resolution == "60"){
    const date = new Date(barTime * 1000)
    date.setDate(date.getDate() + 1)
    return date.getTime() / 1000
  } else if(resolution == "120"){
    const date = barTime + 7200 * 1000
    return date
  } else if(resolution == "240"){
    const date = barTime + 14400 * 1000
    return date
  }else if(resolution == "360"){
    const date = barTime + 21600 * 1000
    return date
  }else if(resolution == "720"){
    const date = barTime + 43200 * 1000
    return date
  }else if(resolution == "1440"){
    const date = barTime + 86400 * 1000
    return date
  }

}

function getSymbol(symbol){
  if (symbol.startsWith('USD')) {
    return symbol.split('/').pop()
  } else {
    return symbol.split('/')[0]
  }
}

export async function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar,
) {
  let bar = {}
  // if(lastDailyBar  && lastDailyBar.time){
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name)
    const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time, resolution)
    store.subscribe(updateBar)
    function updateBar(){
      const state = store.getState().market.listing[localStorage.getItem("symbol") ? localStorage.getItem("symbol").split("/")[0] : "BNB"]
      if (moment.utc(new Date()).valueOf() >= nextDailyBarTime) {
        bar = {
          time: state.time === 0 ? lastDailyBar.time : moment.utc(new Date()).valueOf(),
          open: state.normal_price,
          high: state.normal_price,
          low: state.normal_price,
          close: state.normal_price,
        }
      } else {
        bar = {
          subscriberUID,
          resolution,
          lastDailyBar,
          time: state.time === 0 ? lastDailyBar.time : moment.utc(new Date()).valueOf(),
          open: state.normal_price,
          high: state.normal_price,
          low: state.normal_price,
          close: state.normal_price,
        }
      }
      onRealtimeCallback(bar)
    }
  // }else{
  //   store.subscribe(updateBar)
  //   function updateBar(){
  //   const state = store.getState().market.listing[localStorage.getItem("symbol") ? getSymbol(localStorage.getItem("symbol")) : "BNB"]
  //   bar = {
  //     time: moment.utc(new Date()).valueOf(),
  //     open: state.normal_price,
  //     high: state.normal_price,
  //     low: state.normal_price,
  //     close: state.normal_price,
  //   }
  //   onRealtimeCallback(bar)
  //  }
  // }
  // console.log("bar",bar)

}

export function unsubscribeFromStream(subscriberUID) {
}
