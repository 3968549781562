import { MDBContainer, MDBCol } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { useState } from 'react'

//REDUX
import { useDispatch, useSelector } from 'react-redux'
import { searchMarket } from '../../redux/reducer/marketReducer'
import { useTranslation } from 'react-i18next'

export default function Header() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useSelector((state) => state.market)
  const [animation, setAnimation] = useState(false)

  return (
    <MDBContainer className="common-header-main">
      <MDBCol className={animation ? 'back-btn after' : 'back-button'}>
        <div className="round-btn">
          {animation && (
            <input
              className="input-search"
              value={search}
              placeholder={t('Search')}
              onChange={(e) => dispatch(searchMarket(e.target.value))}
            />
          )}
          <Icon
            icon="eva:search-outline"
            color="#edebeb"
            width="20"
            height="30"
            onClick={() => setAnimation(!animation)}
          />
        </div>
      </MDBCol>
    </MDBContainer>
  )
}
