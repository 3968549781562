import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    Aapl,
    ADA,
    Amzn,
    ATOM,
    Aud,
    AVAX,
    BCH,
    BNB,
    BTC,
    Cad,
    Chf,
    Cnh,
    Doge,
    Dot,
    Eth,
    Eur,
    Gbp,
    Goog,
    Hkd,
    HKD,
    Jpy,
    Ltc,
    Matic,
    Mxn,
    Near,
    Nflx,
    Nzd,
    Sgd,
    Tsla,
    Xag,
    Xau,
    Zar,
  } from '../../assets/images'
import apiServices from "../../services/api.services";

export const getFavourite = createAsyncThunk("favourite", async ({}, thunkAPI) => {
  let account = localStorage.getItem('accounts')
  if(account) {
    try{
        const response = await apiServices.getFavourite({ account: account});
        return response;
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
  }else{
    if (localStorage.getItem('favourite')) {
      return localStorage.getItem('favourite').split(',')
    }
  }
});

export const addFavourite = createAsyncThunk("addFavourite", async (pairing, thunkAPI) => {
  pairing = pairing + '/USDT'
  let account = localStorage.getItem('accounts')
  try{
      if(account) {
        const response = await apiServices.addFavourite({ address: account, pairing: pairing});
        return response;
      }else{
        let favourite = localStorage.getItem('favourite')
        if (favourite) {
          localStorage.setItem('favourite', favourite + ',' + pairing)
        } else {
          localStorage.setItem('favourite', pairing)
        }
      }
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
});

export const removeFavourite = createAsyncThunk("removeFavourite", async (pairing, thunkAPI) => {
  pairing = pairing + '/USDT'
  let account = localStorage.getItem('accounts')
  try{
      if(account) {
        const response = await apiServices.deleteFavourite({ address: account, pairing: pairing});
        return pairing;
      }else{
        let favourite = localStorage.getItem('favourite')
        if (favourite) {
          favourite = favourite.split(',')
    
          const filtered_favourite = favourite.filter((item) => {
            if (item != pairing) {
              return item
            }
          })
          localStorage.setItem('favourite', filtered_favourite.toString())
          return pairing
        }
      }
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
});

export const getMarketInfo = createAsyncThunk(
  'get/marketInfo',
  async ({ data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.marketInfo(data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const initialState = {
    refresh: false,
    marketType: "ALL",
    search: "",
    trade_favorite_list: [],
    favourite: [],
    marketData: {
        volume: '-',
        interest: '-',
        trades: '-',
    },
    listing: {
        AVAX: {
          image: AVAX,
          title: 'AVAX',
          symbol: "Crypto.AVAX/USD",
          from: "USD",
          to: "AVAX",
          type: 'Crypto',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        ADA: {
          image: ADA,
          title: 'ADA',
          symbol: "Crypto.ADA/USD",
          from: "USD",
          to: "ADA",
          type: 'Crypto',
          decimal: 6,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        ATOM: {
          image: ATOM,
          title: 'ATOM',
          symbol: "Crypto.ATOM/USD",
          from: "USD",
          to: "ATOM",
          type: 'Crypto',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        BCH: {
          image: BCH,
          title: 'BCH',
          symbol: "Crypto.BCH/USD",
          from: "USD",
          to: "BCH",
          type: 'Crypto',
          decimal: 4,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        BNB: {
          image: BNB,
          title: 'BNB',
          symbol: "Crypto.BNB/USD",
          from: "USD",
          to: "BNB",
          type: 'Crypto',
          decimal: 4,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        BTC: {
          image: BTC,
          title: 'BTC',
          symbol: "Crypto.BTC/USD",
          from: "USD",
          to: "BTC",
          type: 'Crypto',
          decimal: 2,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        DOGE: {
          image: Doge,
          title: 'DOGE',
          symbol: "Crypto.DOGE/USD",
          from: "USD",
          to: "DOGE",
          type: 'Crypto',
          decimal: 6,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        DOT: {
          image: Dot,
          title: 'DOT',
          symbol: "Crypto.DOT/USD",
          from: "USD",
          to: "DOT",
          type: 'Crypto',
          decimal: 6,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        ETH: {
          image: Eth,
          title: 'ETH',
          symbol: "Crypto.ETH/USD",
          from: "USD",
          to: "ETH",
          type: 'Crypto',
          decimal: 3,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        LTC: {
          image: Ltc,
          title: 'LTC',
          symbol: "Crypto.LTC/USD",
          from: "USD",
          to: "LTC",
          type: 'Crypto',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        MATIC: {
          image: Matic,
          title: 'MATIC',
          symbol: "Crypto.MATIC/USD",
          from: "USD",
          to: "MATIC",
          type: 'Crypto',
          decimal: 6,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        NEAR: {
          image: Near,
          title: 'NEAR',
          symbol: "Crypto.NEAR/USD",
          from: "USD",
          to: "NEAR",
          type: 'Crypto',
          decimal: 6,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        AAPL: {
          image: Aapl,
          title: 'AAPL',
          symbol: "Equity.US.AAPL/USD",
          from: "USD",
          to: "AAPL",
          type: 'Equity',
          decimal: 3,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        AMZN: {
          image: Amzn,
          title: 'AMZN',
          symbol: "Equity.US.AMZN/USD",
          from: "USD",
          to: "AMZN",
          type: 'Equity',
          decimal: 3,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        GOOG: {
          image: Goog,
          title: 'GOOG',
          symbol: "Equity.US.GOOG/USD",
          from: "USD",
          to: "GOOG",
          type: 'Equity',
          decimal: 3,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        NFLX: {
          image: Nflx,
          title: 'NFLX',
          symbol: "Equity.US.NFLX/USD",
          from: "USD",
          to: "NFLX",
          type: 'Equity',
          decimal: 3,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        TSLA: {
          image: Tsla,
          title: 'TSLA',
          symbol: "Equity.US.TSLA/USD",
          from: "USD",
          to: "TSLA",
          type: 'Equity',
          decimal: 3,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        AUD: {
          image: Aud,
          title: 'AUD',
          symbol: "FX.AUD/USD",
          from: "USD",
          to: "AUD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        EUR: {
          image: Eur,
          title: 'EUR',
          symbol: "FX.EUR/USD",
          from: "USD",
          to: "EUR",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        GBP: {
          image: Gbp,
          title: 'GBP',
          symbol: "FX.GBP/USD",
          from: "USD",
          to: "GBP",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        NZD: {
          image: Nzd,
          title: 'NZD',
          symbol: "FX.NZD/USD",
          from: "USD",
          to: "NZD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        CAD: {
          image: Cad,
          title: 'CAD',
          symbol: "FX.USD/CAD",
          from: "CAD",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        CHF: {
          image: Chf,
          title: 'CHF',
          symbol: "FX.USD/CHF",
          from: "CHF",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        CNH: {
          image: Cnh,
          title: 'CNH',
          symbol: "FX.USD/CNH",
          from: "CNH",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        HKD: {
          image: Hkd,
          title: 'HKD',
          symbol: "FX.USD/HKD",
          from: "HKD",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        JPY: {
          image: Jpy,
          title: 'JPY',
          symbol: "FX.USD/JPY",
          from: "JPY",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        MXN: {
          image: Mxn,
          title: 'MXN',
          symbol: "FX.USD/MXN",
          from: "MXN",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        SGD: {
          image: Sgd,
          title: 'SGD',
          symbol: "FX.USD/SGD",
          from: "SGD",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        ZAR: {
          image: Zar,
          title: 'ZAR',
          symbol: "FX.USD/ZAR",
          from: "ZAR",
          to: "USD",
          type: 'Forex',
          decimal: 5,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        XAU: {
          image: Xau,
          title: 'XAU',
          symbol: "Metal.XAU/USD",
          from: "USD",
          to: "XAU",
          type: 'Metals',
          decimal: 2,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        },
        XAG: {
          image: Xag,
          title: 'XAG',
          symbol: "Metal.XAG/USD",
          from: "USD",
          to: "XAG",
          type: 'Metals',
          decimal: 2,
          priceChange: 0,
          volume: '-',
          funding: '-',
          trade: '-',
          price: "0",
          is_favourite: false,
        }
    },
    datafeed: []
}

const marketSlice = createSlice({
    name: "market",
    initialState,
    reducers:{
        setDataFeed: (state,action) => {
          state.datafeed = action.payload
        },
        selectedMarketType: (state,action) => {
            return{
                ...state,
                marketType: action.payload
            }
        },
        searchMarket: (state,action) => {
            return{
                ...state,
                search: action.payload
            }
        },
        setTradePrice: (state,action) => {
            for(var key in action.payload){
              if(key in state.listing){
                void (state.listing[key] = {...state.listing[key], price: action.payload[key].price, normal_price: action.payload[key].normal_price, time: action.payload[key].time})
              }
            }
        },
    },
    extraReducers:{
        [getMarketInfo.pending]: (state, action) => {

        },
        [getMarketInfo.fulfilled]: (state, action) => {
          for(var key in state.listing){
            const symbol = state.listing[key].to + "/" + state.listing[key].from
            void (state.listing[key] = {
              ...state.listing[key], 
              priceChange: Math.floor(action.payload[symbol].priceChange * Math.pow(10, state.listing[key].decimal)) / Math.pow(10, state.listing[key].decimal),
              trade: Math.floor(action.payload[symbol].trades * Math.pow(10, state.listing[key].decimal)) / Math.pow(10, state.listing[key].decimal),
              volume: Math.floor(action.payload[symbol].volume * Math.pow(10, state.listing[key].decimal)) / Math.pow(10, state.listing[key].decimal),
              })
          }
        },
        [getFavourite.pending]: (state, action) => {
          return {
              ...state,
          };
        },
        [getFavourite.fulfilled]: (state, action) => {
          if(action.payload){
            for (let i = 0; i < action.payload.length; i++){
              for(var key in state.listing){
                if(key + '/USDT' == action.payload[i]){
                  void (state.listing[key].is_favourite = true)
                  // for(var index in state.trade_favorite_list){
                    // void (state.trade_favorite_list =[...state.trade_favorite_list, state.listing[key]])
                  // }
                }
              }
            }
            void (state.favourite = action.payload)
          }
        },
        [getFavourite.rejected]: (state, action) => {
          return {
              ...state,
          };
        },
        [removeFavourite.fulfilled]: (state, action) => {
         for(var key in state.listing){
          if(key + '/USDT' == action.payload){
            void (state.listing[key].is_favourite = false)
          }
        }
        },
        [removeFavourite.rejected]: (state, action) => {
          return {
              ...state,
          };
        },
    }
})

export const {
    selectedMarketType,
    searchMarket,
    setTradePrice,
    setFavourite,
    setDataFeed,
} = marketSlice.actions
const { reducer } = marketSlice
export default reducer;