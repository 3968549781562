import { useState } from 'react'

//lib
import { MDBCardHeader, MDBCol, MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { toast } from 'react-toastify'
import { Icon } from '@iconify/react'

//component
import ModalBox from '../../../components/modal/full.modal.box'

//hook
import useUtils from '../../../hook/useUtils'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getWalletBalance } from '../../../redux/reducer/profileReducer'
import {
  createOrder,
  getAllOrder,
  getPosition,
  setPrompWindow,
  setTradeConfirmModal,
} from '../../../redux/reducer/tradeReducer'
import { useTranslation } from 'react-i18next'
import useTradeOrder from '../../../hook/useTradeOrder'

export default function TradeConfirmModal({ show, title, setShow }) {
  const { t } = useTranslation()
  const { listing } = useSelector((state) => state.market)
  const { handlePairing } = useTradeOrder()

  const trading_view_pricefeed =
    localStorage.getItem('symbol') &&
    listing[handlePairing('symbol', localStorage.getItem('symbol'))].normal_price
      ? listing[handlePairing('symbol', localStorage.getItem('symbol'))].normal_price
          .toFixed(listing[handlePairing('symbol', localStorage.getItem('symbol'))].decimal)
          .replace(/\.?0+$/, '')
      : listing['BNB'].price

  const dispatch = useDispatch()
  const { convertSha256 } = useUtils()
  const { trade_order_loading } = useSelector((state) => state.trade)
  const { trade_data, liquidation, price, fee, symbol, positionSize } = useSelector(
    (state) => state.trade.trade_confirm_data,
  )

  const { token, accounts, selectedTrading } = useSelector((state) => state.connector)
  const { passphrase } = useSelector((state) => state.profile)
  const [promptWindow, setPromptWindow] = useState(false)

  const calculateTimeinForce = () => {
    const selectedTime = new Date(Date.now() + 1 * parseInt(trade_data.expiredAt) * 60 * 60 * 1000)

    return selectedTime.valueOf().toString()
  }
  let order_price = trade_data.type == 'market' ? trading_view_pricefeed : price

  const handleClick = async () => {
    let payload = {
      address: accounts,
      symbol: symbol,
      expiredAt: calculateTimeinForce(),
      price: Number(order_price),
      collateral: Number(trade_data.collateral),
      leverage: Number(trade_data.leverage),
      signature: token,
      timeInForce: 'GTC',
      type: trade_data.type,
      orderType: `open ${trade_data.orderType}`,
      orderMethod: trade_data.orderType,
    }
    dispatch(
      createOrder({
        payload: payload,
        data: convertSha256(token, passphrase),
        network: selectedTrading,
      }),
    )
      .unwrap()
      .then((data) => {
        if (promptWindow) {
          dispatch(setPrompWindow(true))
        }
        dispatch(setTradeConfirmModal({ status: false, data: {} }))
        const result = data.data
        toast(
          <div>
            <MDBRow className="m-0">
              <MDBCol className="text-start">
                <label className="_label">
                  {t(`${result.type}`)} {t(`Order`)}
                </label>
              </MDBCol>
              <MDBCol className="text-end col-4">
                <label className="_label --order-status">{t(`${result.status}`)}</label>
              </MDBCol>
            </MDBRow>
            <MDBRow className="m-0">
              <MDBCol>
                <label className="_label --total-title">{t(`Type`)}</label>
                <label className="_label --total-title">{t(`Collateral`)}</label>
                <label className="_label --total-title">{t(`Price`)}</label>
              </MDBCol>
              <MDBCol className="text-end">
                <label className="_label --amount">{t(`${result.orderType}`)}</label>
                <label className="_label --amount">{result.collateral}</label>
                <label className="_label --amount">{Math.round(result.price * 100) / 100}</label>
              </MDBCol>
            </MDBRow>
          </div>,
          {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            // progress: undefined,
            theme: 'dark',
          },
        )
        dispatch(
          getWalletBalance({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        dispatch(
          getAllOrder({
            payload: {
              address: accounts,
              status: 'all',
            },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        dispatch(
          getPosition({
            payload: {
              address: accounts,
            },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
      })
      .catch((ex) => {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            toast.error(t(`${errors[item]}`), {
              position: 'top-left',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'dark',
            })
          })
        } else {
          toast.error(JSON.stringify(ex.message), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          })
        }
      })
  }

  return (
    <ModalBox
      className={'trade-order-container'}
      show={show}
      setShow={setShow}
      screenSize="s"
      reactModal={true}
      content={
        <div style={{ width: '21em' }}>
          <MDBCardHeader>
            <MDBRow className="d-flex align-items-center">
              <MDBCol className="col-8">
                <label className="_label --symbol">{localStorage.getItem('symbol')}</label>
                <label className={`_label --order-type --${trade_data.orderType}`}>
                  {t(`${`open ${trade_data.orderType}`}`)}
                </label>
              </MDBCol>
              <MDBCol className="back-btn col-4">
                <div
                  className="round-btn"
                  onClick={() => dispatch(setTradeConfirmModal({ status: false, data: {} }))}>
                  <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBModalBody>
            <MDBContainer className="order-detail-container">
              <MDBRow className="d-flex align-items-center justify-content-center m-0">
                <MDBCol className="p-0">
                  <label className="_label --title">{t(`Order Type`)}</label>
                  <label className="_label --title">{t(`Order Price`)}</label>
                  <label className="_label --title">{t(`Position Leverage`)}</label>
                  <label className="_label --title">{t(`Position Size`)}</label>
                  <label className="_label --title">{t(`Fees`)}</label>
                  <label className="_label --title">{t(`Estimated Liq. Price`)}</label>
                </MDBCol>
                <MDBCol className="p-0 text-end">
                  <label className={`_label --title-value --${trade_data.orderType}`}>
                    {t(`${trade_data.type}`)}
                  </label>
                  <label className="_label --title-value">{order_price} USDT</label>
                  {trade_data.type == 'conditional' && (
                    <label className="_label --title-value">{trade_data.orderPrice} USDT</label>
                  )}
                  <label className="_label --title-value">
                    {trade_data.leverage}
                    <span style={{ textTransform: 'lowercase' }}>x</span>
                  </label>
                  <label className="_label --title-value">{positionSize} USDT</label>
                  <label className="_label --title-value">{fee} USDT</label>
                  <label className="_label --title-value">{liquidation}</label>
                </MDBCol>
              </MDBRow>
              <MDBRow className="_checkbox">
                <label className="_checkbox --promp-window">
                  <input
                    type="checkbox"
                    checked={promptWindow}
                    onChange={() => {}}
                    onClick={() => setPromptWindow(!promptWindow)}
                  />
                  {t(`Do not prompt confirmation window anymore`)}
                </label>
              </MDBRow>
              <MDBRow className="--row-special d-flex align-items-center justify-content-center mt-4">
                <button
                  className="_button --confirm"
                  onClick={() => handleClick()}
                  disabled={trade_order_loading}>
                  {trade_order_loading ? t('Creating..') : t('Confirm')}
                </button>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </div>
      }
    />
  )
}
