//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Pusher from 'pusher-js'

//component
import Navbar from '../../components/navbar/navbar-one'
import HeaderDesktop from '../../components/header/header-desktop'
import HeaderMobile from '../../components/header/header-mobile'
import WalletConnectUI from './../ConnectWallet'
import TradeView from './component/trade.view.v2'
import TradeBox from './component/trade.box'
import ControlPanel from './component/control.panel'
import TakePofitModal from './component/take.profit.modal'
import ConfirmModal from './component/confirm.modal'
import useWindowDimensions from '../../components/common/window-dimension'
import OrderDetailModal from './component/order.detail.modal'
import TradeConfirmModal from './component/trade.confirm.modal'
import PositionDetailModal from './component/position.detail.modal'

//hook
import useUtils from '../../hook/useUtils'

//redux
import { getFavourite } from '../../redux/reducer/marketReducer'
import { useDispatch, useSelector } from 'react-redux'
import {
  cancelOrder,
  cancelPosition,
  getAllOrder,
  getPosition,
  setCancelOrder,
  setClosePosition,
  setOrderDetailModal,
  setPositionDetailModal,
  setTradeConfirmModal,
  setTradeData,
} from '../../redux/reducer/tradeReducer'
import { getTradeList, getWalletBalance, login } from '../../redux/reducer/profileReducer'

//service
import web3Services from '../../services/web3.services'
import { useTranslation } from 'react-i18next'
import WalletHistoryModal from '../Profile/component/wallet.history.modal'
import ShareCard from '../../components/modal/share.card.box'

// Pusher.logToConsole = true

export default function Trade() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const token = localStorage.getItem('token')
  const passphrase = localStorage.getItem('passphrase')
  const { convertSha256 } = useUtils()
  const [recoverKeySetting, setRecoverKeySetting] = useState(false)
  const { walletConnect, accounts, selectedTrading } = useSelector((state) => state.connector)
  // const { passphrase } = useSelector((state) => state.profile)
  const {
    share_modal,
    position_detail_modal,
    take_profit_modal,
    selected_cancel_id,
    cancel_order,
    close_position,
    trade_confirm_modal,
    selected_position_id,
    order_detail_modal,
  } = useSelector((state) => state.trade)

  const { wallet_history_modal } = useSelector((state) => state.profile)

  const { width } = useWindowDimensions()
  const [responsive, setResponsive] = useState('mobile')

  useEffect(() => {
    if (width >= '992') {
      setResponsive('desktop')
    } else {
      setResponsive('mobile')
    }
  }, [width])

  useEffect(() => {
    dispatch(getFavourite({}))
    if (web3Services.isSignRequire() && accounts && token) {
      let payload = {
        address: accounts,
        hash: token,
      }
      dispatch(login({ payload }))
        .unwrap()
        .then(() => {
          dispatch(
            getWalletBalance({
              payload: { address: accounts },
              data: convertSha256(token, passphrase),
              network: selectedTrading,
            }),
          )
          dispatch(
            getAllOrder({
              payload: {
                address: accounts,
                status: 'all',
              },
              data: convertSha256(token, passphrase),
              network: selectedTrading,
            }),
          )
          dispatch(
            getPosition({
              payload: {
                address: accounts,
              },
              data: convertSha256(token, passphrase),
              network: selectedTrading,
            }),
          )
          dispatch(
            getTradeList({
              payload: { address: accounts },
              data: convertSha256(token, passphrase),
              network: selectedTrading,
            }),
          )
        })
        .catch((ex) => {
          const errors = ex.response.data.errors
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(t(errors[item]), {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'dark',
              })
            })
          } else {
            toast.error(t(JSON.stringify(ex.message)), {
              position: 'top-left',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'dark',
            })
          }
        })
    }
  }, [accounts])

  useEffect(() => {
    dispatch(setTradeData({ orderType: 'long' }))
  }, [])

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
      cluster: 'ap1',
      auth: {
        headers: {
          Accept: 'application/json',
        },
      },
    })
    var socketId = null
    pusher.connection.bind('connected', () => {
      socketId = pusher.connection.socket_id
    })
    if (accounts && token && passphrase) {
      const orderChannel = pusher.subscribe(`${selectedTrading}-order-${accounts.toUpperCase()}`)
      orderChannel.bind(`processing_order`, (data) => {
        dispatch(
          getAllOrder({
            payload: {
              address: accounts,
              status: 'all',
            },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        dispatch(
          getTradeList({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
      })

      const PositionChannel = pusher.subscribe(
        selectedTrading + '-position-' + accounts.toUpperCase(),
      )
      PositionChannel.bind(`update_position`, (data) => {
        const result = data.data.event
        toast(
          <div>
            <MDBRow className="m-0">
              <MDBCol className="text-start">
                <label className="_label">{result ? t(result) : t('Update Position')}</label>
              </MDBCol>
            </MDBRow>
          </div>,
          {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            // progress: undefined,
            theme: 'dark',
          },
        )
        dispatch(
          getPosition({
            payload: {
              address: accounts,
            },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
      })

      const WalletChannel = pusher.subscribe(selectedTrading + '-wallet-' + accounts.toUpperCase())
      WalletChannel.bind(`update_order`, (data) => {
        dispatch(
          getAllOrder({
            payload: {
              address: accounts,
              status: 'all',
            },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        dispatch(
          getTradeList({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
      })

      WalletChannel.bind(`update_wallet`, (data) => {
        dispatch(
          getWalletBalance({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
      })

      return () => {
        pusher.disconnect()
        orderChannel.unbind(`processing_order`)
        WalletChannel.unbind(`update_wallet`)
        orderChannel.unbind(`update_order`)
        PositionChannel.unbind(`update_position`)
      }
    }
  }, [accounts, token, passphrase, localStorage.getItem('symbol')])

  return (
    <MDBContainer className="market-container" breakpoint="fluid">
      <HeaderDesktop
        recoverKeySetting={recoverKeySetting}
        setRecoverKeySetting={setRecoverKeySetting}
      />
      <div className="line"></div>
      <Navbar />
      {responsive == 'mobile' ? (
        <div className="mobile-version">
          <HeaderMobile />
          <MDBRow>
            <TradeView responsive={responsive} />
            <TradeBox responsive={responsive} />
            <ControlPanel responsive={responsive} />
          </MDBRow>
        </div>
      ) : (
        <MDBRow className="desktop-version">
          <MDBCol className="content-section trade" style={{ zIndex: 1, paddingTop: '0.2em' }}>
            <MDBRow>
              <MDBCol className="col col-12 col-lg-9" style={{ padding: '0em' }}>
                <TradeView responsive={responsive} />
                <ControlPanel responsive={responsive} />
              </MDBCol>
              <TradeBox responsive={responsive} />
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )}
      {walletConnect && <WalletConnectUI setRecoverKeySetting={setRecoverKeySetting} />}
      {take_profit_modal && <TakePofitModal show={take_profit_modal} />}
      {close_position && (
        <ConfirmModal
          show={close_position}
          title={'Close Position'}
          setShow={() => dispatch(setClosePosition(false))}
          handleClick={() =>
            dispatch(
              cancelPosition({
                payload: { address: accounts, id: selected_position_id },
                data: convertSha256(token, passphrase),
                network: selectedTrading,
              }),
            )
              .unwrap()
              .then((data) => {
                dispatch(
                  getAllOrder({
                    payload: {
                      address: accounts,
                      status: 'all',
                    },
                    data: convertSha256(token, passphrase),
                    network: selectedTrading,
                  }),
                )
                dispatch(
                  getPosition({
                    payload: {
                      address: accounts,
                    },
                    data: convertSha256(token, passphrase),
                    network: selectedTrading,
                  }),
                )
              })
              .catch((ex) => {
                const errors = ex.response.data.errors
                if (errors && Object.keys(errors).length > 0) {
                  Object.keys(errors).map((item, i) => {
                    toast.error(
                      <div>
                        <MDBRow className="m-0">
                          <MDBCol className="text-start">
                            <label className="_label">{t('Close Position')}</label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="m-0">
                          <MDBCol>
                            <label className="_label --total-title">{t(errors[item])}</label>
                          </MDBCol>
                        </MDBRow>
                      </div>,
                      {
                        position: 'top-left',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'dark',
                      },
                    )
                  })
                } else {
                  toast.error(JSON.stringify(ex.message), {
                    position: 'top-left',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'dark',
                  })
                }
              })
          }
        />
      )}
      {cancel_order && (
        <ConfirmModal
          show={cancel_order}
          title={'Cancel Order'}
          setShow={() => dispatch(setCancelOrder(false))}
          handleClick={() =>
            dispatch(
              cancelOrder({
                payload: { address: accounts, id: selected_cancel_id },
                data: convertSha256(token, passphrase),
                network: selectedTrading,
              }),
            )
              .unwrap()
              .then((data) => {
                const result = data
                toast(
                  <div>
                    <MDBRow className="m-0">
                      <MDBCol className="text-start">
                        <label className="_label">{t('Cancel Order')}</label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        <label className="_label --order-status">{t(result.status)}</label>
                      </MDBCol>
                    </MDBRow>
                  </div>,
                  {
                    position: 'top-left',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'dark',
                  },
                )
                dispatch(
                  getAllOrder({
                    payload: {
                      address: accounts,
                      status: 'all',
                    },
                    data: convertSha256(token, passphrase),
                    network: selectedTrading,
                  }),
                )
                dispatch(
                  getPosition({
                    payload: {
                      address: accounts,
                    },
                    data: convertSha256(token, passphrase),
                    network: selectedTrading,
                  }),
                )
                dispatch(
                  getTradeList({
                    payload: { address: accounts },
                    data: convertSha256(token, passphrase),
                    network: selectedTrading,
                  }),
                )
              })
              .catch((ex) => {
                const errors = ex.response.data.errors
                if (errors && Object.keys(errors).length > 0) {
                  Object.keys(errors).map((item, i) => {
                    toast.error(
                      <div>
                        <MDBRow className="m-0">
                          <MDBCol className="text-start">
                            <label className="_label">{t('Close Position')}</label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="m-0">
                          <MDBCol>
                            <label className="_label --total-title">{t(errors[item])}</label>
                          </MDBCol>
                        </MDBRow>
                      </div>,
                      {
                        position: 'top-left',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'dark',
                      },
                    )
                  })
                } else {
                  toast.error(t(JSON.stringify(ex.message)), {
                    position: 'top-left',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'dark',
                  })
                }
              })
          }
        />
      )}
      {trade_confirm_modal && (
        <TradeConfirmModal
          show={trade_confirm_modal}
          setShow={() => dispatch(setTradeConfirmModal({ status: false, data: {} }))}
        />
      )}
      {position_detail_modal && (
        <PositionDetailModal
          show={position_detail_modal}
          setShow={() => dispatch(setPositionDetailModal({ status: false, data: {} }))}
        />
      )}
      {order_detail_modal && (
        <OrderDetailModal
          show={order_detail_modal}
          setShow={() => dispatch(setOrderDetailModal({ status: false, data: {} }))}
        />
      )}
      {wallet_history_modal && <WalletHistoryModal show={wallet_history_modal} />}
      {share_modal && <ShareCard show={share_modal} />}
    </MDBContainer>
  )
}
