import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useTradeOrder from '../../../hook/useTradeOrder'

export default function PortfolioReport() {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { netPnl, positionSize, calculateRolloverFee, calculateFundingFee, calculateCloseFee } =
    useTradeOrder()
  const { listing } = useSelector((state) => state.market)
  const { position_detail } = useSelector((state) => state.trade)

  return (
    <MDBCol className="portfolio-report-container">
      <MDBContainer className="p-0">
        {/* <MDBRow className="progress-header">
          <MDBCol className="title">
            <label>Weekly Profit</label>
          </MDBCol>
        </MDBRow>
        <MDBRow className="progress-row">
          <MDBCol className="col-1 report-note-container p-0">
            <MDBRow>
              <label>High</label>
            </MDBRow>
            <MDBRow>
              <label>{high}</label>
            </MDBRow>
            <MDBRow>
              <label>Low</label>
            </MDBRow>
            <MDBRow>
              <label>{low}</label>
            </MDBRow>
          </MDBCol>
          {weeklyReport.map((item, index) => (
            <MDBCol key={index} className="progress-bar-report">
              <div className="progress-bar-col">
                <label>{item.label}</label>
                <MDBProgress height="10">
                  <MDBProgressBar width={item.data} valuemin={0} valuemax={100} />
                </MDBProgress>
              </div>
            </MDBCol>
          ))}
        </MDBRow> */}
        <MDBRow className="sub-header-wallet-container">
          <MDBCol>{/* <span>See All</span> */}</MDBCol>
          <MDBCol className="text-end">
            <label>{t(`Open Position`)}</label>
          </MDBCol>
        </MDBRow>
        {position_detail.length == 0 && (
          <MDBRow className="open-position-container">
            <label className="coming-soon-label">{t(`You have no position`)}</label>
          </MDBRow>
        )}
        {position_detail && position_detail[0] && (
          <MDBRow
            className="open-position-container"
            onClick={() => {
              navigate('/trade')
            }}>
            <MDBCol className="col-4 p-0">{position_detail[0].symbol}</MDBCol>
            <MDBCol
              className={`col-4 p-0 rate ${
                netPnl(
                  position_detail[0].type,
                  positionSize(position_detail[0].amount, position_detail[0].leverage),
                  position_detail[0].symbol
                    ? listing[position_detail[0].symbol.split('/')[0]].normal_price
                    : 0,
                  position_detail[0].averageOpen,
                  calculateRolloverFee(position_detail[0].amount),
                  calculateFundingFee(position_detail[0].amount, position_detail[0].leverage),
                  calculateCloseFee(position_detail[0].amount, position_detail[0].leverage),
                ) > 0
                  ? '--long'
                  : '--short'
              }`}>
              <MDBRow>
                <label>
                  {netPnl(
                    position_detail[0].type,
                    positionSize(position_detail[0].amount, position_detail[0].leverage),
                    position_detail[0].symbol
                      ? listing[position_detail[0].symbol.split('/')[0]].normal_price
                      : 0,
                    position_detail[0].averageOpen,
                    calculateRolloverFee(position_detail[0].amount),
                    calculateFundingFee(position_detail[0].amount, position_detail[0].leverage),
                    calculateCloseFee(position_detail[0].amount, position_detail[0].leverage),
                  )}
                </label>
              </MDBRow>
              <MDBRow className="detail">
                <label style={{ fontSize: '10px' }}>{t(`Net P&L`)}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="col-2 p-0 detail">
              <MDBRow>
                <label>{t(`Type`)}</label>
              </MDBRow>
              <MDBRow>
                <label>{t(`Entry Price`)}</label>
              </MDBRow>
              <MDBRow>
                <label>{t(`Collateral`)}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="col-2 p-0 status">
              <MDBRow className={`type --${position_detail[0].type} m-0`}>
                <label>{t(`${position_detail[0].type}`)}</label>
              </MDBRow>
              <MDBRow className="price m-0">
                <label>{Math.round(position_detail[0].averageOpen * 100) / 100}</label>
              </MDBRow>
              <MDBRow className="size m-0">
                <label>{Math.round(position_detail[0].collateral * 100) / 100}</label>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
        {position_detail && position_detail[1] && (
          <MDBRow
            className="open-position-container"
            onClick={() => {
              navigate('/trade')
            }}>
            <MDBCol className="col-4 p-0">{position_detail[1].symbol}</MDBCol>
            <MDBCol
              className={`col-4 p-0 rate ${
                netPnl(
                  position_detail[1].type,
                  positionSize(position_detail[1].amount, position_detail[1].leverage),
                  position_detail[1].symbol
                    ? listing[position_detail[1].symbol.split('/')[0]].normal_price
                    : 0,
                  position_detail[1].averageOpen,
                  calculateRolloverFee(position_detail[1].amount),
                  calculateFundingFee(position_detail[1].amount, position_detail[1].leverage),
                  calculateCloseFee(position_detail[1].amount, position_detail[1].leverage),
                ) > 0
                  ? '--long'
                  : '--short'
              }`}>
              <MDBRow>
                <label>
                  {netPnl(
                    position_detail[1].type,
                    positionSize(position_detail[1].amount, position_detail[1].leverage),
                    position_detail[1].symbol
                      ? listing[position_detail[1].symbol.split('/')[0]].normal_price
                      : 0,
                    position_detail[1].averageOpen,
                    calculateRolloverFee(position_detail[1].amount),
                    calculateFundingFee(position_detail[1].amount, position_detail[1].leverage),
                    calculateCloseFee(position_detail[1].amount, position_detail[1].leverage),
                  )}
                </label>
              </MDBRow>
              <MDBRow className="detail">
                <label style={{ fontSize: '10px' }}>{t(`Net P&L`)}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="col-2 p-0 detail">
              <MDBRow>
                <label>{t(`Type`)}</label>
              </MDBRow>
              <MDBRow>
                <label>{t(`Entry Price`)}</label>
              </MDBRow>
              <MDBRow>
                <label>{t(`Collateral`)}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="col-2 p-0 status">
              <MDBRow className={`type --${position_detail[1].type} m-0`}>
                <label>{t(`${position_detail[1].type}`)}</label>
              </MDBRow>
              <MDBRow className="price m-0">
                <label>{Math.round(position_detail[1].averageOpen * 100) / 100}</label>
              </MDBRow>
              <MDBRow className="size m-0">
                <label>{Math.round(position_detail[1].collateral * 100) / 100}</label>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
        {position_detail && position_detail[2] && (
          <MDBRow
            className="open-position-container"
            onClick={() => {
              navigate('/trade')
            }}>
            <MDBCol className="col-4 p-0">{position_detail[2].symbol}</MDBCol>
            <MDBCol
              className={`col-4 p-0 rate ${
                netPnl(
                  position_detail[2].type,
                  positionSize(position_detail[2].amount, position_detail[2].leverage),
                  position_detail[2].symbol
                    ? listing[position_detail[2].symbol.split('/')[0]].normal_price
                    : 0,
                  position_detail[2].averageOpen,
                  calculateRolloverFee(position_detail[2].amount),
                  calculateFundingFee(position_detail[2].amount, position_detail[2].leverage),
                  calculateCloseFee(position_detail[2].amount, position_detail[2].leverage),
                ) > 0
                  ? '--long'
                  : '--short'
              }`}>
              <MDBRow>
                <label>
                  {netPnl(
                    position_detail[2].type,
                    positionSize(position_detail[2].amount, position_detail[2].leverage),
                    position_detail[2].symbol
                      ? listing[position_detail[2].symbol.split('/')[0]].normal_price
                      : 0,
                    position_detail[2].averageOpen,
                    calculateRolloverFee(position_detail[2].amount),
                    calculateFundingFee(position_detail[2].amount, position_detail[2].leverage),
                    calculateCloseFee(position_detail[2].amount, position_detail[2].leverage),
                  )}
                </label>
              </MDBRow>
              <MDBRow className="detail">
                <label style={{ fontSize: '10px' }}>{t(`Net P&L`)}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="col-2 p-0 detail">
              <MDBRow>
                <label>{t(`Type`)}</label>
              </MDBRow>
              <MDBRow>
                <label>{t(`Entry Price`)}</label>
              </MDBRow>
              <MDBRow>
                <label>{t(`Collateral`)}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="col-2 p-0 status">
              <MDBRow className={`type --${position_detail[2].type} m-0`}>
                <label>{t(`${position_detail[2].type}`)}</label>
              </MDBRow>
              <MDBRow className="price m-0">
                <label>{Math.round(position_detail[2].averageOpen * 100) / 100}</label>
              </MDBRow>
              <MDBRow className="size m-0">
                <label>{Math.round(position_detail[2].collateral * 100) / 100}</label>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>
    </MDBCol>
  )
}
