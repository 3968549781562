import { MDBCol, MDBRow } from 'mdb-react-ui-kit'

//service
import web3Services from '../../../services/web3.services'

//lib
import { Icon } from '@iconify/react'

//assets
import { Wallet, BNB } from '../../../assets/images'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setWalletConnect } from '../../../redux/reducer/connectorReducer'
import {
  setDepositModal,
  setWalletHistoryModal,
  setWithdrawModal,
} from '../../../redux/reducer/profileReducer'
import { useTranslation } from 'react-i18next'

export default function WalletSummary({ responsive }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { walletBalance } = useSelector((state) => state.profile)

  const balance = Math.floor(walletBalance * Math.pow(10, 2)) / Math.pow(10, 2)

  return (
    <div className="wallet-summary-container">
      <MDBRow className="header-container text-start">
        <label>{t(`Hello`)}..</label>
        <span>{t(`Here is your summary`)}</span>
      </MDBRow>
      <MDBRow>
        <MDBCol className="col-12 col-lg-7">
          <div className="wallet-summary-box">
            <div
              className={
                responsive == 'mobile'
                  ? 'd-flex align-items-center justify-content-center'
                  : 'd-flex align-items-center justify-content-start'
              }>
              <img src={Wallet} width="70" height="70" alt="wallet" />
              <div className="text-detail">
                <label className="title">{t(`Net USDT Value`)}</label>
                <br />
                <label className="price-value">
                  $ {web3Services.isSignRequire ? balance.toLocaleString() : 0}
                </label>
              </div>
            </div>
            <MDBRow className="pt-3">
              <MDBCol>
                <label className="title d-flex align-items-center justify-content-start lh-1">
                  {t(`COIN`)}
                </label>
              </MDBCol>
              <MDBCol className="text-end">
                <label className="title d-flex align-items-center justify-content-end lh-1">
                  {t(`AMOUNT`)}
                  <span>
                    <Icon icon="bi:info" color="#1A1A1A" width="18" height="18" />
                  </span>
                </label>
              </MDBCol>
            </MDBRow>
            {responsive == 'mobile' ? (
              <div>
                <MDBRow className="align-items-center listing">
                  <MDBCol className="col-2">
                    <img src={BNB} width="25" />
                  </MDBCol>
                  <MDBCol className="col-6">
                    <label className="w-100 usdt-text">USDT</label>
                  </MDBCol>
                  <MDBCol className="col-4 text-end">
                    <label className="w-100 usdt-text">
                      {web3Services.isSignRequire ? balance.toLocaleString() : 0}
                    </label>
                  </MDBCol>
                </MDBRow>
                {web3Services.isSignRequire() && (
                  <div className="pt-3 d-flex align-items-center justify-content-center">
                    <button
                      onClick={
                        () => dispatch(setWithdrawModal(true))
                        // toast.info(
                        //   <div>
                        //     <label className="_label">Withdraw Coming Soon</label>
                        //   </div>,
                        //   {
                        //     position: 'top-left',
                        //     autoClose: 2000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     // progress: undefined,
                        //     theme: 'light',
                        //   },
                        // )
                      }
                      className="withdraw-button">
                      {t(`Withdraw`)}
                    </button>
                    <button
                      onClick={() => dispatch(setDepositModal(true))}
                      className="deposit-button">
                      {t(`Deposit`)}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="line-container">
                <button
                  className="history-btn"
                  onClick={() => {
                    if (web3Services.isSignRequire()) {
                      dispatch(setWalletHistoryModal(true))
                    } else {
                      dispatch(setWalletConnect(true))
                    }
                  }}>
                  {t(`History`)}
                </button>
              </div>
            )}
          </div>
        </MDBCol>
        {responsive != 'mobile' ? (
          <MDBCol className="col-12 col-lg-5">
            <MDBCol className="col-12">
              <div className="wallet-summary-box pb-4" style={{ minHeight: '244px' }}>
                <MDBRow>
                  <MDBCol className="col-6">
                    <label className="title d-flex align-items-center justify-content-start lh-1">
                      {t(`COIN`)}
                    </label>
                  </MDBCol>
                  <MDBCol className="text-end col-6 p-0">
                    <label className="title d-flex align-items-center justify-content-end lh-1">
                      {t(`AMOUNT`)}
                      <span>
                        <Icon icon="bi:info" color="#1A1A1A" width="18" height="18" />
                      </span>
                    </label>
                  </MDBCol>
                  {/* <MDBCol className="text-end col-4">
                  <label className="title d-flex align-items-center justify-content-end lh-1">
                    Action
                  </label>
                </MDBCol> */}
                </MDBRow>
                <div className="line-container"></div>
                <MDBRow className="align-items-center listing">
                  <MDBCol className="col-6 d-flex align-items-center">
                    <img src={BNB} width="25" height="25" />
                    <div style={{ paddingLeft: '1em' }}>
                      <label className="w-100 usdt-text">USDT</label>
                    </div>
                  </MDBCol>
                  <MDBCol className="col-6 text-end">
                    <label className="w-100 usdt-text">
                      {web3Services.isSignRequire ? balance.toLocaleString() : 0}
                    </label>
                  </MDBCol>
                  <MDBCol>
                    {web3Services.isSignRequire() && (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginTop: '3.5rem', justifyContent: 'center' }}>
                        <button
                          onClick={
                            () => dispatch(setWithdrawModal(true))
                            // toast.info(
                            //   <div>
                            //     <label className="_label">Withdraw Coming Soon</label>
                            //   </div>,
                            //   {
                            //     position: 'top-left',
                            //     autoClose: 2000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     // progress: undefined,
                            //     theme: 'light',
                            //   },
                            // )
                          }
                          className="withdraw-button">
                          {t(`Withdraw`)}
                        </button>
                        <button
                          onClick={() => dispatch(setDepositModal(true))}
                          className="deposit-button">
                          {t(`Deposit`)}
                        </button>
                      </div>
                    )}
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
          </MDBCol>
        ) : (
          <></>
        )}
        {responsive == 'mobile' ? (
          <MDBCol>
            <button
              className="history-btn"
              onClick={() => {
                if (web3Services.isSignRequire()) {
                  dispatch(setWalletHistoryModal(true))
                } else {
                  dispatch(setWalletConnect(true))
                }
              }}>
              {t('History')}
            </button>
          </MDBCol>
        ) : (
          <></>
        )}
      </MDBRow>
    </div>
  )
}
