//lib
import { MDBCol, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setModalRebateHistory } from '../../../redux/reducer/profileReducer'

//component
import ModalBox from '../../../components/modal/full.modal.box'
import { useTranslation } from 'react-i18next'

export default function RebateHistory({ show, type }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { trade_referrals_rebate_history } = useSelector(
    (state) => state.profile.referral_detail.trade_referrals,
  )

  const { affiliates_rebate_history } = useSelector(
    (state) => state.profile.referral_detail.affiliates,
  )

  return (
    <ModalBox
      reactModal={true}
      show={show}
      setShow={() => {
        dispatch(setModalRebateHistory({ status: false }))
      }}
      // screenSize="fullscreen-md-down"
      className="rebate-history-container"
      content={
        <MDBModalBody>
          <MDBRow className="title-bold m-0">
            <label>{t('Rebate History')}</label>
          </MDBRow>
          <div className="deco-referral">
            <div className="line"></div>
          </div>
          {type == 'trader' ? (
            <div>
              {trade_referrals_rebate_history.length > 0 &&
                trade_referrals_rebate_history.slice(0, 3).map((listing, index) => (
                  <div className="referral-box">
                    <MDBRow className="referral-row">
                      <MDBCol className="title-sub">
                        <label>{t('Date')}</label>
                      </MDBCol>
                      <MDBCol className="value text-end col-5">
                        <label>{listing.date}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="referral-row">
                      <MDBCol className="title-sub">
                        <label>{t('Transaction')}</label>
                      </MDBCol>
                      <MDBCol className="referral-code text-end col-5">
                        <label>{listing.transaction}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="referral-row">
                      <MDBCol className="title-sub">
                        <label>{t('Amount')}</label>
                      </MDBCol>
                      <MDBCol className="value text-end col-5">
                        <label>$ {listing.amount}</label>
                      </MDBCol>
                    </MDBRow>
                    <div className="deco-referral">
                      <div className="line"></div>
                    </div>
                    <div className="deco-referral">
                      <div className="line"></div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div>
              {affiliates_rebate_history.length > 0 &&
                affiliates_rebate_history.slice(0, 3).map((listing, index) => (
                  <div className="referral-box">
                    <MDBRow className="referral-row">
                      <MDBCol className="title-sub">
                        <label>{t('Date')}</label>
                      </MDBCol>
                      <MDBCol className="value text-end col-5">
                        <label>{listing.date}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="referral-row">
                      <MDBCol className="title-sub">
                        <label>{t('Transaction')}</label>
                      </MDBCol>
                      <MDBCol className="referral-code text-end col-5">
                        <label>{listing.transaction}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="referral-row">
                      <MDBCol className="title-sub">
                        <label>{t('Amount')}</label>
                      </MDBCol>
                      <MDBCol className="value text-end col-5">
                        <label>$ {listing.amount}</label>
                      </MDBCol>
                    </MDBRow>
                    <div className="deco-referral">
                      <div className="line"></div>
                    </div>
                    <div className="deco-referral">
                      <div className="line"></div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          <MDBRow className="referrals-btn">
            <button className="more-history-btn">{t('No rebates distribution history yet')}</button>
          </MDBRow>
        </MDBModalBody>
      }
    />
  )
}
