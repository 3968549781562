import { useSelector } from 'react-redux'
import useConnect from './useConnect'
import useUtils from './useUtils'

export default function useAddToNetwork(wallet) {
  const connection = useConnect()
  const { accounts: address } = useSelector((state) => state.connector)
  const { toHex } = useUtils()
  async function addToNetwork(wallet) {
    const params = {
      chainId: toHex(parseInt(process.env.REACT_APP_CHAIN_ID)), // A 0x-prefixed hexadecimal string
      chainName: `${process.env.REACT_APP_NETWORK_NAME}`,
      nativeCurrency: {
        name: `${process.env.REACT_APP_CHAIN_LABEL} ${process.env.REACT_APP_NETWORK}`,
        decimals: parseInt(process.env.REACT_APP_NATIVE_CURRENCY_DECIMAL), // 2-6 characters long
        symbol: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL,
      },
      rpcUrls: [process.env.REACT_APP_RPC_URL],
      // blockExplorerUrl: [process.env.REACT_APP_EXPLORER_URL],
    }
    try {
      // if (wallet == 'okxWallet' && typeof window.okxwallet !== 'undefined') {
      //   if (!address) {
      //     await connection({ walletType: wallet })
      //   }
      //   // window.okxwallet.request({ method: 'eth_requestAccounts' })

      //   const result = window.okxwallet.request({
      //     method: 'eth_requestAccounts',
      //     params: [params, address],
      //   })

      //   return result
      // } else
      if (window.ethereum) {
        if (!address) {
          await connection({ walletType: wallet })
        }

        const result = window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [params, address],
        })

        return result
      } else {
        // throw new Error('No Ethereum Wallet')
        connection({ walletType: wallet })
      }
    } catch (error) {
      return false
    }
    //   else if (window.ethereum) {
    //     if (!address) {
    //       await connection({ walletType: wallet })
    //     }

    //     const result = window.ethereum.request({
    //       method: 'wallet_addEthereumChain',
    //       params: [params, address],
    //     })

    //     return result
    //   } else {
    //     // throw new Error('No Ethereum Wallet')
    //     connection({ walletType: wallet })
    //   }
    // }
  }

  async function switchNetwork() {
    if (window.ethereum) {
      if (window.ethereum.networkVersion !== process.env.REACT_APP_CHAIN_ID) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: `${process.env.REACT_APP_NETWORK_NAME}`,
              chainId: toHex(parseInt(process.env.REACT_APP_CHAIN_ID)),
              nativeCurrency: {
                name: `${process.env.REACT_APP_CHAIN_LABEL} ${process.env.REACT_APP_NETWORK}`,
                decimals: parseInt(process.env.REACT_APP_NATIVE_CURRENCY_DECIMAL), // 2-6 characters long
                symbol: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL,
              },
              rpcUrls: [process.env.REACT_APP_RPC_URL],
            },
          ],
        })
        //   }
        // }
      }
    }
  }

  return { addToNetwork, switchNetwork }
}
