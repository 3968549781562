import { MDBModalBody } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import ModalBox from '../../components/modal/full.modal.box'
import ChooseWallet from './component/choose.wallet'
import LinkingWallet from './component/linking.wallet'
import WrongNetwork from './component/wrong.network'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalStorage, setStep, setWalletConnect } from '../../redux/reducer/connectorReducer'
import { connectors } from '../../pages/Home/component/connectors'
import useWindowDimensions from '../../components/common/window-dimension'

export default function WalletConnect() {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [responsive, setResponsive] = useState('mobile')

  const { deactivate } = useWeb3React()
  const { step, walletConnect, accounts, chainId, provider } = useSelector(
    (state) => state.connector,
  )

  useEffect(() => {
    if (width >= '992') {
      setResponsive('desktop')
    } else {
      setResponsive('mobile')
    }
  }, [width])

  const strChainId = chainId ? chainId.toString() : ''
  useEffect(() => {
    if (accounts && provider) {
      dispatch(setStep(1))
      dispatch(setLocalStorage({ key: 'provider', values: provider }))
      dispatch(setLocalStorage({ key: 'chainId', values: connectors[provider].chainId }))
    }
  }, [])
  return (
    <ModalBox
      show={walletConnect}
      setShow={() => dispatch(setWalletConnect(false))}
      screenSize="s"
      reactModal={true}
      className={'wallet-connect-container'}
      content={
        <MDBModalBody
          className="connect-wallet"
          style={{ minHeight: responsive == 'mobile' ? '35em' : '27em' }}>
          {accounts && !process.env.REACT_APP_ALLOWED_CHAIN_IDS.split(',').includes(strChainId) ? (
            <WrongNetwork deactivate={deactivate} />
          ) : (
            <div>
              {step == 0 && <ChooseWallet />}
              {step == 1 && <LinkingWallet />}
            </div>
          )}
        </MDBModalBody>
      }
    />
  )
}
