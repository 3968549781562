import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'

const injected = new InjectedConnector({
  supportedChainIds: process.env.REACT_APP_SUPPORTED_CHAIN_IDS,
})

const walletconnect = new WalletConnectConnector({
  rpcUrl: process.env.REACT_APP_RPC_URL,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000,
})

const connectorWallet = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org', // Required
  qrcodeModal: QRCodeModal,
})

const metamaskWallet = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org', // Required
  qrcodeModal: QRCodeModal,
  qrcodeModalOptions: {
    mobileLinks: ['metamask'],
  },
})

const bitkeepWallet = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org', // Required
  qrcodeModal: QRCodeModal,
  qrcodeModalOptions: {
    mobileLinks: ['bitkeep'],
  },
})

const imtokenWallet = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org', // Required
  qrcodeModal: QRCodeModal,
  qrcodeModalOptions: {
    mobileLinks: ['imtoken'],
  },
})

const okxWallet = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org', // Required
  qrcodeModal: QRCodeModal,
  qrcodeModalOptions: {
    mobileLinks: ['okx'],
  },
})

const walletlink = new WalletLinkConnector({
  url: process.env.REACT_APP_RPC_URL,
  appName: 'deritrade',
})

export const connectors = {
  injected: injected,
  metamaskWallet: metamaskWallet,
  bitkeepWallet: bitkeepWallet,
  imtokenWallet: imtokenWallet,
  connectorWallet: connectorWallet,
  walletConnect: walletconnect,
  okxWallet: okxWallet,
  coinbaseWallet: walletlink,
}
