import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { init } from 'ityped'

export default function Banner() {
  const { t } = useTranslation()
  const [state, setState] = useState(false)

  useEffect(() => {
    setState(!state)
  }, [localStorage.getItem('i18nextLng')])

  useEffect(() => {
    init(document.querySelector('.mobile-wrap'), {
      strings: [t('you'), t('him'), t('everyone'), t('me')],
      loop: true,
      typeSpeed: 500,
      backSpeed: 300,
    })
  }, [])

  return (
    <MDBCol
      className="banner-container"
      style={{
        textAlign: 'center',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '39px',
      }}>
      <div>
        <MDBRow className="banner-text-container">
          <label className="title">
            <span style={{ color: '#F0A000', fontSize: '2rem' }}>D E R I -</span>
            <span style={{ color: '#EDEBEB', fontSize: '2rem' }}> T r a d e</span>
          </label>
          <span className="text" style={{ color: '#EDEBEB', fontSize: '1.5rem' }}>
            {t(`a platform for`)} <span className="mobile-wrap"></span>
          </span>
        </MDBRow>
      </div>
    </MDBCol>
  )
}
