import { useState, useRef, useEffect } from 'react'
import _ from 'lodash'

//component
import SegmentedControl from '../../../components/element/segmentedControl'
import web3Services from '../../../services/web3.services'

//hook
import useUtils from '../../../hook/useUtils'
import useTradeOrder from '../../../hook/useTradeOrder'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Dropdown } from 'primereact/dropdown'
import { Message } from 'primereact/message'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import SliderMUI from '@mui/material/Slider'
//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  createOrder,
  getAllOrder,
  getPosition,
  setTradeConfirmModal,
  setTradeData,
} from '../../../redux/reducer/tradeReducer'
import { setWalletConnect } from '../../../redux/reducer/connectorReducer'
import { getWalletBalance } from '../../../redux/reducer/profileReducer'
import { useTranslation } from 'react-i18next'

export default function TradeBox() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [markPrice, setMarkPrice] = useState(false)
  const { listing } = useSelector((state) => state.market)
  const {
    trading_view_info,
    prompt_confirm_window,
    trade_order_loading,
    position_detail,
    order_detail,
  } = useSelector((state) => state.trade)
  const total_order = position_detail.length + order_detail.length
  const {
    handlePairing,
    liquidationPrice,
    positionSize,
    calculateFee,
    calculateRolloverFee,
    calculateFundingFee,
  } = useTradeOrder()

  // const trading_view_pricefeed = localStorage.getItem('symbol')
  //   ? listing[handlePairing('symbol', localStorage.getItem('symbol'))].price
  //   : listing['BNB'].price

  const symbol = localStorage.getItem('symbol')

  const trading_view_pricefeed =
    symbol && listing[handlePairing('symbol', symbol)].normal_price
      ? listing[handlePairing('symbol', symbol)].normal_price
          .toFixed(listing[handlePairing('symbol', symbol)].decimal)
          .replace(/\.?0+$/, '')
      : listing['BNB'].price

  const { convertSha256 } = useUtils()
  const decimal = 100
  const [liquidation, seLiquidation] = useState(0)
  const exceptThisSymbols = ['e', 'E', '+', '-']

  const marks = [
    {
      value: 1,
      label: '1x',
    },
    {
      value: 5,
      label: '5x',
    },
    {
      value: 10,
      label: '10x',
    },
    {
      value: 15,
      label: '15x',
    },
    {
      value: 20,
      label: '20x',
    },
    {
      value: 25,
      label: '25x',
    },
    {
      value: 30,
      label: '30x',
    },
    {
      value: 35,
      label: '35x',
    },
    {
      value: 40,
      label: '40x',
    },
    {
      value: 45,
      label: '45x',
    },
    {
      value: 50,
      label: '50x',
    },
  ]

  const { trade_data } = useSelector((state) => state.trade)
  const { token, accounts, selectedTrading } = useSelector((state) => state.connector)
  const { walletBalance, passphrase } = useSelector((state) => state.profile)

  const all_order_price =
    trade_data.type == 'market'
      ? trading_view_pricefeed
      : markPrice
      ? trading_view_pricefeed
      : trade_data.triggerPrice

  const handleLevarageValue = (values, type) => {
    let leverage = values
    dispatch(setTradeData({ leverage: values }))
    let liquidationPrice = 0
    if (
      trade_data.orderType &&
      all_order_price > 0 &&
      walletBalance > 0 &&
      trade_data.amountInput > 0
    ) {
      if (trade_data.orderType == 'long') {
        liquidationPrice = all_order_price - walletBalance / trade_data.amountInput
      } else {
        liquidationPrice = all_order_price + walletBalance / trade_data.amountInput
      }
      if (liquidationPrice < 0) {
        const quantity = (walletBalance * leverage) / all_order_price

        if (quantity) {
          const usdt = quantity * all_order_price
          dispatch(setTradeData({ amountInput: String(Math.round(quantity * decimal) / decimal) }))
          dispatch(setTradeData({ usdtAmountInput: String(Math.round(usdt * decimal) / decimal) }))
        }
      }
    }
  }

  const tab = [
    {
      label: 'Long',
      value: 'long',
      ref: useRef(),
    },
    {
      label: 'Short',
      value: 'short',
      ref: useRef(),
    },
  ]

  const [data, setData] = useState({
    total_order: total_order,
    address: accounts,
    symbol: `${trading_view_info.exchange}.${trading_view_info.symbol}`,
    expiredAt: trade_data.expiredAt,
    price: trading_view_info.pricefeed,
    orderPrice: Number(trade_data.orderPrice),
    leverage: trade_data.leverage,
    signature: token,
    timeInForce: 'GTC',
    type: trade_data.type,
    orderType: trade_data.orderType,
    balance: walletBalance,
    collateral: Number(trade_data.collateral),
    liquidation: liquidation,
    triggerPrice: Number(trade_data.triggerPrice),
    position_size: positionSize(trade_data.collateral, trade_data.leverage),
  })

  const useSchema = Yup.object({
    total_order: Yup.number().max(19, 'Sum of Position and Order capped at 20'),
    address: Yup.string().required('Connect Wallet').nullable(),
    symbol: Yup.string().required('Something went wrong. Please try again later.'),
    expiredAt: Yup.string().required('Something went wrong. Please try again later.'),
    collateral: Yup.number()
      .required('Collateral is required.')
      .test((values, ctx) => {
        if (Number(trade_data.collateral) > walletBalance) {
          return ctx.createError({ message: 'Not Enough USDT' })
        } else {
          return true
        }
      }),
    balance: Yup.number().required('Not Enough USDT'),
    leverage: Yup.number().required('leverage is required').moreThan(0, 'leverage is required'),
    signature: Yup.string().required('Connect Network').nullable(),
    timeInForce: Yup.string().required('Something went wrong. Please try again later.'),
    type: Yup.string().required('Something went wrong. Please try again later.'),
    orderType: Yup.string().required('Something went wrong. Please try again later.'),
    price: Yup.number().test((values, ctx) => {
      if (trading_view_pricefeed <= 0 || trading_view_pricefeed == '0') {
        return ctx.createError({ message: 'Kindy refresh again for the latest pricefeed.' })
      } else {
        return true
      }
    }),
    triggerPrice: Yup.number()
      .when('type', {
        is: 'limit' || 'stop',
        then: Yup.number().moreThan(0, 'Wrong Limit Price'),
      })
      .test((values, ctx) => {
        if (trade_data.orderType == 'long' && trade_data.type == 'limit') {
          if (values > trading_view_pricefeed) {
            return ctx.createError({ message: 'Wrong Limit Price' })
          }
        } else if (trade_data.orderType == 'short' && trade_data.type == 'limit') {
          if (values < trading_view_pricefeed) {
            return ctx.createError({ message: 'Wrong Limit Price' })
          }
        } else if (trade_data.orderType == 'short' && trade_data.type == 'stop') {
          if (values > trading_view_pricefeed) {
            return ctx.createError({ message: 'Wrong Stop Price' })
          } else if (values == 0) {
            return ctx.createError({ message: 'Wrong Stop Price' })
          }
        } else if (trade_data.orderType == 'long' && trade_data.type == 'stop') {
          if (values < trading_view_pricefeed) {
            return ctx.createError({ message: 'Wrong Stop Price' })
          }
        } else if (markPrice) {
          return true
        }
        return true
      }),
    position_size: Yup.number().test((values, ctx) => {
      if (positionSize(trade_data.collateral, trade_data.leverage) < 300) {
        return ctx.createError({ message: 'Position Size < 300 USDT' })
      } else {
        return true
      }
    }),
  })

  const calculateTimeinForce = () => {
    const selectedTime = new Date(Date.now() + 1 * parseInt(trade_data.expiredAt) * 60 * 60 * 1000)

    return selectedTime.valueOf().toString()
  }

  useEffect(() => {
    setData({
      total_order: total_order,
      address: accounts,
      symbol: `${trading_view_info.exchange}.${trading_view_info.symbol}`,
      expiredAt: trade_data.expiredAt,
      price: trading_view_pricefeed,
      orderPrice: Number(trade_data.orderPrice),
      leverage: trade_data.leverage,
      signature: token,
      timeInForce: 'GTC',
      type: trade_data.type,
      orderType: trade_data.orderType,
      balance: walletBalance,
      collateral: Number(trade_data.collateral),
      liquidation: liquidation,
      position_size: positionSize(trade_data.collateral, trade_data.leverage),
      triggerPrice: Number(trade_data.triggerPrice),
    })
  }, [
    accounts,
    token,
    walletBalance,
    trade_data,
    all_order_price,
    trading_view_pricefeed,
    position_detail,
    order_detail,
  ])

  const handleSubmit = (setFieldError) => {
    if (web3Services.isSignRequire()) {
      if (prompt_confirm_window) {
        let payload = {
          address: accounts,
          symbol: localStorage.getItem('symbol'),
          expiredAt: calculateTimeinForce(),
          price: Number(all_order_price),
          collateral: Number(trade_data.collateral),
          // amount: Number(amount),
          // size: Number(trade_data.amountInput),
          leverage: Number(trade_data.leverage),
          signature: token,
          timeInForce: 'GTC',
          type: trade_data.type,
          orderType: `open ${trade_data.orderType}`,
          orderMethod: trade_data.orderType,
        }
        dispatch(
          createOrder({
            payload: payload,
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
          .unwrap()
          .then((data) => {
            const result = data.data
            toast(
              <div>
                <MDBRow className="m-0">
                  <MDBCol className="text-start">
                    <label className="_label">
                      {result.type} {t('Order')}
                    </label>
                  </MDBCol>
                  <MDBCol className="text-end col-4">
                    <label className="_label --order-status">{result.status}</label>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="m-0">
                  <MDBCol>
                    <label className="_label --total-title">{t('type')}</label>
                    <label className="_label --total-title">{t('Collateral')}</label>
                    <label className="_label --total-title">{t('Price')}</label>
                  </MDBCol>
                  <MDBCol className="text-end">
                    <label className="_label --amount">{result.orderType}</label>
                    <label className="_label --amount">{result.collateral}</label>
                    <label className="_label --amount">
                      {Math.round(result.price * decimal) / decimal}
                    </label>
                  </MDBCol>
                </MDBRow>
              </div>,
              {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                // progress: undefined,
                theme: 'dark',
              },
            )
            dispatch(
              getWalletBalance({
                payload: { address: accounts },
                data: convertSha256(token, passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getAllOrder({
                payload: {
                  address: accounts,
                  status: 'all',
                },
                data: convertSha256(token, passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getPosition({
                payload: {
                  address: accounts,
                },
                data: convertSha256(token, passphrase),
                network: selectedTrading,
              }),
            )
          })
          .catch((ex) => {
            // const errors = ex.response.data.errors
            // if (errors && Object.keys(errors).length > 0) {
            //   Object.keys(errors).map((item, i) => {
            //     setFieldError(item, errors[item])
            //   })
            // } else {
            //   toast.error(t(JSON.stringify(ex.message)), {
            //     position: 'top-left',
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     theme: 'dark',
            //   })
            // }
          })
      } else {
        let payload = {
          trade_data: trade_data,
          liquidation: liquidationPrice(
            trade_data.orderType,
            Number(all_order_price),
            Number(trade_data.collateral),
            trade_data.leverage,
            calculateRolloverFee(trade_data.collateral),
            calculateFundingFee(trade_data.collateral, trade_data.leverage),
            symbol,
          ),
          positionSize: positionSize(trade_data.collateral, trade_data.leverage),
          price: all_order_price,
          fee: calculateFee(positionSize(trade_data.collateral, trade_data.leverage)),
          symbol: localStorage.getItem('symbol'),
        }
        dispatch(setTradeConfirmModal({ status: true, data: payload }))
      }
    }
  }

  return (
    <MDBCol className="col-12 col-lg-3" style={{ paddingTop: '0em' }}>
      <MDBContainer className="trade-box-container p-0">
        <SegmentedControl
          name="group-1"
          className={trade_data.orderType}
          callback={(val) => dispatch(setTradeData({ orderType: val }))}
          controlRef={useRef()}
          segments={tab}
        />
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          initialValues={data}
          validationSchema={useSchema}
          onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
            handleSubmit(setFieldError)
            resetForm({ values: '' })
          }}>
          {({ handleReset, errors, values, touched, isValid, setFieldValue, isSubmitting }) => (
            <Form className="modal-form">
              <MDBRow className="m-0">
                <MDBCol className="col-7 trade-columns">
                  <label className="_label --available-title">{t('Trigger Price')}</label>
                  <label
                    className="_label --available-amount"
                    onClick={() => {
                      setMarkPrice(true)
                      dispatch(
                        setTradeData({
                          triggerPrice: trading_view_pricefeed
                            ? trading_view_pricefeed
                            : listing['BNB'].price,
                        }),
                      )
                    }}>
                    {t('Mark.')}
                    {trading_view_pricefeed}
                  </label>
                  <MDBRow
                    className={
                      trade_data.type == 'market'
                        ? '_input --trigger-price-disable m-0'
                        : '_input --trigger-price m-0'
                    }>
                    <input
                      value={String(all_order_price)}
                      name="order_price_input"
                      placeholder={t('Order Price')}
                      type="number"
                      min="0"
                      step="0.01"
                      onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      onWheelCapture={(e) => {
                        e.target.blur()
                      }}
                      onBlur={() => {
                        if (parseFloat(all_order_price) > 0) {
                          const value = parseFloat(all_order_price)
                          dispatch(setTradeData({ triggerPrice: value }))
                          setFieldValue('triggerPrice', value)
                        }
                      }}
                      onChange={(e) => {
                        setMarkPrice(false)
                        dispatch(setTradeData({ triggerPrice: e.target.value }))
                        setFieldValue('triggerPrice', e.target.value)
                      }}
                      disabled={trade_data.type == 'market' ? true : false}
                    />
                  </MDBRow>
                </MDBCol>
                <MDBCol className="col-5 trade-columns spacing text-end">
                  <label className="_label --available-title">{t('Order Type')}</label>
                  <Dropdown
                    value={trade_data.type}
                    onChange={(e) => {
                      dispatch(setTradeData({ type: e.value }))
                    }}
                    options={[
                      { label: t('Limit'), value: 'limit' },
                      { label: t('Market'), value: 'market' },
                      { label: t('Stop'), value: 'stop' },
                    ]}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="m-0 p-2" style={{ height: '1.5em' }}>
                <MDBCol className="p-0">
                  <label className="_label --available-title">{t('Available Balance')} :</label>
                </MDBCol>
                <MDBCol className="text-end p-0">
                  <label className="_label --available-amount">
                    {Math.floor(walletBalance * Math.pow(10, 2)) / Math.pow(10, 2)} USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow className="m-0 p-1">
                <div className="amount-columns">
                  <MDBRow>
                    <MDBCol className="d-flex align-items-center">
                      <input
                        className="_input --collateral"
                        value={String(trade_data.collateral)}
                        placeholder={t('Collateral')}
                        type="number"
                        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onWheelCapture={(e) => {
                          e.target.blur()
                        }}
                        onBlur={() => {
                          if (parseFloat(trade_data.collateral) > 0) {
                            const value = parseFloat(trade_data.collateral)
                            dispatch(setTradeData({ collateral: value }))
                            setFieldValue('collateral', value)
                          }
                        }}
                        onChange={(e) => {
                          // if (countDecimals(e.target.value) <= 2) {
                          dispatch(setTradeData({ collateral: e.target.value }))
                          setFieldValue('collateral', e.target.value)
                          // }
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="text-end col-4">
                      <button
                        className="_button --max"
                        type="button"
                        onClick={() => {
                          dispatch(
                            setTradeData({
                              collateral: walletBalance,
                            }),
                          )
                          setFieldValue('collateral', walletBalance)
                        }}>
                        {t('Max')}
                      </button>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBRow>
              <MDBRow className="leverage-container m-0">
                <MDBCol className="col-7">
                  <label className="_label --bold">{t('Adjust Leverage')}</label>
                  <label className="_label --normal-text">
                    ({t('min')} 1x ~ {t('max')} 50x)
                  </label>
                </MDBCol>
                <MDBCol className="col-5 p-1">
                  <input
                    value={String(trade_data.leverage)}
                    placeholder={'1'}
                    className="_input --leverage"
                    type="number"
                    step={'1'}
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key)}
                    onWheelCapture={(e) => {
                      e.target.blur()
                    }}
                    onChange={(val) => {
                      const number = val.target.value.replace(/\./g, '')
                      if (Number(number) > 50) {
                        handleLevarageValue(50, 'text')
                        setFieldValue('leverage', 50)
                      } else {
                        handleLevarageValue(Number(number), 'text')
                        setFieldValue('leverage', Number(number))
                      }
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="slide-container">
                <SliderMUI
                  defaultValue={1}
                  step={1}
                  marks={marks}
                  min={1}
                  max={50}
                  value={Number(trade_data.leverage)}
                  onChange={(val) => {
                    handleLevarageValue(val.target.value, 'slide')
                  }}
                />
              </MDBRow>
              <MDBRow></MDBRow>
              {!_.isEmpty(errors) && errors.total_order ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="error-msg w-full justify-content-start"
                    severity="error"
                    content={t(`${t(`${Object.values(errors)[0]}`)}`)}
                  />
                </MDBRow>
              ) : !_.isEmpty(errors) && errors.address ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="error-msg w-full justify-content-start"
                    severity="error"
                    content={t(`${Object.values(errors)[0]}`)}
                  />
                </MDBRow>
              ) : !_.isEmpty(errors) && errors.balance ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="error-msg w-full justify-content-start"
                    severity="error"
                    content={t(`${Object.values(errors)[0]}`)}
                  />
                </MDBRow>
              ) : !_.isEmpty(errors) && values.collateral && trade_data.type == 'market' ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="error-msg w-full justify-content-start"
                    severity="error"
                    content={t(`${Object.values(errors)[0]}`)}
                  />
                </MDBRow>
              ) : !_.isEmpty(errors) && values.triggerPrice && values.collateral ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="error-msg w-full justify-content-start"
                    severity="error"
                    content={t(`${Object.values(errors)[0]}`)}
                  />
                </MDBRow>
              ) : !_.isEmpty(errors) && values.triggerPrice ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="error-msg w-full justify-content-start"
                    severity="error"
                    content={t(`${Object.values(errors)[0]}`)}
                  />
                </MDBRow>
              ) : !_.isEmpty(errors) && values.collateral ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="error-msg w-full justify-content-start"
                    severity="error"
                    content={t(`${Object.values(errors)[0]}`)}
                  />
                </MDBRow>
              ) : !web3Services.isSignRequire() ? (
                <MDBRow className="m-0 p-1 pt-3">
                  <Message
                    className="warm-msg w-full justify-content-start"
                    content={
                      selectedTrading == 'testnet'
                        ? `${t('You are trading on our')} ${
                            process.env.REACT_APP_CHAIN_LABEL + ' ' + t(`${selectedTrading}`)
                          } ${t(
                            `app with test funds. Create an account on our mainnet app to start trading with real funds`,
                          )}.`
                        : `${t('You are trading on our')} ${
                            process.env.REACT_APP_CHAIN_LABEL + ' ' + t(`${selectedTrading}`)
                          }. ${t(`Create an account to start trading with real funds`)}.`
                    }
                  />
                </MDBRow>
              ) : null}
              <button
                type="submit"
                className={`_button --confirm --${trade_data.orderType}`}
                disabled={trade_order_loading || !isValid}
                onClick={() => {
                  if (!web3Services.isSignRequire()) {
                    dispatch(setWalletConnect(true))
                  }
                }}>
                {trade_data.orderType == 'long'
                  ? trade_order_loading
                    ? t('Waiting..')
                    : t('Confirm Long')
                  : trade_order_loading
                  ? t('Waiting..')
                  : t('Confirm Short')}
              </button>
              <MDBRow className="m-0 p-1">
                <MDBCol className="p-0">
                  <label className="_label --total-title">{t(`Position Size`)} :</label>
                  <label className="_label --total-title">{t(`Fees`)} :</label>
                  <label className="_label --total-title">{t(`Liq.Price`)} :</label>
                </MDBCol>
                <MDBCol className="text-end p-0">
                  <label className="_label --amount">
                    {positionSize(Number(trade_data.collateral), trade_data.leverage)} USDT
                  </label>
                  <label className="_label --amount">
                    {calculateFee(positionSize(Number(trade_data.collateral), trade_data.leverage))}{' '}
                    USDT
                  </label>
                  <label className="_label --amount">
                    {liquidationPrice(
                      trade_data.orderType,
                      Number(all_order_price),
                      Number(trade_data.collateral),
                      trade_data.leverage,
                      calculateRolloverFee(trade_data.collateral, trade_data.leverage),
                      calculateFundingFee(trade_data.collateral, trade_data.leverage),
                      symbol,
                    )}
                  </label>
                </MDBCol>
              </MDBRow>
            </Form>
          )}
        </Formik>
      </MDBContainer>
    </MDBCol>
  )
}
