import { useState } from 'react'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'

//asset
import { FirstPrize, SecondPrize, ThirdPrize } from '../../../assets/images'

//redux
import { useTranslation } from 'react-i18next'

export default function LeaderBoardDesktop({ title, data }) {
  const { t } = useTranslation()
  const [order, setOrder] = useState(true) // true is asc , false is desc
  const dataAsc = data ? data.slice(0, 10) : ''
  const dataDesc = data ? data.slice(-10) : ''
  let emptyValue = [
    {
      prize: 1,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 2,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 3,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 4,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 5,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 6,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 7,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 8,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 9,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 10,
      name: '- -',
      revenue: '$ - -',
    },
  ]

  const newListing = data.map((_, index) => {
    delete emptyValue[index]
  })

  var i = 1
  var iDesc = 10 - data.length + 1

  return (
    <MDBCol className="leaderboard-container">
      <MDBRow className="header-container text-start">
        <label>
          {t(`Leaderboard`)}
          <div className="badge">
            <div className="background" style={{ display: 'block' }}>
              <label>{t(`${title}`)}</label>
            </div>
          </div>
        </label>
        <span>
          {title == 'Referral Program'
            ? t('Leaderboard of Deri Trade will refresh every 28th of the month')
            : t('Leaderboard of Deri Trade will refresh every month')}
        </span>
      </MDBRow>
      <div>
        <MDBContainer className="leaderboard-box">
          <div className="header-leaderboard">
            <MDBRow className="filter-row padding">
              <label
                className="active"
                onClick={() => {
                  setOrder(!order)
                }}>
                {order ? (
                  <Icon
                    icon="akar-icons:arrow-right"
                    color="#EDEBEB"
                    width="14"
                    height="14"
                    rotate={3}
                  />
                ) : (
                  <Icon
                    icon="akar-icons:arrow-right"
                    color="#EDEBEB"
                    width="14"
                    height="14"
                    rotate={1}
                  />
                )}
                {t(`Rank`)}
              </label>
              {/* <label>All Time</label> */}
            </MDBRow>
            <MDBRow className="padding title">
              <MDBCol className="text-start">
                <label>
                  {title == 'Referral Program' ? t('Referral Rankings') : t('Master traders')}
                </label>
              </MDBCol>
              <MDBCol className="text-end">
                <label>{title == 'Referral Program' ? t('Total Referred') : t('PnL (%)')}</label>
              </MDBCol>
            </MDBRow>
          </div>
          {/* order ? 'asc' : 'desc' */}
          {order ? (
            <>
              {dataAsc.map((item, index) => (
                <div key={index}>
                  <div className="line"></div>
                  <MDBRow className="padding leaderboard-row">
                    <MDBCol className="col-3 name">
                      {order ? (
                        index == 0 ? (
                          <img src={FirstPrize} width="25" />
                        ) : index == 1 ? (
                          <img src={SecondPrize} width="25" />
                        ) : index == 2 ? (
                          <img src={ThirdPrize} width="25" />
                        ) : (
                          <span className="number">{(index += 1)}.</span>
                        )
                      ) : (
                        <span className="number">{(index += 1)}.</span>
                      )}
                      <label>
                        {item.name
                          ? item.name
                          : item._id.slice(0, 15)
                          ? item.name
                            ? item.name
                            : item._id.slice(0, 15)
                          : item._id.slice(0, 15)}
                      </label>
                    </MDBCol>
                    <MDBCol className="text-end revenue">
                      <label>
                        <span className={item.totalPnlPercentage < 0 ? 'red' : 'green'}>
                          {item.totalPnlPercentage < 0
                            ? ''
                            : title == 'Referral Program'
                            ? ''
                            : '+ '}
                        </span>
                        {item.count
                          ? item.count
                          : item.totalPnlPercentage
                          ? item.totalPnlPercentage.toFixed(2)
                          : '- -'}
                      </label>
                    </MDBCol>
                  </MDBRow>
                </div>
              ))}
              {emptyValue.map((item, index) => (
                <div key={index}>
                  <div className="line"></div>
                  <MDBRow className="padding leaderboard-row">
                    <MDBCol className="col-3 name">
                      {order ? (
                        index == 0 ? (
                          <img src={FirstPrize} width="25" />
                        ) : index == 1 ? (
                          <img src={SecondPrize} width="25" />
                        ) : index == 2 ? (
                          <img src={ThirdPrize} width="25" />
                        ) : (
                          <span className="number">{(index += 1)}.</span>
                        )
                      ) : (
                        <span className="number">{(index += 1)}.</span>
                      )}
                      <label>{item._id ? item._id.slice(0, 15) : '- -'}</label>
                    </MDBCol>
                    <MDBCol className="text-end revenue">
                      <label>
                        <span className={item.totalPnlPercentage < 0 ? 'red' : 'green'}>
                          {item.totalPnlPercentage < 0
                            ? ''
                            : title == 'Referral Program'
                            ? ''
                            : '+ '}
                        </span>
                        {item.count
                          ? item.count
                          : item.totalPnlPercentage
                          ? item.totalPnlPercentage.toFixed(2)
                          : '- -'}
                      </label>
                    </MDBCol>
                  </MDBRow>
                </div>
              ))}
            </>
          ) : (
            <>
              {emptyValue.map((item, index) => (
                <div key={index}>
                  <div className="line"></div>
                  <MDBRow className="padding leaderboard-row">
                    <MDBCol className="col-3 name">
                      <span className="number">{i++}.</span>
                      <label>{item.name ? item.name : item._id.slice(0, 15)}</label>
                    </MDBCol>
                    <MDBCol className="text-end revenue">
                      <label>
                        <span className={item.totalPnlPercentage < 0 ? 'red' : 'green'}>
                          {item.totalPnlPercentage < 0
                            ? ''
                            : title == 'Referral Program'
                            ? ''
                            : '+ '}
                        </span>
                        {item.count
                          ? item.count
                          : item.totalPnlPercentage
                          ? item.totalPnlPercentage.toFixed(2)
                          : '- -'}
                      </label>
                    </MDBCol>
                  </MDBRow>
                </div>
              ))}
              {dataDesc.reverse().map((item, index) => (
                <div key={index}>
                  <div className="line"></div>
                  <MDBRow className="padding leaderboard-row">
                    <MDBCol className="col-3 name">
                      {order ? (
                        index == 0 ? (
                          <img src={FirstPrize} width="25" />
                        ) : index == 1 ? (
                          <img src={SecondPrize} width="25" />
                        ) : index == 2 ? (
                          <img src={ThirdPrize} width="25" />
                        ) : (
                          <span className="number">{iDesc++}.</span>
                        )
                      ) : (
                        <span className="number">{iDesc++}.</span>
                      )}
                      <label>{item.name ? item.name : item._id.slice(0, 15)}</label>
                    </MDBCol>
                    <MDBCol className="text-end revenue">
                      <label>
                        <span className={item.totalPnlPercentage < 0 ? 'red' : 'green'}>
                          {item.totalPnlPercentage < 0
                            ? ''
                            : title == 'Referral Program'
                            ? ''
                            : '+ '}
                        </span>
                        {item.count
                          ? item.count
                          : item.totalPnlPercentage
                          ? item.totalPnlPercentage.toFixed(2)
                          : '- -'}
                      </label>
                    </MDBCol>
                  </MDBRow>
                </div>
              ))}
            </>
          )}
        </MDBContainer>
      </div>
    </MDBCol>
  )
}
