import { useEffect, useState } from 'react'
import { Skeleton } from 'primereact/skeleton'

//lib
import moment from 'moment'
import { MDBCol, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'

//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  getRealizedPnL,
  getTradeList,
  getTransferList,
  setWalletHistoryDetailModal,
  setWalletHistoryModal,
  setWalletRealizedDetailModal,
} from '../../../redux/reducer/profileReducer'

//component
import Header from '../../../components/header/header-three'
import ModalBox from '../../../components/modal/full.modal.box'
import HistoryDetailModal from './history.detail.modal'
import RealizedDetailModal from './realized.detail.modal'
import useTradeOrder from '../../../hook/useTradeOrder'
import { useTranslation } from 'react-i18next'
import useUtils from '../../../hook/useUtils'
import { Icon } from '@iconify/react'
import { toast } from 'react-toastify'
import { setShareButton } from '../../../redux/reducer/tradeReducer'

export default function WalletHistoryModal({ show }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { convertSha256 } = useUtils()
  const { netPnlRate, handlePairing } = useTradeOrder()
  const {
    wallet_history,
    passphrase,
    historyDetailModal,
    realizedDetailModal,
    wallet_history_trade_listing,
    wallet_history_transfer_listing,
    wallet_history_interest_listing,
    wallet_history_realized_listing,
  } = useSelector((state) => state.profile)

  const pricefeed = useSelector((state) => state.market.listing)

  const [selectedTab, setSelectedTab] = useState('Realized P&L')
  const tab = ['Realized P&L', 'Transfers', 'Order History']
  const { token, accounts, selectedTrading } = useSelector((state) => state.connector)

  useEffect(() => {
    if (accounts && token) {
      // dispatch(
      //   getFundingList({
      //     payload: { address: accounts },
      //     data: convertSha256(token, passphrase),
      //     network: selectedTrading,
      //   }),
      // )
      dispatch(
        getTradeList({
          payload: { address: accounts },
          data: convertSha256(token, passphrase),
          network: selectedTrading,
        }),
      )
      dispatch(
        getTransferList({
          payload: { address: accounts },
          data: convertSha256(token, passphrase),
          network: selectedTrading,
        }),
      )
      dispatch(
        getRealizedPnL({
          payload: { address: accounts },
          data: convertSha256(token, passphrase),
          network: selectedTrading,
        }),
      )
    }
  }, [])

  const [isDisabled, setIsDisabled] = useState(false)

  function showNotification() {
    if (isDisabled) {
      return
    }

    setIsDisabled(true)
    toast.info(
      <div>
        <MDBRow className="m-0">
          <MDBCol className="text-start">
            <label className="_label">{t('Sharing Coming Soon')}</label>
          </MDBCol>
        </MDBRow>
      </div>,
      {
        position: 'top-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        theme: 'dark',
      },
    )

    setTimeout(() => {
      setIsDisabled(false)
    }, 60000) // 禁用60秒
  }

  return (
    <ModalBox
      className="wallet-history-container"
      show={show}
      screenSize="lg"
      scrollable={true}
      staticBackdrop={true}
      reactModal={true}
      setShow={() => dispatch(setWalletHistoryModal(false))}
      content={
        <MDBModalBody>
          <Header onClick={() => dispatch(setWalletHistoryModal(false))} />
          <div className="content-container">
            <div
              className="wallet-history-data"
              style={{ display: 'flex', overflow: 'scroll', height: '2em' }}>
              {tab.map((label, index) =>
                wallet_history ? (
                  <Skeleton
                    key={index}
                    width="5rem"
                    height="1.5rem"
                    className="m-2 mt-0"></Skeleton>
                ) : (
                  <label
                    style={{ cursor: 'pointer' }}
                    key={index}
                    onClick={() => setSelectedTab(label)}
                    className={
                      label == selectedTab ? 'tab-wallet-history active' : 'tab-wallet-history'
                    }>
                    {t(`${label}`)}
                  </label>
                ),
              )}
            </div>
            {wallet_history ? (
              <div>
                <div className="line-container"></div>
                <Skeleton width="5rem" height="1rem" className="m-2 mt-0"></Skeleton>
                <Skeleton width="6rem" height="1rem" className="m-2 mt-0"></Skeleton>
                <Skeleton width="10rem" height="1rem" className="m-2 mt-0"></Skeleton>
              </div>
            ) : selectedTab == 'Realized P&L' ? (
              <div className="data-listing">
                {wallet_history_realized_listing.length > 0 ? (
                  wallet_history_realized_listing.map((listing, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                      <div className="line-container"></div>
                      <div className="--share-button">
                        <Icon
                          icon="grommet-icons:share"
                          width="0.8em"
                          height="0.8em"
                          onClick={() => {
                            // showNotification()
                            dispatch(
                              setShareButton({
                                status: true,
                                data: listing,
                              }),
                            )
                          }}
                        />
                      </div>
                      <MDBRow
                        className="mt-4"
                        onClick={() => {
                          dispatch(setWalletRealizedDetailModal({ status: true, data: listing }))
                        }}>
                        <MDBCol className="col-4 col-md">
                          <label
                            className={
                              listing.type == 'long'
                                ? 'status-text w-100 green align-items-start d-flex justify-content-start'
                                : 'status-text w-100 red align-items-start d-flex justify-content-start'
                            }>
                            {t(listing.type)}
                          </label>
                          <label className="bold-text align-items-start d-flex justify-content-start">
                            {listing.symbol ? listing.symbol : ''}
                          </label>
                          <label className="normal-text align-items-start d-flex justify-content-start">
                            {moment(listing.createdAt).format('DD MMM YYYY hh:mm')}
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end p-0 col-md col-4">
                          <label className="bold-text">
                            {Math.round(listing.amount * 100) / 100} ({t('Collateral')})
                          </label>
                          <label className="bold-text align-items-end d-flex justify-content-end">
                            {Math.round(
                              listing.averageClose *
                                Math.pow(
                                  10,
                                  pricefeed[handlePairing('symbol', listing.symbol)].decimal,
                                ),
                            ) /
                              Math.pow(
                                10,
                                pricefeed[handlePairing('symbol', listing.symbol)].decimal,
                              )}{' '}
                            ({t('Price')})
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end">
                          <label className={`bold-text --${listing.pnl > 0 ? 'long' : 'short'}`}>
                            {Math.round(listing.pnl * 100) / 100} (
                            {netPnlRate(listing.pnl, listing.amount)})
                          </label>
                          <label
                            className={`bold-text d-flex justify-content-end align-items-end --${listing.status}`}
                            style={{ textTransform: 'capitalize' }}>
                            {t(`${listing.status}`)}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center pt-5">
                    {t('Realized P&L List is Empty')}
                  </div>
                )}
                <div className="line-container"></div>
              </div>
            ) : selectedTab == 'Transfers' ? (
              <div className="data-listing">
                {wallet_history_transfer_listing.length > 0 ? (
                  wallet_history_transfer_listing.map((listing, index) => (
                    <div key={index}>
                      <div className="line-container"></div>
                      <MDBRow>
                        <MDBCol>
                          <label className="bold-text align-items-start d-flex justify-content-start">
                            {t(`${listing.type}`)}
                          </label>
                          <label className="normal-text align-items-start d-flex justify-content-start">
                            {moment(listing.createdAt).format('DD MMM YYYY hh:mm')}
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end">
                          <label className="bold-text align-items-center d-flex justify-content-center">
                            {listing.status == 'active' ? t('Confirm') : ''}
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end">
                          <label className="bold-text align-items-center d-flex justify-content-end">
                            $ {Math.round(listing.amount * 100) / 100} (USDT)
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center pt-5">
                    {t('Transfers List is Empty')}
                  </div>
                )}
                <div className="line-container"></div>
              </div>
            ) : selectedTab == 'Funding Payment' ? (
              <div className="data-listing">
                {wallet_history_interest_listing.length > 0 ? (
                  wallet_history_interest_listing.map((listing, index) => (
                    <div key={index}>
                      <div className="line-container"></div>
                      <MDBRow>
                        <MDBCol>
                          <label className="bold-text align-items-start d-flex justify-content-start">
                            {t(`${listing.orderType}`)}
                          </label>
                          <label className="normal-text align-items-start d-flex justify-content-start">
                            {moment(listing.createdAt).format('DD MMM YYYY hh:mm')}
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end">
                          <label className="bold-text">
                            {listing.type} ({t(`Role`)})
                          </label>
                          <label className="bold-text align-items-end d-flex justify-content-end">
                            ${listing.position_size} ({t(`Size`)})
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end">
                          <label className="bold-text align-items-center d-flex justify-content-end">
                            ${listing.fee} ({t(`Amount`)})
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center pt-5">
                    {t('Funding Payment List is Empty')}
                  </div>
                )}
                <div className="line-container"></div>
              </div>
            ) : selectedTab == 'Order History' ? (
              <div className="data-listing">
                {wallet_history_trade_listing.length > 0 ? (
                  wallet_history_trade_listing.map((listing, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        dispatch(setWalletHistoryDetailModal({ status: true, data: listing }))
                      }}>
                      <div className="line-container"></div>
                      <MDBRow>
                        <MDBCol className="col-4 col-md">
                          <label
                            className={
                              listing.orderMethod == 'long'
                                ? 'status-text w-100 green align-items-start d-flex justify-content-start'
                                : 'status-text w-100 red align-items-start d-flex justify-content-start'
                            }>
                            {listing.orderType}
                          </label>
                          <label className="bold-text align-items-start d-flex justify-content-start">
                            {listing.symbol ? listing.symbol : ''}
                          </label>
                          <label className="normal-text align-items-start d-flex justify-content-start">
                            {moment(listing.createdAt).format('DD MMM YYYY hh:mm')}
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end p-0 col-md col-4">
                          <label className="bold-text">
                            {Math.round(listing.collateral * 100) / 100} ({t(`Collateral`)})
                          </label>
                          <label className="bold-text align-items-end d-flex justify-content-end">
                            ${' '}
                            {listing.price
                              ? Math.round(
                                  listing.price *
                                    Math.pow(
                                      10,
                                      pricefeed[handlePairing('symbol', listing.symbol)].decimal,
                                    ),
                                ) /
                                Math.pow(
                                  10,
                                  pricefeed[handlePairing('symbol', listing.symbol)].decimal,
                                )
                              : '--'}{' '}
                            ({t(`Price`)})
                          </label>
                        </MDBCol>
                        <MDBCol className="d-grid text-end">
                          <label className="bold-text">
                            {' '}
                            -{Math.round(listing.fee * 100) / 100} ({t(`Fee`)})
                          </label>
                          <label
                            className={`bold-text --${listing.status}`}
                            style={{ textTransform: 'capitalize' }}>
                            {t(`${listing.status}`)}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center pt-5">
                    {t('Order History List is Empty')}
                  </div>
                )}
                <div className="line-container"></div>
              </div>
            ) : (
              <div className="data-listing">
                <div className="d-flex align-items-center justify-content-center pt-5">
                  {t('Realized P&L Coming Soon')}
                </div>
                <div className="line-container"></div>
              </div>
            )}
          </div>
          {historyDetailModal && (
            <HistoryDetailModal
              show={historyDetailModal}
              setShow={() => dispatch(setWalletHistoryDetailModal({ status: false, data: {} }))}
            />
          )}
          {realizedDetailModal && (
            <RealizedDetailModal
              show={realizedDetailModal}
              setShow={() => dispatch(setWalletRealizedDetailModal({ status: false, data: {} }))}
            />
          )}
        </MDBModalBody>
      }
    />
  )
}
