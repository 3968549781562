import http from './http.services'
import auth from './auth.services'
import httpService from './http.services'

// process.env.REACT_APP_API_URL

async function register(address) {
  // http.setToken(auth.getToken())
  const response = await http.post(process.env.REACT_APP_API_URL + `/register`, {
    address: address,
  })
  return response.data.data
}


async function getReferrals(payload) {
  http.setToken(auth.getToken())
  const response = await http.post(process.env.REACT_APP_API_URL + `/referrals`, payload)
  return response.data.data
}

async function getFavourite(payload) {
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/favourite?address=` + payload.account,
  )
  return response.data.data
}

async function getReferralRanking() {
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/referral_rankings`,
  )
  return response.data.data
}

async function getTradeRanking(network) {
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/${network}/trader_rankings`,
  )
  return response.data.data
}

async function addFavourite(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/favourite`, payload)
  return response.data.data
}

async function deleteFavourite(payload) {
  const response = await http.delete(
    process.env.REACT_APP_API_URL +
      `/favourite?address=${payload.address}&pairing=${payload.pairing}`,
  )
  return response.data.data
}

async function createOrder(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/create_order`, payload,
  )
  return response.data.data
}

async function cancelOrder(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/cancel_order`, payload,
  )
  return response.data.data
}

async function getAllOrder(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/order` , payload,
  )
  return response.data.data.data
}

async function getUserMetric(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/profile/user_metric`, payload,
  )
  return response.data.data.data
}

async function getDeposit(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/wallet/deposit`, payload,
  )
  return response.data.data
}

async function getWithdraw(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/wallet/withdrawal`, payload,
  )
  return response.data.data
}

async function getWalletBalance(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/wallet/wallet_balance` , payload,
  )
  return response.data.data
}

async function getPosition(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/position` , payload,
  )
  return response.data.data.data
}

async function getRealizedPnL(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/profile/position_list` , payload,
  )
  return response.data.data.data
}


async function cancelPosition(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/close_position` , payload,
  )
  return response.data.data.data
}

async function getFill(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/fill` , payload,
  )
  return response.data.data.data
}

async function getTradeList(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/profile/trade_list` , payload,
  )
  return response.data.data.data
}

async function getTransferList(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/profile/transfer_list` , payload,
  )
  return response.data.data.data
}

async function getFundingList(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/profile/funding_list` , payload,
  )
  return response.data.data.data
}

async function setTpSl(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/set_tpsl` , payload,
  )
  return response.data.data.data
}

async function homeInfo(signature,network) {
  httpService.setSignature(signature)
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/${network}/home_info`,
  )
  return response.data.data.data
}

async function marketInfo(signature,network) {
  httpService.setSignature(signature)
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/${network}/market_info`,
  )
  return response.data.data.data
}

async function getUserRanking(payload,signature,network) {
  httpService.setSignature(signature)
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/${network}/profile/user_ranking` , payload,
  )
  return response.data.data.data
}

export default {
  getUserMetric,
  getUserRanking,
  getWithdraw,
  getTradeRanking,
  marketInfo,
  homeInfo,
  getRealizedPnL,
  setTpSl,
  getFundingList,
  getTransferList,
  getTradeList,
  cancelPosition,
  getPosition,
  getFill,
  createOrder,
  cancelOrder,
  getDeposit,
  getWalletBalance,
  getAllOrder,
  register,
  getFavourite,
  addFavourite,
  deleteFavourite,
  getReferrals,
  getReferralRanking,
}
