//lib
import { MDBCol, MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { MDBCardHeader } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'

//redux
import { useSelector } from 'react-redux'

//component
import ModalBox from '../../../components/modal/full.modal.box'
import { useTranslation } from 'react-i18next'

export default function ConfirmModal({ show, title, setShow, handleClick }) {
  const { t } = useTranslation()

  const { trade_order_cancel_loading, trade_position_cancel_loading } = useSelector(
    (state) => state.trade,
  )
  return (
    <ModalBox
      className={'trade-order-container --cancel-order'}
      show={show}
      setShow={setShow}
      screenSize="s"
      reactModal={true}
      content={
        <div style={{ width: '21em' }}>
          <MDBCardHeader>
            <MDBRow className="d-flex align-items-center">
              <MDBCol className="col-8">
                <label className="_label --symbol">{t(title)}</label>
              </MDBCol>
              <MDBCol className="back-btn col-4">
                <div style={{ cursor: 'pointer' }} className="round-btn" onClick={setShow}>
                  <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBModalBody>
            <MDBContainer className="order-detail-container">
              <MDBRow className="mb-5">
                <MDBCol>
                  <label className="_label --title" style={{ fontSize: '0.875rem' }}>
                    {t(`Note`)} :{' '}
                    {t(
                      `If the available balance is less than your holding position, it may be because some of your assets are occupied by the margin`,
                    )}{' '}
                    .
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow className="m-0">
                <button
                  className="_button --confirm"
                  disabled={
                    trade_order_cancel_loading ? true : trade_position_cancel_loading ? true : false
                  }
                  onClick={() => handleClick()}>
                  {trade_order_cancel_loading
                    ? t('Waiting...')
                    : trade_position_cancel_loading
                    ? t('Waiting...')
                    : t('Confirm')}
                </button>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </div>
      }
    />
  )
}
