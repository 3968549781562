import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getReferrals,
  setClearSearchReferralsDetail,
  setSearchReferrals,
} from '../../redux/reducer/profileReducer'
import Detail from './component/detail'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'

export default function AdminPortal() {
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const { search_referral_detail } = useSelector((state) => state.profile)

  const handleSearch = async () => {
    dispatch(getReferrals({ address: value }))
      .unwrap()
      .then(() => {
        // dispatch(getFavourite({}))
      })
      .catch((error) => {
        dispatch(setClearSearchReferralsDetail())

        const MySwal = withReactContent(Swal)
        const errors = error.response.data.errors
        let errorMsg = ''
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            //   setFieldError(item, errors[item]);
            errorMsg += errors[item]
          })

          MySwal.fire({
            icon: 'error',
            title: 'Error',
            text: `${errorMsg}`,
            customClass: {
              title: 'custom-sweet-alert-title',
            },
          }).then((result) => {
            // window.location.href = process.env.REACT_APP_URL
          })
        } else {
          toast.error(JSON.stringify(error.message), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          })
        }
      })
    dispatch(setSearchReferrals(value))
  }

  useEffect(() => {
    if (value == '') {
      dispatch(setClearSearchReferralsDetail())
    }
  }, [value])

  return (
    <MDBContainer className="admin-portal-container">
      <MDBRow className="admin-portal-row">
        <MDBCol className="text-end p-0 col-9">
          <input
            className="w-100"
            placeholder="Search..."
            onChange={(e) => setValue(e.target.value)}
          />
        </MDBCol>
        <MDBCol className="text-start p-0 col-3">
          <button
            className="w-100"
            onClick={() => {
              handleSearch()
            }}>
            Search
          </button>
        </MDBCol>
      </MDBRow>
      {search_referral_detail !== '' ? <Detail /> : ''}
    </MDBContainer>
  )
}
