



import {
	makeApiRequest,
	findExchangeForSymbol,
} from './helpers.js';

import {
    subscribeOnStream,
    unsubscribeFromStream,
} from './streaming.js';



const lastBarsCache = new Map();

const configurationData = {
    supported_resolutions: ["1","5","15","30",'1h',"2h","4h","6h","12h",'24h'],
};

export default {
    onReady: (callback) => {
        setTimeout(() => callback(configurationData));
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
		setTimeout(function(){
			const symbolInfo = {
				// container_id: "tradingview_90063",
				ticker: symbolName.split('.').pop(),
				name: symbolName.split('.').pop(),
				description: localStorage.getItem("symbol"),
				type:  symbolName.split('.').pop(),
				session: '24x7',
				timezone: 'Etc/UTC',
				exchange:  "Crypto",
				minmov: 1,
				pricescale: Math.pow(10, localStorage.getItem("decimal")),
				has_intraday: true,
				supported_resolutions: configurationData.supported_resolutions,
				volume_precision: 1,
				// allow_symbol_change: false,
				data_status: 'streaming',
			};

        onSymbolResolvedCallback(symbolInfo);
    },0)
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;

		
		
		function periodLengthSeconds(resolution, requiredPeriodsCount) { 
			let daysCount = 0; 
			if (resolution === 'D' || resolution === '1D') { 
				daysCount = requiredPeriodsCount; 
			} else if (resolution === 'M' || resolution === '1M') { 
				daysCount = 31 * requiredPeriodsCount; 
			} else if (resolution === 'W' || resolution === '1W') { 
				daysCount = 7 * requiredPeriodsCount; 
			} else { 
				daysCount = requiredPeriodsCount * parseInt(resolution) / (24 * 60); 
			} return daysCount * 24 * 60 * 60; 
		}
		
		var tox = to > 0 ? to:1
		var fromx = to - periodLengthSeconds(resolution, 1500)
		fromx = fromx < 0 ? 0 :fromx 
		
		// const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

		const parsedSymbol = findExchangeForSymbol(localStorage.getItem("symbol"), true)


		localStorage.setItem("interval",resolution)

		let interval = "histominute"
		let aggregate = "1"
		if(Number(resolution) < 60){
			interval = "histominute"
			aggregate = resolution
		} else if(Number(resolution) >= 1440){
			interval = "histoday"
			aggregate = "1"
		} else {
			interval = "histohour"
		}

		
		const urlParameters = {
			e: parsedSymbol.exchange,
			fsym: parsedSymbol.fromSymbol,
			tsym: parsedSymbol.toSymbol,
			toTs: tox,
			limit: 1000,
			aggregate: aggregate
		};
		const query = Object.keys(urlParameters)
			.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
			.join('&');

		try {
			const data = await makeApiRequest(`data/v2/${interval}?${query}`);

			if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
				// "noData" should be set if there is no data in the requested period.
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}
			// console.log("data",data)
			let bars = [];
			data.Data.Data.forEach(bar => {
				if (bar.time >= from && bar.time < to) {
					bars = [...bars, {
						time: bar.time * 1000,
						low: bar.low,
						high: bar.high,
						open: bar.open,
						close: bar.close,
						// volume: bar.volumefrom,
					}];
				}
			});
			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			onHistoryCallback(bars, {
				noData: false,
			});
		} catch (error) {
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscriberUID,
		onResetCacheNeededCallback,
	) => {
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		unsubscribeFromStream(subscriberUID);
	},
};

