import { MDBContainer, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit'

import { WalletConnectIcon1 } from '../../../assets/images'

import LinkingWalletStep1 from './linking.wallet.step1'

import { useDispatch, useSelector } from 'react-redux'
import { setToggleStatus } from '../../../redux/reducer/connectorReducer'
import useSignMessage from '../../../hook/useSignMessage'
import { useTranslation } from 'react-i18next'
export default function LinkingWallet() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toggleStatus } = useSelector((state) => state.connector)
  const { verifyStatus, signMessage } = useSignMessage()

  return (
    <MDBContainer>
      <MDBRow className="header-title">
        <label>
          <img src={WalletConnectIcon1} width="45" />/ {t('Link wallet')}
        </label>
      </MDBRow>
      <MDBRow className="header-desc">
        <label>
          {t(
            'You will receive two signature requests.Signing is free and will not send a transaction',
          )}
        </label>
      </MDBRow>
      <LinkingWalletStep1 verifyStatus={verifyStatus} />
      {/* <LinkingWalletStep2 /> */}
      <MDBRow className="remember-container">
        <MDBCol>
          <label>{t('Remember me')}</label>
        </MDBCol>
        <MDBCol className="text-end">
          <label className="switch">
            <input
              type="checkbox"
              checked={toggleStatus}
              onChange={() => {}}
              onClick={() => dispatch(setToggleStatus(!toggleStatus))}
            />
            <span className="slider round"></span>
          </label>
        </MDBCol>
      </MDBRow>
      <MDBRow className="send-request-btn">
        <MDBBtn
          onClick={() => {
            signMessage()
          }}>
          {t('Send requests')}
        </MDBBtn>
      </MDBRow>
    </MDBContainer>
  )
}
