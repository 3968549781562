//lib
import { useEffect } from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'

//redux
import { useSelector } from 'react-redux'

//asset
import { Bab } from '../../../assets/images'

//hook
import usePythNetwork from '../../../hook/usePythNetwork'
import { useTranslation } from 'react-i18next'

export default function ProfileDetails() {
  const { t } = useTranslation()
  const { requestPriceFeed } = usePythNetwork()
  const { walletBalance, ranking } = useSelector((state) => state.profile)
  const { accounts, resolveName } = useSelector((state) => state.connector)
  const balance = Math.floor(walletBalance * Math.pow(10, 2)) / Math.pow(10, 2)
  useEffect(() => {
    requestPriceFeed()
  }, [])

  return (
    <MDBRow className="profile-detail-container">
      <MDBCol className="profile-detail-picture">
        <div>
          <img src={Bab} width="80" />
          {resolveName != 'null' && resolveName != null ? (
            <label className="text-indent">{resolveName.toUpperCase()}</label>
          ) : (
            <span>
              <label className="text-ellipsis">{accounts}</label>
              <label className="text-indent">{accounts ? accounts.slice(-5) : ''}</label>
            </span>
          )}
        </div>
      </MDBCol>
      <MDBCol className="profile-detail-wallet">
        <MDBRow>
          <MDBCol className="profile-wallet-box">
            <label className="title">{t(`Wallet Balance`)}</label>
            <label className="value">{balance.toLocaleString()}</label>
            <label className="detail">USDT</label>
          </MDBCol>
          <MDBCol className="profile-wallet-box">
            <label className="title">{t(`Rank`)}</label>
            <label className="value">{ranking}</label>
            <label className="detail">{t(`Master Traders`)}</label>
          </MDBCol>
          <MDBCol className="profile-wallet-box">
            <label className="title">{t(`Follower`)}</label>
            <label className="value">--</label>
            <label className="detail">{t(`Copy Trade`)}</label>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  )
}
