import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useRef, useState } from 'react'

//component
import SegmentedControl from '../../../components/element/segmentedControl'
import AnnouncementCard from './announcement.card'
import Portfolio from './portfolio'
import ModalBoxAnnouncement from './modalbox.announcement'

//sweetalert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/pagination'
// import required modules
import { Pagination } from 'swiper'

export default function Annoucement() {
  const [tabStatus, setTabStatus] = useState('announcement')
  const { modalAnnouncement, annoucementList, selectedAnnoucement } = useSelector(
    (state) => state.home,
  )

  const alertProfolio = () => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      icon: 'error',
      title: 'Error',
      text: `Please connect wallet for further action`,
      customClass: {
        title: 'custom-sweet-alert-title',
      },
    })
  }

  return (
    <MDBCol className="announcement-container">
      <MDBRow className="tab-bar-container">
        <SegmentedControl
          name="group-1"
          alertProfolio={alertProfolio}
          callback={(val) => setTabStatus(val)}
          controlRef={useRef()}
          segments={[
            {
              label: 'Announcements',
              value: 'announcement',
              ref: useRef(),
            },
            {
              label: 'My Portfolio',
              value: 'portfolio',
              ref: useRef(),
            },
          ]}
        />
      </MDBRow>
      {tabStatus == 'announcement' ? (
        <MDBRow className="announcement-content-container">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            breakpoints={{
              650: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
            className="mySwiper2">
            {annoucementList.map((item, index) => (
              <SwiperSlide key={index} className="announcement-swiper">
                <div className="card-container">
                  <AnnouncementCard listing={item} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {modalAnnouncement && <ModalBoxAnnouncement data={selectedAnnoucement} />}
        </MDBRow>
      ) : (
        <MDBContainer>
          <Portfolio />
        </MDBContainer>
      )}
    </MDBCol>
  )
}
