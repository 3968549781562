import { useSelector } from 'react-redux'

export default function useTradeOrder() {
  const { listing } = useSelector((state) => state.market)
  const decimal = 100
  const fixed_fee = 0.0008 //0.08%
  const funding_fee = 0.0001 //0.01%
  const close_fee = 0.0008 //0.08%
  const open_fee = 0.0008 //0.08%
  const rollover_fee = 0.0001 //0.01%

  function netPnl(
    type,
    positionSize,
    currentPrice,
    entryPrice,
    rolloverFee,
    fundingFee,
    closingFee,
  ) {
    if (currentPrice > 0 && entryPrice > 0 && closingFee && type == 'long') {
      let pnl = (positionSize / entryPrice) * (currentPrice - entryPrice)

      pnl = pnl - fundingFee + rolloverFee - closingFee

      return Math.round(pnl * decimal) / decimal
    } else if (currentPrice > 0 && entryPrice > 0 && closingFee && type == 'short') {
      let pnl = (positionSize / entryPrice) * (currentPrice - entryPrice) * -1

      pnl = pnl - fundingFee + rolloverFee - closingFee

      return Math.round(pnl * decimal) / decimal
    }
    return 0
  }

  const netPnlRate = (pnl, positionSize) => {
    if (pnl && positionSize) {
      const rate = (pnl / positionSize) * 100

      return rate.toFixed(2) + '%'
    }

    return '0.00%'
  }

  function liquidationPrice(
    orderType,
    openPrice,
    collateral,
    leverage,
    rolloverFee,
    fundingFee,
    symbol,
  ) {
    let liquidationPrice = 0
    if (orderType && openPrice && collateral > 0 && leverage > 0) {
      const liquidationPriceDistance =
        (openPrice * (collateral * 0.9 - rolloverFee - fundingFee)) / collateral / leverage
      if (orderType == 'long') {
        liquidationPrice = openPrice - liquidationPriceDistance
      } else {
        liquidationPrice = openPrice + liquidationPriceDistance
      }
      return liquidationPrice > 0
        ? Math.round(
            liquidationPrice * Math.pow(10, listing[handlePairing('symbol', symbol)].decimal),
          ) / Math.pow(10, listing[handlePairing('symbol', symbol)].decimal)
        : 0
    }
    return 0
  }

  function positionSize(collateral, leverage) {
    if (collateral && leverage) {
      const positionSize = collateral * leverage
      return positionSize > 0 ? Math.round(positionSize * decimal) / decimal : 0
    }
    return 0
  }

  function parseFullSymbol(fullSymbol) {
    const match = fullSymbol.match(/^(\w+).(\w+)\/(\w+)$/)
    if (!match) {
      return ''
    }

    return {
      exchange: match[1],
      fromSymbol: match[2],
      toSymbol: match[3],
    }
  }

  function calculateFee(positionSize) {
    if (positionSize > 0) {
      const fee = positionSize * fixed_fee
      return fee > 0 ? Math.ceil(fee * decimal) / decimal : 0
    }
    return 0
  }

  function priceChange(openPrice, closePrice) {
    if (openPrice > 0 && closePrice > 0) {
      const priceChange = ((closePrice - openPrice) / closePrice) * 100
      return Math.ceil(priceChange * decimal) / decimal
    }
    return 0
  }

  function takeProfitPrice(status, type, takePrice, price, leverage, symbol) {
    let takeProfit = 'None'
    if (status == 'long') {
      if (takePrice > 0) {
        if (type == 'custom') {
          takeProfit = ((takePrice - price) / (price / leverage)) * 100
        } else {
          takeProfit = price + ((price / leverage) * takePrice) / 100
        }
      }
    } else {
      if (takePrice > 0) {
        if (type == 'custom') {
          takeProfit = -(((takePrice - price) / (price / leverage)) * 100)
        } else {
          takeProfit = price - ((price / leverage) * takePrice) / 100
        }
      }
    }
    if (Number(takeProfit) < -100) {
      return Math.round(-100 * decimal) / decimal
    } else {
      return (
        Math.round(takeProfit * Math.pow(10, listing[handlePairing('symbol', symbol)].decimal)) /
        Math.pow(10, listing[handlePairing('symbol', symbol)].decimal)
      )
    }
  }

  function stopPrice(status, type, takePrice, price, leverage, symbol) {
    let stopPrice = 'None'
    if (status == 'long') {
      if (takePrice > 0) {
        if (type == 'custom') {
          stopPrice = (-(takePrice - price) / (price / leverage)) * -1 * 100
        } else {
          stopPrice = price - ((price / leverage) * takePrice) / 100
        }
      }
    } else {
      if (takePrice > 0) {
        if (type == 'custom') {
          stopPrice = (-(takePrice - price) / (price / leverage)) * 100
        } else {
          stopPrice = price + ((price / leverage) * takePrice) / 100
        }
      }
    }
    if (Number(stopPrice) < -100) {
      return Math.round(-100 * decimal) / decimal
    } else {
      return (
        Math.round(stopPrice * Math.pow(10, listing[handlePairing('symbol', symbol)].decimal)) /
        Math.pow(10, listing[handlePairing('symbol', symbol)].decimal)
      )
    }
  }

  function calculateFundingFee(collateral, leverage) {
    if (collateral) {
      const calculateFundingFee = funding_fee * collateral * leverage
      return Math.round(calculateFundingFee * decimal) / decimal
    }
    return 0
  }

  function calculateRolloverFee(collateral) {
    if (collateral) {
      const calculateRolloverFee = rollover_fee * collateral
      return Math.round(calculateRolloverFee * decimal) / decimal
    }
    return 0
  }

  function calculateCloseFee(collateral, leverage) {
    if (collateral) {
      const calculateCloseFee = close_fee * collateral * leverage
      return Math.round(calculateCloseFee * decimal) / decimal
    }
    return 0
  }

  function calculateOpenFee(collateral, leverage) {
    if (collateral) {
      const calculateOpenFee = open_fee * collateral * leverage
      return Math.round(calculateOpenFee * decimal) / decimal
    }
    return 0
  }

  function handlePairing(type, symbol) {
    if (symbol != '' && symbol != null && symbol != undefined) {
      if (type == 'trading') {
        //BNB/USD

        if (symbol.startsWith('USD')) {
          const newSymbol = symbol.split('/').pop()
          return `Crypto:${newSymbol}/USDT`
        } else {
          const newSymbol = symbol.split('/')[0]
          return `Crypto:${newSymbol}/USDT`
        }
      } else if (type == 'pyth' && symbol) {
        const newSymbol = symbol.split('.')
        if (newSymbol[0] == 'Crypto') {
          return newSymbol[1]
        } else if (newSymbol[0] == 'Equity') {
          const newEquitySymbol = symbol.split('.').pop()
          return newEquitySymbol
        } else if (newSymbol[0] == 'Metal') {
          return newSymbol[1]
        } else if (newSymbol[0] == 'FX') {
          return newSymbol[1]
        }
      } else if (type == 'symbol' && symbol) {
        if (symbol.startsWith('USD')) {
          return symbol.split('/').pop()
        } else {
          return symbol.split('/')[0]
        }
      }
    } else {
      return 'Binance:BNB/USDT'
    }
  }

  function convertPairing(values, type) {
    let pairing = ''
    let exchangePairing = ''
    for (var key in listing) {
      if (`${listing[key].to}/${listing[key].from}` == values) {
        pairing = `${listing[key].to}/${listing[key].from}`
        exchangePairing = `${listing[key].type}.${listing[key].to}/${listing[key].from}`
      }
    }
    if (type == 'exchange') {
      return exchangePairing
    } else {
      return pairing
    }
  }

  function convertInterval(value) {
    if (value == 60) {
      return '1h'
    } else if (value == 120) {
      return '2h'
    } else if (value == 240) {
      return '4h'
    } else if (value == 360) {
      return '6h'
    } else if (value == 720) {
      return '12h'
    } else if (value == 1440) {
      return '24h'
    } else {
      return value
    }
  }

  const countDecimals = (values) => {
    if (Math.floor(values.valueOf()) === values.valueOf()) return 0

    var str = values.toString()
    if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
      return str.split('-')[1] || 0
    } else if (str.indexOf('.') !== -1) {
      return str.split('.')[1].length || 0
    }
    return str.split('-')[1] || 0
  }

  return {
    netPnlRate,
    convertInterval,
    convertPairing,
    countDecimals,
    handlePairing,
    calculateOpenFee,
    calculateFundingFee,
    calculateRolloverFee,
    calculateCloseFee,
    priceChange,
    stopPrice,
    takeProfitPrice,
    netPnl,
    liquidationPrice,
    positionSize,
    parseFullSymbol,
    calculateFee,
  }
}
