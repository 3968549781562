import { createSlice,  createAsyncThunk } from '@reduxjs/toolkit'
import authServices from '../../services/auth.services'
import apiServices from '../../services/api.services'
import web3Services from '../../services/web3.services'

export const login = createAsyncThunk('login', async ({ payload }, thunkAPI) => {
  try {
    const response = await authServices.login(payload)
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getReferrals = createAsyncThunk(
  'connector/getReferrals',
  async (payload, thunkAPI) => {
    try {
      const respond = await apiServices.getReferrals(payload)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getReferralsRanking = createAsyncThunk(
  'profile/getReferralsRanking',
  async (payload, thunkAPI) => {
    try {
      const respond = await apiServices.getReferralRanking()
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getTradeRanking = createAsyncThunk(
  'profile/getTradeRanking',
  async (payload, thunkAPI) => {
    try {
      const respond = await apiServices.getTradeRanking(payload)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deposit = createAsyncThunk(
  'trade/deposit',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getDeposit(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const metric = createAsyncThunk(
  'profile/getUserMetric',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getUserMetric(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const withdraw = createAsyncThunk(
  'trade/withdraw',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getWithdraw(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getWalletBalance = createAsyncThunk(
  'trade/walletBalance',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getWalletBalance(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getFundingList = createAsyncThunk(
  'profile/getFundingList',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getFundingList(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getTradeList = createAsyncThunk(
  'profile/getTradeList',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getTradeList(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getTransferList = createAsyncThunk(
  'profile/getTransferList',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getTransferList(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getRealizedPnL = createAsyncThunk(
  'profile/getRealizedPnL',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getRealizedPnL(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getUserRanking = createAsyncThunk(
  'profile/getUserRanking',
  async ({payload,data,network}, thunkAPI) => {
    try {
      const respond = await apiServices.getUserRanking(payload,data,network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const initialState = {
  leaderboard_referral:[],
  leaderBoard_trade_master: [],
  metricsData: {
    totalPnl: '--',
    volume: '--',
  },
  referral_detail: {
    trade_referrals: {
      referral_code: '',
      trading_fee_discount: ' - ',
      total_trading_volume: '0.00',
      total_rebate: '0.00',
      trade_referrals_rebate_history_status: false,
      trade_referrals_rebate_history: [
        {
          date: ' - ',
          transaction: ' - ',
          amount: ' - ',
        },
      ],
      loader: false,
    },
    affiliates: {
      tier_level: '0',
      referral_code: '',
      referral_rate: ' - ',
      referral_code_desc: '',
      referral_link: '',
      total_trader_referral: 0,
      total_trading_volume: '0.00',
      total_rebate: '0.00',
      affiliates_rebate_history_status: false,
      affiliates_rebate_history: [
        {
          date: ' - ',
          transaction: ' - ',
          amount: ' - ',
        },
      ],
      loader: false,
    },
  },
  search_referral_detail: '',
  search_referral: '',
  referral_address: [],
  basis_point: 0,
  walletBalance: '0',
  checkIn: true,
  checkInDate: 0,
  referral_link: '',
  modalRebateHistory: false,
  passphrase: localStorage.getItem('passphrase'),
  //handle button status
  button_is_checking: false,
  btn_affiliates_is_checking: false,
  wallet_history_modal:false,
  wallet_history_realized_listing: [],
  wallet_history_transfer_listing: [],
  wallet_history_interest_listing: [],
  wallet_history_trade_listing:[],
  deposit_balance: 2000,
  deposit_submit_loading: false,
  withdraw_submit_loading: false,
  isEditing: false,
  isLoading: true,
  liq_wallet: "20",
  withdraw_modal: false,
  deposit_modal: false,
  historyDetailModal: false,
  historyDetailModalData: {},
  realizedDetailModal: false,
  realizedDetailModalData: {},
  wallet_history: false,
  ranking: "--"
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setWalletRealizedDetailModal: (state,action) => {
      state.realizedDetailModal = action.payload.status
      state.realizedDetailModalData = action.payload.data
    },
    setWalletHistoryDetailModal: (state,action) => {
      state.historyDetailModal = action.payload.status
      state.historyDetailModalData = action.payload.data
    },
    setDepositBalance:(state,action) => {
      state.deposit_balance = action.payload
    },
    setWithdrawModal:(state,action) => {
      state.withdraw_modal = action.payload
    },
    setDepositModal:(state,action) => {
      state.deposit_modal = action.payload
    },
    setWalletHistoryModal:(state,action) => {
      state.wallet_history_modal = action.payload
    },
    setClearSearchReferralsDetail:(state,action) => {
      state.search_referral = ""
      state.search_referral_detail = ""
    },
    setSearchReferrals: (state, action) => {
      state.search_referral = action.payload
    },
    setIsEditing: (state, action) => {
      state.isLoading = action.payload
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload
    },
    setWalletBalance: (state, action) => {
      return {
        ...state,
        walletBalance: action.payload,
      }
    },
    setModalRebateHistory: (state, action) => {
      return {
        ...state,
        modalRebateHistory: action.payload.status,
      }
    },
    setTradeRebateHistory: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          trade_referrals: {
            ...state.referral_detail.trade_referrals,
            trade_referrals_rebate_history_status: action.payload.status,
          },
        },
      }
    },
    setAffiliatesRebateHistory: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          affiliates: {
            ...state.referral_detail.affiliates,
            affiliates_rebate_history_status: action.payload.status,
          },
        },
      }
    },
    setButtonIsChecking: (state, action) => {
      return {
        ...state,
        button_is_checking: action.payload,
      }
    },
    setBtnAffiliatesIsChecking: (state, action) => {
      return {
        ...state,
        button_is_checking: action.payload,
      }
    },
    setTradeReferralCode: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          trade_referrals: {
            ...state.referral_detail.trade_referrals,
            referral_code: action.payload,
          },
        },
      }
    },
    getAffiliatesReferralCode: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          affiliates: {
            ...state.referral_detail.affiliates,
            referral_code: action.payload,
            referral_link: `${process.env.REACT_APP_URL}/profile?ref=` + action.payload,
          },
        },
      }
    },
    setTier: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          affiliates: {
            ...state.referral_detail.affiliates,
            tier_level: parseInt(action.payload) + 1,
          },
        },
      }
    },
    setBasisValue: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          trade_referrals: {
            ...state.referral_detail.trade_referrals,
            totalRebate: action.payload.totalRebate,
          },
          affiliates: {
            ...state.referral_detail.affiliates,
            discountShare: action.payload.discountShare,
          },
        },
      }
    },
    setBasis: (state, action) => {
      return {
        ...state,
        basis_point: action.payload,
      }
    },
    handleProfileDisconnect: (state, action) => {
      // localStorage.clear()
      web3Services.clearLocalStorage()
      return {
        ...state,
        metricsData: {
          profitLossPrice: '--',
          profitLossRate: '--',
          volume: '--',
        },
        referral_detail: {
          trade_referrals: {
            referral_code: '',
            trading_fee_discount: ' - ',
            total_trading_volume: '0.00',
            total_rebate: '0.00',
            trade_referrals_rebate_history_status: false,
            trade_referrals_rebate_history: [],
          },
          affiliates: {
            tier_level: '0',
            referral_code: '',
            referral_rate: ' - ',
            referral_code_desc: '',
            referral_link: ``,
            total_trader_referral: 0,
            total_trading_volume: '0.00',
            total_rebate: '0.00',
            affiliates_rebate_history_status: false,
            affiliates_rebate_history: [],
          },
        },
        basis_point: 0,
        walletBalance: '0',
        checkIn: true,
        checkInDate: 0,
        referral_link: '',
        modalRebateHistory: false,
        //handle button status
        button_is_checking: false,
        btn_affiliates_is_checking: false,
        isEditing: false,
      }
    },
    setTradeLoader: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          trade_referrals: {
            ...state.referral_detail.trade_referrals,
            loader: action.payload,
          },
        },
      }
    },
    setAffiliatesLoader: (state, action) => {
      return {
        ...state,
        referral_detail: {
          ...state.referral_detail,
          affiliates: {
            ...state.referral_detail.affiliates,
            loader: action.payload,
          },
        },
      }
    },
  },
  extraReducers: {
    [withdraw.pending]:(state,action) => {
      state.withdraw_submit_loading = true
    },
    [withdraw.fulfilled]:(state,action) => {
      state.withdraw_modal = false
      state.withdraw_submit_loading = false
    },
    [withdraw.rejected]:(state,action) => {
      state.withdraw_submit_loading = false
    },
    [getTradeRanking.pending]: (state, action) => {},
    [getTradeRanking.fulfilled]: (state, action) => {
     state.leaderBoard_trade_master = action.payload.data
    },
    [getReferralsRanking.pending]: (state, action) => {},
    [getReferralsRanking.fulfilled]: (state, action) => {
     state.leaderboard_referral = action.payload.data
    },
    [getReferrals.pending]: (state, action) => {},
    [getReferrals.fulfilled]: (state, action) => {
      state.referral_detail.affiliates.total_trader_referral = action.payload.count
      state.search_referral_detail = action.payload.count
      state.referral_address = action.payload.referrals
    },
    [getUserRanking.pending]: (state, action) => {},
    [getUserRanking.fulfilled]: (state, action) => {
      state.ranking = action.payload
    },
    [login.pending]: (state, action) => {
      return {
        ...state,
      }
    },
    [login.fulfilled]: (state, action) => {
      localStorage.setItem("passphrase",action.payload.passphrase)
      return {
        ...state,
        checkIn: action.payload.daily_check_in,
        checkInDate: action.payload.no_check_in,
        referral_link: action.payload.referral_code,
        passphrase: action.payload.passphrase
      }
    },
    [login.rejected]: (state, action) => {
      return {
        ...state,
      }
    },
    [metric.pending]:(state,action) => {
    },
    [metric.fulfilled]:(state,action) => {
      state.metricsData = action.payload
    },
    [deposit.pending]:(state,action) => {
      state.deposit_submit_loading = true
    },
    [deposit.fulfilled]:(state,action) => {
      state.deposit_modal = false
      state.deposit_submit_loading = false
    },
    [deposit.rejected]:(state,action) => {
      state.deposit_submit_loading = false
    },
    [getWalletBalance.fulfilled]:(state,action) => {
      state.walletBalance = action.payload.balance
    },
    [getTradeList.fulfilled]:(state,action) => {
      state.wallet_history_trade_listing = action.payload
    },
    [getTransferList.fulfilled]:(state,action) => {
      state.wallet_history_transfer_listing = action.payload
    },
    [getFundingList.fulfilled]:(state,action) => {
      state.wallet_history_interest_listing = action.payload
    },
    [getRealizedPnL.pending]:(state,action) => {
      state.wallet_history = true
    },
    [getRealizedPnL.fulfilled]:(state,action) => {
      state.wallet_history = false
      state.wallet_history_realized_listing = action.payload
    },
    [getRealizedPnL.rejected]:(state,action) => {
      state.wallet_history = false
    },
  },
})

export const {
  setWalletRealizedDetailModal,
  setWalletHistoryDetailModal,
  setDepositBalance,
  setWithdrawModal,
  setDepositModal,
  setWalletHistoryModal,
  setClearSearchReferralsDetail,
  setSearchReferrals,
  setTradeLoader,
  setAffiliatesLoader,
  handleProfileDisconnect,
  setIsEditing,
  setTier,
  setBasis,
  setBasisValue,
  getAffiliatesReferralCode,
  setTradeReferralCode,
  setButtonIsChecking,
  setBtnAffiliatesIsChecking,
  setWalletBalance,
  setModalRebateHistory,
  setTradeRebateHistory,
  setAffiliatesRebateHistory,
} = profileSlice.actions
const { reducer } = profileSlice
export default reducer
