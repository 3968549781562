import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { useState } from 'react'

//lib
import { toast } from 'react-toastify'

//asset
import DefaultGif from '../../../assets/images/gif/favourite-icon-default.png'
import LoopingGif from '../../../assets/images/gif/favourite-icon-looping.gif'
import FullGif from '../../../assets/images/gif/favourite-icon-without-looping.gif'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { addFavourite, getFavourite, removeFavourite } from '../../../redux/reducer/marketReducer'
import { useNavigate } from 'react-router-dom'

export default function ListingMarketCard({ item, responsive }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [startGif, setStartGIf] = useState(false)
  const { marketType, favourite } = useSelector((state) => state.market)
  const formatCash = (num, digits = 1) => {
    var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  const handleClick = (values, type, decimal) => {
    if (type == 'Crypto') {
      navigate('/trade')
      localStorage.setItem('symbol', values)
      localStorage.setItem('decimal', decimal)
    } else {
      toast.info(
        <div>
          <MDBRow className="m-0">
            <MDBCol className="text-start">
              <label className="_label">{item.title + '/' + item.from} Coming Soon</label>
            </MDBCol>
          </MDBRow>
        </div>,
        {
          position: 'top-left',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          theme: 'dark',
        },
      )
    }
  }

  return (
    <div style={{ position: 'relative' }} key={item.title}>
      {responsive == 'mobile' ? (
        <div>
          {marketType == item.type ? (
            <>
              <MDBRow
                id={item.title}
                className={'listing-container'}
                style={{ zIndex: 1, background: '#1a1a1a', position: 'relative' }}>
                <MDBCol className="title col-8 p-0">
                  <img
                    onClick={() => {
                      if (favourite.includes(item.title + '/USDT')) {
                        dispatch(removeFavourite(item.title))
                          .unwrap()
                          .then(() => {
                            dispatch(getFavourite({}))
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      } else {
                        dispatch(addFavourite(item.title))
                          .then(() => {
                            setStartGIf(true)
                            dispatch(getFavourite({}))
                            setTimeout(() => {
                              setStartGIf(false)
                            }, 1000)
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      }
                    }}
                    style={{
                      filter: favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? ''
                          : ''
                        : 'brightness(300%)',
                    }}
                    src={
                      favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? LoopingGif
                          : FullGif
                        : DefaultGif
                    }
                    width={30}
                  />
                  <img src={item.image} width="35" />
                  <label
                    onClick={() => {
                      handleClick(`${item.to}/${item.from}`, item.type, item.decimal)
                    }}>
                    {item.title}
                    <span>USDT</span>
                  </label>
                </MDBCol>
                <MDBCol className="price text-end p-0">
                  <label>$ {item.price ? parseFloat(item.price) : '0'}</label>
                  <span>{item.volume <= 0 ? '-' : formatCash(item.volume)}</span>
                </MDBCol>
              </MDBRow>
            </>
          ) : marketType == 'ALL' ? (
            <>
              <MDBRow
                id={item.title}
                className={'listing-container'}
                style={{ zIndex: 1, background: '#1a1a1a', position: 'relative' }}>
                <MDBCol className="title col-8 p-0">
                  <img
                    onClick={() => {
                      if (favourite.includes(item.title + '/USDT')) {
                        dispatch(removeFavourite(item.title))
                          .unwrap()
                          .then(() => {
                            dispatch(getFavourite({}))
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      } else {
                        dispatch(addFavourite(item.title))
                          .then(() => {
                            setStartGIf(true)
                            dispatch(getFavourite({}))
                            setTimeout(() => {
                              setStartGIf(false)
                            }, 1000)
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      }
                    }}
                    style={{
                      filter: favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? ''
                          : ''
                        : 'brightness(300%)',
                    }}
                    src={
                      favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? LoopingGif
                          : FullGif
                        : DefaultGif
                    }
                    width={30}
                  />
                  <img src={item.image} width="35" />
                  <label
                    onClick={() => {
                      handleClick(`${item.to}/${item.from}`, item.type, item.decimal)
                    }}>
                    {item.title}
                    <span>USDT</span>
                  </label>
                </MDBCol>
                <MDBCol className="price text-end p-0">
                  <label>$ {item.price ? parseFloat(item.price) : '0'}</label>
                  <span>{item.volume <= 0 ? '-' : formatCash(item.volume)}</span>
                </MDBCol>
              </MDBRow>
            </>
          ) : marketType == 'Favourite' && item.is_favourite == true ? (
            <>
              <MDBRow
                id={item.title}
                className={'listing-container'}
                style={{ zIndex: 1, background: '#1a1a1a', position: 'relative' }}>
                <MDBCol className="title col-8 p-0">
                  <img
                    onClick={() => {
                      if (favourite.includes(item.title + '/USDT')) {
                        dispatch(removeFavourite(item.title))
                          .unwrap()
                          .then(() => {
                            dispatch(getFavourite({}))
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      } else {
                        dispatch(addFavourite(item.title))
                          .then(() => {
                            setStartGIf(true)
                            dispatch(getFavourite({}))
                            setTimeout(() => {
                              setStartGIf(false)
                            }, 1000)
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      }
                    }}
                    style={{
                      filter: favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? ''
                          : ''
                        : 'brightness(300%)',
                    }}
                    src={
                      favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? LoopingGif
                          : FullGif
                        : DefaultGif
                    }
                    width={30}
                  />
                  <img src={item.image} width="35" />
                  <label
                    onClick={() => {
                      handleClick(`${item.to}/${item.from}`, item.type, item.decimal)
                    }}>
                    {item.title}
                    <span>USDT</span>
                  </label>
                </MDBCol>
                <MDBCol className="price text-end p-0">
                  <label>$ {item.price ? parseFloat(item.price) : '0'}</label>
                  <span>{item.volume <= 0 ? '-' : formatCash(item.volume)}</span>
                </MDBCol>
              </MDBRow>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div>
          {marketType == item.type ? (
            <>
              <MDBRow id={item.title} className="listing-container" key={item.title}>
                <MDBCol className="title col-3" style={{ paddingRight: 0 }}>
                  <img
                    onClick={() => {
                      if (favourite.includes(item.title + '/USDT')) {
                        dispatch(removeFavourite(item.title))
                          .unwrap()
                          .then(() => {
                            dispatch(getFavourite({}))
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      } else {
                        dispatch(addFavourite(item.title))
                          .then(() => {
                            setStartGIf(true)
                            dispatch(getFavourite({}))
                            setTimeout(() => {
                              setStartGIf(false)
                            }, 1000)
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      }
                    }}
                    style={{
                      filter: favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? ''
                          : ''
                        : 'brightness(300%)',
                    }}
                    src={
                      favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? LoopingGif
                          : FullGif
                        : DefaultGif
                    }
                    width={30}
                  />
                  <img src={`${item.image}`} width="35" height="35" />
                  <label
                    onClick={() => {
                      handleClick(`${item.to}/${item.from}`, item.type, item.decimal)
                    }}>
                    {item.title}
                    <span>USDT</span>
                  </label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>$ {parseFloat(item.price)}</label>
                </MDBCol>
                <MDBCol className={`text ${item.status} col`}>
                  <label>{item.priceChange.toFixed(2)} %</label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>{item.volume <= 0 ? '-' : formatCash(item.volume)}</label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>{item.trade <= 0 ? '-' : formatCash(item.trade)}</label>
                </MDBCol>
              </MDBRow>
            </>
          ) : marketType == 'ALL' ? (
            <>
              <MDBRow id={item.title} className="listing-container" key={item.title}>
                <MDBCol className="title col-3" style={{ paddingRight: 0 }}>
                  <img
                    onClick={() => {
                      if (favourite.includes(item.title + '/USDT')) {
                        dispatch(removeFavourite(item.title))
                          .unwrap()
                          .then(() => {
                            dispatch(getFavourite({}))
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      } else {
                        dispatch(addFavourite(item.title))
                          .then(() => {
                            setStartGIf(true)
                            dispatch(getFavourite({}))
                            setTimeout(() => {
                              setStartGIf(false)
                            }, 1000)
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      }
                    }}
                    style={{
                      filter: favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? ''
                          : ''
                        : 'brightness(300%)',
                    }}
                    src={
                      favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? LoopingGif
                          : FullGif
                        : DefaultGif
                    }
                    width={30}
                  />
                  <img src={`${item.image}`} width="35" height="35" />
                  <label
                    onClick={() => {
                      handleClick(`${item.to}/${item.from}`, item.type, item.decimal)
                    }}>
                    {item.title}
                    <span>USDT</span>
                  </label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>$ {item.price ? parseFloat(item.price) : '0'}</label>
                </MDBCol>
                <MDBCol className={`text ${item.status} col`}>
                  <label>{item.priceChange.toFixed(2)} %</label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>{item.volume <= 0 ? '-' : formatCash(item.volume)}</label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>{item.trade <= 0 ? '-' : formatCash(item.trade)}</label>
                </MDBCol>
              </MDBRow>
            </>
          ) : marketType == 'Favourite' && item.is_favourite == true ? (
            <>
              <MDBRow id={item.title} className="listing-container" key={item.title}>
                <MDBCol className="title col-3" style={{ paddingRight: 0 }}>
                  <img
                    onClick={() => {
                      if (favourite.includes(item.title + '/USDT')) {
                        dispatch(removeFavourite(item.title))
                          .unwrap()
                          .then(() => {
                            dispatch(getFavourite({}))
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      } else {
                        dispatch(addFavourite(item.title))
                          .then(() => {
                            setStartGIf(true)
                            dispatch(getFavourite({}))
                            setTimeout(() => {
                              setStartGIf(false)
                            }, 1000)
                          })
                          .catch((ex) => {
                            const errors = ex.response.data.errors
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item], {
                                  position: 'top-left',
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  theme: 'dark',
                                })
                              })
                            } else {
                              toast.error(JSON.stringify(ex.message), {
                                position: 'top-left',
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: 'dark',
                              })
                            }
                          })
                      }
                    }}
                    style={{
                      filter: favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? ''
                          : ''
                        : 'brightness(300%)',
                    }}
                    src={
                      favourite.includes(item.title + '/USDT')
                        ? startGif
                          ? LoopingGif
                          : FullGif
                        : DefaultGif
                    }
                    width={30}
                  />
                  <img src={`${item.image}`} width="35" height="35" />
                  <label
                    onClick={() => {
                      handleClick(`${item.to}/${item.from}`, item.type, item.decimal)
                    }}>
                    {item.title}
                    <span>USDT</span>
                  </label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>$ {item.price ? parseFloat(item.price) : '0'}</label>
                </MDBCol>
                <MDBCol className={`text ${item.status} col`}>
                  <label>{item.priceChange.toFixed(2)} %</label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>{item.volume <= 0 ? '-' : formatCash(item.volume)}</label>
                </MDBCol>
                <MDBCol className="text col">
                  <label>{item.trade <= 0 ? '-' : formatCash(item.trade)}</label>
                </MDBCol>
              </MDBRow>
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )
}
