import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useSearchParams } from 'react-router-dom'
import ShareBackground from '../../assets/images/background/Share-Backgroud.png'
import base64 from 'base64-js'
import QRCode from 'qrcode.react'

export default function Share() {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryString = searchParams.get('text')

  const convertString = new TextDecoder().decode(base64.toByteArray(queryString))

  function queryStringToObject() {
    if (convertString) {
      const pairs = convertString.split('&')
      const result = {}

      pairs.forEach((pair) => {
        const [key, value] = pair.split('=')
        result[key] = decodeURIComponent(value)
      })

      return result
    }
  }

  return (
    <MDBContainer>
      <div className="share-modal-box --position-card" fixed="center">
        <div className="card-container">
          <div className="border-container">
            {/* <MDBContainer
              className="card"
              style={{ backgroundImage: `url(${ShareBackground})`, backgroundSize: 'cover' }}>
              <MDBRow>
                <label className="_label --name">
                  DERI <span>TRADE</span>
                </label>
              </MDBRow>
              <MDBRow className="label-column">
                <label className={`_label --card-label --${queryStringToObject().type}`}>
                  {queryStringToObject().type}
                </label>
                <label className="_label --card-label">{queryStringToObject().leverage}X</label>
                <label className="_label --card-label">
                  {queryStringToObject().symbol} <span>Perpetual</span>
                </label>
              </MDBRow>
              <MDBRow>
                <label
                  className={`_label --rate ${
                    queryStringToObject().rate > 0 ? '--profit' : '--loss'
                  }`}>
                  {queryStringToObject().rate}
                </label>
              </MDBRow>
              <MDBRow className="align-items-center">
                <label className="_label --price-label">Open Price</label>
                <label className="_label --price">
                  {Math.round(queryStringToObject().open_price * 100) / 100}
                </label>
              </MDBRow>
              <MDBRow className="align-items-center">
                <label className="_label --price-label">Mark Price</label>
                <label className="_label --price">{queryStringToObject().market_price}</label>
              </MDBRow>
              {queryStringToObject().referral_link &&
              queryStringToObject().code &&
              queryStringToObject().code == 'null' ? (
                <MDBRow className="align-items-center">
                  <MDBRow className="align-items-center mt-2">
                    <MDBCol className="d-flex align-items-center col-2">
                      <QRCode className="qr-gen" size={50} value={process.env.REACT_APP_URL} />
                    </MDBCol>
                    <MDBCol style={{ lineHeight: '1em' }}>
                      <label className="_label --refer">SCAN TO REGISTER !</label>
                    </MDBCol>
                  </MDBRow>
                </MDBRow>
              ) : (
                <MDBRow className="align-items-center mt-2">
                  <MDBCol className="d-flex align-items-center col-2">
                    <QRCode
                      className="qr-gen"
                      size={50}
                      value={queryStringToObject().referral_link}
                    />
                  </MDBCol>
                  <MDBCol style={{ lineHeight: '1em' }}>
                    <label className="_label --refer w-100">REFERRAL CODE</label>
                    <label className="_label --refer-code w-100">
                      {queryStringToObject().code}
                    </label>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer> */}
            <MDBContainer className="card" style={{ backgroundImage: `url(${ShareBackground})` }}>
              <MDBRow className="h-100 align-items-center content-background">
                <MDBCol className="text-center">
                  <MDBRow>
                    <label className="_label --symbol">
                      {queryStringToObject().symbol}{' '}
                      <span className={`--${queryStringToObject().type}`}>
                        {queryStringToObject().type}
                      </span>
                    </label>
                  </MDBRow>
                  {console.log('yellow', queryStringToObject().pnl)}
                  <label
                    className={`_label --rate ${
                      Number(queryStringToObject().pnl) > 0 ? '--profit' : '--loss'
                    }`}>
                    {queryStringToObject().rate}
                  </label>
                  <MDBRow className="label-column">
                    <div>
                      <label className="_label --price-label">Entry Price:</label>
                      <label className="_label --price">{queryStringToObject().open_price}</label>
                    </div>
                    <div>
                      <label className="_label --price-label">Leverage:</label>
                      <label className="_label --price">{queryStringToObject().leverage}X</label>
                    </div>
                    <div>
                      <label className="_label --price-label">Last Price:</label>
                      <label className="_label --price">{queryStringToObject().market_price}</label>
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol
                  className="d-grid justify-content-center p-0"
                  style={{ placeItems: 'center' }}>
                  {/* <MDBCol className="d-flex align-items-center col-2"> */}
                  <QRCode
                    className="qr-gen"
                    size={50}
                    value={
                      queryStringToObject().code !== ''
                        ? queryStringToObject().referral_link
                        : process.env.REACT_APP_URL
                    }
                  />
                  <label className="_label --qrcode-label">Scan the QR Code to register.</label>
                  {/* </MDBCol> */}
                  {/* <MDBCol style={{ lineHeight: '1em' }}>
                      <label className="_label --refer w-100">
                        {referral_code !== '' ? 'REFERRAL CODE' : 'SCAN TO REGISTER !'}
                      </label>
                      {referral_code !== '' ? (
                        <label className="_label --refer-code w-100">{referral_code}</label>
                      ) : (
                        ''
                      )}
                    </MDBCol> */}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </div>
    </MDBContainer>
  )
}
