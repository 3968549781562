import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import QRCodeModal from '@walletconnect/qrcode-modal'
import _ from 'lodash'
import Web3 from 'web3'

//common component
import { connectors } from '../../pages/Home/component/connectors'

//sweetalert helper
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//services
import apiServices from '../../services/api.services'
import web3Services from '../../services/web3.services'

export const registerAccount = createAsyncThunk(
  'connector/register',
  async ({ payload }, thunkAPI) => {
    const { deactivate, address } = payload

    try {
      const response = await apiServices.register(address)
      return response
    } catch (error) {
      const MySwal = withReactContent(Swal)

      let errors = error.response.data
      let errorMsg = ''
      if (errors) {
        if (errors && Object.keys(errors).length > 0) {
          errors = errors.errors
          Object.keys(errors).map((item, i) => {
            errorMsg += errors[item]
          })
          MySwal.fire({
            icon: 'error',
            title: 'Error',
            text: `${errorMsg}`,
            customClass: {
              title: 'custom-sweet-alert-title',
            },
          }).then((result) => {
            // localStorage.clear()
            web3Services.clearLocalStorage()
            connectors[localStorage.getItem('provider')].killSession()
            deactivate()
          })
        }
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: `Unexpected error. Please refresh or contact customer support if error persist.`,
          customClass: {
            title: 'custom-sweet-alert-title',
          },
        }).then((result) => {
          // localStorage.clear()
          web3Services.clearLocalStorage()
          connectors[localStorage.getItem('provider')].killSession()
          deactivate()
        })
      }
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getReferrals = createAsyncThunk(
  'connector/getReferrals',
  async (address) => {
    const respond = await apiServices.getReferrals({ address: address })
    return respond
  },
)

export const reverseResolveName = createAsyncThunk(
  'connector/reverseResolveName',
  async (address) => {
    // bsc domain example
    const SID = require('@siddomains/sidjs').default
    const SIDfunctions = require('@siddomains/sidjs')
    // const address = '0xb688A89028438397cBdF427b6f91D0489E47Ba91'
    // const address = '0x88dC0cc038bF0A1D9a79E3E3Bb958A55882a838B'
    const rpc = process.env.REACT_APP_SPACE_ID_RPC
    const chainId = process.env.REACT_APP_ALLOWED_CHAIN_IDS
    const provider = new Web3.providers.HttpProvider(rpc)
    let sid = new SID({ provider, sidAddress: SIDfunctions.getSidAddress(chainId) })
    const name = await sid.getName(address)
    return name.name
  },
)

export const chooseWallet = createAsyncThunk(
  'connector/chooseWallet',
  async ({ payload }, thunkAPI) => {
    const { wallet, networkActive, isOpened } = payload

    if (wallet == 'metamaskWallet' && window.ethereum) {
      networkActive(connectors.injected)
      return {
        type: 'injected',
      }
    } else if (wallet == 'okxWallet' && window.okxwallet) {
      networkActive(connectors.injected)
      return {
        type: 'injected',
      }
    }else {
      if (isOpened) {
        QRCodeModal.open(connectors[wallet].uri, () => { })
      } else {
        try {
          let res = await connectors[wallet].connect()
          if (res) {
            window.localStorage.setItem('provider', wallet)
            localStorage.setItem('accounts', res.accounts[0])
            localStorage.setItem('chainId', res.chainId)
            return {
              type: wallet,
              accounts: res.accounts[0],
              chainId: res.chainId,
            }
          }
        } catch (error) {
          // window.localStorage.clear()
          web3Services.clearLocalStorage()
          window.location.reload(false)
          return thunkAPI.rejectWithValue(error)
        }
      }
    }
  },
)

const initialState = {
  //modalbox
  openSettingModal: false,
  openRecoverKeySetting: false,
  openMoreModal: false,
  openNetworkModal: false,
  refresh: false,
  backdrop: false,
  //status
  isOpened: false,
  selectedTrading: "testnet",
  //event disconnect and activate connection
  token: localStorage.getItem('token'),
  remember: localStorage.getItem('remember'),
  provider: localStorage.getItem('provider'),
  sign: false,
  chainId: localStorage.getItem('chainId'),
  accounts: localStorage.getItem('accounts'),
  favourite: localStorage.getItem('favourite'),
  step: 0,
  walletConnect: false,
  // linking wallet remember me toggle
  toggleStatus: false,
  resolveName: localStorage.getItem('resolveName'),
  referrals: 0,
}

const connectorSlice = createSlice({
  name: 'connector',
  initialState,
  reducers: {
    setSelectedTradingVersion: (state,action) => {
      if(action.payload){
        state.backdrop = true
      }
      state.selectedTrading = action.payload
      state.openNetworkModal = false;
    },
    setOpenNetwokModal: (state, action) => {
      if(action.payload){
        state.backdrop = true
      }
        state.openNetworkModal = action.payload;
        state.openSettingModal = false;
        state.openRecoverKeySetting = false;
    },
    setToggleStatus: (state, action) => {
      return {
        ...state,
        toggleStatus: action.payload,
      }
    },
    setConnectStorage: (state, action) => {
      localStorage.setItem('provider', action.payload.provider)
      localStorage.setItem('accounts', action.payload.accounts)
      localStorage.setItem('chainId', action.payload.chainId)
      return {
        ...state,
        provider: action.payload.provider,
        accounts: action.payload.accounts,
        chainId: action.payload.chainId,
      }
    },
    setLocalStorage: (state, action) => {
      localStorage.setItem(action.payload.key, action.payload.values)
      void (state[action.payload.key] = action.payload.values)
    },
    setWalletConnect: (state, action) => {
        state.walletConnect = action.payload;
        state.backdrop = false
        state.openNetworkModal = false;
        state.openSettingModal = false;
        state.openRecoverKeySetting = false;
        state.openMoreModal = false;
    },
    setRecoverKeySetting: (state, action) => {
      if(action.payload){
        state.backdrop = true
      }
        state.openNetworkModal = false;
        state.openSettingModal = false;
        state.openRecoverKeySetting = action.payload;
        state.openMoreModal = false;
    },
    setSettingModal: (state, action) => {
      if(action.payload){
        state.backdrop = true
      }
      state.openNetworkModal = false;
      state.openSettingModal = false;
      state.openRecoverKeySetting = false;
      state.openSettingModal = action.payload;
    },
    setMoreModal: (state, action) => {
      if(action.payload){
        state.backdrop = true
      }
      state.openNetworkModal = false;
      state.openSettingModal = false;
      state.openRecoverKeySetting = false;
      state.openMoreModal = action.payload;
    },
    setRefresh: (state, action) => {
      return {
        ...state,
        refresh: !state.refresh,
      }
    },
    setStep: (state, action) => {
      return {
        ...state,
        step: action.payload,
      }
    },
    handleIsOpened: (state, action) => {
      return {
        ...state,
        step: action.payload,
      }
    },
    handleBackdrop:(state,action)=>{
      state.backdrop = action.payload
      state.openNetworkModal = false;
      state.openSettingModal = false;
      state.openRecoverKeySetting = false;
      state.openMoreModal = false;
    },
    handleDisconnect: (state, action) => {
      // localStorage.clear()
      web3Services.clearLocalStorage()
      return {
        ...state,
        step: 0,
        provider: null,
        accounts: null,
        chainId: null,
        token: null,
        sign: false,
        backdrop: false,
        remember: false,
        walletConnect: false,
        openSettingModal: false,
        openRecoverKeySetting: false,
        refresh: !state.refresh,
      }
    },
  },
  extraReducers: {
    [getReferrals.pending]: (state, action) => { },
    [getReferrals.fulfilled]: (state, action) => {
      state.referrals = action.payload
    },
    [reverseResolveName.pending]: (state, action) => { },
    [reverseResolveName.fulfilled]: (state, action) => {
      localStorage.setItem('resolveName', action.payload)
      state.resolveName = action.payload
    },
    [registerAccount.pending]: (state, action) => {
      return {
        ...state,
      }
    },
    [registerAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        step: 1,
      }
    },
    [registerAccount.rejected]: (state, action) => {
      return {
        ...state,
      }
    },
    [chooseWallet.pending]: (state, action) => {
      return {
        ...state,
      }
    },
    [chooseWallet.fulfilled]: (state, action) => {
      return {
        ...state,
        provider: action.payload.type,
        accounts: action.payload.accounts,
        chainId: action.payload.chainId,
        refresh: !state.refresh,
      }
    },
    [chooseWallet.rejected]: (state, action) => {
      return {
        ...state,
      }
    },
  },
})

export const {
  handleBackdrop,
  setSelectedTradingVersion,
  setOpenNetwokModal,
  setToggleStatus,
  setConnectStorage,
  setLocalStorage,
  accountsChanged,
  activateConnection,
  setSettingModal,
  setMoreModal,
  setRefresh,
  setStep,
  handleLoginStatus,
  handleDisconnect,
  handleIsOpened,
  setWalletConnect,
  setRecoverKeySetting,
} = connectorSlice.actions
const { reducer } = connectorSlice
export default reducer
