import { useState } from 'react'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'

//assets
import { FirstPrize, SecondPrize, ThirdPrize } from '../../../assets/images'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import web3Services from '../../../services/web3.services'

export default function LeaderBoard({ tabBar, setTabBar, data }) {
  const { t } = useTranslation()
  const { ranking } = useSelector((state) => state.profile)
  const [order, setOrder] = useState(true) // true is asc , false is desc
  const dataAsc = data ? data.slice(0, 10) : ''
  const dataDesc = data ? data.slice(-10) : ''

  let emptyValue = [
    {
      prize: 1,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 2,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 3,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 4,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 5,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 6,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 7,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 8,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 9,
      name: '- -',
      revenue: '$ - -',
    },
    {
      prize: 10,
      name: '- -',
      revenue: '$ - -',
    },
  ]

  const newListing = data.map((_, index) => {
    delete emptyValue[index]
  })

  var i = 1
  var iDesc = 10 - data.length + 1

  return (
    <MDBCol className="leaderboard-container">
      <MDBRow className="header-container">
        <label>{t(`Leaderboard`)}</label>
        <span>
          {tabBar == 'Referral Program'
            ? t('Leaderboard of Deri Trade will refresh every 28th of the month')
            : t('Leaderboard of Deri Trade will refresh every month')}
        </span>
      </MDBRow>
      <MDBRow className="tab-leaderboard">
        <MDBCol>
          <div
            className={tabBar == 'Referral Program' ? 'button-tab active' : 'button-tab'}
            onClick={() => setTabBar('Referral Program')}>
            <label>{t(`Referral Program`)}</label>
          </div>
        </MDBCol>
        <MDBCol>
          <div
            className={tabBar == 'Master Traders' ? 'button-tab active' : 'button-tab'}
            onClick={() => setTabBar('Master Traders')}>
            <label>{t(`Master Traders`)}</label>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBContainer className="leaderboard-box">
        <MDBRow className="filter-row padding justify-content-between">
          <label
            className="active"
            onClick={() => {
              setOrder(!order)
            }}>
            {order ? (
              <Icon
                icon="akar-icons:arrow-right"
                color="#EDEBEB"
                width="14"
                height="14"
                rotate={3}
              />
            ) : (
              <Icon
                icon="akar-icons:arrow-right"
                color="#EDEBEB"
                width="14"
                height="14"
                rotate={1}
              />
            )}
            {t(`Rank`)}
          </label>
          {tabBar == 'Master Traders' && web3Services.isSignRequire() && (
            <label>
              {' '}
              Current Rank :
              <label className="active" style={{ paddingLeft: '0.5em' }}>
                {ranking}
              </label>
            </label>
          )}
        </MDBRow>
        <MDBRow className="padding">
          <MDBCol className="text-start">
            <label>
              {tabBar == 'Referral Program' ? t(`Referral Rankings`) : t('Master Traders')}
            </label>
          </MDBCol>
          <MDBCol className="text-end">
            <label>{tabBar == 'Referral Program' ? t('Total Referred') : t('PnL (%)')}</label>
          </MDBCol>
        </MDBRow>
        {order ? (
          <>
            {dataAsc.map((item, index) => (
              <div key={index}>
                <div className="line"></div>
                <MDBRow className="padding leaderboard-row">
                  <MDBCol className="col-6 name" style={{ whiteSpace: 'nowrap' }}>
                    {order ? (
                      index == 0 ? (
                        <img src={FirstPrize} width="25" />
                      ) : index == 1 ? (
                        <img src={SecondPrize} width="25" />
                      ) : index == 2 ? (
                        <img src={ThirdPrize} width="25" />
                      ) : (
                        <span className="number">{(index += 1)}.</span>
                      )
                    ) : (
                      <span className="number">{(index += 1)}.</span>
                    )}
                    <label style={{ fontSize: '14px' }}>{item.name ? item.name : item._id}</label>
                  </MDBCol>
                  <MDBCol className="col-6 text-end">
                    <label
                      style={{
                        fontSize: '12px',
                        color: item.count
                          ? '#9e9e9e'
                          : item.totalPnlPercentage > 0
                          ? '#59D08E'
                          : '#9e9e9e',
                      }}>
                      {/* <span>+</span> */}
                      {item.count
                        ? item.count
                        : item.totalPnlPercentage
                        ? item.totalPnlPercentage.toFixed(2)
                        : '- -'}
                    </label>
                  </MDBCol>
                </MDBRow>
              </div>
            ))}
            {emptyValue.map((item, index) => (
              <div key={index}>
                <div className="line"></div>
                <MDBRow className="padding leaderboard-row">
                  <MDBCol className="col-6 name" style={{ whiteSpace: 'nowrap' }}>
                    {order ? (
                      emptyValue.length > 7 && index == 0 ? (
                        <img src={FirstPrize} width="25" />
                      ) : emptyValue.length > 7 && index == 1 ? (
                        <img src={SecondPrize} width="25" />
                      ) : emptyValue.length > 7 && index == 2 ? (
                        <img src={ThirdPrize} width="25" />
                      ) : (
                        <span className="number">{(index += 1)}.</span>
                      )
                    ) : (
                      <span className="number">{(index += 1)}.</span>
                    )}
                    <label>{item.name ? item.name : item._id}</label>
                  </MDBCol>
                  <MDBCol className="col-6 text-end revenue">
                    <label
                      style={{
                        color: item.count
                          ? '#9e9e9e'
                          : item.totalPnlPercentage > 0
                          ? '#59D08E'
                          : '#9e9e9e',
                      }}>
                      {/* <span className="green">+</span> */}
                      {item.count
                        ? item.count
                        : item.totalPnlPercentage
                        ? item.totalPnlPercentage.toFixed(2)
                        : '- -'}
                    </label>
                  </MDBCol>
                </MDBRow>
              </div>
            ))}
          </>
        ) : (
          <>
            {emptyValue.map((item, index) => (
              <div key={index}>
                <div className="line"></div>
                <MDBRow className="padding leaderboard-row">
                  <MDBCol className="col-6 name" style={{ whiteSpace: 'nowrap' }}>
                    <span className="number">{i++}.</span>
                    <label>{item.name ? item.name : item._id}</label>
                  </MDBCol>
                  <MDBCol className="col-6 text-end revenue">
                    <label
                      style={{
                        color: item.count
                          ? '#9e9e9e'
                          : item.totalPnlPercentage > 0
                          ? '#59D08E'
                          : '#9e9e9e',
                      }}>
                      {item.count
                        ? item.count
                        : item.totalPnlPercentage
                        ? item.totalPnlPercentage.toFixed(2)
                        : '- -'}
                    </label>
                  </MDBCol>
                </MDBRow>
              </div>
            ))}
            {dataDesc.map((item, index) => (
              <div key={index}>
                <div className="line"></div>
                <MDBRow className="padding leaderboard-row">
                  <MDBCol className="col-6 name" style={{ whiteSpace: 'nowrap' }}>
                    <span className="number">{iDesc++}.</span>
                    <label style={{ fontSize: '14px' }}>{item.name ? item.name : item._id}</label>
                  </MDBCol>
                  <MDBCol className="col-6 text-end">
                    <label
                      style={{
                        fontSize: '12px',
                        color: item.count
                          ? '#9e9e9e'
                          : item.totalPnlPercentage > 0
                          ? '#59D08E'
                          : '#9e9e9e',
                      }}>
                      {item.count
                        ? item.count
                        : item.totalPnlPercentage
                        ? item.totalPnlPercentage.toFixed(2)
                        : '- -'}
                    </label>
                  </MDBCol>
                </MDBRow>
              </div>
            ))}
          </>
        )}
      </MDBContainer>
    </MDBCol>
  )
}
