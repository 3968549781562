import { useRef, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

//lib
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTooltip } from 'mdb-react-ui-kit'

//asset
import LoaderSvg from '../../../assets/images/svg/dual-ball-loader.svg'

//service
import web3Services from '../../../services/web3.services'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setRecoverKeySetting, setWalletConnect } from '../../../redux/reducer/connectorReducer'
import { setButtonIsChecking, setModalRebateHistory } from '../../../redux/reducer/profileReducer'

//hook
import useReferralCode from '../../../hook/useReferralCode'

//component
import ButtonDisconnect from '../../../components/element/btnDisconnect'
import { useTranslation } from 'react-i18next'

export default function TradeReferrals({ responsive }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const { setReferralCode, codeOwners, renderText } = useReferralCode()
  const { isEditing, button_is_checking } = useSelector((state) => state.profile)
  const { total_trading_volume, total_rebate, referral_code, totalRebate, loader } = useSelector(
    (state) => state.profile.referral_detail.trade_referrals,
  )
  const { accounts, openRecoverKeySetting } = useSelector((state) => state.connector)
  const [searchParams, setSearchParams] = useSearchParams()
  const [valueReferralCode, setValueReferralCode] = useState(referral_code)
  useEffect(() => {
    if (searchParams.get('ref')) {
      setValueReferralCode(searchParams.get('ref'))
      dispatch(setButtonIsChecking('code_taken'))
    }
  }, [])

  if (loader) {
    return (
      <div className="loader-container">
        <img src={LoaderSvg} />
      </div>
    )
  }
  return (
    <div>
      <MDBRow className="referral-row">
        <MDBCol className="title ">
          <label>{t('Trading Fee Discount')}</label>
        </MDBCol>
        <MDBCol className="value col-5">
          <label>
            {totalRebate === Infinity
              ? '-'
              : totalRebate === undefined
              ? '-'
              : totalRebate && web3Services.isSignRequire() && referral_code
              ? totalRebate
              : '-'}{' '}
            %
          </label>
        </MDBCol>
      </MDBRow>
      <div className="deco-referral">
        <div className="line"></div>
      </div>
      {web3Services.isSignRequire() && referral_code && (
        <div>
          {responsive == 'mobile' ? (
            <>
              <MDBRow className="referral-row">
                <MDBCol className="title ">
                  <MDBTooltip
                    tag="span"
                    wrapperProps={{ href: '#' }}
                    title={t('Volume traded by this account with an active referral code')}>
                    <label>{t('Total Trading Volume')}</label>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol className="value col-5">
                  <label>$ {total_trading_volume}</label>
                </MDBCol>
              </MDBRow>
              <div className="deco-referral">
                <div className="line"></div>
              </div>
              <MDBRow className="referral-row">
                <MDBCol className="title ">
                  <MDBTooltip
                    tag="span"
                    wrapperProps={{ href: '#' }}
                    title={t('Rebates earned by this account as a trader')}>
                    <label>{t('Total Rebates')}</label>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol className="value col-5">
                  <label>$ {total_rebate}</label>
                </MDBCol>
              </MDBRow>
              <div className="deco-referral">
                <div className="line"></div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      )}
      <MDBRow className="m-0 justify-content-center">
        {web3Services.isSignRequire() && referral_code ? (
          <>
            {responsive == 'desktop' && (
              <MDBCol className="col-12 col-md-7 referral-row">
                <MDBRow>
                  <MDBCol className="col-md-6 referral-row">
                    <MDBRow>
                      <MDBTooltip
                        tag="span"
                        wrapperProps={{ href: '#' }}
                        title="Volume traded by this account with an active referral code">
                        <label className="title-bold">{t('Total Trading Volume')}</label>
                      </MDBTooltip>
                    </MDBRow>
                    <span className="sub-title">$ {total_trading_volume}</span>
                  </MDBCol>
                  <MDBCol className="col-md-6 referral-row">
                    <MDBRow>
                      <MDBTooltip
                        tag="span"
                        wrapperProps={{ href: '#' }}
                        title="Rebates earned by this account as a trader">
                        <label className="title-bold">{t('Total Rebates')}</label>
                      </MDBTooltip>
                    </MDBRow>
                    <span className="sub-title">$ {total_rebate}</span>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )}
          </>
        ) : (
          <MDBCol className="col-12 col-md-7 referral-row">
            <MDBRow>
              <label className="title-bold">{t('Enter Referral Code')}</label>
            </MDBRow>
            <span className="sub-title">
              {t('Please input a referral code to benefit from fee discounts.')}
            </span>
          </MDBCol>
        )}
        {web3Services.isSignRequire() ? (
          <MDBCol
            className={
              responsive == 'mobile'
                ? ' col-12 col-md-5 p-0'
                : 'mt-auto mb-auto col-12 col-md-5 p-0'
            }>
            <MDBContainer className="input-referral-container p-0">
              <MDBRow className="input-referral d-flex align-items-center justify-content-center">
                {referral_code && !isEditing ? (
                  <input
                    placeholder="Enter referral code"
                    value={referral_code}
                    readOnly
                    disabled
                    ref={inputRef}
                    onChange={(e) => {
                      if (e.target.value == '') {
                        dispatch(setButtonIsChecking(false))
                      } else {
                        setTimeout(() => {
                          codeOwners(e.target.value)
                        }, 1000)
                      }
                    }}
                  />
                ) : (
                  <input
                    placeholder={t('Enter referral code')}
                    value={valueReferralCode}
                    ref={inputRef}
                    onChange={(e) => {
                      if (e.target.value == '') {
                        setValueReferralCode(e.target.value)
                        dispatch(setButtonIsChecking(false))
                      } else {
                        setValueReferralCode(e.target.value)
                        setTimeout(() => {
                          codeOwners(e.target.value)
                        }, 1000)
                      }
                    }}
                  />
                )}
              </MDBRow>
            </MDBContainer>
            {referral_code && !isEditing ? (
              <>
                {responsive == 'mobile' ? (
                  <MDBRow className="referrals-btn">
                    <MDBBtn
                      className="history-btn"
                      onClick={() => {
                        dispatch(setModalRebateHistory({ status: true }))
                      }}>
                      {t('View History')}
                    </MDBBtn>
                  </MDBRow>
                ) : (
                  ''
                )}
              </>
            ) : (
              <MDBRow className="referrals-btn">
                <MDBBtn
                  className={'submit-btn'}
                  disabled={button_is_checking == 'code_taken' ? false : true}
                  onClick={() => {
                    if (button_is_checking == 'code_taken') {
                      setReferralCode(valueReferralCode)
                    }
                  }}>
                  <MDBTooltip
                    tag="span"
                    wrapperProps={{ href: '#' }}
                    title={t(
                      'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday.',
                    )}>
                    {renderText(button_is_checking, valueReferralCode, 'trader', t)}
                  </MDBTooltip>
                </MDBBtn>
              </MDBRow>
            )}
          </MDBCol>
        ) : (
          <MDBCol className="col-12 col-md-5 p-0">
            {accounts ? (
              <MDBRow className="referrals-btn">
                <MDBBtn
                  type="button"
                  className="connect-btn"
                  onClick={() => dispatch(setRecoverKeySetting(!openRecoverKeySetting))}>
                  {t('Onboarding')}
                </MDBBtn>
                {openRecoverKeySetting && (
                  <MDBContainer className="setting-container recover-key">
                    <label>
                      {t('Welcome back!')}
                      <br /> <span>{t('Sign in to verify ownership')}</span>
                    </label>
                    <button
                      type="button"
                      className="recover-btn"
                      onClick={() => {
                        dispatch(setWalletConnect(true))
                      }}>
                      {t('Signature Here')}
                    </button>
                    <ButtonDisconnect />
                  </MDBContainer>
                )}
              </MDBRow>
            ) : (
              <MDBRow className="referrals-btn">
                <MDBBtn className="connect-btn" onClick={() => dispatch(setWalletConnect(true))}>
                  {t('Connect Wallet')}
                </MDBBtn>
              </MDBRow>
            )}
          </MDBCol>
        )}
      </MDBRow>
    </div>
  )
}
