import { useState } from 'react'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import { Icon } from '@iconify/react'
import _ from 'lodash'

//assets
import { BinanceIcon, MoreIcon } from '../../../assets/images'

//service
import web3Services from './../../../services/web3.services'
import { sweetAlertHelper } from '../../../components/helper/sweetalert.helper'
import { connectors } from '../../Home/component/connectors'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { handleDisconnect, setRecoverKeySetting } from '../../../redux/reducer/connectorReducer'
import { handleProfileDisconnect } from '../../../redux/reducer/profileReducer'

//hook
import useUtils from '../../../hook/useUtils'
import { useTranslation } from 'react-i18next'
// import { useTranslation } from 'react-i18next'

export default function Setting() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { renderTradingVersion, handleTradingVersion } = useUtils()
  const [changeTradingVersion, setTradingVersion] = useState(true)
  const [changeLanguage, setChangeLanguage] = useState(true)
  const { provider, selectedTrading } = useSelector((state) => state.connector)
  const { deactivate } = useWeb3React()

  const selected = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'

  const handleChange = (lang) => {
    window.location.reload()
    i18n.changeLanguage(lang)
  }

  const setting = [
    {
      height: '15em',
      icon: 'mdi:world-wide-web',
      textIcon: !changeLanguage
        ? 'material-symbols:arrow-drop-down-rounded'
        : 'material-symbols:arrow-right-rounded',
      label: 'Language',
      dropdownOption: [
        {
          values: 'en',
          label: 'English',
          action: () => {
            handleChange('en')
          },
        },
        {
          values: 'zh',
          label: '简体中文',
          action: () => {
            handleChange('zh')
          },
        },
        {
          values: 'cht',
          label: '繁体中文',
          action: () => {
            handleChange('cht')
          },
        },
        {
          values: 'es',
          label: 'Spanish',
          action: () => {
            handleChange('es')
          },
        },
        {
          values: 'de',
          label: 'German',
          action: () => {
            handleChange('de')
          },
        },
        {
          values: 'fr',
          label: 'French',
          action: () => {
            handleChange('fr')
          },
        },
        {
          values: 'hi',
          label: 'Hindi',
          action: () => {
            handleChange('hi')
          },
        },
        {
          values: 'id',
          label: 'Indonesian',
          action: () => {
            handleChange('id')
          },
        },
        {
          values: 'jp',
          label: 'Japanese',
          action: () => {
            handleChange('jp')
          },
        },
        {
          values: 'kr',
          label: 'Korean',
          action: () => {
            handleChange('kr')
          },
        },
        {
          values: 'ph',
          label: 'Filipino',
          action: () => {
            handleChange('ph')
          },
        },
        {
          values: 'pt',
          label: 'Portuguese (Portugal)',
          action: () => {
            handleChange('pt')
          },
        },
        {
          values: 'ru',
          label: 'Russian',
          action: () => {
            handleChange('ru')
          },
        },
        {
          values: 'th',
          label: 'Thai',
          action: () => {
            handleChange('th')
          },
        },
        {
          values: 'tr',
          label: 'Turkish',
          action: () => {
            handleChange('tr')
          },
        },
        {
          values: 'vn',
          label: 'Vietnamese',
          action: () => {
            handleChange('vn')
          },
        },
      ],
      state: changeLanguage,
      action: () => {
        setChangeLanguage(!changeLanguage)
      },
    },
    {
      height: '3em',
      image: BinanceIcon,
      textIcon: !changeTradingVersion
        ? 'material-symbols:arrow-drop-down-rounded'
        : 'material-symbols:arrow-right-rounded',
      label: `${process.env.REACT_APP_CHAIN_LABEL} ${t(process.env.REACT_APP_NETWORK)} : ${t(
        renderTradingVersion(selectedTrading),
      )}`,
      dropdownOption: [
        {
          label: renderTradingVersion(handleTradingVersion(selectedTrading)),
          action: () => {
            toast.info(
              <div>
                <label className="_label">
                  {t('Live Trading')} {t('Coming Soon')}
                </label>
              </div>,
              {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                // progress: undefined,
                theme: 'light',
              },
            )
          },
        },
      ],
      state: changeTradingVersion,
      action: () => {
        setTradingVersion(!changeTradingVersion)
      },
    },
    {
      icon: 'bi:journal-code',
      textIcon: 'lucide:screen-share',
      label: 'Documentations',
    },
    {
      icon: 'ri:discord-line',
      textIcon: 'lucide:screen-share',
      label: 'Discord',
      action: () => {
        window.open('https://discord.gg/rsnGNqAbZY', '_blank', 'noopener,noreferrer')
      },
    },
    {
      icon: 'jam:twitter-circle',
      textIcon: 'lucide:screen-share',
      label: 'Twitter',
      action: () => {
        window.open('https://twitter.com/DeriTrade', '_blank', 'noopener,noreferrer')
      },
    },
    {
      icon: 'ri:telegram-line',
      textIcon: 'lucide:screen-share',
      label: 'Telegram',
      action: () => {
        window.open('https://t.me/DeriTrade', '_blank', 'noopener,noreferrer')
      },
    },
    {
      icon: 'material-symbols:sticky-note-2-outline',
      textIcon: 'lucide:screen-share',
      label: 'Contact Us',
      action: () => {
        window.open('mailto:contact@deri.trade', '_blank', 'noopener,noreferrer')
      },
    },
    {
      image: MoreIcon,
      label: 'More',
      action: () => {
        sweetAlertHelper({
          icon: 'warning',
          title: t('Coming soon'),
        })
      },
    },
  ]

  return (
    <MDBCol className="leaderboard-container">
      <MDBRow className="header-container" style={{ marginBottom: '1em' }}>
        <label>{t(`Settings`)}</label>
      </MDBRow>
      <MDBContainer className="leaderboard-box">
        <>
          {setting.map((item, index) => (
            <div key={index}>
              <div className="line"></div>
              <MDBRow className="padding leaderboard-row" onClick={item.action}>
                <MDBCol className="name col-2">
                  {item.icon && <Icon icon={item.icon} color="#9E9E9E" width="20" />}
                  {item.image && <img src={item.image} width="20" />}
                </MDBCol>
                <MDBCol className="text-end">
                  <label style={{ fontSize: '12px' }}>{t(`${item.label}`)}</label>
                  <Icon
                    icon={item.textIcon}
                    style={{ marginLeft: '0.3em' }}
                    color="#9E9E9E"
                    width={item.textIcon == 'lucide:screen-share' ? 12 : 22}
                  />
                </MDBCol>
              </MDBRow>
              <div
                style={{ height: item.dropdownOption && item.state ? '0em' : item.height }}
                className={
                  item.dropdownOption && item.state ? 'trading-setting show' : 'trading-setting'
                }>
                {item.dropdownOption &&
                  item.dropdownOption.map((dropdown, index) => (
                    <MDBRow key={index}>
                      <label
                        style={{ color: selected == dropdown.value ? '#ffdc00' : '#9E9E9E' }}
                        className="_label --dropdown-label"
                        onClick={dropdown.action}>
                        {t(`${dropdown.label}`)}
                      </label>
                    </MDBRow>
                  ))}
              </div>
            </div>
          ))}
          {web3Services.isLogin() && (
            <div>
              <div className="line"></div>
              <MDBRow
                className="padding leaderboard-row"
                onClick={() => {
                  dispatch(setRecoverKeySetting(false))
                  dispatch(handleDisconnect())
                  dispatch(handleProfileDisconnect())
                  _.isEqual(provider, 'connectorWallet')
                    ? connectors[provider].killSession()
                    : deactivate()
                }}>
                <MDBCol className="name">
                  <Icon icon={'eva:close-square-outline'} color="#9E9E9E" width="20" />
                </MDBCol>
                <MDBCol className="text-end">
                  <label style={{ fontSize: '12px' }}>{t('Disconnect')}</label>
                </MDBCol>
              </MDBRow>
            </div>
          )}
        </>
      </MDBContainer>
    </MDBCol>
  )
}
