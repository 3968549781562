import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import _ from 'lodash'
import { Link } from 'react-scroll'

import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

//assets
import { Icon } from '@iconify/react'
import { LogoMainDesktop, BinanceIcon } from '../../assets/images'

//redux
import {
  handleBackdrop,
  setMoreModal,
  setOpenNetwokModal,
  setWalletConnect,
} from '../../redux/reducer/connectorReducer'
import { useDispatch, useSelector } from 'react-redux'

//component
import ButtonConnectWallet from '../element/btnConnectWallet'
import ButtonDisconnect from '../element/btnDisconnect'
import useEventHandler from '../../hook/useEventHandler'
import { toast } from 'react-toastify'
import useUtils from '../../hook/useUtils'
import { useTranslation } from 'react-i18next'

export default function HeaderDesktop() {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()
  const { renderTradingVersion, handleTradingVersion } = useUtils()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { reconnectInjected, walletConnectEventHandler, injectedEventHandler } = useEventHandler()
  const {
    provider,
    openRecoverKeySetting,
    openNetworkModal,
    openSettingModal,
    refresh,
    selectedTrading,
    backdrop,
  } = useSelector((state) => state.connector)

  const handleChange = (lang) => {
    window.location.reload()
    i18n.changeLanguage(lang)
  }

  const morelistMenu = [
    {
      icon: 'clarity:language-line',
      screenShare: null,
      label: 'Language',
      menuItem: [
        {
          values: 'en',
          label: 'English',
          action: () => {
            handleChange('en')
          },
        },
        {
          values: 'zh',
          label: '简体中文',
          action: () => {
            handleChange('zh')
          },
        },
        {
          values: 'cht',
          label: '繁体中文',
          action: () => {
            handleChange('cht')
          },
        },
        {
          values: 'es',
          label: 'Spanish',
          action: () => {
            handleChange('es')
          },
        },
        {
          values: 'de',
          label: 'German',
          action: () => {
            handleChange('de')
          },
        },
        {
          values: 'fr',
          label: 'French',
          action: () => {
            handleChange('fr')
          },
        },
        {
          values: 'hi',
          label: 'Hindi',
          action: () => {
            handleChange('hi')
          },
        },
        {
          values: 'id',
          label: 'Indonesian',
          action: () => {
            handleChange('id')
          },
        },
        {
          values: 'jp',
          label: 'Japanese',
          action: () => {
            handleChange('jp')
          },
        },
        {
          values: 'kr',
          label: 'Korean',
          action: () => {
            handleChange('kr')
          },
        },
        {
          values: 'ph',
          label: 'Filipino',
          action: () => {
            handleChange('ph')
          },
        },
        {
          values: 'pt',
          label: 'Portuguese (Portugal)',
          action: () => {
            handleChange('pt')
          },
        },
        {
          values: 'ru',
          label: 'Russian',
          action: () => {
            handleChange('ru')
          },
        },
        {
          values: 'th',
          label: 'Thai',
          action: () => {
            handleChange('th')
          },
        },
        {
          values: 'tr',
          label: 'Turkish',
          action: () => {
            handleChange('tr')
          },
        },
        {
          values: 'vn',
          label: 'Vietnamese',
          action: () => {
            handleChange('vn')
          },
        },
      ],
    },
    {
      icon: 'material-symbols:sticky-note-2-outline',
      screenShare: 'lucide:screen-share',
      label: 'Legal',
    },
    {
      icon: 'material-symbols:sticky-note-2-outline',
      screenShare: 'lucide:screen-share',
      label: 'Terms of Use',
    },
    {
      icon: 'mingcute:safety-certificate-line',
      screenShare: 'lucide:screen-share',
      label: 'Privacy Policy',
    },
    {
      icon: 'iconoir:antenna-signal',
      screenShare: 'lucide:screen-share',
      label: 'Status',
    },
    {
      icon: 'bi:journal-code',
      screenShare: 'lucide:screen-share',
      label: 'Documentations',
    },
    {
      icon: 'ri:discord-line',
      screenShare: 'lucide:screen-share',
      label: 'Discord',
      action: () => {
        window.open('https://discord.gg/rsnGNqAbZY', '_blank', 'noopener,noreferrer')
      },
    },
    {
      icon: 'jam:twitter-circle',
      screenShare: 'lucide:screen-share',
      label: 'Twitter',
      action: () => {
        window.open('https://twitter.com/DeriTrade', '_blank', 'noopener,noreferrer')
      },
    },
    {
      icon: 'ri:telegram-line',
      screenShare: 'lucide:screen-share',
      label: 'Telegram',
      action: () => {
        window.open('https://t.me/DeriTrade', '_blank', 'noopener,noreferrer')
      },
    },
    {
      icon: 'material-symbols:sticky-note-2-outline',
      screenShare: 'lucide:screen-share',
      label: 'Contact Us',
      action: () => {
        window.open('mailto:contact@deri.trade', '_blank', 'noopener,noreferrer')
      },
    },
    {
      icon: 'material-symbols:help-outline-rounded',
      screenShare: null,
      label: 'Help',
    },
  ]

  useEffect(() => {
    injectedEventHandler()
    dispatch(setMoreModal(false))
    dispatch(setOpenNetwokModal(false))
    dispatch(handleBackdrop())
  }, [])

  useEffect(() => {
    if (provider) {
      if (provider == 'injected') {
        reconnectInjected()
        walletConnectEventHandler()
      } else {
        walletConnectEventHandler()
      }
    }
  }, [refresh])

  // const selected = localStorage.getItem('i18nextLng')
  // const handleChange = (lang) => {
  //   i18n.changeLanguage(lang)
  // }

  return (
    <MDBContainer className="common-header-main-desktop " breakpoint="none">
      <MDBRow>
        <MDBCol className="col-2">
          <picture>
            <img
              alt="deri-trade-logo"
              src={LogoMainDesktop}
              width="130"
              height="50"
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer' }}
            />
          </picture>
        </MDBCol>
        <MDBCol className="col-6 header-tab">
          <MDBRow className="align-items-center">
            <MDBCol className="text-center">
              <label
                onClick={() => navigate('/')}
                style={{ color: pathname === '/' ? '#EDEBEB' : '#9E9E9E' }}>
                {t('Home')}
              </label>
            </MDBCol>
            <MDBCol className="text-center">
              <label
                onClick={() => {
                  navigate('/market')
                }}
                style={{ color: pathname === '/market' ? '#EDEBEB' : '#9E9E9E' }}>
                {t('Market')}
              </label>
            </MDBCol>
            <MDBCol className="text-center">
              <label
                onClick={() => navigate('/trade')}
                style={{ color: pathname === '/trade' ? '#EDEBEB' : '#9E9E9E' }}>
                {t('Trade')}
              </label>
            </MDBCol>
            <MDBCol className="text-center">
              <label
                onClick={() => navigate('/profile')}
                style={{
                  color: pathname === '/profile' ? '#EDEBEB' : '#9E9E9E',
                }}>
                {t('Profile')}
              </label>
            </MDBCol>
            <MDBCol className="text-center" style={{ position: 'relative', whiteSpace: 'nowrap' }}>
              <Menu
                menuButton={
                  <MenuButton>
                    <label
                      // onClick={() => dispatch(setMoreModal(!openMoreModal))}
                      style={{ paddingTop: '3px' }}>
                      {t('More')}{' '}
                      <Icon icon="material-symbols:arrow-drop-down-rounded" width="25" />
                    </label>
                  </MenuButton>
                }>
                {morelistMenu.map((item, index) => (
                  <div key={index}>
                    {item.menuItem ? (
                      <SubMenu
                        label={
                          <button
                            className="more-btn"
                            key={index}
                            onClick={() => {
                              if (item.action) {
                                item.action()
                              }
                            }}>
                            <Icon icon={item.icon} color="#9E9E9E" width="20" />{' '}
                            {t(`${item.label}`)}
                          </button>
                        }>
                        {item.menuItem.length > 0 &&
                          item.menuItem.map((menuItem, index) => (
                            <MenuItem
                              key={index}
                              style={{
                                color:
                                  localStorage.getItem('i18nextLng') == menuItem.values
                                    ? '#EBEBEB'
                                    : localStorage.getItem('i18nextLng') == 'en-GB' &&
                                      menuItem.values == 'en'
                                    ? '#EBEBEB'
                                    : '',
                              }}
                              onClick={() => {
                                if (menuItem.action) {
                                  menuItem.action()
                                }
                              }}>
                              {t(`${menuItem.label}`)}
                            </MenuItem>
                          ))}
                      </SubMenu>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          if (item.action) {
                            item.action()
                          }
                        }}>
                        <Icon icon={item.icon} color="#9E9E9E" width="20" /> {t(`${item.label}`)}
                      </MenuItem>
                    )}
                  </div>
                ))}
              </Menu>
              {/* <label
                onClick={() => dispatch(setMoreModal(!openMoreModal))}
                style={{ paddingTop: '3px' }}>
                {t('More')} <Icon icon="material-symbols:arrow-drop-down-rounded" width="25" />
              </label> */}
              {/* {openMoreModal && (
                <MDBContainer className="setting-container more">
                  {morelistMenu.map((item, index) => (
                    <button
                      className="more-btn"
                      key={index}
                      onClick={() => {
                        if (item.action) {
                          item.action()
                        }
                      }}>
                      <Icon icon={item.icon} color="#9E9E9E" width="20" /> {t(`${item.label}`)}
                    </button>
                  ))}
                </MDBContainer>
              )} */}
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol
          className="col-4 d-flex p-0"
          style={{ position: 'relative', alignItems: 'center', justifyContent: 'end' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              onClick={() => dispatch(setOpenNetwokModal(!openNetworkModal))}
              style={{
                display: 'flex',
                marginRight: '1em',
                padding: '0.3em',
                position: 'relative',
              }}>
              <div className="align-items-center d-flex">
                <img
                  alt="bnb-chain-logo"
                  style={{ marginRight: '0.5em' }}
                  src={BinanceIcon}
                  width="30"
                  height="auto"
                />
              </div>
              <label
                style={{
                  right: '9.5em',
                  marginRight: '0.5em',
                  color: '#9E9E9E',
                  fontSize: '14px',
                  justifyContent: 'end',
                  cursor: 'pointer',
                  lineHeight: '1em',
                }}>
                {process.env.REACT_APP_CHAIN_LABEL}{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {t(`${process.env.REACT_APP_NETWORK}`)}
                </span>
                <br />
                <span style={{ fontSize: '0.65rem', color: '#9E9E9E' }}>
                  {t(`${renderTradingVersion(selectedTrading)}`)}
                </span>
              </label>
              <Icon icon="material-symbols:arrow-drop-down-rounded" color="#9E9E9E" width="25" />
              {openNetworkModal && (
                <MDBContainer className="setting-container network">
                  <div
                    onClick={() => {
                      dispatch(handleBackdrop())

                      // dispatch(setSelectedTradingVersion(handleTradingVersion(selectedTrading)))
                      toast.info(
                        <div>
                          <label className="_label">
                            {t('Live Trading')}
                            {t('Coming soon')}
                          </label>
                        </div>,
                        {
                          position: 'top-left',
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          // progress: undefined,
                          theme: 'light',
                        },
                      )
                    }}
                    style={{
                      textAlign: 'end',
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    {/* <img src={BinanceIcon} width="30" /> */}
                    <label
                      style={{
                        right: '9.5em',
                        color: '#9E9E9E',
                        fontSize: '14px',
                        cursor: 'pointer',
                        justifyContent: 'start',
                      }}>
                      {/* {process.env.REACT_APP_CHAIN_LABEL} */}
                      <span style={{ textTransform: 'capitalize' }}>
                        {selectedTrading == 'testnet' ? t('Live Trading') : t('Demo Trading')}
                      </span>
                    </label>
                  </div>
                </MDBContainer>
              )}
            </div>
            <ButtonConnectWallet />
          </div>
          {openRecoverKeySetting && (
            <MDBContainer className="setting-container recover-key">
              <label>
                {t('Welcome back!')}
                <br /> <span> {t('Sign in to verify ownership')}.</span>
              </label>
              <button
                className="recover-btn"
                onClick={() => {
                  dispatch(setWalletConnect(true))
                }}>
                {t('Signature Here')}
              </button>
              <ButtonDisconnect />
            </MDBContainer>
          )}
          {openSettingModal && (
            <MDBContainer className="setting-container">
              <ButtonDisconnect />
            </MDBContainer>
          )}
        </MDBCol>
      </MDBRow>
      {backdrop && (
        <div
          onClick={() => {
            dispatch(handleBackdrop(false))
          }}
          className="backdrop-container"></div>
      )}
    </MDBContainer>
  )
}
