import SimpleCrypto from 'simple-crypto-js'
import _ from 'lodash'


function isSignRequire() {
  // const { deactivate } = useWeb3React
  try {
    const rememberData = localStorage.getItem('remember')
    const remember = rememberData
    const signData = localStorage.getItem('sign')
    const sign = signData
    if (remember === 'false' && sign === 'false') {
      // require to sign
      return false
    } else if (remember == null && sign == 'false') {
      return false
    } else if (remember == 'undefined' && sign == 'false') {
      return false
    } else if (remember == null && sign == null) {
      return false
    } else {
      // not require to sign
      return true
    }
  } catch (e) {
    // this happen when invalid encryption formed due to changing version/encryption method
    // window.localStorage.clear()
    clearLocalStorage()
    // window.location.reload(false)
  }
}

function isLogin() {
  const account = localStorage.getItem('accounts')
  const provider = localStorage.getItem('provider')
  if (account && provider) {
    return true
  }
  return false
}

function encrypt(plainText) {
  var _secretKey = process.env.REACT_APP_SECRET_KEY
  var simpleCrypto = new SimpleCrypto(_secretKey)
  var chiperText = simpleCrypto.encrypt(plainText)
  return chiperText
}

function decrypt(plainText) {
  var _secretKey = process.env.REACT_APP_SECRET_KEY
  var simpleCrypto = new SimpleCrypto(_secretKey)
  var chiperText = simpleCrypto.decrypt(plainText)
  return chiperText
}

function smartContractIssueNotification() {
  return {
    title: `Smart Contracts<br/>Non-Detectable`,
    html: `Only BNB Chain allows.<br/>Kindly switch to BNB Chain and refresh if the problem persists.`,
  }
}

function clearLocalStorage() {
  localStorage.removeItem('provider')
  localStorage.removeItem('accounts')
  localStorage.removeItem('chainId')
  localStorage.removeItem('sign')
  localStorage.removeItem('token')
  localStorage.removeItem('remember')
  localStorage.removeItem('passphrase')
  localStorage.removeItem('resolveName')
}

export default {
  clearLocalStorage,
  isSignRequire,
  encrypt,
  decrypt,
  isLogin,
  smartContractIssueNotification,
}
