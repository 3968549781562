import { useState } from 'react'

// Pyth
import { PythConnection } from '@pythnetwork/client/lib/PythConnection'
import { getPythClusterApiUrl, getPythProgramKeyForCluster } from '@pythnetwork/client/lib/cluster'
import { PythHttpClient } from '@pythnetwork/client'
import { Connection, PublicKey } from '@solana/web3.js'
import { useDispatch, useSelector } from 'react-redux'
import { setTradePrice } from '../redux/reducer/marketReducer'

const SOLANA_CLUSTER_NAME = 'devnet'
const connection = new Connection(getPythClusterApiUrl(SOLANA_CLUSTER_NAME))
const pythPublicKey = getPythProgramKeyForCluster(SOLANA_CLUSTER_NAME)

export default function usePythNetwork() {
  const dispatch = useDispatch()
  const [pythConnection] = useState(new PythConnection(connection, pythPublicKey))
  const { listing } = useSelector((state) => state.market)

  // function subscribePriceFeed() {
  //   pythConnection.onPriceChange((product, price) => {
  //     for (var key in listing) {
  //       if (listing[key].symbol == product.symbol) {
  //         let final_price = price.price || price.aggregate.price
  //         if (listing[key].type === 'Metals') {
  //           final_price = final_price.toFixed(2)
  //         } else if (listing[key].type === 'Forex') {
  //           final_price = final_price.toFixed(5)
  //         } else if (listing[key].type === 'Crypto') {
  //           final_price = final_price.toFixed(2)
  //         } else if (listing[key].type === 'Equity') {
  //           final_price = final_price.toFixed(3)
  //         }
  //         dispatch(
  //           setTradePrice({
  //             [product.base]: {
  //               price: Number(final_price),
  //               normal_price: Number(price.aggregate.price),
  //               time: 0,
  //             },
  //           }),
  //         )
  //       }
  //     }
  //   })
  // }

  async function requestPriceFeed() {
    const pythClient = new PythHttpClient(connection, pythPublicKey)
    const data = await pythClient.getData()
    for (var key in listing) {
      let price = data.productPrice.get(listing[key].symbol).previousPrice
      if (listing[key].type === 'Metals') {
        price = price.toFixed(2)
      } else if (listing[key].type === 'Forex') {
        price = price.toFixed(5)
      } else if (listing[key].type === 'Crypto') {
        price = price.toFixed(2)
      } else if (listing[key].type === 'Equity') {
        price = price.toFixed(3)
      }
      dispatch(
        setTradePrice({
          [key]: {
            price: price,
            normal_price: data.productPrice.get(listing[key].symbol).previousPrice,
            time: 0,
          },
        }),
      )
    }
    // subscribePriceFeed()
  }

  return { pythConnection, requestPriceFeed }
}
