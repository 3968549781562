import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import web3Services from '../../services/web3.services'

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControl = ({
  alertProfolio,
  name,
  segments,
  callback,
  defaultIndex = 0,
  controlRef,
  className,
}) => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const token = web3Services.isSignRequire()
  const componentReady = useRef()
  // const token = localStorage.getItem('token')

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true
  }, [])

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref
    const { offsetWidth, offsetLeft } = activeSegmentRef.current
    const { style } = controlRef.current

    style.setProperty('--highlight-width', `${offsetWidth}px`)
    style.setProperty('--highlight-x-pos', `${offsetLeft}px`)
  }, [activeIndex, callback, controlRef, segments])

  const onInputChange = (value, index) => {
    if (!token && value == 'portfolio') {
      alertProfolio()
    } else {
      setActiveIndex(index)
      callback(value, index)
    }
  }

  return (
    <div className="controls-container" ref={controlRef}>
      <div className={`controls ${className} ${componentReady.current ? 'ready' : 'idle'}`}>
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${i === activeIndex ? 'active' : 'inactive'}`}
            ref={item.ref}>
            <input
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => {
                onInputChange(item.value, i)
              }}
              checked={i === activeIndex}
            />
            <label htmlFor={item.label}>{t(`${item.label}`)}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SegmentedControl
