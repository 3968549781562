import { useDispatch, useSelector } from 'react-redux'
import socketService from '../services/socket.service'
import { setTradePrice } from '../redux/reducer/marketReducer'

export default function useSocket() {
  const dispatch = useDispatch()
  const { accounts } = useSelector((state) => state.connector)
  const { listing } = useSelector((state) => state.market)
  const socket = socketService.subscribeSocket(accounts)

  function getSocket() {
    socket.onopen = () => {}
    socket.addEventListener('open', (event) => {
      // socket.send('Hello from React.js')
    })

    socket.addEventListener('message', (event) => {
      let data = JSON.parse(event.data)
      for (var key in listing) {
        if (listing[key].symbol == data.symbol) {
          let final_price = data.price_feed
          if (listing[key].type === 'Metals') {
            final_price = final_price.toFixed(2)
          } else if (listing[key].type === 'Forex') {
            final_price = final_price.toFixed(5)
          } else if (listing[key].type === 'Crypto') {
            final_price = final_price.toFixed(2)
          } else if (listing[key].type === 'Equity') {
            final_price = final_price.toFixed(3)
          }
          dispatch(
            setTradePrice({
              [key]: {
                price: Number(final_price),
                normal_price: Number(data.price_feed),
                time: data.timestamp,
              },
            }),
          )
        }
      }
    })

    // socket.addEventListener('error', (event) => {
    //   // console.error('WebSocket error:', event)
    // })

    // socket.addEventListener('close', (event) => {
    //   setTimeout(() => {
    //     getSocket()
    //   }, 1000)
    // })
  }

  function disconnectSocket() {
    socket.close()
  }

  return { getSocket, disconnectSocket }
}
