import sha256 from 'crypto-js/sha256'

export function toHex(num) {
  return '0x' + num.toString(16)
}

export function convertSha256(sign, passphrase) {
  const text = process.env.REACT_APP_API_SECRET_KEY + passphrase + sign
  const hash = sha256(text)
  return hash
}

export const renderTradingVersion = (selectedTrading) => {
  if (selectedTrading == 'mainnet') {
    return 'Live Trading'
  }
  return 'Demo Trading'
}

export const handleTradingVersion = (selectedTrading) => {
  if (selectedTrading == 'testnet') {
    return 'mainnet'
  }
  return 'testnet'
}

export default function useUtils() {
  return {
    handleTradingVersion,
    renderTradingVersion,
    toHex,
    convertSha256,
  }
}
