import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useEffect } from 'react'

//component
import ListingMarketCard from './listing.market.card.v2'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getFavourite } from '../../../redux/reducer/marketReducer'
import { useTranslation } from 'react-i18next'

export default function MarketListing({ responsive }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { listing, search, favourite } = useSelector((state) => state.market)

  useEffect(() => {
    dispatch(getFavourite({}))
  }, [])

  return (
    <MDBContainer className="market-listing-container">
      {responsive == 'mobile' ? (
        <div className="mobile-version">
          {Object.entries(listing).map((item, index) => (
            <div key={index}>
              {search == '' ? (
                <ListingMarketCard
                  favourite={favourite}
                  key={index}
                  item={item[1]}
                  responsive={responsive}
                />
              ) : item[1].title.indexOf(search ? search.toUpperCase() : search) > -1 ? (
                <ListingMarketCard
                  favourite={favourite}
                  key={index}
                  item={item[1]}
                  responsive={responsive}
                />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="desktop-version">
          <div className="market-listing-box">
            <MDBRow className="listing-header-container m-0">
              <MDBCol className="header col-3 text-start">
                <label>{t('Markets')}</label>
              </MDBCol>
              <MDBCol className="header col text-start">
                <label>{t('Index Price')}</label>
              </MDBCol>
              <MDBCol className="header col text-start">
                <label>{t('24h Change')}</label>
              </MDBCol>
              <MDBCol className="header col text-start">
                <label>{t('24h Volume')}</label>
              </MDBCol>
              <MDBCol className="header col text-start">
                <label>{t('24h Trades')}</label>
              </MDBCol>
            </MDBRow>
            <div className="market-listing">
              {Object.entries(listing).map((item, index) => (
                <ListingMarketCard
                  favourite={favourite}
                  key={index}
                  item={item[1]}
                  responsive={responsive}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </MDBContainer>
  )
}
