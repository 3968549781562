//lib
import { Icon } from '@iconify/react'
import {
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
} from 'mdb-react-ui-kit'

//component
import ModalBox from '../../../components/modal/full.modal.box'

//hook
import useTradeOrder from '../../../hook/useTradeOrder'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setCancelOrder, setOrderDetailModal } from '../../../redux/reducer/tradeReducer'
import { useTranslation } from 'react-i18next'

export default function OrderDetailModal({ show, setShow }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const decimal = 100
  const { calculateOpenFee } = useTradeOrder()
  const { order_detail_modal_data } = useSelector((state) => state.trade)

  return (
    <ModalBox
      className={'trade-order-container'}
      show={show}
      setShow={setShow}
      screenSize="s"
      reactModal={true}
      content={
        <div style={{ width: '21em' }}>
          <MDBCardHeader>
            <MDBRow className="d-flex align-items-center">
              <MDBCol className="col-8">
                <label className="_label --symbol">{order_detail_modal_data.symbol}</label>
                <label className={`_label --order-type --${order_detail_modal_data.orderMethod}`}>
                  {t(`${`${order_detail_modal_data.orderMethod}`}`)}
                </label>
              </MDBCol>
              <MDBCol className="back-btn col-4">
                <div
                  style={{ cursor: 'pointer' }}
                  className="round-btn"
                  onClick={() => dispatch(setOrderDetailModal({ status: false, data: {} }))}>
                  <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBModalBody style={{ height: '20em', overflow: 'hidden' }}>
            <MDBContainer className="order-detail-container">
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Order Type`)}</label>
                </MDBCol>
                <MDBCol>
                  <label
                    className="_label --title-value-details"
                    style={{ textTransform: 'capitalize' }}>
                    {t(`${order_detail_modal_data.type}`)}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Leverage`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {order_detail_modal_data.leverage}x
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Collateral`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(order_detail_modal_data.collateral * decimal) / decimal} USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Ask Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.ceil(order_detail_modal_data.price * decimal) / decimal}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Fulfill Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.ceil(order_detail_modal_data.price * decimal) / decimal}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Opening Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {calculateOpenFee(
                      order_detail_modal_data.collateral,
                      order_detail_modal_data.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Status`)}</label>
                </MDBCol>
                <MDBCol>
                  <label
                    className={`_label --title-value-details --${order_detail_modal_data.status}`}
                    style={{ textTransform: 'capitalize' }}>
                    {t(`${order_detail_modal_data.status}`)}
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          {order_detail_modal_data.modal_type !== 'history' && (
            <MDBModalFooter>
              <MDBRow className="m-0 w-100">
                <button
                  className="_button --confirm"
                  onClick={() =>
                    dispatch(
                      setCancelOrder({
                        status: true,
                        id: order_detail_modal_data._id,
                        icon: true,
                      }),
                    )
                  }>
                  {t(`Cancel Order`)}
                </button>
              </MDBRow>
            </MDBModalFooter>
          )}
        </div>
      }
    />
  )
}
