import { MDBCol, MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import Header from '../../../components/header/header-three'
import ModalBox from '../../../components/modal/full.modal.box'

import { Player } from 'video-react'
import 'video-react/dist/video-react.css'

import { useDispatch, useSelector } from 'react-redux'
import { setModalAnnouncement } from '../../../redux/reducer/homeReducer'
import { Trans, useTranslation } from 'react-i18next'

export default function ModalBoxAnnouncement({ data }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { modalAnnouncement } = useSelector((state) => state.home)
  var date1 = new Date(data.status)
  var date2 = new Date()
  var Difference_In_Time = date2.getTime() - date1.getTime()

  // To calculate the no. of days between two dates
  var day = Math.floor(Difference_In_Time / (1000 * 3600 * 24))

  return (
    <ModalBox
      className="announcement-detail-modal"
      show={modalAnnouncement}
      screenSize="fullscreen-md-down"
      scrollable={true}
      staticBackdrop={true}
      content={
        <MDBModalBody className="p-0">
          <Header onClick={() => dispatch(setModalAnnouncement({ status: false, data: {} }))} />
          {data.videoCard ? (
            <div className="modal-header-image">
              <div
                className={`video-container ${data.videoClass}`}
                style={{ borderRadius: '0', position: 'relative', zIndex: '1' }}>
                <Player playsInline poster={data.poster ? data.poster : false}>
                  <source src={data.videoCard}></source>
                </Player>
              </div>
            </div>
          ) : data.iframe ? (
            <div className="modal-header-image">
              <div
                className={`video-container ${data.videoClass}`}
                style={{ borderRadius: '0', position: 'relative', zIndex: '1' }}>
                {data.iframe}
              </div>
            </div>
          ) : (
            <div
              className="modal-header-image"
              style={{ backgroundImage: `url(${data.image})` }}></div>
          )}
          <MDBContainer className="content-container" breakpoint="none">
            <div className="top-position">
              {data.video && (
                <div className="video-container">
                  {/* <Player playsInline poster={data.poster ? data.poster : false}>
                    <source src={data.video}></source>
                  </Player> */}
                  {data.video}
                </div>
              )}
              <MDBContainer className="card-container">
                <label className="text">
                  <Trans>{data.desc}</Trans>
                </label>
                <div className="line"></div>
                <MDBRow className="content-author">
                  <MDBCol className="p-0"></MDBCol>
                  <MDBCol className="days text-end p-0">
                    <span>{day == 0 ? t('Today') : t('days ago', { day })}</span>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </MDBContainer>
        </MDBModalBody>
      }
    />
  )
}
