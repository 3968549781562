import { MDBNavbar, MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { useLocation, useNavigate } from 'react-router-dom'

//redux
import { useTranslation } from 'react-i18next'

export default function Navbar() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  return (
    <div>
      <MDBNavbar className="navbar-container" fixed="bottom">
        <MDBContainer>
          <MDBCol className="col-3 text-center" onClick={() => navigate('/')}>
            <Icon
              icon="octicon:home-fill-24"
              color={pathname === '/' ? '#F0A000' : '#9091A0'}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>{t(`Home`)}</label>
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="col-3 text-center"
            onClick={() => {
              navigate('/market')
            }}>
            {/* <img style={{ width: "1.5rem" }} src={MarketIcon} /> */}
            <Icon
              icon="icon-park-solid:stock-market"
              color={pathname === '/market' ? '#F0A000' : '#9091A0'}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>{t(`Market`)}</label>
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="col-3 text-center"
            onClick={() => {
              // if (token) {
              navigate('/trade')
              // } else {
              //   dispatch(openModalLogin());
              // }
            }}>
            <Icon
              icon="fluent:stack-star-16-regular"
              color={pathname === '/trade' ? '#F0A000' : '#9091A0'}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>{t(`Trade`)}</label>
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="col-3 text-center"
            onClick={() => {
              navigate('/profile')
            }}>
            <Icon
              icon="mingcute:profile-fill"
              color={pathname === '/profile' ? '#F0A000' : '#9091A0'}
              width="30"
              height="27"
            />
            <MDBRow className="label">
              <label>{t(`Profile`)}</label>
            </MDBRow>
          </MDBCol>
        </MDBContainer>
      </MDBNavbar>
    </div>
  )
}
