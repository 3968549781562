import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useSelector } from 'react-redux'

export default function Detail() {
  const { search_referral, search_referral_detail, referral_address } = useSelector(
    (state) => state.profile,
  )
  return (
    <MDBContainer className="admin-portal-detail-container">
      <label className="w-100">Address: {search_referral}</label>
      <br />
      <label className="w-100">Total Trader Referral: {search_referral_detail}</label>
      {referral_address.map((item, index) => (
        <label className="w-100">Referrals address: {item.wallet_address}</label>
      ))}
    </MDBContainer>
  )
}
