import { MDBCol, MDBContainer } from 'mdb-react-ui-kit'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useUtils from '../../../hook/useUtils'
import { getWalletBalance } from '../../../redux/reducer/profileReducer'
import { getPosition } from '../../../redux/reducer/tradeReducer'
import web3Services from '../../../services/web3.services'

import PortfolioReport from './portfolio.report'
import PortfolioWallet from './portfolio.wallet'

export default function Portfolio() {
  const dispatch = useDispatch()
  const { convertSha256 } = useUtils()
  const token = localStorage.getItem('token')
  const passphrase = localStorage.getItem('passphrase')
  const { accounts, selectedTrading } = useSelector((state) => state.connector)

  useEffect(() => {
    if (web3Services.isSignRequire() && accounts && token) {
      dispatch(
        getWalletBalance({
          payload: { address: accounts },
          data: convertSha256(token, passphrase),
          network: selectedTrading,
        }),
      )
      dispatch(
        getPosition({
          payload: { address: accounts },
          data: convertSha256(token, passphrase),
          network: selectedTrading,
        }),
      )
    }
  }, [accounts, token])

  return (
    <MDBCol className="portfolio-container p-0">
      <MDBContainer breakpoint="none">
        <PortfolioWallet />
        <PortfolioReport />
        {/* <PortfolioOrder /> */}
      </MDBContainer>
    </MDBCol>
  )
}
