import LoaderBNB from '../../assets/images/gif/loaderBNB.gif'
import FontFaceObserver from 'fontfaceobserver'
import { useState } from 'react'

export default function Loader() {
  const [isLoaded, setIsLoaded] = useState(true)

  const font1 = new FontFaceObserver('cabin')
  const font2 = new FontFaceObserver('montserrat')

  Promise.all([font1.load(), font2.load()]).then(() => {
    setIsLoaded(false)
  })

  return (
    <div className={isLoaded ? 'loading-container' : '--hide loading-container'}>
      <div className="loading">
        <img src={LoaderBNB} alt="..." />
        <label>
          Loading<span className="loading-dots"></span>
        </label>
      </div>
    </div>
  )
}
