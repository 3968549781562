import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import { setShareButton } from '../../redux/reducer/tradeReducer'
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Modal from 'react-modal'
import base64 from 'base64-js'
import useTradeOrder from '../../hook/useTradeOrder'
import { useEffect, useRef, useState } from 'react'
import html2canvas from 'html2canvas'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import ShareBackground from '../../assets/images/background/Share-Backgroud.png'
import { Icon } from '@iconify/react'
import useReferralCode from '../../hook/useReferralCode'
import { isBrowser, isMobile } from 'react-device-detect'
import { FacebookShareButton, TwitterShareButton, TelegramShareButton } from 'react-share'

export default function ShareCard({ show }) {
  const [showActionModal, setShowModalAction] = useState(false)
  const [loading, setLoading] = useState(false)
  const { chainId, accounts, token } = useSelector((state) => state.connector)
  const { getTraderReferralInfo } = useReferralCode()
  const { referral_code, referral_link } = useSelector(
    (state) => state.profile.referral_detail.affiliates,
  )
  const divRef = useRef()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    netPnlRate,
    netPnl,
    positionSize,
    calculateRolloverFee,
    calculateFundingFee,
    calculateCloseFee,
  } = useTradeOrder()
  const { share_modal_detail } = useSelector((state) => state.trade)
  const { listing } = useSelector((state) => state.market)
  const newSymbol = share_modal_detail.symbol ? share_modal_detail.symbol.split('/') : ''

  useEffect(() => {
    if (process.env.REACT_APP_ALLOWED_CHAIN_IDS == chainId) {
      getTraderReferralInfo()
    }
  }, [accounts, token])

  const urlParameters = {
    type: share_modal_detail.type,
    leverage: share_modal_detail.leverage,
    symbol: share_modal_detail.symbol,
    pnl: netPnl(
      share_modal_detail.type,
      positionSize(share_modal_detail.amount, share_modal_detail.leverage),
      listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
      share_modal_detail.averageOpen,
      calculateRolloverFee(share_modal_detail.amount),
      calculateFundingFee(share_modal_detail.amount, share_modal_detail.leverage),
      calculateCloseFee(share_modal_detail.amount, share_modal_detail.leverage),
    ),
    rate: netPnlRate(
      netPnl(
        share_modal_detail.type,
        positionSize(share_modal_detail.amount, share_modal_detail.leverage),
        listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
        share_modal_detail.averageOpen,
        calculateRolloverFee(share_modal_detail.amount),
        calculateFundingFee(share_modal_detail.amount, share_modal_detail.leverage),
        calculateCloseFee(share_modal_detail.amount, share_modal_detail.leverage),
      ),
      share_modal_detail.amount,
    ),
    open_price:
      Math.round(share_modal_detail.averageOpen * Math.pow(10, listing[newSymbol[0]].decimal)) /
      Math.pow(10, listing[newSymbol[0]].decimal),
    market_price:
      listing[newSymbol[0]] && listing[newSymbol[0]].normal_price
        ? listing[newSymbol[0]].normal_price
            .toFixed(listing[newSymbol[0]].decimal)
            .replace(/\.?0+$/, '')
        : 0,
    code: referral_code == '' ? null : referral_code,
    referral_link: referral_code == '' ? null : referral_link,
  }

  const query = Object.keys(urlParameters)
    .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
    .join('&')

  // Encode the string
  const ciphertext = base64.fromByteArray(new TextEncoder().encode(query))

  const maxImageSize = 640 // max size for WhatsApp sharing
  let scaleFactor = 1

  const createBlobFromElement = async (element) => {
    if (divRef.current.clientWidth > maxImageSize || divRef.current.clientHeight > maxImageSize) {
      scaleFactor = Math.min(
        maxImageSize / divRef.current.clientWidth,
        maxImageSize / divRef.current.clientHeight,
      )
    }
    const newWidth = divRef.current.clientWidth * scaleFactor
    const newHeight = divRef.current.clientHeight * scaleFactor

    const options = {
      width: newWidth,
      height: newHeight,
    }
    const canvas = await html2canvas(element, options)
    return new Promise((resolve) => canvas.toBlob(resolve, 'image/jpg'))
  }

  const generateImage = async () => {
    setLoading(true)
    const blob = await createBlobFromElement(divRef.current)
    const shareData = {
      files: [new File([blob], `${share_modal_detail.symbol}.jpg`, { type: blob.type })],
    }
    if ('canShare' in navigator) {
      await navigator
        .share({
          files: shareData.files,
          text: `Welcome to Deri Trade! Click here: ${
            process.env.REACT_APP_URL
          }/position?text=${ciphertext.toString()}`,
          title: 'Welcome to Deri Trade!',
        })
        .then((res) => {
          // dispatch(setShareButton({ status: false, data: {} }))
          // toast(
          //   <div>
          //     <MDBRow className="m-0">
          //       <MDBCol className="text-start">
          //         <label className="_label">{t(`By copying the referral code, you agree to the`)}</label>
          //       </MDBCol>
          //     </MDBRow>
          //   </div>,
          //   {
          //     position: 'top-left',
          //     autoClose: 2000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     theme: 'dark',
          //   },
          // )
        })
        .catch((ex) => {})
      setLoading(false)
    } else {
      setShowModalAction(!showActionModal)
      setLoading(false)
    }
  }

  // const downloadImage = async () => {
  //   // Generate canvas from HTML element
  //   const canvas = await html2canvas(divRef.current, {
  //     width: divRef.current.clientWidth,
  //     height: divRef.current.clientHeight,
  //   })

  //   // Resize the canvas to maximum size of 640 pixels
  //   const maxImageSize = 640
  //   let scaleFactor = 1
  //   if (canvas.width > maxImageSize || canvas.height > maxImageSize) {
  //     scaleFactor = Math.min(maxImageSize / canvas.width, maxImageSize / canvas.height)
  //   }
  //   const newWidth = canvas.width * scaleFactor
  //   const newHeight = canvas.height * scaleFactor
  //   const resizedCanvas = document.createElement('canvas')
  //   resizedCanvas.width = newWidth
  //   resizedCanvas.height = newHeight
  //   const context = resizedCanvas.getContext('2d')

  //   context.drawImage(canvas, 0, 0, newWidth, newHeight)

  //   // Convert canvas to Blob object and create URL
  //   resizedCanvas.toBlob(
  //     (blob) => {
  //       const url = URL.createObjectURL(blob)
  //       // Create anchor element and simulate click to initiate download
  //       const link = document.createElement('a')
  //       link.href = url
  //       link.download = `${share_modal_detail.symbol}_${share_modal_detail.type}.jpg` // set the file name here
  //       document.body.appendChild(link)
  //       link.click()
  //     },
  //     'image/jpeg',
  //     1.0,
  //   )
  // }

  // const [imageDataUrl, setImageDataUrl] = useState(null)

  // const createImage = async () => {
  //   const blob = await createBlobFromElement(divRef.current)
  //   const shareData = new File([blob], `DeriTrade.jpg`, { type: blob.type })

  //   setImageDataUrl(shareData)
  // }

  // const shareFacebook = async () => {
  //   // // Generate canvas from HTML element
  //   // const canvas = await html2canvas(divRef.current, {
  //   //   width: divRef.current.clientWidth,
  //   //   height: divRef.current.clientHeight,
  //   // })
  //   // // Resize the canvas to maximum size of 640 pixels
  //   // const maxImageSize = 640
  //   // let scaleFactor = 1
  //   // if (canvas.width > maxImageSize || canvas.height > maxImageSize) {
  //   //   scaleFactor = Math.min(maxImageSize / canvas.width, maxImageSize / canvas.height)
  //   // }
  //   // const newWidth = canvas.width * scaleFactor
  //   // const newHeight = canvas.height * scaleFactor
  //   // const resizedCanvas = document.createElement('canvas')
  //   // resizedCanvas.width = newWidth
  //   // resizedCanvas.height = newHeight
  //   // const context = resizedCanvas.getContext('2d')
  //   // context.drawImage(canvas, 0, 0, newWidth, newHeight)
  //   // resizedCanvas.toBlob(
  //   //   (blob) => {
  //   //     const formData = new FormData()
  //   //     formData.append('image', imageDataUrl, 'image.jpeg')
  //   //     const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //   //       process.env.REACT_APP_URL,
  //   //     )}&picture=${encodeURIComponent(DeriTradeAnnouncement1)}`
  //   //     window.open(shareUrl, '_blank')
  //   //   },
  //   //   'image/jpeg',
  //   //   1.0,
  //   // )
  //   // create a new canvas element
  //   //-------------------------------------------------
  //   // var canvas = divRef.current
  //   // canvas.width = 400
  //   // canvas.height = 400
  //   // // get the canvas 2D context
  //   // var context = canvas.getContext('2d')
  //   // // draw an image or text on the canvas
  //   // context.fillStyle = '#ff0000'
  //   // context.fillRect(0, 0, canvas.width, canvas.height)
  //   // // convert the canvas to an image data URL
  //   // var imageDataURL = canvas.toDataURL('image/png')
  //   // // share the image to Facebook using the Facebook JavaScript SDK
  //   // FB.api(
  //   //   '/me/photos',
  //   //   'post',
  //   //   {
  //   //     message: 'Check out my canvas image!',
  //   //     url: imageDataURL,
  //   //   },
  //   //   function (response) {
  //   //     if (!response || response.error) {
  //   //     } else {
  //   //     }
  //   //   },
  //   // )
  // }

  // const shareInstagram = async () => {
  //   // Generate canvas from HTML element
  //   const canvas = await html2canvas(divRef.current, {
  //     width: divRef.current.clientWidth,
  //     height: divRef.current.clientHeight,
  //   })

  //   // Resize the canvas to maximum size of 640 pixels
  //   const maxImageSize = 640
  //   let scaleFactor = 1
  //   if (canvas.width > maxImageSize || canvas.height > maxImageSize) {
  //     scaleFactor = Math.min(maxImageSize / canvas.width, maxImageSize / canvas.height)
  //   }
  //   const newWidth = canvas.width * scaleFactor
  //   const newHeight = canvas.height * scaleFactor
  //   const resizedCanvas = document.createElement('canvas')
  //   resizedCanvas.width = newWidth
  //   resizedCanvas.height = newHeight
  //   const context = resizedCanvas.getContext('2d')

  //   context.drawImage(canvas, 0, 0, newWidth, newHeight)

  //   resizedCanvas.toBlob(
  //     (blob) => {
  //       const url = URL.createObjectURL(blob)

  //       const shareUrl = `https://www.instagram.com/create/batchupload/?media_type=photo&source_url=${encodeURIComponent(
  //         url,
  //       )}`
  //       window.open(shareUrl, '_blank')
  //     },
  //     'image/jpeg',
  //     1.0,
  //   )
  // }

  // const shareTwitter = async () => {
  //   // Generate canvas from HTML element
  //   const canvas = await html2canvas(divRef.current, {
  //     width: divRef.current.clientWidth,
  //     height: divRef.current.clientHeight,
  //   })

  //   // Resize the canvas to maximum size of 640 pixels
  //   const maxImageSize = 640
  //   let scaleFactor = 1
  //   if (canvas.width > maxImageSize || canvas.height > maxImageSize) {
  //     scaleFactor = Math.min(maxImageSize / canvas.width, maxImageSize / canvas.height)
  //   }
  //   const newWidth = canvas.width * scaleFactor
  //   const newHeight = canvas.height * scaleFactor
  //   const resizedCanvas = document.createElement('canvas')
  //   resizedCanvas.width = newWidth
  //   resizedCanvas.height = newHeight
  //   const context = resizedCanvas.getContext('2d')

  //   context.drawImage(canvas, 0, 0, newWidth, newHeight)

  //   resizedCanvas.toBlob(
  //     (blob) => {
  //       const url = URL.createObjectURL(blob)

  //       const shareUrl = `https://twitter.com/intent/tweet?text=Welcome to Deri Trade! Click here: ${
  //         process.env.REACT_APP_URL
  //       }/position?text=${ciphertext.toString()}&url=${DeriTradeAnnouncement1}`
  //       window.open(shareUrl, '_blank')
  //     },
  //     'image/jpeg',
  //     1.0,
  //   )
  // }
  // const copyToClipboard = (str) => {
  //   const el = document.createElement('textarea')
  //   el.value = str
  //   el.setAttribute('readonly', '')
  //   el.style.position = 'absolute'
  //   el.style.left = '-9999px'
  //   document.body.appendChild(el)
  //   el.select()
  //   document.execCommand('copy')
  //   document.body.removeChild(el)
  //   toast(
  //     <div>
  //       <MDBRow className="m-0">
  //         <MDBCol className="text-start">
  //           <label className="_label">Copy</label>
  //         </MDBCol>
  //       </MDBRow>
  //     </div>,
  //     {
  //       position: 'top-left',
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       // progress: undefined,
  //       theme: 'dark',
  //     },
  //   )
  // }

  useEffect(() => {
    if ('canShare' in navigator && divRef.current && isMobile) {
      generateImage()
    }
    if (isBrowser) {
      setShowModalAction(true)
    }
  }, [divRef.current])

  return (
    <RemoveScroll>
      <Modal
        isOpen={show}
        ariaHideApp={false}
        onRequestClose={() => {
          if (showActionModal) {
            setShowModalAction(false)
          } else {
            dispatch(setShareButton({ status: false, data: {} }))
          }
        }}
        className={`full-modal-box-container share-modal-box`}>
        <div className="card-container">
          <div className="border-container">
            <MDBContainer
              onClick={() => {
                if (showActionModal) {
                  setShowModalAction(false)
                }
              }}
              className="card"
              ref={divRef}
              style={{ backgroundImage: `url(${ShareBackground})` }}>
              <MDBRow className="h-100 align-items-center content-background">
                <MDBCol className="text-center">
                  <MDBRow>
                    <label className="_label --symbol">
                      {share_modal_detail.symbol}{' '}
                      <span className={`--${share_modal_detail.type}`}>
                        {share_modal_detail.type}
                      </span>
                    </label>
                  </MDBRow>
                  <label
                    className={`_label --rate ${
                      netPnl(
                        share_modal_detail.type,
                        positionSize(share_modal_detail.amount, share_modal_detail.leverage),
                        listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                        share_modal_detail.averageOpen,
                        calculateRolloverFee(share_modal_detail.amount),
                        calculateFundingFee(share_modal_detail.amount, share_modal_detail.leverage),
                        calculateCloseFee(share_modal_detail.amount, share_modal_detail.leverage),
                      ) > 0
                        ? '--profit'
                        : '--loss'
                    }`}>
                    {netPnlRate(
                      netPnl(
                        share_modal_detail.type,
                        positionSize(share_modal_detail.amount, share_modal_detail.leverage),
                        listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                        share_modal_detail.averageOpen,
                        calculateRolloverFee(share_modal_detail.amount),
                        calculateFundingFee(share_modal_detail.amount, share_modal_detail.leverage),
                        calculateCloseFee(share_modal_detail.amount, share_modal_detail.leverage),
                      ),
                      share_modal_detail.amount,
                    )}
                  </label>
                  <MDBRow className="label-column">
                    <div>
                      <label className="_label --price-label">Entry Price:</label>
                      <label className="_label --price">
                        {Math.round(
                          share_modal_detail.averageOpen *
                            Math.pow(10, listing[newSymbol[0]].decimal),
                        ) / Math.pow(10, listing[newSymbol[0]].decimal)}
                      </label>
                    </div>
                    <div>
                      <label className="_label --price-label">Leverage:</label>
                      <label className="_label --price">{share_modal_detail.leverage}X</label>
                    </div>
                    <div>
                      <label className="_label --price-label">Last Price:</label>
                      <label className="_label --price">
                        {listing[newSymbol[0]] && listing[newSymbol[0]].normal_price
                          ? listing[newSymbol[0]].normal_price
                              .toFixed(listing[newSymbol[0]].decimal)
                              .replace(/\.?0+$/, '')
                          : 0}
                      </label>
                    </div>
                  </MDBRow>
                </MDBCol>
                <MDBCol
                  className="d-grid justify-content-center p-0"
                  style={{ placeItems: 'center' }}>
                  {/* <MDBCol className="d-flex align-items-center col-2"> */}
                  <QRCode
                    className="qr-gen"
                    size={50}
                    value={referral_code !== '' ? referral_link : process.env.REACT_APP_URL}
                  />
                  <label className="_label --qrcode-label">Scan the QR Code to register.</label>
                  {/* </MDBCol> */}
                  {/* <MDBCol style={{ lineHeight: '1em' }}>
                      <label className="_label --refer w-100">
                        {referral_code !== '' ? 'REFERRAL CODE' : 'SCAN TO REGISTER !'}
                      </label>
                      {referral_code !== '' ? (
                        <label className="_label --refer-code w-100">{referral_code}</label>
                      ) : (
                        ''
                      )}
                    </MDBCol> */}
                </MDBCol>
              </MDBRow>
              {/* <MDBRow className="label-column">
                <label className={`_label --card-label --${share_modal_detail.type}`}>
                  {share_modal_detail.type}
                </label>
                <label className="_label --card-label">{share_modal_detail.leverage}X</label>
                <label className="_label --card-label">
                  {share_modal_detail.symbol} <span>Perpetual</span>
                </label>
              </MDBRow>
              <MDBRow>
                <label
                  className={`_label --rate ${
                    netPnl(
                      share_modal_detail.type,
                      positionSize(share_modal_detail.amount, share_modal_detail.leverage),
                      listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                      share_modal_detail.averageOpen,
                      calculateRolloverFee(share_modal_detail.amount),
                      calculateFundingFee(share_modal_detail.amount, share_modal_detail.leverage),
                      calculateCloseFee(share_modal_detail.amount, share_modal_detail.leverage),
                    ) > 0
                      ? '--profit'
                      : '--loss'
                  }`}>
                  {netPnlRate(
                    netPnl(
                      share_modal_detail.type,
                      positionSize(share_modal_detail.amount, share_modal_detail.leverage),
                      listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                      share_modal_detail.averageOpen,
                      calculateRolloverFee(share_modal_detail.amount),
                      calculateFundingFee(share_modal_detail.amount, share_modal_detail.leverage),
                      calculateCloseFee(share_modal_detail.amount, share_modal_detail.leverage),
                    ),
                    share_modal_detail.amount,
                  )}
                </label>
              </MDBRow>
              <MDBRow className="align-items-center">
                <label className="_label --price-label">Open Price</label>
                <label className="_label --price">
                  {Math.round(share_modal_detail.averageOpen * 100) / 100}
                </label>
              </MDBRow>
              <MDBRow className="align-items-center">

                <label className="_label --price-label">Last Price</label>
                <label className="_label --price">
                  {listing[newSymbol[0]] && listing[newSymbol[0]].normal_price
                    ? listing[newSymbol[0]].normal_price
                        .toFixed(listing[newSymbol[0]].decimal)
                        .replace(/\.?0+$/, '')
                    : 0}
                </label>
              </MDBRow>
              <MDBRow className="align-items-center mt-2">
                <MDBCol className="d-flex align-items-center col-2">
                  <QRCode
                    className="qr-gen"
                    size={50}
                    value={referral_code !== '' ? referral_link : process.env.REACT_APP_URL}
                  />
                </MDBCol>
                <MDBCol style={{ lineHeight: '1em' }}>
                  <label className="_label --refer w-100">
                    {referral_code !== '' ? 'REFERRAL CODE' : 'SCAN TO REGISTER !'}
                  </label>
                  {referral_code !== '' ? (
                    <label className="_label --refer-code w-100">{referral_code}</label>
                  ) : (
                    ''
                  )}
                </MDBCol>
              </MDBRow> */}
            </MDBContainer>
          </div>
          <MDBRow className="m-0 justify-content-center">
            <MDBBtn
              className="_button --share"
              disabled={loading}
              onClick={() => {
                if ('canShare' in navigator) {
                  if (isBrowser) {
                    setShowModalAction(true)
                  } else {
                    generateImage()
                  }
                } else {
                  setShowModalAction(true)
                }
              }}>
              Share now
            </MDBBtn>
          </MDBRow>
        </div>
        <div className={showActionModal ? 'bottom-container --show' : 'bottom-container'}>
          <MDBContainer className="card" fixed="bottom">
            <MDBRow>
              <label className="mb-2">Share via</label>
            </MDBRow>
            <MDBRow className="m-0 gap-2">
              <div className="_action-card --social col-4">
                <Icon icon="logos:twitter" width="2em" height="2em" />
                <TwitterShareButton
                  url={`${process.env.REACT_APP_URL}/position?text=${ciphertext.toString()}`}
                  title={'Welcome to Deri Trade!'}>
                  Twitter
                </TwitterShareButton>
              </div>
              <div className="_action-card --social col-4">
                <Icon icon="logos:facebook" width="2em" height="2em" />
                <FacebookShareButton
                  url={`${process.env.REACT_APP_URL}/position?text=${ciphertext.toString()}`}
                  quote={'Welcome to Deri Trade!'}>
                  Facebook
                </FacebookShareButton>
              </div>
              <div className="_action-card --social col-4">
                <Icon icon="logos:telegram" width="2em" height="2em" />
                <TelegramShareButton
                  url={`${process.env.REACT_APP_URL}/position?text=${ciphertext.toString()}`}
                  title={'Welcome to Deri Trade!'}>
                  Telegram
                </TelegramShareButton>
              </div>
              {'canShare' in navigator && (
                <div onClick={() => generateImage()} className="_action-card --social col-4">
                  Other
                </div>
              )}
            </MDBRow>
            <MDBRow className="m-0">
              <div className="_action-card --save">
                <CopyToClipboard
                  onCopy={() => {
                    toast(
                      <div>
                        <MDBRow className="m-0">
                          <MDBCol className="text-start">
                            <label className="_label">Copy link</label>
                          </MDBCol>
                        </MDBRow>
                      </div>,
                      {
                        position: 'top-left',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'dark',
                      },
                    )
                  }}
                  text={`${process.env.REACT_APP_URL}/position?text=${ciphertext.toString()}`}>
                  <label>
                    <Icon icon="mingcute:copy-line" /> Copy Link
                  </label>
                </CopyToClipboard>
              </div>
            </MDBRow>
          </MDBContainer>
        </div>
      </Modal>
    </RemoveScroll>
  )
}
