//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useWindowDimensions from '../../../components/common/window-dimension'
import { setOrderDetailModal } from '../../../redux/reducer/tradeReducer'

export default function History({ data }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const decimal = 100

  const { width } = useWindowDimensions()

  return (
    <MDBContainer className="position-card">
      <MDBRow className="position-container m-0">
        <MDBCol className="p-0">
          <MDBRow
            className="position-row m-0"
            onClick={() => {
              let newData = {
                ...data,
                modal_type: 'history',
              }
              dispatch(setOrderDetailModal({ status: true, data: newData }))
            }}>
            <div className="fix-colums p-0">
              <MDBRow className="m-0">
                <label className={`_label --${data.orderMethod}`} style={{ cursor: 'pointer' }}>
                  {t(`Open`)} {t(`${data.orderMethod}`)}
                </label>
              </MDBRow>
              <MDBRow className="m-0">
                <label className="_label --normal-label-title" style={{ cursor: 'pointer' }}>
                  {' '}
                  {t(`${data.symbol}`)}
                </label>
              </MDBRow>
              <MDBRow className="m-0" style={{ cursor: 'pointer' }}>
                <label
                  className="_label --normal-label-title p-0"
                  style={{ cursor: 'pointer', justifyContent: 'left', fontSize: '0.75rem' }}>
                  {' '}
                  {moment(data.createdAt).format('DD MMM YYYY hh:mm:ss')}
                </label>
              </MDBRow>
            </div>
            {width < 1130 && (
              <MDBCol className="p-0">
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {t(`Details`)}
                </label>
              </MDBCol>
            )}
            {width >= 1130 && (
              <MDBCol className=" p-0">
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Order Type`)}
                </label>
                <label
                  className="_label --normal-label"
                  style={{ cursor: 'pointer', textTransform: 'capitalize' }}>
                  {t(`${data.type}`)}
                </label>
              </MDBCol>
            )}
            {width >= 1130 && (
              <MDBCol className=" p-0">
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Leverage`)}
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {Math.round(data.leverage * 100) / 100}x
                </label>
              </MDBCol>
            )}
            {width >= 1130 && (
              <MDBCol className="responsive-colums col-2 p-0">
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Collateral`)} (USDT)
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {Math.round(data.collateral * decimal) / decimal}
                </label>
              </MDBCol>
            )}
            {width >= 1014 && (
              <MDBCol className="responsive-colums col-2 p-0">
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Ask Price`)}
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {Math.round(data.price * decimal) / decimal}
                </label>
              </MDBCol>
            )}
            {width >= 768 && (
              <MDBCol className="responsive-colums col-2 p-0">
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Fulfill Price`)}
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {Math.round(data.price * decimal) / decimal}
                </label>
              </MDBCol>
            )}
            {width >= 328 && (
              <MDBCol className=" p-0">
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Status`)}
                </label>
                <label
                  className={`_label --normal-label --${data.status}`}
                  style={{ cursor: 'pointer', textTransform: 'capitalize' }}>
                  {t(`${data.status}`)}
                </label>
              </MDBCol>
            )}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
