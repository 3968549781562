//lib
import { Icon } from '@iconify/react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import moment from 'moment'

//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  setClosePosition,
  setPositionDetailModal,
  setShareButton,
  setTakeProfitModal,
} from '../../../redux/reducer/tradeReducer'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'
import useTradeOrder from '../../../hook/useTradeOrder'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useState } from 'react'

export default function Position({
  data = {
    type: 'long',
    symbol: 'BNB/USD',
    leverage: 50,
    collateral: 1000,
    averageOpen: 24724,
    liquidationPrice: 40,
  },
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const decimal = 100
  const {
    liquidationPrice,
    positionSize,
    netPnl,
    calculateRolloverFee,
    calculateFundingFee,
    calculateCloseFee,
    netPnlRate,
    handlePairing,
  } = useTradeOrder()
  const { listing } = useSelector((state) => state.market)
  const newSymbol = data.symbol ? data.symbol.split('/') : ''

  const { width } = useWindowDimensions()

  const [isDisabled, setIsDisabled] = useState(false)
  function showNotification() {
    if (isDisabled) {
      return
    }

    setIsDisabled(true)
    toast.info(
      <div>
        <MDBRow className="m-0">
          <MDBCol className="text-start">
            <label className="_label">{t('Sharing Coming Soon')}</label>
          </MDBCol>
        </MDBRow>
      </div>,
      {
        position: 'top-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        theme: 'dark',
      },
    )

    setTimeout(() => {
      setIsDisabled(false)
    }, 60000) // 禁用60秒
  }

  return (
    <MDBContainer className="position-card " style={{ cursor: 'pointer' }}>
      <MDBRow className="position-container m-0">
        <MDBCol className="p-0">
          <MDBRow className="position-row m-0">
            <div
              className="fix-colums --position p-0"
              onClick={() => {
                dispatch(setPositionDetailModal({ status: true, data: data }))
              }}>
              <MDBRow className="m-0 d-flex w-auto">
                <label className={`_label --${data.type}`} style={{ cursor: 'pointer' }}>
                  {t(data.type)}
                </label>
              </MDBRow>
              <MDBRow className="m-0" style={{ cursor: 'pointer' }}>
                <label className="_label --normal-label-title" style={{ cursor: 'pointer' }}>
                  {' '}
                  {data.symbol}
                </label>
              </MDBRow>
              <MDBRow className="m-0" style={{ cursor: 'pointer' }}>
                <label
                  className="_label --normal-label-title p-0"
                  style={{ cursor: 'pointer', justifyContent: 'left', fontSize: '0.75rem' }}>
                  {' '}
                  {moment(data.createdAt).format('DD MMM YYYY hh:mm:ss')}
                </label>
              </MDBRow>
            </div>
            {width < 1130 && (
              <MDBCol
                className="p-0"
                onClick={() => {
                  dispatch(setPositionDetailModal({ status: true, data: data }))
                }}>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {t(`Details`)}
                </label>
              </MDBCol>
            )}
            {width >= 1130 && (
              <MDBCol
                className=" p-0"
                onClick={() => {
                  dispatch(setPositionDetailModal({ status: true, data: data }))
                }}>
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Leverage`)}
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {Math.ceil(data.leverage * 100) / 100}x
                </label>
              </MDBCol>
            )}
            {width >= 1130 && (
              <MDBCol
                className="responsive-colums  p-0"
                onClick={() => {
                  dispatch(setPositionDetailModal({ status: true, data: data }))
                }}>
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Collateral`)} (USDT)
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {Math.round(data.amount * decimal) / decimal}
                </label>
              </MDBCol>
            )}
            {width >= 1014 && (
              <MDBCol
                className="responsive-colums  p-0"
                onClick={() => {
                  dispatch(setPositionDetailModal({ status: true, data: data }))
                }}>
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Open Price`)}
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {Math.round(
                    data.averageOpen *
                      Math.pow(10, listing[handlePairing('symbol', newSymbol[0])].decimal),
                  ) / Math.pow(10, listing[handlePairing('symbol', newSymbol[0])].decimal)}
                </label>
              </MDBCol>
            )}
            {width >= 768 && (
              <MDBCol
                className="responsive-colums  p-0"
                onClick={() => {
                  dispatch(setPositionDetailModal({ status: true, data: data }))
                }}>
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Current Price`)}
                </label>
                <label className={`_label --normal-label`} style={{ cursor: 'pointer' }}>
                  {listing[newSymbol[0]] && listing[newSymbol[0]].normal_price
                    ? listing[newSymbol[0]].normal_price
                        .toFixed(listing[newSymbol[0]].decimal)
                        .replace(/\.?0+$/, '')
                    : 0}
                </label>
              </MDBCol>
            )}
            {width >= 768 && (
              <MDBCol
                className="p-0"
                onClick={() => {
                  dispatch(setPositionDetailModal({ status: true, data: data }))
                }}>
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Take Profit`)}
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {data.takeProfit > 0 ? (
                    <MDBRow style={{ cursor: 'pointer' }}>
                      {Math.round(data.takeProfit * decimal) / decimal}
                    </MDBRow>
                  ) : (
                    <MDBRow style={{ cursor: 'pointer' }}>{t(`None`)}</MDBRow>
                  )}
                </label>
              </MDBCol>
            )}
            {width >= 450 && (
              <MDBCol
                className="p-0"
                onClick={() => {
                  dispatch(setPositionDetailModal({ status: true, data: data }))
                }}>
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Liq/SL`)}
                </label>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {data.stopLoss > 0 ? (
                    <MDBRow style={{ cursor: 'pointer' }}>
                      {t(`SL`)}: {Math.round(data.stopLoss * decimal) / decimal}
                    </MDBRow>
                  ) : (
                    <MDBRow style={{ cursor: 'pointer' }}>
                      {t(`LIQ`)}:{' '}
                      {liquidationPrice(
                        data.type,
                        Number(data.averageOpen),
                        Number(data.amount),
                        data.leverage,
                        calculateRolloverFee(data.collateral),
                        calculateFundingFee(data.collateral, data.leverage),
                        data.symbol,
                      )}
                    </MDBRow>
                  )}
                </label>
              </MDBCol>
            )}
            {width <= 768 && (
              <MDBCol
                className="p-0"
                onClick={() => {
                  dispatch(
                    setTakeProfitModal({ isOpen: true, data: { ...data, position_modal: true } }),
                  )
                }}>
                <label className="_label --normal-label" style={{ cursor: 'pointer' }}>
                  {t(`TP/SL`)}
                </label>
              </MDBCol>
            )}
            {width >= 330 && (
              <MDBCol
                className="p-0"
                onClick={() => {
                  dispatch(setShareButton({ status: true, data: data }))
                  // showNotification()
                }}>
                <label className="_label --title" style={{ cursor: 'pointer' }}>
                  {t(`Net PNL`)}{' '}
                  <div className="d-flex align-items-center" style={{ marginLeft: '0.5em' }}>
                    <Icon icon="grommet-icons:share" width="1em" height="1em" />
                  </div>
                </label>
                <label
                  style={{ cursor: 'pointer' }}
                  className={`_label --normal-label --${
                    netPnl(
                      data.type,
                      positionSize(data.amount, data.leverage),
                      listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                      data.averageOpen,
                      calculateRolloverFee(data.amount),
                      calculateFundingFee(data.amount, data.leverage),
                      calculateCloseFee(data.amount, data.leverage),
                    ) > 0
                      ? 'long'
                      : 'short'
                  }-text`}>
                  {netPnl(
                    data.type,
                    positionSize(data.amount, data.leverage),
                    listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                    data.averageOpen,
                    calculateRolloverFee(data.amount),
                    calculateFundingFee(data.amount, data.leverage),
                    calculateCloseFee(data.amount, data.leverage),
                  ) +
                    ' (' +
                    netPnlRate(
                      netPnl(
                        data.type,
                        positionSize(data.amount, data.leverage),
                        listing[newSymbol[0]] ? listing[newSymbol[0]].normal_price : 0,
                        data.averageOpen,
                        calculateRolloverFee(data.amount),
                        calculateFundingFee(data.amount, data.leverage),
                        calculateCloseFee(data.amount, data.leverage),
                      ),
                      data.amount,
                    ) +
                    ')'}
                </label>
              </MDBCol>
            )}
          </MDBRow>
        </MDBCol>
        <MDBCol
          className="fix-colums --button text-end col-1 d-flex align-items-center p-0"
          onClick={() => dispatch(setClosePosition({ status: true, id: data._id }))}>
          <Icon icon="charm:circle-cross" width="1.5em" height="1.5em" />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
