import { useEffect } from 'react'
//lib
import { MDBCol, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  deposit,
  getTransferList,
  getWalletBalance,
  setDepositBalance,
  setDepositModal,
} from '../../../redux/reducer/profileReducer'

//hook
import useUtils from '../../../hook/useUtils'
import useTradeOrder from '../../../hook/useTradeOrder'

//component
import ModalBox from '../../../components/modal/full.modal.box'
import { useTranslation } from 'react-i18next'

export default function Deposit({ show }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const token = localStorage.getItem('token')
  const passphrase = localStorage.getItem('passphrase')
  const { convertSha256 } = useUtils()
  const { countDecimals } = useTradeOrder()
  const exceptThisSymbols = ['e', 'E', '+', '-']
  const { deposit_balance, walletBalance, deposit_submit_loading } = useSelector(
    (state) => state.profile,
  )
  const { accounts, selectedTrading } = useSelector((state) => state.connector)
  const data = {
    deposit: 2000,
  }
  const balance = Math.floor(walletBalance * Math.pow(10, 2)) / Math.pow(10, 2)

  const useSchema = Yup.object({
    deposit: Yup.number()
      .typeError('Wrong Amount')
      .moreThan(0, 'Wrong Deposit')
      .required('Wrong Deposit'),
  })

  const handleSubmit = () => {
    dispatch(
      deposit({
        payload: { address: accounts, amount: deposit_balance },
        data: convertSha256(token, passphrase),
        network: selectedTrading,
      }),
    )
      .unwrap()
      .then((data) => {
        dispatch(
          getWalletBalance({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        dispatch(
          getTransferList({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        toast(
          <div>
            <MDBRow className="m-0">
              <MDBCol className="text-start">
                <label className="_label">{t(`Deposit Success`)}</label>
              </MDBCol>
            </MDBRow>
            <MDBRow className="m-0">
              <label className="_label --total-title" style={{ textTransform: 'none' }}>
                {t('Your deposit of')} <span className="_label">{data.amount} USDT</span>{' '}
                {t('has been confirmed and is now available for trading.')}
              </label>
            </MDBRow>
          </div>,
          {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            // progress: undefined,
            theme: 'dark',
          },
        )
      })
      .catch((ex) => {
        const errors = ex.response ? ex.response.data.errors : []
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            toast.error(t(errors[item]), {
              position: 'top-left',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'dark',
            })
          })
        } else {
          toast.error(t(JSON.stringify(ex.message)), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          })
        }
      })
  }

  useEffect(() => {
    if (selectedTrading == 'testnet') {
      dispatch(setDepositBalance(2000))
    }
  }, [selectedTrading])

  return (
    <ModalBox
      show={show}
      className={'profile-modal-container'}
      setShow={() => dispatch(setDepositModal(false))}
      reactModal={true}
      content={
        <MDBModalBody className="m-0">
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={data}
            validationSchema={useSchema}
            onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
              handleSubmit(setFieldError)
              resetForm({ values: '' })
            }}>
            {({ errors, setFieldValue, values, isValid, isSubmitting }) => (
              <Form className="modal-form">
                <MDBRow className="profile-modal-row">
                  <MDBCol>
                    <label>/ {t(`Deposit`)}</label>
                  </MDBCol>
                  <MDBCol className="text-end">
                    <div
                      style={{ cursor: 'pointer' }}
                      className="round-btn"
                      onClick={() => dispatch(setDepositModal(false))}>
                      <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="profile-modal-row m-0">
                  <MDBCol className="col-2 p-0">
                    <button
                      className="wallet-options d-flex align-items-center justify-content-center"
                      type="button">
                      {/* <img src={BNB} width="25" height="25" alt="wallet" /> */}
                      USDT
                    </button>
                  </MDBCol>
                  <MDBCol>
                    <input
                      type="number"
                      step={'any'}
                      value={String(deposit_balance)}
                      disabled={false}
                      onBlur={() => {
                        const value = parseFloat(deposit_balance)
                        dispatch(setDepositBalance(value))
                      }}
                      onChange={(e) => {
                        if (countDecimals(e.target.value) <= 2) {
                          dispatch(setDepositBalance(e.target.value))
                        }
                        setFieldValue('deposit', e.target.value)
                      }}
                      onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="profile-modal-row">
                  <label>
                    $ {balance.toLocaleString()} {t(`available`)}
                  </label>
                </MDBRow>
                <MDBRow className="profile-modal-row">
                  <MDBCol>
                    <label className="w-100">
                      {t(`Fee`)}{' '}
                      {/* <Icon
                        icon="mdi:question-mark-circle-outline"
                        color="#707070"
                        width="18"
                        height="18"
                      /> */}
                    </label>
                    <label className="w-100">{t(`Total`)}</label>
                    <label className="w-100">{t(`To Wallet`)}</label>
                  </MDBCol>
                  <MDBCol>
                    <label className="w-100 text-end">-</label>
                    <label className="w-100 text-end">
                      ${deposit_balance > 0 ? parseFloat(deposit_balance).toLocaleString() : 0}
                    </label>
                    <label className="w-100 text-end">
                      ${deposit_balance > 0 ? parseFloat(deposit_balance).toLocaleString() : 0}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow style={{ paddingTop: '1em' }}>
                  <label className="_label --status">
                    {Object.values(errors)[0] ? t(`${Object.values(errors)[0]}`) : ''}
                  </label>
                </MDBRow>
                <MDBRow className="profile-modal-row m-0 pt-3 pb-3">
                  <button
                    type="submit"
                    className="enter-amount-button"
                    disabled={deposit_submit_loading}>
                    {deposit_submit_loading ? t('Waiting..') : t('Submit')}
                  </button>
                </MDBRow>
                <MDBRow className="profile-modal-row text-center">
                  <label style={{ fontSize: '12px' }}>
                    {t(`Deposits are processed on Demo Trading.`)}
                    <br />
                    {t('You do not need to have any funds in your wallet to continue.')}
                    <br />
                    {t('Test funds will be sent directly to your')}{' '}
                    <span style={{ color: '#FFDC00' }}>{t(`Deri Trade`)} </span>
                    {t('account not your wallet.')}
                    <br />
                    {t('*Monthly deposits are capped at $100,000 USD.')}
                    {/* <span style={{ color: '#FFDC00' }}>{t(`deri trade`)}</span> */}
                  </label>
                </MDBRow>
              </Form>
            )}
          </Formik>
        </MDBModalBody>
      }
    />
  )
}
