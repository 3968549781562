import { configureStore } from "@reduxjs/toolkit";

import connectorReducer from "./reducer/connectorReducer"
import homeReducer from "./reducer/homeReducer"
import marketSlice from "./reducer/marketReducer"
import tradeReducer from "./reducer/tradeReducer"
import profileReducer from "./reducer/profileReducer"

const reducer = {
    connector: connectorReducer,
    home: homeReducer,
    market: marketSlice,
    trade: tradeReducer,
    profile: profileReducer,
  };
  const store = configureStore({
    reducer: reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  });
  export default store;