//lib
import { Icon } from '@iconify/react'
import { MDBCardHeader, MDBCol, MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'

//hook
import useTradeOrder from '../../../hook/useTradeOrder'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setWalletRealizedDetailModal } from '../../../redux/reducer/profileReducer'

//component
import ModalBox from '../../../components/modal/full.modal.box'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { setShareButton } from '../../../redux/reducer/tradeReducer'
import { useState } from 'react'

export default function RealizedDetailModal({ show, title, setShow }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { listing } = useSelector((state) => state.market)
  const {
    positionSize,
    priceChange,
    calculateRolloverFee,
    calculateFundingFee,
    calculateCloseFee,
    netPnlRate,
    calculateOpenFee,
    handlePairing,
  } = useTradeOrder()
  const decimal = 100
  const { realizedDetailModalData } = useSelector((state) => state.profile)

  const [isDisabled, setIsDisabled] = useState(false)

  function showNotification() {
    if (isDisabled) {
      return
    }

    setIsDisabled(true)
    toast.info(
      <div>
        <MDBRow className="m-0">
          <MDBCol className="text-start">
            <label className="_label">{t('Sharing Coming Soon')}</label>
          </MDBCol>
        </MDBRow>
      </div>,
      {
        position: 'top-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        theme: 'dark',
      },
    )

    setTimeout(() => {
      setIsDisabled(false)
    }, 60000) // 禁用60秒
  }

  return (
    <ModalBox
      className={'trade-order-container'}
      show={show}
      setShow={setShow}
      screenSize="s"
      scrollable={true}
      reactModal={true}
      content={
        <div style={{ width: '21em' }}>
          <MDBCardHeader>
            <MDBRow className="d-flex align-items-center">
              <MDBCol className="col-8">
                <label className="_label --symbol">
                  {realizedDetailModalData.symbol}{' '}
                  <div>
                    <Icon
                      icon="grommet-icons:share"
                      width="1em"
                      height="1em"
                      onClick={() => {
                        // showNotification()
                        dispatch(
                          setShareButton({
                            status: true,
                            data: realizedDetailModalData,
                          }),
                        )
                      }}
                    />
                  </div>
                </label>
                <label className={`_label --order-type --${realizedDetailModalData.type}`}>
                  {`${t(realizedDetailModalData.type)}`}
                </label>
              </MDBCol>
              <MDBCol className="back-btn col-4">
                <div
                  style={{ cursor: 'pointer' }}
                  className="round-btn"
                  onClick={() =>
                    dispatch(setWalletRealizedDetailModal({ status: false, data: {} }))
                  }>
                  <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBModalBody style={{ height: 'auto' }}>
            <MDBContainer className="order-detail-container">
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Collateral`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(realizedDetailModalData.amount * decimal) / decimal} USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Leverage`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {realizedDetailModalData.leverage}x
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Position Size`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {positionSize(realizedDetailModalData.amount, realizedDetailModalData.leverage)}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Open Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {Math.round(
                      realizedDetailModalData.averageOpen *
                        Math.pow(
                          10,
                          listing[handlePairing('symbol', realizedDetailModalData.symbol)].decimal,
                        ),
                    ) /
                      Math.pow(
                        10,
                        listing[handlePairing('symbol', realizedDetailModalData.symbol)].decimal,
                      )}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Close Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {Math.round(
                      realizedDetailModalData.averageClose *
                        Math.pow(
                          10,
                          listing[handlePairing('symbol', realizedDetailModalData.symbol)].decimal,
                        ),
                    ) /
                      Math.pow(
                        10,
                        listing[handlePairing('symbol', realizedDetailModalData.symbol)].decimal,
                      )}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Price Change`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    {priceChange(
                      realizedDetailModalData.averageOpen,
                      realizedDetailModalData.averageClose,
                    )}
                    %
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Rollover Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    - {calculateRolloverFee(realizedDetailModalData.amount)} USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Funding Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    -{' '}
                    {calculateFundingFee(
                      realizedDetailModalData.amount,
                      realizedDetailModalData.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Opening Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    -{' '}
                    {calculateOpenFee(
                      realizedDetailModalData.collateral,
                      realizedDetailModalData.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Closing Fees`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className="_label --title-value-details">
                    -{' '}
                    {calculateCloseFee(
                      realizedDetailModalData.amount,
                      realizedDetailModalData.leverage,
                    )}{' '}
                    USDT
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Take Profit`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {realizedDetailModalData.takeProfit
                      ? realizedDetailModalData.takeProfit
                      : t('None')}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Stop Loss`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {realizedDetailModalData.stopLoss
                      ? realizedDetailModalData.stopLoss
                      : t('None')}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Liq. Price`)}</label>
                </MDBCol>
                <MDBCol>
                  <label className={`_label --title-value-details`}>
                    {Math.round(
                      realizedDetailModalData.liquidationPrice *
                        Math.pow(
                          10,
                          listing[handlePairing('symbol', realizedDetailModalData.symbol)].decimal,
                        ),
                    ) /
                      Math.pow(
                        10,
                        listing[handlePairing('symbol', realizedDetailModalData.symbol)].decimal,
                      )}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <label className="_label --title">{t(`Net PnL`)}</label>
                </MDBCol>
                <MDBCol>
                  <label
                    className={`_label --title-value-details text-end --${
                      realizedDetailModalData.pnl > 0 ? 'long' : 'short'
                    }`}>
                    {Math.round(realizedDetailModalData.pnl * decimal) / decimal} (
                    {netPnlRate(realizedDetailModalData.pnl, realizedDetailModalData.amount)})
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </div>
      }
    />
  )
}
