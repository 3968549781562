import web3Services from '../../services/web3.services'
import { useDispatch, useSelector } from 'react-redux'
import {
  setRecoverKeySetting,
  setSettingModal,
  setWalletConnect,
} from '../../redux/reducer/connectorReducer'
import { MDBRipple } from 'mdb-react-ui-kit'
import { ConnectButtonIcon } from '../../assets/images'
import { useTranslation } from 'react-i18next'

export default function ButtonConnectWallet() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { openRecoverKeySetting, openSettingModal, accounts, chainId, resolveName } = useSelector(
    (state) => state.connector,
  )

  const strChainId = chainId ? chainId.toString() : ''
  return (
    <>
      {accounts ? (
        process.env.REACT_APP_ALLOWED_CHAIN_IDS.split(',').includes(strChainId) ? (
          web3Services.isSignRequire() ? (
            <MDBRipple
              className="connect-btn-login"
              onClick={() => {
                web3Services.isSignRequire()
                  ? dispatch(setSettingModal(!openSettingModal))
                  : dispatch(setWalletConnect(true))
              }}>
              <label style={{ cursor: 'pointer' }}>
                {resolveName != 'null' && resolveName != null
                  ? resolveName.toUpperCase()
                  : accounts}
              </label>
            </MDBRipple>
          ) : (
            <MDBRipple className="boading-btn">
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => dispatch(setRecoverKeySetting(!openRecoverKeySetting))}>
                {t('Onboarding')}
              </button>
            </MDBRipple>
          )
        ) : (
          <MDBRipple className="boading-btn">
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch(setRecoverKeySetting(!openRecoverKeySetting))}>
              {t('Onboarding')}
            </button>
          </MDBRipple>
        )
      ) : (
        <MDBRipple className="connect-btn">
          <button style={{ cursor: 'pointer' }} onClick={() => dispatch(setWalletConnect(true))}>
            {t('Connect Wallet')}{' '}
            <img src={ConnectButtonIcon} alt="deri-trade-logo" width="22" height="auto" />
          </button>
        </MDBRipple>
      )}
    </>
  )
}
