import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'

import { Icon } from '@iconify/react'

export default function Header({ onClick }) {
  return (
    <MDBContainer className="common-header-main">
      <MDBRow>
        <MDBCol className="back-btn">
          <div className="round-btn" onClick={onClick} style={{ cursor: 'pointer' }}>
            <Icon icon="akar-icons:arrow-left" color="#edebeb" width="20" height="25" />
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
