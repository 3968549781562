import { useEffect, useState } from 'react'
import _ from 'lodash'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { toast } from 'react-toastify'

//common component
import Header from '../../components/header/header-mobile'
import HeaderDesktop from '../../components/header/header-desktop'
import Navbar from '../../components/navbar/navbar-one'
import WalletConnect from '../ConnectWallet'
import web3Services from '../../services/web3.services'

//asset
import { Bab } from '../../assets/images'

//component
import ProfileLayout from './component/profile.layout'
import Referrals from './component/referrals'
import LeaderBoard from './component/leaderboard'
import Metrisc from './component/metrics'
import LeaderBoardDesktop from './component/leaderboard.desktop'
import ProfileDetails from './component/profile.details'
import Setting from './component/setting'
import useWindowDimensions from '../../components/common/window-dimension'
import WalletSummary from './component/wallet.summary'
import WalletHistoryModal from './component/wallet.history.modal'
import Deposit from './component/deposit'
import Withdraw from './component/withdraw'

//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  getReferralsRanking,
  getTradeRanking,
  getUserRanking,
  getWalletBalance,
  login,
  metric,
} from '../../redux/reducer/profileReducer'

//hook
import useUtils from '../../hook/useUtils'
import ShareCard from '../../components/modal/share.card.box'

export default function Profile() {
  const dispatch = useDispatch()
  const { convertSha256 } = useUtils()
  const [tabBar, setTabBar] = useState('Referral Program')
  const { width } = useWindowDimensions()
  const [responsive, setResponsive] = useState('mobile')
  const {
    wallet_history_modal,
    withdraw_modal,
    deposit_modal,
    leaderBoard_trade_master,
    leaderboard_referral,
    passphrase,
  } = useSelector((state) => state.profile)

  const { share_modal } = useSelector((state) => state.trade)
  const { walletConnect, token, accounts, selectedTrading } = useSelector(
    (state) => state.connector,
  )

  useEffect(() => {
    dispatch(getReferralsRanking())
    dispatch(getTradeRanking(selectedTrading))
    if (web3Services.isSignRequire() && accounts && token) {
      let payload = {
        address: accounts,
        hash: token,
      }
      dispatch(login({ payload }))
        .unwrap()
        .then(() => {
          dispatch(
            getWalletBalance({
              payload: { address: accounts },
              data: convertSha256(token, passphrase),
              network: selectedTrading,
            }),
          )
          dispatch(
            getUserRanking({
              payload: { address: accounts },
              data: convertSha256(token, passphrase),
              network: selectedTrading,
            }),
          )
          dispatch(
            metric({
              payload: { address: accounts },
              data: convertSha256(token, passphrase),
              network: selectedTrading,
            }),
          )
        })
        .catch((ex) => {
          const errors = ex.response.data.errors
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item], {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'dark',
              })
            })
          } else {
            toast.error(JSON.stringify(ex.message), {
              position: 'top-left',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'dark',
            })
          }
        })
    }
  }, [accounts])

  useEffect(() => {
    if (width >= '992') {
      setResponsive('desktop')
    } else {
      setResponsive('mobile')
    }
  }, [width])

  return (
    <MDBContainer className="profile-container" breakpoint="fluid">
      <HeaderDesktop />
      <div className="line"></div>
      <Header />
      <Navbar />
      <ProfileLayout />
      {responsive == 'mobile' ? (
        <MDBContainer className="profile-content">
          <MDBRow className="profile-picture-container">
            <img src={Bab} width="80" />
          </MDBRow>
          <WalletSummary responsive={responsive} />
          <Referrals />
          {web3Services.isSignRequire() && (
            <LeaderBoard
              tabBar={tabBar}
              setTabBar={setTabBar}
              data={tabBar == 'Referral Program' ? leaderboard_referral : leaderBoard_trade_master}
            />
          )}
          <Setting />
        </MDBContainer>
      ) : (
        <MDBRow className="profile-desktop-version">
          <MDBCol className="col-1 sidebar-container"></MDBCol>
          <MDBCol className="col-11 content-section">
            {web3Services.isSignRequire() && <ProfileDetails />}
            <MDBContainer>
              <WalletSummary responsive={responsive} />
              <Metrisc />
              <Referrals />
              <LeaderBoardDesktop title="Master Traders" data={leaderBoard_trade_master} />
              <LeaderBoardDesktop title="Referral Program" data={leaderboard_referral} />
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      )}
      {wallet_history_modal && <WalletHistoryModal show={wallet_history_modal} />}
      {deposit_modal && <Deposit show={deposit_modal} />}
      {withdraw_modal && <Withdraw show={withdraw_modal} />}
      {walletConnect && <WalletConnect />}
      {share_modal && <ShareCard show={share_modal} />}
    </MDBContainer>
  )
}
