import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DeriTradeAnnouncement1, DeriTradeAnnouncement10, DeriTradeAnnouncement11, DeriTradeAnnouncement12, DeriTradeAnnouncement13, DeriTradeAnnouncement14, DeriTradeAnnouncement15, DeriTradeAnnouncement16, DeriTradeAnnouncement17, DeriTradeAnnouncement17_1, DeriTradeAnnouncement18, DeriTradeAnnouncement19, DeriTradeAnnouncement2, DeriTradeAnnouncement20, DeriTradeAnnouncement21, DeriTradeAnnouncement22, DeriTradeAnnouncement23, DeriTradeAnnouncement24, DeriTradeAnnouncement25, DeriTradeAnnouncement26, DeriTradeAnnouncement26_1, DeriTradeAnnouncement27, DeriTradeAnnouncement28, DeriTradeAnnouncement29, DeriTradeAnnouncement3,DeriTradeAnnouncement30,DeriTradeAnnouncement31,DeriTradeAnnouncement4,DeriTradeAnnouncement5, DeriTradeAnnouncement6, DeriTradeAnnouncement7, DeriTradeAnnouncement8, DeriTradeAnnouncement9 } from '../../assets/images'
import Video2 from "../../assets/images/video/referal.mp4"
import apiServices from "../../services/api.services";

export const getHomeInfo = createAsyncThunk(
  'get/homeInfo',
  async ({ data, network }, thunkAPI) => {
    try {
      const respond = await apiServices.homeInfo( data, network)
      return respond
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const initialState = {
    weeklyReport: [
        {
            label: 'S',
            data: '10',
        },
        {
            label: 'M',
            data: '10',
        },
        {
            label: 'T',
            data: '10',
        },
        {
            label: 'W',
            data: '10',
        },
        {
            label: 'T',
            data: '10',
        },
        {
            label: 'F',
            data: '10',
        },
        {
            label: 'S',
            data: '10',
        },
    ],
    weeklyProfit: "0.00",
    high: "--",
    low: "--",
    walletBalance: "--",
    returnEquity: "--",
    modalAnnouncement: false,
    annoucementList: [
      {
        id: 31,
        image: DeriTradeAnnouncement31,
        title:
        <label>DeriTrade X BNBCHAIN Public Testnet Competition!</label>,
        desc: (
          <div>
            <span>
            DeriTrade X BNBCHAIN Public Testnet Competition!
            <br />
            <br />
            Trade now on Deri Trade to win over 2500 BUSD Prize Pool!
            <br />
            <br />
            Begin on March 24, 2023, and end on April 24, 2023.
            <br />
            <br />
            Learn more: <a target="_blank" href="https://medium.com/@deritrade/deri-trade-x-bnb-chain-public-testnet-competition-d5fc82a01a5" style={{ outline: 'none',wordBreak: "break-word" }}>
            https://medium.com/@deritrade/deri-trade-x-bnb-chain-public-testnet-competition-d5fc82a01a5
              </a>
            <br />
            <br />
            #BNBChain #RealYield #DDEX
            </span>
          </div>
        ),
        people: 'see more',
        status: '3/23/2023',
      },
      {
        id: 30,
        image: DeriTradeAnnouncement30,
        title:
        <label>DeriTrade PUBLIC TESTNET is now!<br /><br />BNBCHAIN & DeriTrade fam, you are invited to participate in the event!</label>,
        desc: (
          <div>
            <span>
            DeriTrade PUBLIC TESTNET is now!
            <br />
            <br />
            BNBCHAIN & DeriTrade fam, you are invited to participate in the event!
            <br />
            <br />
            Let's trade on DeriTrade start on March 1, 2023!
            <br />
            <br />
            #airdrop #incentivized
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/28/2023',
      },
      {
        id: 29,
        image: DeriTradeAnnouncement29,
        title:
        "We're excited to share that DeriTrade PRIVATE TESTNET is launching on February 16, 2023 on BNBChain !",
        desc: (
          <div>
            <span>
            We're excited to share that DeriTrade PRIVATE TESTNET is launching on February 16, 2023 on BNBChain !
            <br />
            <br />
            DeriTrade Alpha Pass Holder, be ready for it!
            <br />
            <br />
            The PUBLIC TESTNET will start two weeks later.
            <br />
            <br />
            Stay tuned for upcoming announcements.
            <br />
            <br />
            #airdrop #incentivized
            </span>
            <br />
            <br />
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 28,
        image: DeriTradeAnnouncement28,
        title:
        "We're thrilled to announce DeriTrade is now on Opensea",
        desc: (
          <div>
            <span>
            We're thrilled to announce DeriTrade is now on Opensea
            </span>
            <br />
            <br />
            <span>
            <a target="_blank" href="https://opensea.io/collection/deri-trade-alpha-pass" style={{ outline: 'none',wordBreak: "break-word" }}>
            📍 https://opensea.io/collection/deri-trade-alpha-pass
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 27,
        image: DeriTradeAnnouncement27,
        title:
        "We're delighted to announce our new partnership with Element_Market!",
        desc: (
          <div>
            <span>
            We're delighted to announce our new partnership with Element_Market!
            <br />
            <br />
            DeriTrade ALPHA PASS is now verified on Element_Market.
            </span>
            <br />
            <br />
            <span>
            Official Link :
            <br />
            <a target="_blank" href="https://element.market/collections/deri-trade-alpha-pass" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://element.market/collections/deri-trade-alpha-pass
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 26,
        image: DeriTradeAnnouncement26,
        title:
        "DeriTrade First AMA with SpaceIDProtocol, over 4000 audience members with us, thank you for SpaceIDProtocol, Mint Soon and everyone who participated in AMA.",
        desc: (
          <div>
            <span>
            DeriTrade First AMA with SpaceIDProtocol, over 4000 audience members with us, thank you for SpaceIDProtocol, Mint Soon and everyone who participated in AMA.
            </span>
            <br />
            <br />
            <img src={DeriTradeAnnouncement26_1} width="100%"/>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 25,
        image: DeriTradeAnnouncement25,
        title:
        "We're thrilled to announce DeriTrade is now verified on NFTradeOfficial !",
        desc: (
          <div>
            <span>
            We're thrilled to announce DeriTrade is now verified on NFTradeOfficial !
            <br />
            <br />
            DeriTrade ALPHA PASS is now verified on tofuNFT .
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://nftrade.com/collection/deri-trade-alpha-pass" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://nftrade.com/collection/deri-trade-alpha-pass
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 24,
        image: DeriTradeAnnouncement24,
        title:
        "We're delighted to announce our new partnership with tofuNFT !",
        desc: (
          <div>
            <span>
            We're delighted to announce our new partnership with tofuNFT !
            <br />
            <br />
            DeriTrade ALPHA PASS is now verified on tofuNFT .
            </span>
            <br />
            <br />
            <span>
            Official Link :
              <a target="_blank" href="https://tofunft.com/collection/deri-trade-alpha-pass/items" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://tofunft.com/collection/deri-trade-alpha-pass/items
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 23,
        image: DeriTradeAnnouncement23,
        title:
        "[DO NOT MISS] DERI TRADE REFERRAL CONTEST & CAMPAIGNS!.",
        desc: (
          <div>
            <span>
            [DO NOT MISS] DERI TRADE REFERRAL CONTEST & CAMPAIGNS!
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://medium.com/@deritrade/do-not-miss-deri-trade-referral-contest-campaigns-6c293ef8b688" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://medium.com/@deritrade/do-not-miss-deri-trade-referral-contest-campaigns-6c293ef8b688
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 22,
        image: DeriTradeAnnouncement22,
        title:
        "MILESTONE, THANK YOU.",
        desc: (
          <div>
            <span>
            MILESTONE, THANK YOU.
            <br />
            <br />
            44 days, when we achieved 15,543 unique wallet addresses to join our campaigns, is our milestone.
            <br />
            <br />
            THANK YOU FOR JOINING US & BE PART OF DeriTrade & BNBCHAIN FAMS!
            </span>
            <br />
            <br />
            <span>
              Check your address on here: <br />
              <a target="_blank" href="https://docs.google.com/spreadsheets/d/16_-aYlvilh_E_6NftnuQGeg3e5hwrzw6ih2vFQpjop4/edit?usp=sharing" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://docs.google.com/spreadsheets/d/16_-aYlvilh_E_6NftnuQGeg3e5hwrzw6ih2vFQpjop4/edit?usp=sharing
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 21,
        image: DeriTradeAnnouncement21,
        title:
        "📌 IF YOU KNOW, YOU KNOW",
        desc: (
          <div>
            <span>
            📌 IF YOU KNOW, YOU KNOW
            <br />
            <br />
            </span>
            <span>
              <a target="_blank" href="https://medium.com/@deritrade/if-you-know-you-know-e3dfd9c8c83c" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://medium.com/@deritrade/if-you-know-you-know-e3dfd9c8c83c
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 20,
        // image: DeriTradeAnnouncement18,
        iframe: <iframe src="https://www.youtube.com/embed/XhInkZzfKzI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        image: DeriTradeAnnouncement20,
        videoClass: "special",
        title:
        "ANNOUNCEMENT OF THE DERI TRADE ALPHA PASS",
        desc: (
          <div>
            <span>
            ANNOUNCEMENT OF THE DERI TRADE ALPHA PASS
            <br />
            <br />
            </span>
            <span>
              <a target="_blank" href="https://medium.com/@deritrade/announcement-of-the-deri-trade-alpha-pass-ce0e7073f4f3" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://medium.com/@deritrade/announcement-of-the-deri-trade-alpha-pass-ce0e7073f4f3
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 19,
        image: DeriTradeAnnouncement19,
        title:
        "Deri Trade Q&A",
        desc: (
          <div>
            <span>
            Deri Trade Q&A
            <br />
            <br />
            </span>
            <span>
              <a target="_blank" href="https://medium.com/@deritrade/deri-trade-q-a-dd380024e26c" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://medium.com/@deritrade/deri-trade-q-a-dd380024e26c
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 18,
        image: DeriTradeAnnouncement18,
        title:
        "ROADMAP",
        desc: (
          <div>
            <span>
            ROADMAP
            <br />
            <br />
            We would like to share our roadmap, and please stay with us and grow with us together.
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 17,
        image: DeriTradeAnnouncement17,
        video: <iframe src="https://www.youtube-nocookie.com/embed/mAa0Hu9wu-E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        title:
        "A platform for EVERYONE #PoweredByPyth",
        desc: (
          <div>
            <span>
            A platform for EVERYONE #PoweredByPyth
            <br />
            <br />
            Deri.Trade is a decentralized derivatives protocol built on BNBCHAIN soon to be available on mainnet 
            <br />
            <br />
            Thanks to Pyth Network data, anyone will be able to get exposure to crypto, FX, metals and so much more!
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 16,
        image: DeriTradeAnnouncement16,
        title:
        "We're delighted to announce our new partnership with SpaceIDProtocol.",
        desc: (
          <div>
            <span>
            We're delighted to announce our new partnership with SpaceIDProtocol.
            <br />
            <br />
            SPACE ID provides a universal name service network and DeriTrade has already supported .bnb domain.
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 15,
        // image: DeriTradeAnnouncement9,
        image: DeriTradeAnnouncement15,
        videoCard: Video2,
        videoClass: "special",
        title:
        "REFERRAL SYSTEM IS LIVE",
        desc: (
          <div>
            <span>
            REFERRAL SYSTEM IS LIVE
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://twitter.com/DeriTrade/status/1610270987773448194" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://twitter.com/DeriTrade/status/1610270987773448194
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 14,
        image: DeriTradeAnnouncement14,
        title:
        "We're thrilled to announce DeriTrade is now on Crew3xyz !",
        desc: (
          <div>
            <span>
            We're thrilled to announce DeriTrade is now on Crew3xyz !
            <br />
            <br />
            DeriTrade Fam, let's do the quests!
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://deritrade.crew3.xyz" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://deritrade.crew3.xyz
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 13,
        image: DeriTradeAnnouncement13,
        title:
        "We're thrilled to announce DeriTrade is now on DoraHacks",
        desc: (
          <div>
            <span>
            We're thrilled to announce DeriTrade is now on DoraHacks
            <br />
            <br />
            DeriTrade Fam, support & follow us now!
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://dorahacks.io/buidl/4020" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://dorahacks.io/buidl/4020
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 12,
        // image: DeriTradeAnnouncement9,
        image: DeriTradeAnnouncement12,
        iframe: <iframe src="https://www.youtube-nocookie.com/embed/7uNPnBb__MY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
        videoClass: "special",
        title:
        "Learn more about us from the official video and what we build on BNBCHAIN !",
        desc: (
          <div>
            <span>
            Learn more about us from the official video and what we build on BNBCHAIN !
            <br />
            </span>
            <br />
            <span>
              <a target="_blank" href="https://www.youtube.com/watch?v=7uNPnBb__MY" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://www.youtube.com/watch?v=7uNPnBb__MY
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 11,
        image: DeriTradeAnnouncement11,
        title:
        "We're thrilled to announce the partnership with TrantorDAO",
        desc: (
          <div>
            <span>
            We're thrilled to announce the partnership with TrantorDAO
            <br />
            <br />
            DeriTrade is now verified on Port3Network
            </span>
            <br />
            <br />
            <span>
              📍 
              <a target="_blank" href="http://trantor.xyz/community/deritrade" style={{ outline: 'none',wordBreak: "break-word" }}>
              http://trantor.xyz/community/deritrade
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 10,
        image: DeriTradeAnnouncement10,
        title:
        "We're thrilled to announce the partnership with TaskOnxyz !",
        desc: (
          <div>
            <span>
            We're thrilled to announce the partnership with TaskOnxyz !
            <br />
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 9,
        image: DeriTradeAnnouncement9,
        title:
        "We're thrilled to announce the partnership with Port3Network",
        desc: (
          <div>
            <span>
            We're thrilled to announce the partnership with Port3Network
            <br />
            <br />
            DeriTrade is now verified on Port3Network
            </span>
            <br />
            <br />
            <span>
              📍 
              <a target="_blank" href="http://soquest.xyz/space/DeriTrade" style={{ outline: 'none',wordBreak: "break-word" }}>
              http://soquest.xyz/space/DeriTrade
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 8,
        image: DeriTradeAnnouncement8,
        title:
        "Join DeriTrade discord and get verified with Guildxyz !",
        desc: (
          <div>
            <span>
            Join DeriTrade discord and get verified with Guildxyz !
            <br />
            </span>
          </div>
        ),
        people: 'see more',
        status: '2/6/2023',
      },
      {
        id: 7,
        image: DeriTradeAnnouncement7,
        title:
        "BNB Chain Innovations Meetup in Seoul",
        desc: (
          <div>
            <span>
            BNB Chain Innovations Meetup in Seoul
            <br/>
            <br/>
            It's a great honour to be here with the BNBCHAIN community! 
            <br />
            <br />
            Thanks to BNBCHAIN, CertiK, and DWFLABS for this amazing event!
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://twitter.com/BNBCHAIN/status/1604043985714044930" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://twitter.com/BNBCHAIN/status/1604043985714044930
              </a>
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://twitter.com/BNBCHAIN/status/1608764958715559944" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://twitter.com/BNBCHAIN/status/1608764958715559944
              </a>
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://www.instagram.com/reel/CmXYIRVNOkv" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://www.instagram.com/reel/CmXYIRVNOkv
              </a>
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://m.blog.naver.com/bnbchain/222959885421" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://m.blog.naver.com/bnbchain/222959885421
              </a>
            </span>
            <br />
            <br />
            <span>
              <a target="_blank" href="https://www.bnbchain.org/en/blog/seoul-innovation-meetup-bnb-chain/" style={{ outline: 'none',wordBreak: "break-word" }}>
              https://www.bnbchain.org/en/blog/seoul-innovation-meetup-bnb-chain/
              </a>
            </span>
            <br />
          </div>
        ),
        people: 'see more',
        status: '12/16/2022',
      },
      {
        id: 6,
        image: DeriTradeAnnouncement6,
        title: "We're thrilled to announce our space on Galxe!",
        desc: (
          <div>
            <span>
              We're thrilled to announce our space on Galxe!
              <br />
              <br />
              Find us on : <a
                target="_blank"
                href="https://galxe.com/deritrade"
                style={{ outline: 'none' }}>
                https://galxe.com/deritrade
              </a>
            </span>
          </div>
        ),
        people: 'see more',
        status: '12/10/2022',
      },
    {
      id: 5,
      image: DeriTradeAnnouncement5,
      title: 'WELCOME TO @DeriTrade ! Join our Raffle & Giveaways NOW! ',
      desc: (
        <div>
          <span>
            WELCOME TO @DeriTrade !
            <br />
            <br />
            Join our Raffle & Giveaways NOW!
          </span>
          <br />
          <br />
          <span style={{ wordBreak: 'break-all' }}>
            <a
              target="_blank"
              href="https://app.quest3.xyz/quest/712457015151796272"
              style={{ outline: 'none' }}>
              https://app.quest3.xyz/quest/712457015151796272
            </a>
          </span>
          <br />
        </div>
      ),
      people: 'see more',
      status: '12/3/2022',
    },

    {
      id: 4,
      image: DeriTradeAnnouncement4,
      title: 'WELCOME TO @DeriTrade ! CALLING ALL #BAB HOLDERS!',
      desc: (
        <div>
          <span>
            WELCOME TO @DeriTrade !
            <br />
            <br />
            CALLING ALL #BAB HOLDERS!
            <br />
            <br />
            Join our Raffle & Giveaways NOW!
          </span>
          <br />
          <br />
          <span style={{ wordBreak: 'break-all' }}>
            <a
              target="_blank"
              href="https://app.quest3.xyz/quest/712458485423985088"
              style={{ outline: 'none' }}>
              https://app.quest3.xyz/quest/712458485423985088
            </a>
          </span>
          <br />
        </div>
      ),
      people: 'see more',
      status: '12/3/2022',
    },
    {
      id: 3,
      image: DeriTradeAnnouncement3,
      title:
        "We're thrilled to announce the partnership with Quest3. Deri Trade is now verified on Quest3",
      desc: (
        <div>
          <span>
            We're thrilled to announce the partnership with Quest3. <br />
            Deri Trade is now verified on Quest3
          </span>
          <br />
          <br />
          <span>
            Find us on :{' '}
            <a target="_blank" href="https://app.quest3.xyz/deritrade" style={{ outline: 'none' }}>
              app.quest3.xyz/deritrade
            </a>
          </span>
          <br />
        </div>
      ),
      people: 'see more',
      status: '12/1/2022',
    },
    {
      id: 2,
      image: DeriTradeAnnouncement2,
      title:
        " We're thrilled to announce the partnership with CyberConnect. Deri Trade is now verified on link3to",
      desc: (
        <div>
          <span>
            We're thrilled to announce the partnership with CyberConnect.
            <br />
            Deri Trade is now verified on link3to
          </span>
          <br />
          <a target="_blank" href="https://link3.to/deritrade" style={{ outline: 'none' }}>
            📍 link3.to/deritrade
          </a>
          <br />
          <br />
          <span>🌏 Let embrace Web3 together on BNBChain</span>
        </div>
      ),
      people: 'see more',
      status: '12/1/2022',
    },

    {
      id: 1,
      image: DeriTradeAnnouncement1,
      title: 'Deri Trade is joining the BNB Chain family, stay tuned for more excitement!',
      desc: 'Deri Trade is joining the BNB Chain family, stay tuned for more excitement!',
      people: 'see more',
      status: '11/24/2022',
    },
    ],
    selectedAnnoucement: {

    },
    marketInfoLoader: false,
    marketInfo:{}
}

const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers:{
        setModalAnnouncement: (state,action) => {
            return{
                ...state,
                modalAnnouncement: action.payload.status,
                selectedAnnoucement : action.payload.data
            }
        },
        setWalletBalance: (state,action) => {
            return{
                ...state,
                walletBalance: action.payload
            }
        }
    },
    extraReducers:{
      [getHomeInfo.pending]:(state,action) => {
        state.marketInfoLoader = true
      },
      [getHomeInfo.fulfilled]:(state,action) => {
        state.marketInfoLoader = false
        state.marketInfo = action.payload
      },
      [getHomeInfo.rejected]:(state,action) => {
        state.marketInfoLoader = false
      }
    }
})

export const {
    setWalletBalance,
    setModalAnnouncement,
} = homeSlice.actions
const { reducer } = homeSlice
export default reducer;