import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import _ from 'lodash'

// image
import { TrendingRadio } from '../../assets/images'

//common component
import Header from '../../components/header/header-mobile'
import HeaderDesktop from '../../components/header/header-desktop'
import Navbar from '../../components/navbar/navbar-one'
import useWindowDimensions from '../../components/common/window-dimension'

// component
import Banner from './component/banner'
import TrendingTrade from './component/trending.trade'
import Market from './component/markets'
import Annoucement from './component/announcement'
import WalletConnectUI from './../ConnectWallet'

//redux
import { useDispatch, useSelector } from 'react-redux'
import Partner from './component/partner'
import { setSelectedTabPanel } from '../../redux/reducer/tradeReducer'
import useUtils from '../../hook/useUtils'
import { getHomeInfo } from '../../redux/reducer/homeReducer'
import { login } from '../../redux/reducer/profileReducer'
import web3Services from '../../services/web3.services'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/modal/loader'

export default function Home({ isLoaded }) {
  const { walletConnect } = useSelector((state) => state.connector)
  const { t } = useTranslation()
  const token = localStorage.getItem('token')
  const passphrase = localStorage.getItem('passphrase')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [responsive, setResponsive] = useState('mobile')
  // const { passphrase } = useSelector((state) => state.profile)
  const { convertSha256 } = useUtils()

  const { accounts, selectedTrading } = useSelector((state) => state.connector)

  useEffect(() => {
    if (width >= '992') {
      setResponsive('desktop')
    } else {
      setResponsive('mobile')
    }
  }, [width])

  useEffect(() => {
    dispatch(
      getHomeInfo({
        data: convertSha256(token, passphrase),
        network: selectedTrading,
      }),
    )
  }, [])

  useEffect(() => {
    if (web3Services.isSignRequire() && accounts && token && passphrase) {
      if (web3Services.isSignRequire() && accounts && token) {
        let payload = {
          address: accounts,
          hash: token,
        }
        dispatch(login({ payload }))
          .unwrap()
          .then(() => {
            // dispatch(
            //   getWalletBalance({
            //     payload: { address: accounts },
            //     data: convertSha256(token, passphrase),
            //     network: selectedTrading,
            //   }),
            // )
            // dispatch(
            //   getAllOrder({
            //     payload: { address: accounts, status: 'all' },
            //     data: convertSha256(token, passphrase),
            //     network: selectedTrading,
            //   }),
            // )
            // dispatch(
            //   getPosition({
            //     payload: { address: accounts },
            //     data: convertSha256(token, passphrase),
            //     network: selectedTrading,
            //   }),
            // )
          })
          .catch((ex) => {
            const errors = ex.response.data.errors
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item], {
                  position: 'top-left',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: 'dark',
                })
              })
            } else {
              toast.error(JSON.stringify(ex.message), {
                position: 'top-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'dark',
              })
            }
          })
      }
    }
  }, [accounts, token, passphrase])

  return (
    <MDBContainer className="home-container" breakpoint="fluid">
      <Navbar />
      {responsive == 'mobile' ? (
        <div className="mobile-version">
          <Header />
          <Banner />
          <TrendingTrade />
          <Market />
          <Annoucement />
          <Partner responsive={responsive} />
        </div>
      ) : (
        <>
          <HeaderDesktop />
          <div className="line"></div>
          <MDBRow className="desktop-version">
            <MDBCol className="col-2 sidebar-container">
              <MDBCol className="trending-image d-flex">
                <div className="line"></div>
                <div>
                  <img src={TrendingRadio} width="25" alt="" height="auto" />{' '}
                </div>
                <label>{t(`Overview`)}</label>
              </MDBCol>
              <MDBCol className="trending-image d-flex">
                <div className="line"></div>
                <img src={TrendingRadio} width="25" alt="" height="auto" />{' '}
                <label
                  onClick={() => {
                    navigate('/trade')
                    dispatch(setSelectedTabPanel('position'))
                    // const MySwal = withReactContent(Swal)
                    // MySwal.fire({
                    //   icon: 'warning',
                    //   title: 'Coming Soon',
                    //   //  text: 'Coming Soon',
                    //   customClass: {
                    //     title: 'custom-sweet-alert-title',
                    //   },
                    // }).then((result) => {})
                  }}>
                  {t(`Position`)}
                </label>
              </MDBCol>
              <MDBCol className="trending-image d-flex">
                <div className="line"></div>
                <img src={TrendingRadio} width="25" alt="" height="auto" />{' '}
                <label
                  onClick={() => {
                    navigate('/trade')
                    dispatch(setSelectedTabPanel('order'))
                    // const MySwal = withReactContent(Swal)
                    // MySwal.fire({
                    //   icon: 'warning',
                    //   title: 'Coming Soon',
                    //   //  text: 'Coming Soon',
                    //   customClass: {
                    //     title: 'custom-sweet-alert-title',
                    //   },
                    // }).then((result) => {})
                  }}>
                  {t(`Order`)}
                </label>
              </MDBCol>
            </MDBCol>
            <MDBCol className="col-10 content-section">
              <MDBContainer>
                <Banner responsive={responsive} />
                <Annoucement />
                <TrendingTrade />
                {/* <section name="market">
                  <div id="market"> */}
                <Market />
                {/* <HeaderMarket />
                    <MarketListing />
                    <BannerDesktop /> */}
                {/* </div>
                </section> */}
                <Partner responsive={responsive} />
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </>
      )}
      {walletConnect && <WalletConnectUI />}
    </MDBContainer>
  )
}
