import { useEffect, useState } from 'react'
//lib
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTooltip } from 'mdb-react-ui-kit'
import { CopyToClipboard } from 'react-copy-to-clipboard'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setRecoverKeySetting, setWalletConnect } from '../../../redux/reducer/connectorReducer'
import {
  setButtonIsChecking,
  setModalRebateHistory,
  getReferrals,
} from '../../../redux/reducer/profileReducer'

//service
import web3Services from '../../../services/web3.services'
import { Icon } from '@iconify/react'

//assets
import LoaderSvg from '../../../assets/images/svg/dual-ball-loader.svg'

//component
import ButtonDisconnect from '../../../components/element/btnDisconnect'

//hook
import useReferralCode from '../../../hook/useReferralCode'
import { useTranslation } from 'react-i18next'

export default function AffiliatesReferrals({ responsive }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { generateReferralCode, codeOwners, tier, renderText } = useReferralCode()
  const [copied, setCopied] = useState(false)
  const [valueReferralCode, setValueReferralCode] = useState('')
  const {
    discountShare,
    total_trader_referral,
    total_rebate,
    total_trading_volume,
    referral_link,
    referral_code,
    tier_level,
    loader,
  } = useSelector((state) => state.profile.referral_detail.affiliates)
  const { button_is_checking } = useSelector((state) => state.profile)
  const { accounts, openRecoverKeySetting } = useSelector((state) => state.connector)

  useEffect(() => {
    let text = setInterval(() => {
      setCopied(false)
    }, 1000)
    return () => {
      clearInterval(text)
    }
  }, [copied])
  useEffect(() => {
    if (accounts) {
      dispatch(getReferrals({ address: accounts }))
    }

    tier()
  }, [])

  if (loader) {
    return (
      <div className="loader-container">
        <img src={LoaderSvg} />
      </div>
    )
  }
  return (
    <div>
      {referral_code ? (
        <div>
          <MDBRow className="referral-row">
            <MDBCol className="title">
              <label>{t('Referral Rate')}</label>
            </MDBCol>
            <MDBCol className="value col-5">
              <label>
                {discountShare === Infinity
                  ? '-'
                  : discountShare === undefined
                  ? '-'
                  : discountShare && web3Services.isSignRequire()
                  ? discountShare
                  : '-'}
                %
              </label>
            </MDBCol>
          </MDBRow>
          {responsive == 'mobile' && web3Services.isSignRequire() && referral_code && (
            <div>
              <div className="deco-referral">
                <div className="line"></div>
              </div>
              <MDBRow className="referral-row">
                <MDBCol className="title ">
                  <MDBTooltip
                    tag="span"
                    wrapperProps={{ href: '#' }}
                    title={t('Amount of traders you referred')}>
                    <label>{t('Total Traders Referred')}</label>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol className="value col-5">
                  <label>{total_trader_referral}</label>
                </MDBCol>
              </MDBRow>
              <div className="deco-referral">
                <div className="line"></div>
              </div>
              <MDBRow className="referral-row">
                <MDBCol className="title ">
                  <MDBTooltip
                    tag="span"
                    wrapperProps={{ href: '#' }}
                    title={t('Volume traded by your referred traders.')}>
                    <label>{t('Total Trading Volume')}</label>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol className="value col-5">
                  <label>$ {total_trading_volume}</label>
                </MDBCol>
              </MDBRow>
              <div className="deco-referral">
                <div className="line"></div>
              </div>
              <MDBRow className="referral-row">
                <MDBCol className="title ">
                  <MDBTooltip
                    tag="span"
                    wrapperProps={{ href: '#' }}
                    title={t('Rebates earned by this account as an affiliate')}>
                    <label>{t('Total Rebates')}</label>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol className="value col-5">
                  <label>{total_rebate}</label>
                </MDBCol>
              </MDBRow>
              <div className="deco-referral">
                <div className="line"></div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <MDBRow className="referral-row">
            <MDBCol className="title ">
              <label>{referral_code ? `(Tier ${tier_level})` : t('Referral Rate')}</label>
            </MDBCol>
            <MDBCol className="value col-5">
              <label>
                {discountShare === Infinity
                  ? '-'
                  : discountShare === undefined
                  ? '-'
                  : discountShare && web3Services.isSignRequire() && referral_code
                  ? discountShare
                  : '-'}{' '}
                %
              </label>
            </MDBCol>
          </MDBRow>
          <div className="deco-referral">
            <div className="line"></div>
          </div>
        </div>
      )}
      {
        <MDBRow className="referral-row">
          {web3Services.isSignRequire() && referral_code && (
            <MDBCol className="col-8 col-md-7 title">
              <label className="w-100">{t('My Referral Code')}</label>
              <label className="referral-link">
                &nbsp;&nbsp;&nbsp;{referral_link ? referral_link.slice(0, 30) : ''}
              </label>
            </MDBCol>
          )}
          {web3Services.isSignRequire() && referral_code && (
            <CopyToClipboard
              onCopy={() => setCopied(true)}
              text={referral_link ? referral_link : ''}>
              <MDBCol className="col-4 col-md-5 button-share">
                <button className={copied ? 'active' : ''}>
                  {copied ? (
                    <span>{t('copied!')}</span>
                  ) : (
                    <Icon icon="tabler:copy" color="#7174eb" width="40" height="35" />
                  )}
                </button>
              </MDBCol>
            </CopyToClipboard>
          )}
        </MDBRow>
      }
      {web3Services.isSignRequire() && referral_code && (
        <MDBRow className="referral-row noted">
          <label>
            {t('By copying the referral code, you agree to the')}
            <span>{t('Referral Term and Conditions.')}</span>
          </label>
        </MDBRow>
      )}
      <MDBRow className="m-0 justify-content-center">
        {web3Services.isSignRequire() && referral_code ? (
          ''
        ) : (
          <MDBCol className="col-12 col-md-7 referral-row">
            <MDBRow>
              <label className="title-bold">{t('Generate Referral Code')}</label>
            </MDBRow>
            <span className="sub-title">{t('Create one now and start earning rebates!')}</span>
          </MDBCol>
        )}
        {web3Services.isSignRequire() ? (
          <MDBCol className="col-12 col-md-5 p-0">
            {referral_code ? (
              ''
            ) : (
              <MDBContainer className="input-referral-container">
                <MDBRow className="input-referral enter-code d-flex align-items-center justify-content-center">
                  <input
                    placeholder="Enter code"
                    value={valueReferralCode}
                    onChange={(e) => {
                      setValueReferralCode(e.target.value)
                      if (e.target.value == '') {
                        dispatch(setButtonIsChecking(false))
                      } else {
                        setTimeout(() => {
                          codeOwners(e.target.value)
                        }, 1000)
                      }
                    }}
                  />
                </MDBRow>
              </MDBContainer>
            )}
            <MDBRow className="referrals-btn">
              {referral_code ? (
                <>
                  {responsive == 'mobile' && (
                    <MDBBtn
                      className="history-btn"
                      onClick={() => {
                        dispatch(setModalRebateHistory({ status: true }))
                      }}>
                      {t('View History')}
                    </MDBBtn>
                  )}
                </>
              ) : (
                <MDBBtn
                  className={button_is_checking == 'creating' ? 'creating-btn' : 'submit-btn '}
                  disabled={button_is_checking == 'code_empty' ? false : true}
                  onClick={() => {
                    if (button_is_checking == 'code_empty') {
                      generateReferralCode(valueReferralCode)
                    }
                  }}>
                  {renderText(button_is_checking, valueReferralCode, 'affiliates', t)}
                </MDBBtn>
              )}
            </MDBRow>
          </MDBCol>
        ) : (
          <MDBCol className="col-12 col-md-5 p-0">
            {accounts ? (
              <MDBRow className="referrals-btn">
                <MDBBtn
                  type="button"
                  className="connect-btn"
                  onClick={() => dispatch(setRecoverKeySetting(!openRecoverKeySetting))}>
                  {t('Onboarding')}
                </MDBBtn>
                {openRecoverKeySetting && (
                  <MDBContainer className="setting-container recover-key">
                    <label>
                      {t('Welcome back!')}
                      <br /> <span>{t('Sign in to verify ownership')}</span>
                    </label>
                    <button
                      type="button"
                      className="recover-btn"
                      onClick={() => {
                        dispatch(setWalletConnect(true))
                      }}>
                      {t('Signature Here')}
                    </button>
                    <ButtonDisconnect />
                  </MDBContainer>
                )}
              </MDBRow>
            ) : (
              <MDBRow className="referrals-btn">
                <MDBBtn className="connect-btn" onClick={() => dispatch(setWalletConnect(true))}>
                  {t('Connect Wallet')}
                </MDBBtn>
              </MDBRow>
            )}
          </MDBCol>
        )}
        {responsive == 'desktop' && web3Services.isSignRequire() && referral_code && (
          <MDBRow className="referral-row">
            <MDBCol>
              <MDBRow className="referral-row">
                <MDBTooltip
                  tag="span"
                  wrapperProps={{ href: '#' }}
                  title={t('Amount of traders you referred')}>
                  <label className="title-bold">{t('Total Traders Referred')}</label>
                </MDBTooltip>
              </MDBRow>
              <MDBRow>
                <label className="sub-title">{total_trader_referral}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol>
              <MDBRow className="referral-row">
                <MDBTooltip
                  tag="span"
                  wrapperProps={{ href: '#' }}
                  title={t('Volume traded by your referred traders.')}>
                  <label className="title-bold">{t('Total Trading Volume')}</label>
                </MDBTooltip>
              </MDBRow>
              <MDBRow>
                <label className="sub-title">$ {total_trading_volume}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol>
              <MDBRow className="referral-row">
                <MDBTooltip
                  tag="span"
                  wrapperProps={{ href: '#' }}
                  title={t('Rebates earned by this account as an affiliate')}>
                  <label className="title-bold">{t('Total Rebates')}</label>
                </MDBTooltip>
              </MDBRow>
              <MDBRow>
                <label className="sub-title">$ {total_rebate}</label>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
      </MDBRow>
    </div>
  )
}
