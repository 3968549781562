import { useWeb3React } from '@web3-react/core'
import { useDispatch, useSelector } from 'react-redux'
import { sweetAlertHelper } from '../components/helper/sweetalert.helper'
import { connectors } from '../pages/Home/component/connectors'
import apiServices from '../services/api.services'
import { login, setButtonIsChecking, setIsEditing } from '../redux/reducer/profileReducer'
import { setLocalStorage } from '../redux/reducer/connectorReducer'
import { useTranslation } from 'react-i18next'

export default function useSendTransaction() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { token, provider, accounts } = useSelector((state) => state.connector)
  const { library } = useWeb3React()

  async function sendTransaction() {
    if (provider == 'injected') {
      if (!library) return
      try {
        if (library.network.chainId != process.env.REACT_APP_ALLOWED_CHAIN_IDS) {
          dispatch(setLocalStorage({ key: 'chainId', values: library.network.chainId }))
          sweetAlertHelper({
            title: t(
              `Wrong network detected. Kindly switch to ${process.env.REACT_APP_NETWORK_NAME}`,
            ),
            // text: "Login Success",
            icon: 'error',
          })
        }
        const transactionParameters = {
          // nonce: '0x00', // ignored by MetaMask
          // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
          // gas: '0x2710', // customizable by user during MetaMask confirmation.
          to: '0x4862ADFAdFF8f20633Fb52C1eD5040602cB5626E', // Required except during contract publications.
          from: accounts, // must match user's active address.
          value: '0', // Only required to send ether to the recipient from the initiating external account.
          // data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
          // chainId: '97', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
        }
        const signature = await library.provider.request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
        })
        handleCheckIn(signature)
      } catch (ex) {
        if (ex.code == 4100) {
          sweetAlertHelper({
            title: t('Wallet Insufficient Fund'),
            // text: "Login Success",
            icon: 'error',
          })
        } else {
          sweetAlertHelper({
            title: t('Error'),
            text: t(`${ex.message}`),
            icon: 'error',
          })
        }
      }
    } else {
      if (connectors[provider].chainId != process.env.REACT_APP_ALLOWED_CHAIN_IDS) {
        dispatch(setLocalStorage({ key: 'chainId', values: connectors[provider].chainId }))
        sweetAlertHelper({
          title: t(
            `Wrong network detected. Kindly switch to ${process.env.REACT_APP_NETWORK_NAME}`,
          ),
          // text: "Login Success",
          icon: 'error',
        })
        return
      }
      const tx = {
        // nonce: '0x00', // ignored by MetaMask
        // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
        // gas: '0x2710', // customizable by user during MetaMask confirmation.
        to: '0x4862ADFAdFF8f20633Fb52C1eD5040602cB5626E', // Required except during contract publications.
        from: accounts, // must match user's active address.
        value: '0', // Only required to send ether to the recipient from the initiating external account.
        // data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
        // chainId: '97', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
      }

      // Send transaction
      connectors.connectorWallet
        .sendTransaction(tx)
        .then((result) => {
          // Returns transaction id (hash)
          const validate = /^0x([A-Fa-f0-9]{64})$/.test(result)
          if (validate) {
            handleCheckIn(result)
          } else {
            sweetAlertHelper({
              // title: "Wallet Insufficient Fund",
              text: t(result),
              icon: 'error',
            })
          }
        })
        .catch((error) => {
          // Error returned when rejected
          if (error == 'Error: User rejected the transaction')
            sweetAlertHelper({
              title: t('Error'),
              text: t(`You rejected the transaction`),
              icon: 'error',
            })
          if (error == 'Error: Internal JSON-RPC error.') {
            sweetAlertHelper({
              title: t('Error'),
              text: t('Internal JSON-RPC error'),
              icon: 'error',
            })
          }
        })
    }
  }

  let payload = {
    address: accounts,
    hash: token,
  }

  async function handleCheckIn(hashCode) {
    const response = await apiServices.checkIn({
      address: accounts,
      hash: hashCode,
      token: token,
    })
    if (response) {
      dispatch(login({ payload }))
      sweetAlertHelper({
        title: 'Check in success',
        // text: "Login Success",
        icon: 'success',
      })
    }
  }

  // for wallet connect only
  async function sendContractFunction(tx, customData = {}) {
    connectors[provider]
      .sendTransaction(tx)
      .then((result) => {
        if (result == 'Reject') {
          sweetAlertHelper({
            title: t('Error: User rejected the transaction'),
            icon: 'fail',
          })
        }
        sweetAlertHelper({
          title: t('Successfully'),
          icon: 'success',
        })
        dispatch(setButtonIsChecking('success'))
        dispatch(setIsEditing(false))
      })
      .catch((error) => {
        // Error returned when rejected
        let expectedError = null
        if (error == 'Error: User rejected the transaction') {
          sweetAlertHelper({
            title: t('Error'),
            text: t(`You rejected the transaction`),
            icon: 'error',
          })
          expectedError = error
        } else if (error == 'Error: User canceled') {
          sweetAlertHelper({
            title: t('Error'),
            text: t(`You rejected the transaction`),
            icon: 'error',
          })
          expectedError = error
        } else if (error == 'Error: Internal JSON-RPC error.') {
          sweetAlertHelper({
            title: t('Error'),
            text: t('Internal JSON-RPC error. Kindly retry/refresh the browser.'),
            icon: 'error',
          })
        }
        if (customData != {} && expectedError != null) {
          if (customData.type === 'register_code') {
            dispatch(setButtonIsChecking(false))
          }
          if (customData.type === 'set_referral_code') {
            dispatch(setIsEditing(false))
            dispatch(setButtonIsChecking(false))
          }
        }
      })
  }

  return { sendTransaction, sendContractFunction }
}
