//framework & plugin
import { MDBModal, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit'
import Modal from 'react-modal'
import { RemoveScroll } from 'react-remove-scroll'

const customStyles = {
  content: {
    WebkitTransform2d: 'translate(-50%, -50%)',
  },
}

export default function ModalBox({
  className,
  show,
  screenSize,
  content,
  setShow,
  scrollable,
  reactModal,
}) {
  return (
    <RemoveScroll>
      {!reactModal ? (
        <MDBModal
          staticBackdrop
          className={`full-modal-box-container ${className}`}
          tabIndex="-1"
          show={show}>
          <MDBModalDialog size={`${screenSize}`} scrollable={scrollable ? true : false}>
            <MDBModalContent>{content}</MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      ) : (
        <Modal
          isOpen={show}
          ariaHideApp={false}
          onRequestClose={setShow}
          className={`full-modal-box-container ${className}`}
          style={customStyles}>
          {content}
        </Modal>
      )}
    </RemoveScroll>
  )
}
