import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  chooseWallet,
  registerAccount,
  setConnectStorage,
  setStep,
} from '../redux/reducer/connectorReducer'

export default function useConnect() {
  const dispatch = useDispatch()
  const { isOpened, provider, chainId, accounts } = useSelector((state) => state.connector)
  const {
    active,
    activate: networkActive,
    deactivate,
    account: accountInjected,
    chainId: chainIdInjected,
  } = useWeb3React()

  function connection({ walletType }) {
    if (accounts && process.env.REACT_APP_ALLOWED_CHAIN_IDS.split(',').includes(chainId)) {
      dispatch(setStep(1))
    } else {
      dispatch(
        chooseWallet({
          payload: {
            networkActive: networkActive,
            wallet: walletType,
            isOpened: isOpened,
          },
        }),
      )
        .unwrap()
        .then((res) => {
          //because unable to pass deactivate into reducer
          if (res.type !== 'injected') {
            dispatch(
              registerAccount({ payload: { address: res.accounts, deactivate: deactivate } }),
            )
          }
        })
    }
  }

  useEffect(() => {
    //only web3-react injected
    //choose wallet injected unable to pass the data into reducer
    if (accountInjected && active) {
      dispatch(
        setConnectStorage({
          provider: provider,
          accounts: accountInjected,
          chainId: chainIdInjected,
        }),
      )
      dispatch(registerAccount({ payload: { address: accountInjected, deactivate: deactivate } }))
    }
  }, [accountInjected])

  return connection
}
