import { useDispatch, useSelector } from 'react-redux'
import { connectors } from '../pages/Home/component/connectors'
import { useWeb3React } from '@web3-react/core'
import _ from 'lodash'
import {
  handleDisconnect,
  handleIsOpened,
  setLocalStorage,
  setWalletConnect,
  reverseResolveName,
} from '../redux/reducer/connectorReducer'
import { handleProfileDisconnect } from '../redux/reducer/profileReducer'
import { handleTradeDisconnect } from '../redux/reducer/tradeReducer'
import web3Services from '../services/web3.services'

export default function useEventHandler() {
  const dispatch = useDispatch()
  const { provider } = useSelector((state) => state.connector)
  const { activate: networkActive, deactivate } = useWeb3React()

  function reconnectInjected() {
    networkActive(connectors[provider])
  }

  function walletConnectEventHandler() {
    if (provider == 'null') {
      // deadlock happen when provider null
      // clear all localstorage and refresh
      // window.localStorage.clear()
      web3Services.clearLocalStorage()
      window.location.reload(false)
    } else {
      connectors[provider].on('disconnect', (error, payload) => {
        if (payload) {
          dispatch(handleDisconnect({ provider: provider }))
          dispatch(handleProfileDisconnect())
          dispatch(handleTradeDisconnect())
          _.isEqual(provider, 'connectorWallet') ? connectors[provider].killSession() : deactivate()
        }

        if (error) {
          throw error
        }
      })
      connectors[provider].on('modal_closed', (error, payload) => {
        if (error) {
          throw error
        }
        if (payload) {
          dispatch(handleIsOpened(true))
          // window.localStorage.clear()
          web3Services.clearLocalStorage()
        }
      })
    }
  }
  function injectedEventHandler() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          reverseResolveName(accounts)
        } else {
          dispatch(handleDisconnect({ provider: provider }))
          dispatch(handleProfileDisconnect())
          dispatch(handleTradeDisconnect())
          _.isEqual(provider, 'connectorWallet') ? connectors[provider].killSession() : deactivate()
        }
      })

      window.ethereum.on('networkChanged', (chainId, item) => {
        dispatch(setLocalStorage({ key: 'chainId', values: chainId }))
        if (!process.env.REACT_APP_ALLOWED_CHAIN_IDS.split(',').includes(chainId)) {
          dispatch(setWalletConnect(true))
        }
      })
    }
  }

  return { reconnectInjected, walletConnectEventHandler, injectedEventHandler }
}
