import { useDispatch, useSelector } from 'react-redux'
import { sweetAlertHelper } from '../components/helper/sweetalert.helper'
import DTReferralStorage from '../contracts/DTReferralStorage.json'
import Web3 from 'web3'
import {
  getAffiliatesReferralCode,
  setAffiliatesLoader,
  setBasis,
  setBasisValue,
  setButtonIsChecking,
  setIsEditing,
  setTier,
  setTradeLoader,
  setTradeReferralCode,
} from '../redux/reducer/profileReducer'
import { useEffect } from 'react'
import useSendTransaction from './useSendTransaction'
import useUtils from './useUtils'
import { LoaderGif } from '../assets/images'
import LoaderSvg from '../assets/images/svg/dual-ball-loader.svg'
import web3Services from '../services/web3.services'
import { useTranslation } from 'react-i18next'
export default function useReferralCode() {
  const dispatch = useDispatch()
  const { chainId } = useSelector((state) => state.connector)
  const { t } = useTranslation()
  const { sendContractFunction } = useSendTransaction()
  const { toHex } = useUtils()
  var Contract = require('web3-eth-contract')
  // set provider for all later instances to use
  Contract.setProvider(process.env.REACT_APP_RPC_URL)

  const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS

  const { utils, errors } = require('ethers')
  const { accounts, provider } = useSelector((state) => state.connector)
  const { tier_level } = useSelector((state) => state.profile.referral_detail.affiliates)
  const { basis_point } = useSelector((state) => state.profile)
  const { referral_code } = useSelector((state) => state.profile.referral_detail.trade_referrals)
  const { referral_code: affiliate_referral_code } = useSelector(
    (state) => state.profile.referral_detail.affiliates,
  )
  const smart_contract_issue_notification = web3Services.smartContractIssueNotification()
  async function codeOwners(code, type) {
    if (accounts) {
      dispatch(setButtonIsChecking('checking'))
      const bytesCode = utils.formatBytes32String(code)
      if (window.ethereum) {
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)
        await contract.methods
          .codeOwners(bytesCode)
          .call({ from: accounts })
          .then(async (txHash) => {
            if (txHash == ZERO_ADDRESS) {
              dispatch(setButtonIsChecking('code_empty'))
            } else {
              dispatch(setButtonIsChecking('code_taken'))
            }
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        contract.setProvider(process.env.REACT_APP_RPC_URL)
        await contract.methods
          .codeOwners(bytesCode)
          .call({ from: accounts })
          .then(async (txHash) => {
            if (txHash == ZERO_ADDRESS) {
              dispatch(setButtonIsChecking('code_empty'))
            } else {
              dispatch(setButtonIsChecking('code_taken'))
            }
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      }
    }
  }

  async function getTraderReferralInfo() {
    if (accounts) {
      if (window.ethereum) {
        dispatch(setAffiliatesLoader(true))
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)

        await contract.methods
          .getTraderReferralInfo(accounts)
          .call({ from: accounts })
          .then(async (txHash) => {
            dispatch(setAffiliatesLoader(false))
            getUserCodes()
            // dispatch(getAffiliatesReferralCode(utils.parseBytes32String(txHash)))
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
            // dispatch(setAffiliatesLoader(false))
          })
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        contract.setProvider(process.env.REACT_APP_RPC_URL)
        await contract.methods
          .getTraderReferralInfo(accounts)
          .call({ from: accounts })
          .then(async (txHash) => {
            dispatch(setAffiliatesLoader(false))
            getUserCodes()
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
            // dispatch(setAffiliatesLoader(false))
          })
      }
    }
  }

  async function traderReferralCodes() {
    if (accounts) {
      if (window.ethereum) {
        dispatch(setTradeLoader(true))
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)
        await contract.methods
          .traderReferralCodes(accounts)
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(setTradeLoader(false))
            dispatch(setTradeReferralCode(utils.parseBytes32String(result)))
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
            // dispatch(setTradeLoader(false))
          })
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        contract.setProvider(process.env.REACT_APP_RPC_URL)
        await contract.methods
          .traderReferralCodes(accounts)
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(setTradeLoader(false))
            dispatch(setTradeReferralCode(utils.parseBytes32String(result)))
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
            // dispatch(setTradeLoader(false))
          })
      }
    }
  }

  async function setReferralCode(code) {
    if (accounts) {
      dispatch(setButtonIsChecking('creating'))
      const bytesCode = utils.formatBytes32String(code)
      if (provider === 'injected') {
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)

        await contract.methods
          .setTraderReferralCodeByUser(bytesCode)
          .send({ from: accounts })
          .then(async (result) => {
            if (result == ZERO_ADDRESS) {
              dispatch(setButtonIsChecking('code_not_exist'))
            } else {
              dispatch(setIsEditing(false))
              dispatch(setButtonIsChecking('success'))
              traderReferralCodes()
              sweetAlertHelper({
                title: t('Update Successfully'),
                icon: 'success',
              })
            }
          })
          .catch((ex) => {
            if (ex.code == 4001) {
              sweetAlertHelper({
                text: t('User denied transaction signature.'),
                icon: 'error',
              })
            } else {
              sweetAlertHelper({
                text: t('Transaction has been reverted by the EVM. Kindly retry.'),
                icon: 'error',
              })
            }
            dispatch(setButtonIsChecking(false))
          })
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        const encodedABI = contract.methods.setTraderReferralCodeByUser(bytesCode).encodeABI()

        // contract.once(
        //   'setTraderReferralCodeByUser',
        //   {
        //     _code: bytesCode,
        //   },
        //   function (error, result) {
        //   },
        // )

        const tx = {
          // nonce: '0x00', // ignored by MetaMask
          // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
          gasLimit: toHex(500000),
          gasPrice: toHex(10000000000),
          // gas: '0x2710', // customizable by user during MetaMask confirmation.
          to: process.env.REACT_APP_CONTRACT_ADDRESS, // Required except during contract publications.
          from: accounts, // must match user's active address.
          // value: '0', // Only required to send ether to the recipient from the initiating external account.
          data: encodedABI, // Optional, but used for defining smart contract creation and interaction.
          // chainId: '97', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
        }
        sendContractFunction(tx, { type: 'set_referral_code' })
      }
    }
  }

  async function generateReferralCode(code) {
    if (accounts) {
      const bytesCode = utils.formatBytes32String(code)
      dispatch(setButtonIsChecking('creating'))

      if (provider === 'injected') {
        if (window.ethereum) {
          await window.ethereum.enable()
          window.web3 = new Web3(window.ethereum)
          const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)

          const estimatedGas = await contract.methods
            .registerCode(bytesCode)
            .estimateGas({ from: accounts })

          await contract.methods
            .registerCode(bytesCode)
            .send({ from: accounts, gas: estimatedGas })
            .then(async (txHash) => {
              dispatch(setButtonIsChecking('success'))
              getTraderReferralInfo()
              sweetAlertHelper({
                text: t('Generate Successfully'),
                icon: 'success',
              })
            })
            .catch((ex) => {
              if (ex.code == 4001) {
                sweetAlertHelper({
                  text: t('User denied transaction signature.'),
                  icon: 'error',
                })
              } else {
                sweetAlertHelper({
                  text: t('Transaction has been reverted by the EVM. Kindly retry.'),
                  icon: 'error',
                })
              }
              dispatch(setButtonIsChecking(false))
            })
        }
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        const encodedABI = contract.methods.registerCode(bytesCode).encodeABI()

        const tx = {
          // nonce: '0x00', // ignored by MetaMask
          // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
          gasLimit: toHex(500000),
          gasPrice: toHex(10000000000),
          // gas: '0x2710', // customizable by user during MetaMask confirmation.
          to: process.env.REACT_APP_CONTRACT_ADDRESS, // Required except during contract publications.
          from: accounts, // must match user's active address.
          // value: '0', // Only required to send ether to the recipient from the initiating external account.
          data: encodedABI, // Optional, but used for defining smart contract creation and interaction.
          // chainId: '97', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
        }
        sendContractFunction(tx, { type: 'register_code' })
      }
    }
  }

  async function basisPoints() {
    if (accounts) {
      if (window.ethereum) {
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)

        await contract.methods
          .BASIS_POINTS()
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(setBasis(result))
            referrerTier()
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        contract.setProvider(process.env.REACT_APP_RPC_URL)
        await contract.methods
          .BASIS_POINTS()
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(setBasis(result))
            referrerTier()
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      }
    }
  }

  async function referrerTier() {
    if (accounts) {
      if (window.ethereum) {
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)

        await contract.methods
          .referrerTiers(accounts)
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(setTier(result))
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        contract.setProvider(process.env.REACT_APP_RPC_URL)
        await contract.methods
          .referrerTiers(accounts)
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(setTier(result))
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      }
    }
  }

  async function tier() {
    if (accounts) {
      if (window.ethereum) {
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)
        if (referral_code != '' || affiliate_referral_code != '') {
          await contract.methods
            .tiers(tier_level > 0 ? tier_level - 1 : 0)
            .call({ from: accounts })
            .then(async (result) => {
              if (parseInt(result.totalRebate) > 0) {
                dispatch(
                  setBasisValue({
                    totalRebate: (parseInt(result.totalRebate) / basis_point) * 100,
                    discountShare: (parseInt(result.discountShare) / basis_point) * 100,
                  }),
                )
              }
            })
            .catch((ex) => {
              sweetAlertHelper({
                title: t(smart_contract_issue_notification.title),
                html: t(smart_contract_issue_notification.html),
                icon: 'error',
              })
            })
        }
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        contract.setProvider(process.env.REACT_APP_RPC_URL)
        await contract.methods
          .tiers(tier_level > 0 ? tier_level - 1 : 0)
          .call({ from: accounts })
          .then(async (result) => {
            if (parseInt(result.totalRebate) > 0) {
              dispatch(
                setBasisValue({
                  totalRebate: (parseInt(result.totalRebate) / basis_point) * 100,
                  discountShare: (parseInt(result.discountShare) / basis_point) * 100,
                }),
              )
            }
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      }
    }
  }

  async function getUserCodes() {
    if (accounts) {
      if (window.ethereum) {
        if (provider == 'injected') {
          await window.ethereum.enable()
        }
        window.web3 = new Web3(window.ethereum)
        const contract = new window.web3.eth.Contract(DTReferralStorage, contractAddress)
        await contract.methods
          .userCodes(accounts)
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(getAffiliatesReferralCode(utils.parseBytes32String(result)))
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      } else {
        const contract = new Contract(DTReferralStorage, contractAddress)
        contract.setProvider(process.env.REACT_APP_RPC_URL)
        await contract.methods
          .userCodes(accounts)
          .call({ from: accounts })
          .then(async (result) => {
            dispatch(getAffiliatesReferralCode(utils.parseBytes32String(result)))
          })
          .catch((ex) => {
            sweetAlertHelper({
              title: t(smart_contract_issue_notification.title),
              html: t(smart_contract_issue_notification.html),
              icon: 'error',
            })
          })
      }
    }
  }

  function renderText(status, value, type) {
    if (type == 'affiliates' && t) {
      if (!value) {
        return t('Enter a Code')
      } else if (status == 'code_empty') {
        return t('Enter a Code')
      } else if (status == 'checking') {
        return (
          <div>
            {t('Checking Code')} <img style={{ width: '5%' }} src={LoaderGif} />
          </div>
        )
      } else if (status == 'creating') {
        return (
          <div>
            {t('Processing')}{' '}
            <div className="loader-container" style={{ display: 'inline', minHeight: '0' }}>
              <img src={LoaderSvg} style={{ width: '8%' }} />
            </div>
          </div>
        )
      } else if (status == 'code_taken') {
        return t('Code already taken')
      } else {
        return t('Enter a Code')
      }
    } else if (type == 'trader' && t) {
      if (!value) {
        return t('Enter Referral Code')
      } else if (status == 'code_empty') {
        return t('Referral Code does not exist')
      } else if (status == 'checking') {
        return (
          <div>
            {t('Checking Code')} <img style={{ width: '5%' }} src={LoaderGif} />
          </div>
        )
      } else if (status == 'creating') {
        return (
          <div>
            {t('Processing')} <img style={{ width: '5%' }} src={LoaderGif} />
          </div>
        )
      } else if (status == 'code_taken') {
        return t('Enter Referral Code')
      } else {
        return t('Enter Referral Code')
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_ALLOWED_CHAIN_IDS == chainId) {
      basisPoints()
      referrerTier()
      tier()
    }
  }, [basis_point])

  useEffect(() => {
    if (process.env.REACT_APP_ALLOWED_CHAIN_IDS == chainId) {
      tier()
    }
  }, [referral_code, affiliate_referral_code])

  return {
    renderText,
    basisPoints,
    getTraderReferralInfo,
    generateReferralCode,
    codeOwners,
    setReferralCode,
    traderReferralCodes,
    getUserCodes,
    tier,
  }
}
