import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { convertUtf8ToHex } from '@walletconnect/utils'
import { connectors } from '../pages/Home/component/connectors'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useState } from 'react'
import {
  setLocalStorage,
  setRecoverKeySetting,
  setWalletConnect,
  reverseResolveName,
} from '../redux/reducer/connectorReducer'
import {
  login,
  getReferrals,
  getWalletBalance,
  getFundingList,
  getTradeList,
  getTransferList,
  getRealizedPnL,
  metric,
} from '../redux/reducer/profileReducer'
import useUtils from './useUtils'
import { getAllOrder, getPosition } from '../redux/reducer/tradeReducer'
import { getFavourite } from '../redux/reducer/marketReducer'
import web3Services from '../services/web3.services'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export default function useSignMessage() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { convertSha256 } = useUtils()
  const [signature, setSignature] = useState(null)
  const message = `Welcome to DERI Trade\nDomain: ${process.env.REACT_APP_URL}`
  const [verifyStatus, setVerifyStatus] = useState('')
  const { passphrase } = useSelector((state) => state.profile)
  const { accounts, provider, toggleStatus, token, selectedTrading } = useSelector(
    (state) => state.connector,
  )
  const { library } = useWeb3React()

  let payload = {
    address: accounts,
    hash: signature,
  }

  async function signMessage() {
    setVerifyStatus('pending')
    if (provider == 'injected') {
      try {
        const signature = await library.provider.request({
          method: 'personal_sign',
          params: [message, accounts],
        })
        if (signature) {
          setSignature(signature)
          dispatch(setLocalStorage({ key: 'sign', values: 'true' }))
          dispatch(setLocalStorage({ key: 'token', values: signature }))
          dispatch(setLocalStorage({ key: 'remember', values: toggleStatus }))
          dispatch(reverseResolveName(accounts))
          dispatch(getReferrals({ address: accounts }))
          setVerifyStatus('success')
          dispatch(setWalletConnect(false))
          dispatch(setRecoverKeySetting(false))
        }
      } catch (ex) {
        if (ex) {
          setVerifyStatus('fail')
        }
        if (ex == "TypeError: Cannot read properties of undefined (reading 'provider')") {
          // window.localStorage.clear()
          web3Services.clearLocalStorage()
          window.location.reload(false)
          dispatch(setRecoverKeySetting(false))
        }
      }
    } else {
      const msgParams = [
        convertUtf8ToHex(message), // Required
        accounts, // Required
      ]

      // Sign personal message
      connectors[provider]
        .signPersonalMessage(msgParams)
        .then((result) => {
          // Returns signature.
          setSignature(result)
          dispatch(setLocalStorage({ key: 'token', values: result }))
          dispatch(setLocalStorage({ key: 'sign', values: 'true' }))
          dispatch(setLocalStorage({ key: 'remember', values: toggleStatus }))
          dispatch(reverseResolveName(accounts))
          dispatch(getReferrals({ address: accounts }))
          setVerifyStatus('success')
          dispatch(setWalletConnect(false))
          dispatch(setRecoverKeySetting(false))
        })
        .catch((error) => {
          // Error returned when rejected
          setVerifyStatus('fail')
          if (error == 'Error: Session currently disconnected') {
            // window.localStorage.clear()
            web3Services.clearLocalStorage()
            window.location.reload(false)
            dispatch(setRecoverKeySetting(false))
          }
        })
    }
  }

  useEffect(() => {
    if (signature) {
      if (searchParams.get('referral_code')) {
        payload = {
          address: accounts,
          hash: signature,
          referral_code: searchParams.get('referral_code'),
        }
      }
      dispatch(login({ payload }))
        .unwrap()
        .then((data) => {
          dispatch(getFavourite({}))
          dispatch(
            getWalletBalance({
              payload: { address: accounts },
              data: convertSha256(signature, data.passphrase),
              network: selectedTrading,
            }),
          )
          if (window.location.pathname == '/home') {
            dispatch(
              getAllOrder({
                payload: { address: accounts, status: 'all' },
                data: convertSha256(token, passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getPosition({
                payload: { address: accounts },
                data: convertSha256(token, passphrase),
                network: selectedTrading,
              }),
            )
          }
          if (window.location.pathname == '/trade') {
            dispatch(
              getAllOrder({
                payload: {
                  address: accounts,
                  status: 'all',
                },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getPosition({
                payload: {
                  address: accounts,
                },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getTradeList({
                payload: {
                  address: accounts,
                },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
            // subscribeSocket()
          }
          if (window.location.pathname == '/profile') {
            dispatch(
              metric({
                payload: { address: accounts },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getRealizedPnL({
                payload: { address: accounts },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getFundingList({
                payload: { address: accounts },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getTradeList({
                payload: { address: accounts },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
            dispatch(
              getTransferList({
                payload: { address: accounts },
                data: convertSha256(signature, data.passphrase),
                network: selectedTrading,
              }),
            )
          }
        })
        .catch((ex) => {
          const MySwal = withReactContent(Swal)
          const errors = ex.response ? ex.response.data.errors : null
          let errorMsg = ''
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              //   setFieldError(item, errors[item]);
              errorMsg += errors[item]
            })

            MySwal.fire({
              icon: 'error',
              title: t('Error'),
              text: t(`${errorMsg}`),
              customClass: {
                title: 'custom-sweet-alert-title',
              },
            }).then((result) => {
              window.location.href = process.env.REACT_APP_URL
            })
          } else {
            toast.error(JSON.stringify(ex.message), {
              position: 'top-left',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'dark',
            })
          }
        })
    }
  }, [signature])

  return { signMessage, verifyStatus }
}
