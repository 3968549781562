import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { useRef, useState } from 'react'

//common component
import SegmentedControl from '../../../components/element/segmentedControl'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { selectedMarketType } from '../../../redux/reducer/marketReducer'
import { useTranslation } from 'react-i18next'

export default function HeaderMarket() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { marketType } = useSelector((state) => state.market)
  const typeList = ['ALL', 'Favourite', 'Crypto', 'Equity', 'Forex', 'Metals']
  const [filter, setFilter] = useState('Trending')

  return (
    <MDBCol className="header-market-container">
      <div className="mobile-version">
        <MDBRow className="market-type-container">
          {typeList.map((item, index) => (
            <div
              key={index}
              className={marketType == item ? 'active' : null}
              onClick={() => dispatch(selectedMarketType(item))}>
              <label>{t(item)}</label>
            </div>
          ))}
        </MDBRow>
        <SegmentedControl
          name="group-1"
          callback={(val) => setFilter(val)}
          controlRef={useRef()}
          segments={[
            {
              label: 'Trending',
              value: 'trending',
              ref: useRef(),
            },
            {
              label: 'Gainers',
              value: 'gainers',
              ref: useRef(),
            },
            {
              label: 'Losers',
              value: 'losers',
              ref: useRef(),
            },
            {
              label: 'Volume',
              value: 'volume',
              ref: useRef(),
            },
          ]}
        />
      </div>
      <div className="desktop-version">
        <MDBRow className="market-type-container">
          {typeList.map((item, index) => (
            <div
              key={index}
              className={marketType == item ? 'active' : null}
              onClick={() => {
                dispatch(selectedMarketType(item))
              }}>
              <label style={{ cursor: 'pointer' }}>{t(item)}</label>
            </div>
          ))}
        </MDBRow>
        <MDBRow className="m-0">
          <SegmentedControl
            name="group-1"
            callback={(val) => setFilter(val)}
            controlRef={useRef()}
            segments={[
              {
                label: 'Trending',
                value: 'trending',
                ref: useRef(),
              },
              {
                label: 'Gainers',
                value: 'gainers',
                ref: useRef(),
              },
              {
                label: 'Losers',
                value: 'losers',
                ref: useRef(),
              },
              {
                label: 'Volume',
                value: 'volume',
                ref: useRef(),
              },
            ]}
          />
        </MDBRow>
      </div>
    </MDBCol>
  )
}
