//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedTabPanel } from '../../../redux/reducer/tradeReducer'

//component
import Order from './order'
import Position from './position'
import History from './history'
import { useTranslation } from 'react-i18next'
import { setWalletHistoryModal } from '../../../redux/reducer/profileReducer'
import web3Services from '../../../services/web3.services'

export default function ControlPanel({ responsive }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { selected_tab_panel, position_detail, order_detail, history_detail } = useSelector(
    (state) => state.trade,
  )

  const { wallet_history_trade_listing } = useSelector((state) => state.profile)

  return (
    <MDBCol className="col-12">
      <MDBContainer className="action-panel-container">
        <MDBRow className="panel-tab m-0" style={{ gap: '1em' }}>
          {/* <MDBCol className="text-start"> */}
          <label
            style={{ cursor: 'pointer', width: 'auto' }}
            className={
              selected_tab_panel == 'position' ? `_label --${selected_tab_panel} p-0` : '_label p-0'
            }
            onClick={() => {
              dispatch(setSelectedTabPanel('position'))
            }}>
            {t(`Position`)} ({t(`${position_detail.length}`)})
          </label>
          {/* </MDBCol> */}
          {/* <MDBCol className="text-start col-6 col-md-10"> */}
          <label
            style={{ cursor: 'pointer', width: 'auto' }}
            className={
              selected_tab_panel == 'order' ? `_label --${selected_tab_panel} p-0` : '_label p-0'
            }
            onClick={() => {
              dispatch(setSelectedTabPanel('order'))
            }}>
            {t(`Order`)} ({t(`${order_detail.length}`)})
          </label>
          {web3Services.isSignRequire() && (
            <label
              style={{ cursor: 'pointer', width: 'auto' }}
              className={selected_tab_panel == 'history' ? `_label --order p-0` : '_label p-0'}
              onClick={() => {
                // dispatch(setSelectedTabPanel('history'))
                dispatch(setWalletHistoryModal(true))
              }}>
              {t(`History`)}
            </label>
          )}
          {/* </MDBCol> */}
        </MDBRow>
        <div className="line-container --dark"></div>
        {selected_tab_panel == 'position' &&
          (position_detail.length > 0 ? (
            position_detail.map((item, index) => (
              <MDBCol key={index} className="position-col">
                <Position data={item} responsive={responsive} />
                {index == position_detail.length - 1 ? (
                  ''
                ) : (
                  <div className="line-container --ligth"></div>
                )}
              </MDBCol>
            ))
          ) : (
            <div className="text-center pt-5" style={{ color: '#EDEBEB' }}>
              {t(`You have no position`)}
            </div>
          ))}
        {selected_tab_panel == 'order' &&
          (order_detail.length > 0 ? (
            order_detail.map((item, index) => (
              <MDBCol key={index} className="position-col">
                <Order data={item} index={index} />
                {index == order_detail.length - 1 ? (
                  ''
                ) : (
                  <div className="line-container --ligth"></div>
                )}
              </MDBCol>
            ))
          ) : (
            <div className="text-center pt-5" style={{ color: '#EDEBEB' }}>
              {t(`You have no orders`)}
            </div>
          ))}
        {selected_tab_panel == 'history' &&
          (wallet_history_trade_listing.length > 0 ? (
            wallet_history_trade_listing.map((item, index) => (
              <MDBCol key={index} className="position-col">
                <History data={item} />
              </MDBCol>
            ))
          ) : (
            <div className="text-center pt-5" style={{ color: '#EDEBEB' }}>
              {t(`You have no history`)}
            </div>
          ))}
      </MDBContainer>
      {/* )} */}
    </MDBCol>
  )
}
