import './App.scss'
import './App.desktop.scss'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'swiper/css/bundle'

//pages
import Home from './pages/Home'
import Market from './pages/Market'
import Profile from './pages/Profile'
import Trade from './pages/Trade'

import { useEffect } from 'react'
import AdminPortal from './pages/AdminPortal'
import usePythNetwork from './hook/usePythNetwork'
import useSocket from './hook/useSocket'
import Share from './pages/Share'
import Loader from './components/modal/loader'


function App() {
  if(!localStorage.getItem("symbol")){
    localStorage.setItem("symbol","BNB/USD")
    localStorage.setItem("decimal","4")
  }
  const { getSocket } = useSocket()

  const { requestPriceFeed } = usePythNetwork()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  useEffect(() => {
    requestPriceFeed()
    getSocket()
  }, [])

  return (
    <BrowserRouter>
      <Loader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/market" element={<Market />} />
        <Route path="/trade" element={<Trade />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin_portal/temp/check_wallet" element={<AdminPortal />} />
        <Route path="/position" element={<Share />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
