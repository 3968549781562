import io from 'socket.io-client'

let ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT

// const socket = io(ENDPOINT, {
//   secure: true,
//   transports: ["websocket"],
//   // forceNew: true,
//   reconnection: false,
//   jsonp: true,
// });
const socket = new WebSocket(ENDPOINT) // connect now

function subscribeSocket() {
  return socket
}

export default {
  subscribeSocket,
}
