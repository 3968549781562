import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import _ from 'lodash'

//common component
import Header from '../../components/header/header-four'
import Navbar from '../../components/navbar/navbar-one'
import useWindowDimensions from '../../components/common/window-dimension'
import WalletConnectUI from './../ConnectWallet'

//component
import HeaderMarket from './component/header.market'
import MarketListing from './component/listing.market'

//redux
import { useDispatch, useSelector } from 'react-redux'

//hook
import HeaderDesktop from '../../components/header/header-desktop'
import { getMarketInfo } from '../../redux/reducer/marketReducer'
import useUtils from '../../hook/useUtils'

export default function Market({ isLoaded }) {
  const dispatch = useDispatch()
  const { convertSha256 } = useUtils()
  const { walletConnect, selectedTrading, passphrase, token, accounts } = useSelector(
    (state) => state.connector,
  )
  const { width } = useWindowDimensions()
  const [responsive, setResponsive] = useState('mobile')

  useEffect(() => {
    if (width >= '992') {
      setResponsive('desktop')
    } else {
      setResponsive('mobile')
    }
  }, [width])

  useEffect(() => {
    dispatch(
      getMarketInfo({
        data: convertSha256(token, passphrase),
        network: selectedTrading,
      }),
    )
  }, [accounts, token, passphrase])

  return (
    <MDBContainer className="market-container" breakpoint="fluid">
      {responsive == 'mobile' ? (
        <div>
          <Header />
          <Navbar />
          <MDBContainer className="p-0">
            <HeaderMarket responsive={responsive} />
          </MDBContainer>
          <MarketListing responsive={responsive} />
        </div>
      ) : (
        <>
          <HeaderDesktop />
          <div className="line"></div>
          <MDBRow className="desktop-version">
            <MDBCol className="col-2 sidebar-container"></MDBCol>
            <MDBCol className="col-10 content-section">
              <MDBContainer>
                {/* <Markets /> */}
                <HeaderMarket responsive={responsive} />
                <MarketListing responsive={responsive} />
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </>
      )}
      {walletConnect && <WalletConnectUI />}
    </MDBContainer>
  )
}
