import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'

import { connectors } from '../../Home/component/connectors'
import { useWeb3React } from '@web3-react/core'

import { Icon } from '@iconify/react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { handleDisconnect, setWalletConnect } from '../../../redux/reducer/connectorReducer'
import { handleProfileDisconnect } from '../../../redux/reducer/profileReducer'
import { useEffect } from 'react'
import useAddToNetwork from '../../../hook/useAddToNetwork'
import web3Services from '../../../services/web3.services'
import { useTranslation } from 'react-i18next'

export default function WrongNetwork() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { deactivate } = useWeb3React()
  const { provider } = useSelector((state) => state.connector)
  const { switchNetwork } = useAddToNetwork()

  useEffect(() => {
    switchNetwork()
  }, [])

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol size="8">
          <label className="header-title">{t('Wrong Network')}</label>
        </MDBCol>
        <MDBCol size="4" className="disconnect-btn-col">
          <button
            className="disconnect-btn"
            onClick={() => {
              dispatch(setWalletConnect(false))
              // window.localStorage.clear()
              web3Services.clearLocalStorage()
              dispatch(handleDisconnect({ provider: provider }))
              dispatch(handleProfileDisconnect())
              _.isEqual(provider, 'connectorWallet')
                ? connectors[provider].killSession()
                : deactivate()
            }}>
            {t('Disconnect')}{' '}
            <Icon
              icon="fluent-emoji-high-contrast:cross-mark-button"
              color="#6f6e84"
              style={{ marginLeft: '0.2rem' }}
            />
          </button>
        </MDBCol>
      </MDBRow>
      <MDBRow className="header-desc">
        <label>
          {t('To complete Deri Trade onboarding, set your wallet network to')}{' '}
          {process.env.REACT_APP_CHAIN_LABEL} {t(`${process.env.REACT_APP_NETWORK}`)} .
        </label>
      </MDBRow>
    </MDBContainer>
  )
}
