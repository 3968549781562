//lib
import { MDBCol, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  getTransferList,
  getWalletBalance,
  setWithdrawModal,
  withdraw,
} from '../../../redux/reducer/profileReducer'

//component
import ModalBox from '../../../components/modal/full.modal.box'
import useTradeOrder from '../../../hook/useTradeOrder'
import { toast } from 'react-toastify'
import useUtils from '../../../hook/useUtils'
import { useTranslation } from 'react-i18next'

export default function Withdraw({ show }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const token = localStorage.getItem('token')
  const passphrase = localStorage.getItem('passphrase')
  const { convertSha256 } = useUtils()
  const { countDecimals } = useTradeOrder()
  const exceptThisSymbols = ['e', 'E', '+', '-', '.']
  const { accounts, selectedTrading } = useSelector((state) => state.connector)
  const { walletBalance, withdraw_submit_loading } = useSelector((state) => state.profile)
  const data = {
    withdraw: '',
  }

  const balance = Math.floor(walletBalance * Math.pow(10, 2)) / Math.pow(10, 2)

  const useSchema = Yup.object({
    withdraw: Yup.number()
      .typeError('Wrong Amount')
      .moreThan(0, 'Wrong Amount')
      .required('Wrong Amount')
      .max(walletBalance, 'Insufficient fund'),
  })

  const handleSubmit = (values) => {
    dispatch(
      withdraw({
        payload: { address: accounts, amount: values.withdraw },
        data: convertSha256(token, passphrase),
        network: selectedTrading,
      }),
    )
      .unwrap()
      .then((data) => {
        dispatch(
          getWalletBalance({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        dispatch(
          getTransferList({
            payload: { address: accounts },
            data: convertSha256(token, passphrase),
            network: selectedTrading,
          }),
        )
        toast(
          <div>
            <MDBRow className="m-0">
              <MDBCol className="text-start">
                <label className="_label">{t(`Withdrawal success`)}</label>
              </MDBCol>
            </MDBRow>
          </div>,
          {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          },
        )
      })
      .catch((ex) => {
        const errors = ex.response ? ex.response.data.errors : []
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            toast.error(t(errors[item]), {
              position: 'top-left',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'dark',
            })
          })
        } else {
          toast.error(t(JSON.stringify(ex.message)), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          })
        }
      })
  }

  return (
    <ModalBox
      show={show}
      className={'profile-modal-container withdraw'}
      setShow={() => dispatch(setWithdrawModal(false))}
      reactModal={true}
      content={
        <MDBModalBody className="m-0">
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={data}
            validationSchema={useSchema}
            onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
              if (passphrase) {
                handleSubmit(values)
                resetForm({ values: '' })
              }
            }}>
            {({ errors, setFieldValue, values, isValid, isSubmitting }) => (
              <Form className="modal-form">
                <MDBRow className="profile-modal-row">
                  <MDBCol>
                    <label>/ {t(`Withdraw`)}</label>
                  </MDBCol>
                  <MDBCol className="text-end">
                    <div
                      style={{ cursor: 'pointer' }}
                      className="round-btn"
                      onClick={() => dispatch(setWithdrawModal(false))}>
                      <Icon icon={'radix-icons:cross-2'} color="#edebeb" width="20" height="25" />
                    </div>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="profile-modal-row m-0">
                  <MDBCol className="col-2 p-0">
                    <button
                      className="wallet-options  d-flex align-items-center justify-content-center"
                      type="button">
                      USDT
                    </button>
                  </MDBCol>
                  <MDBCol>
                    <input
                      type="number"
                      step={'any'}
                      value={String(values.withdraw)}
                      disabled={false}
                      onBlur={() => {
                        const value = parseFloat(values.withdraw)
                        setFieldValue('withdraw', Number(value))
                      }}
                      onChange={(e) => {
                        if (countDecimals(e.target.value) <= 2) {
                          setFieldValue('withdraw', Number(e.target.value))
                        }
                        setFieldValue('withdraw', Number(e.target.value))
                      }}
                      onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="profile-modal-row">
                  <label>
                    ${balance.toLocaleString()} {t(`available`)}
                  </label>
                </MDBRow>
                <MDBRow className="profile-modal-row">
                  <MDBCol>
                    <label className="w-100">{t(`Max Withdraw`)}</label>
                    {/* <label className="w-100">Deri Wallet</label> */}
                  </MDBCol>
                  <MDBCol>
                    <label className="w-100 text-end">${balance.toLocaleString()}</label>
                    {/* <label className="w-100 text-end">-</label> */}
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow className="profile-modal-row">
                  <label className="text-white">Notics</label>
                  <label>
                    Funds withdrawal from your account to your wallet will remain on the mainnet
                  </label>
                </MDBRow> */}
                <MDBRow className="profile-modal-row">
                  <MDBCol>
                    <label className="w-100">
                      {t(`Fee`)}
                      <Icon
                        icon="mdi:question-mark-circle-outline"
                        color="#707070"
                        width="18"
                        height="18"
                      />
                    </label>
                    <label className="w-100">{t(`Total`)}</label>
                  </MDBCol>
                  <MDBCol>
                    <label className="w-100 text-end">-</label>
                    <label className="w-100 text-end">
                      {values.withdraw ? values.withdraw.toLocaleString() : '-'}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow style={{ paddingTop: '1em' }}>
                  <label className="_label --status">
                    {Object.values(errors)[0] ? t(`${Object.values(errors)[0]}`) : ''}
                  </label>
                </MDBRow>
                <MDBRow className="profile-modal-row m-0 pt-3 pb-3">
                  <MDBCol className="col-9 p-1">
                    <button
                      className="enter-amount-button"
                      type="submit"
                      disabled={withdraw_submit_loading ? true : errors.withdraw ? true : false}>
                      {withdraw_submit_loading ? t(`Waiting..`) : t(`Submit`)}
                    </button>
                  </MDBCol>
                  <MDBCol className="col-3 p-1">
                    <button
                      className="max-button"
                      type="button"
                      onClick={() => {
                        setFieldValue('withdraw', walletBalance)
                      }}>
                      {t(`Max`)}
                    </button>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="profile-modal-row text-center">
                  <label style={{ fontSize: '12px' }}>
                    {t(`for Deri Trade Demo Trading purposes only.`)}{' '}
                  </label>
                </MDBRow>
              </Form>
            )}
          </Formik>
        </MDBModalBody>
      }
    />
  )
}
