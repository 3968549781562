import { useState, useRef } from 'react'
import { useEffect } from 'react'
//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'
import useReferralCode from '../../../hook/useReferralCode'

//component
import SegmentedControl from '../../../components/element/segmentedControl'
import AffiliatesReferrals from './affiliates.referrals'
import TradeReferrals from './trade.referrals'
import RebateHistory from './rebate.history.mobile'

//service
import web3Services from '../../../services/web3.services'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setRecoverKeySetting } from '../../../redux/reducer/connectorReducer'
import { useTranslation } from 'react-i18next'

export default function Referrals() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { chainId } = useSelector((state) => state.connector)
  const [type, setType] = useState('trader')
  const { traderReferralCodes, getTraderReferralInfo, basisPoints } = useReferralCode()
  const { width } = useWindowDimensions()
  const [responsive, setResponsive] = useState('mobile')
  const { modalRebateHistory } = useSelector((state) => state.profile)
  const { referral_code } = useSelector((state) => state.profile.referral_detail.trade_referrals)
  const { referral_code: userCode } = useSelector(
    (state) => state.profile.referral_detail.affiliates,
  )

  const { accounts, token } = useSelector((state) => state.connector)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const retrieveBasisPoint = async () => {
    basisPoints()
  }
  useEffect(() => {
    if (width >= '992') {
      setResponsive('desktop')
    } else {
      setResponsive('mobile')
    }
  }, [width])

  useEffect(() => {
    if (process.env.REACT_APP_ALLOWED_CHAIN_IDS == chainId) {
      retrieveBasisPoint()
      traderReferralCodes()
      getTraderReferralInfo()
    }
  }, [accounts, token])
  return (
    <MDBCol className="referrals-v2-container">
      <MDBRow
        className={
          responsive == 'mobile' ? 'header-container m-0' : 'header-container text-start m-0'
        }>
        <label className={responsive == 'mobile' ? 'p-0' : ''}>{t('Referral Program')}</label>
        <span className={responsive == 'mobile' ? 'p-0' : ''}>
          {t(
            'Get fee discounts and earn rebates through the referral program. For more information, please read the referral program details.',
          )}
        </span>
      </MDBRow>
      <MDBRow className="referrals-tab">
        <SegmentedControl
          name="group-1"
          callback={(val) => {
            setType(val)
            dispatch(setRecoverKeySetting(false))
          }}
          controlRef={useRef()}
          segments={[
            {
              label: 'Traders',
              value: 'trader',
              ref: useRef(),
            },
            {
              label: 'Affiliates',
              value: 'affiliates',
              ref: useRef(),
            },
          ]}
        />
      </MDBRow>
      <MDBContainer className="referrals-box">
        {type == 'trader' ? (
          <TradeReferrals
            responsive={responsive}
            submitSuccess={submitSuccess}
            setSubmitSuccess={setSubmitSuccess}
          />
        ) : (
          <AffiliatesReferrals responsive={responsive} />
        )}
      </MDBContainer>
      <>
        {responsive == 'desktop' &&
          web3Services.isSignRequire() &&
          referral_code &&
          type == 'trader' && (
            <MDBContainer className="referrals-box">
              <MDBRow className="rebate-title-bold m-0">
                {/* <label>Rebate History</label> */}
                <label>{t('No rebates distribution history yet')}</label>
              </MDBRow>
              <MDBRow className="m-0 rebate-header">
                <MDBCol className="rebate-title">
                  <label>{t('Date')}</label>
                </MDBCol>
                <MDBCol className="rebate-title">
                  <label>{t('Transaction')}</label>
                </MDBCol>
                <MDBCol className="rebate-title">
                  <label>{t('Amount')}</label>
                </MDBCol>
              </MDBRow>
              {/* {rebateListing.length > 0 &&
              rebateListing.slice(0, 3).map((listing, index) => (
                <MDBRow className="m-0">
                  <MDBCol className="value">
                    <label>{listing.date}</label>
                  </MDBCol>
                  <MDBCol className="referral-code">
                    <label>{listing.transaction}</label>
                  </MDBCol>
                  <MDBCol className="value">
                    <label>{listing.amount}</label>
                  </MDBCol>
                </MDBRow>
              ))} */}
              {/* <MDBRow className="referrals-btn">
              <button className="more-history-btn">More History</button>
            </MDBRow> */}
            </MDBContainer>
          )}
        {responsive == 'desktop' &&
          userCode &&
          web3Services.isSignRequire() &&
          type == 'affiliates' && (
            <MDBContainer className="referrals-box">
              <MDBRow className="rebate-title-bold m-0">
                {/* <label>Rebate History</label> */}
                <label>{t('No rebates distribution history yet')}</label>
              </MDBRow>
              <MDBRow className="m-0 rebate-header">
                <MDBCol className="rebate-title">
                  <label>{t('Date')}</label>
                </MDBCol>
                <MDBCol className="rebate-title">
                  <label>{t('Transaction')}</label>
                </MDBCol>
                <MDBCol className="rebate-title">
                  <label>{t('Amount')}</label>
                </MDBCol>
              </MDBRow>
              {/* {rebateListing.length > 0 &&
              rebateListing.slice(0, 3).map((listing, index) => (
                <MDBRow className="m-0">
                  <MDBCol className="value">
                    <label>{listing.date}</label>
                  </MDBCol>
                  <MDBCol className="referral-code">
                    <label>{listing.transaction}</label>
                  </MDBCol>
                  <MDBCol className="value">
                    <label>{listing.amount}</label>
                  </MDBCol>
                </MDBRow>
              ))} */}
              {/* <MDBRow className="referrals-btn">
              <button className="more-history-btn">More History</button>
            </MDBRow> */}
            </MDBContainer>
          )}
      </>
      {modalRebateHistory && <RebateHistory show={modalRebateHistory} type={type} />}
    </MDBCol>
  )
}
